import { Divider } from 'antd'
import { isEmpty } from 'lodash'
import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { finalize, from, takeUntil } from 'rxjs'
import { HiringManagerApi } from 'src/api'
import { useUnsubscribe } from 'src/hooks'
import { IUserModel } from 'src/interfaces'
import { BreadcrumbService } from 'src/services'
import { GlobalAction } from 'src/store'
import { NotifyUtils } from 'src/utils'
import { EPaths } from '../../routes.path'
import { Campaigns } from '../campaigns'
import { HiringManagerGeneral } from './general'
import { HiringManagerVideos } from './videos'

const PAGE_TITLE = 'HiringManagers'

export const HiringManagerDetail: FC = () => {
  const unsubscribe$ = useUnsubscribe()
  const { id } = useParams<{ id: string }>()
  const [hiringManager, setHiringManager] = useState<IUserModel>()

  /**
   * breadcrumb
   */
  useEffect(() => {
    BreadcrumbService.items = [{
      route: EPaths.HIRING_MANAGER_LIST,
      label: PAGE_TITLE
    },
    {
      route: [EPaths.HIRING_MANAGER_DETAIL, { id }],
      label: 'Detail'
    }
    ]
    return () => {
      BreadcrumbService.items = []
    }
  }, [id])

  useEffect(() => {
    if (!id) {
      return
    }

    GlobalAction.setLoading(true)

    from(HiringManagerApi.getDetail(id))
      .pipe(takeUntil(unsubscribe$),
        finalize(() => GlobalAction.setLoading(false)))
      .subscribe({
        next: ({ data }) => setHiringManager(data),
        error: NotifyUtils.handleAxiosError
      })
  }, [id, unsubscribe$])

  return (
    <section className="fx fx-column fx-extend gap-1">
      {!isEmpty(hiringManager) && (
        <>
          <HiringManagerGeneral
            hiringManager={hiringManager}
          />
          <Divider className="my-2" style={{ borderColor: 'black' }}/>
        </>
      )}
      {!isEmpty(hiringManager?.pfv) && (
        <>
          <HiringManagerVideos hiringManager={hiringManager}/>
          <Divider className="my-2" style={{ borderColor: 'black' }}/>
        </>
      )}

      <Campaigns/>

    </section>
  )
}
