import { Modal, Table } from 'antd'
import { FC, useCallback, useEffect, useState } from 'react'
import { parseBoolean } from 'src/utils'

interface IProps {
  file?: File
  open: boolean
  onCancel: () => any
  onSave: (data: { id: number; verifiedEmail: string }[]) => any
  loading?: boolean
}

export const ModalTableToImport: FC<IProps> = ({ file, open, onCancel, onSave, loading }) => {
  const [data, setData] = useState<{ id: number; verifiedEmail: string }[]>([])

  const handleFile = useCallback(async (file: File) => {
    const fileUrl = URL.createObjectURL(file)
    const response = await fetch(fileUrl)
    const text = await response.text()
    const lines = text.split('\n')

    const _data = lines.map((line) => line.split(','))
    const header = _data[0]
    const data = _data.slice(1)
    const indexVerified = header.findIndex(h => h === 'verifiedEmail')
    const indexId = header.findIndex(h => h === 'id')
    const connectedPhil = header.findIndex(h => h === 'connectedPhil')
    const connectedDaniel = header.findIndex(h => h === 'connectedDaniel')
    const connectedAshley = header.findIndex(h => h === 'connectedAshley')
    const connectedCecilia = header.findIndex(h => h === 'connectedCecilia')

    const importedData = data.map(row => ({
      id: +row[indexId],
      verifiedEmail: row[indexVerified],
      connectedPhil: parseBoolean(row[connectedPhil]),
      connectedDaniel: parseBoolean(row[connectedDaniel]),
      connectedAshley: parseBoolean(row[connectedAshley]),
      connectedCecilia: parseBoolean(row[connectedCecilia])
    }))

    setData(importedData)
  }, [])

  useEffect(() => {
    if (open && file) {
      handleFile(file)
    }
  }, [file, handleFile, open])

  return (
    <Modal
      width={800}
      confirmLoading={loading}
      closable={!loading}
      open={open}
      title="Imported data"
      onCancel={!loading ? onCancel : undefined}
      onOk={() => onSave(data)}
    >
      <Table
        dataSource={data}
        columns={[
          {
            title: 'Id',
            dataIndex: 'id',
            key: 'id'
          },
          {
            title: 'Verified Email',
            dataIndex: 'verifiedEmail',
            key: 'verifiedEmail'
          },
          {
            title: 'Connected Phil',
            dataIndex: 'connectedPhil',
            key: 'connectedPhil',
            render: (value) => value ? 'Yes' : 'No'
          },
          {
            title: 'Connected Daniel',
            dataIndex: 'connectedDaniel',
            key: 'connectedDaniel',
            render: (value) => value ? 'Yes' : 'No'
          },
          {
            title: 'Connected Ashley',
            dataIndex: 'connectedAshley',
            key: 'connectedAshley',
            render: (value) => value ? 'Yes' : 'No'
          },
          {
            title: 'Connected Cecilia',
            dataIndex: 'connectedCecilia',
            key: 'connectedCecilia',
            render: (value) => value ? 'Yes' : 'No'
          }
        ]}
      />
    </Modal>
  )
}
