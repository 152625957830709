import { Button, Input, Modal } from 'antd'
import { ComponentProps, FC, SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { CampaignApi } from 'src/api'
import { useDidMountEffect, useUnsubscribe, useValidation } from 'src/hooks'
import { ICandidateModel } from 'src/interfaces'
import { EventUtils, NotifyUtils } from 'src/utils'
import * as Yup from 'yup'

const schema = Yup.object().shape({
  campaignId: Yup.number().integer().positive().required('Campaign ID is required')
})

export const DialogAddToCampaign: FC<Omit<
  ComponentProps<typeof Modal>,
  'onOk' | 'afterClose'
> & {
  videoId: number
  // onOk?: () => any
  afterClose?: () => any
  btnProps?: ComponentProps<typeof Button>
}> = ({
  btnProps,
  ...props
}) => {
  const unsubscribe$ = useUnsubscribe()
  const [loading, setLoading] = useState(false)
  const [invisible, setInvisible] = useState<boolean | ICandidateModel>(true)
  const [formData, setFormData] = useState<{ campaignId?: number }>({})
  const { errors, validate } = useValidation({
    data: formData,
    schema
  })

  useEffect(() => {
    setInvisible(!props.open)
  }, [props.open])

  useDidMountEffect(() => {
    if (invisible) {
      setFormData({})
      props.afterClose?.()
    }
  }, [invisible])

  const submit = useCallback(async (e: SyntheticEvent) => {
    EventUtils.preventDefault(e)

    const { isValid } = await validate()

    if (!formData.campaignId || !isValid) {
      return
    }

    const promise = CampaignApi.addSubmission(
      formData.campaignId,
      { videoId: props.videoId }
    )
    setLoading(true)
    from(promise)
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          NotifyUtils.handleAxiosError(error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(() => {
        NotifyUtils.success({ message: 'Add submission successfully' })
        setInvisible(true)
      })
  }, [validate, formData, props.videoId, unsubscribe$])

  return (
    <>
      <Button
        type="primary"
        {...btnProps}
        onClick={() => setInvisible(false)}
      >
        {props.children || 'Add to campaign'}
      </Button>

      <Modal
        title={props.title || 'Add to campaign as submission'}
        open={!invisible}
        closable={!loading} // display X icon
        keyboard={false} // disable close on press ESC
        maskClosable={false} // disable close on click outside
        okText="Add to campaign"
        cancelText="Close"
        okButtonProps={{ disabled: loading }}
        cancelButtonProps={{ disabled: loading }}
        onOk={submit}
        onCancel={() => setInvisible(true)}
      >
        {!invisible && (
          <form onSubmit={submit} className="fx fx-column fx-extend gap-2">
            <Input
              type="number"
              step={1}
              min={0}
              required
              readOnly={loading}
              name="campaignId"
              placeholder="Campaign ID"
              value={formData.campaignId || ''}
              status={errors.hasError('campaignId') ? 'error' : undefined}
              onChange={(e) => setFormData((prev) => ({
                ...prev,
                campaignId: Number(e.target.value)
              }))}
            />
          </form>
        )}
      </Modal>
    </>
  )
}
