import { CloseOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Card, Form, Input, Result, Timeline, Tooltip } from 'antd'
import { AxiosError } from 'axios'
import moment from 'moment'
import { FieldData } from 'rc-field-form/lib/interface'
import { FC, useCallback, useEffect, useState } from 'react'
import sanitizeHtml from 'sanitize-html'
import { CandidateApi } from 'src/api'
import { Editor, ModalFullscreen, RangePicker } from 'src/components'
import { ILinkedinEducationModel } from 'src/interfaces'
import { NotifyUtils } from 'src/utils'
import { v4 } from 'uuid'

interface ICandidateEducationProps {
  candidateId?: number | string
  education?: ILinkedinEducationModel[]
  onOk?: () => void
  loading?: boolean
}

const defaultEducation = () => ({
  key: v4(),
  school: '',
  degree: ''
})

export const CandidateEducation: FC<ICandidateEducationProps> = ({ loading: parentLoading, onOk, candidateId, education = [] }) => {
  const [openModalEducation, setOpenModalEducation] = useState(false)
  const [loadingModal, setLoadingModal] = useState(false)
  const [newEducation, setNewEducation] = useState<(Partial<ILinkedinEducationModel> & { key?: string })[]>([])

  useEffect(() => {
    if (education?.length) {
      setNewEducation(education.map(edu => ({
        ...edu,
        key: v4()
      })))

      return () => {
        setNewEducation([defaultEducation()])
      }
    }
  }, [education])

  const handleDeleteEducation = (index: number) => {
    const education = [...newEducation]
    education.splice(index, 1)
    setNewEducation(education)
  }

  const handleOnFieldsChange = (allFields: FieldData[], index: number) => {
    console.log(allFields)
    const education = [...newEducation]
    education[index] = {
      ...education[index],
      ...allFields.reduce((acc, { name, value }) => {
        if ((name as string[])[0] === 'duration') {
          const [start, end] = Array.isArray(value)
            ? value
            : []
          acc.startDate = start?.toDate()
          acc.endDate = end?.toDate()
          return acc
        }

        acc[(name as (keyof ILinkedinEducationModel)[])[0]] = value
        return acc
      }, {} as Partial<ILinkedinEducationModel>)
    }

    setNewEducation(education)
  }

  const handleSubmit = useCallback(async () => {
    try {
      if (!candidateId) return
      setLoadingModal(true)

      await CandidateApi.addEducation(candidateId, {
        education: newEducation
      })

      onOk?.()
      setOpenModalEducation(false)
      NotifyUtils.success({ message: 'Add education successfully' })
    } catch (error) {
      NotifyUtils.handleAxiosError(error as AxiosError)
    } finally {
      setLoadingModal(false)
    }
  }, [setLoadingModal, candidateId, newEducation, onOk, setOpenModalEducation])

  return (
    <div>
      <Card
        title="Education"
        bordered={false}
        loading={parentLoading}
        extra={Boolean(education.length) && (
          <Tooltip title="Modify education">
            <Button
              type="link"
              disabled={parentLoading}
              onClick={() => setOpenModalEducation(true)}
            >
              Modify
            </Button>
          </Tooltip>
        )}
      >
        {education.length
          ? (
            <Timeline mode="left">
              {education.map((edu, index) => (
                <Timeline.Item key={index} label={edu.duration || ' '}>
                  <div style={{ fontWeight: 'bold' }}>{edu.school}</div>
                  {Boolean(edu.degree) && (<div>Degree: {edu.degree}</div>)}
                  {Boolean(edu.duration) && (<div>{edu.duration}</div>)}
                  {Boolean(edu.grade) && (<div>Grade: {edu.grade}</div>)}
                  {Boolean(edu.activities) && (<div>Activities: <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(edu.activities || '') }}/></div>)}
                  <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(edu.description || '') }}/>
                </Timeline.Item>
              ))}
            </Timeline>
          )
          : (
            <Result
              title="There is no education data"
              extra={(
                <Button type="primary" onClick={() => setOpenModalEducation(true)}>
                  Add Education
                </Button>
              )}
            />
          )}
      </Card>

      <ModalFullscreen
        centered
        title="Add new education"
        open={openModalEducation}
        onOk={handleSubmit}
        onCancel={() => setOpenModalEducation(false)}
        confirmLoading={loadingModal}
      >
        <div style={{ position: 'absolute', bottom: 78, right: 24, zIndex: 2 }}>
          <Tooltip title="Modify education">
            <Button
              type="primary"
              shape="circle"
              icon={<PlusOutlined/>}
              onClick={() => setNewEducation([...newEducation, defaultEducation()])}
            />
          </Tooltip>
        </div>

        {newEducation.map((edu, index) => (
          <Card
            key={`card.${edu.key}`}
            style={{ marginBottom: 10, position: 'relative' }}
          >
            {newEducation.length !== 1 && (
              <Button
                style={{ position: 'absolute', right: 0, top: 0 }}
                icon={<CloseOutlined/>}
                type="text"
                onClick={() => handleDeleteEducation(index)}
              />
            )}

            <Form
              key={index}
              labelCol={{
                sm: 6,
                md: 5,
                lg: 4
              }}
              name="basic"
              autoComplete="off"
              onFieldsChange={(_, allFields) => handleOnFieldsChange(allFields, index)}
              initialValues={{
                school: edu.school,
                degree: edu.degree,
                duration: [
                  edu.startDate ? moment(edu.startDate) : null,
                  edu.endDate ? moment(edu.endDate) : null
                ],
                grade: edu.grade,
                activities: edu.activities,
                description: edu.description
              }}
            >
              <Form.Item label="School" name="school">
                <Input/>
              </Form.Item>

              <Form.Item label="Degree" name="degree">
                <Input/>
              </Form.Item>

              <Form.Item label="Duration" name="duration">
                <RangePicker picker="month" allowEmpty={[true, true]}/>
              </Form.Item>

              <Form.Item label="Grade" name="grade">
                <Input/>
              </Form.Item>

              <Form.Item label="Activities" name="activities">
                <Editor id={`activity_${index}`} initialValue={edu.activities || ''} onChange={() => null}/>
              </Form.Item>

              <Form.Item label="Description" name="description">
                <Editor id={`description_${index}`} initialValue={edu.description || ''} onChange={() => null}/>
              </Form.Item>
            </Form>
          </Card>
        ))}
      </ModalFullscreen>
    </div>
  )
}
