import clsx from 'clsx'
import { FC, HTMLAttributes, useState } from 'react'
import { StyleUtils } from 'src/utils'
import { ModalMedia } from '../modal-media'
import Style from './style.module.scss'

interface IProps extends Omit<HTMLAttributes<HTMLDivElement>, 'value' | 'onChange' | 'onClick'> {
  value?: string
  onChange?: (value: string) => any
}

export const ImagePicker: FC<IProps> = ({
  value,
  onChange,
  ...props
}) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <div
        {...props}
        className={clsx(Style.imgPicker, props.className)}
        style={{
          ...props.style,
          backgroundImage: StyleUtils.backgroundImage(value)
        }}
        onClick={() => setOpen(true)}
      >
        {props.children || 'Select Image'}
      </div>

      <ModalMedia
        open={open}
        btnProps={{ className: 'd-none' }}
        afterClose={(media) => {
          setOpen(false)
          if (media?.publicUrl) {
            onChange?.(media.publicUrl)
          }
        }}
      />
    </>
  )
}
