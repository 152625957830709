import { Button, Popconfirm, Space } from 'antd'
import { IUserModel } from 'src/interfaces'
import { browserHistory, generate } from 'src/router'
import { TimeZoneService } from 'src/services'
import { UserUtils } from 'src/utils'
import { ERoutes } from '../../routes'

interface IUserAction {
  onDestroy?: (record: IUserModel) => any
  onDelete?: (record: IUserModel) => any
}

export const renderHiringsColumns = ({ onDestroy, onDelete }: IUserAction) => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (__: any, record: IUserModel) => UserUtils.getFullName(record)
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email'
  },
  {
    title: 'Icon',
    dataIndex: 'pfp',
    key: 'pfp',
    render: (val: IUserModel['pfp']) => !val?.url
      ? null
      : <img src={val.url} alt="icon" style={{ height: 50 }}/>
  },
  {
    title: 'Company Name',
    dataIndex: 'companies',
    key: 'companies',
    render: (val: IUserModel['companies']) => val?.[0]?.name
  },
  {
    title: 'Company Logo',
    dataIndex: 'companies',
    key: 'companies',
    render: (val: IUserModel['companies']) => !val?.[0]?.logo?.url
      ? null
      : <img src={val[0].logo.url} alt="icon" style={{ height: 50 }}/>
  },
  {
    title: 'Company Link',
    dataIndex: 'companies',
    key: 'companies',
    render: (val: IUserModel['companies']) => val?.[0]?.url
  },
  {
    title: 'X – Factor',
    dataIndex: 'bio',
    key: 'bio'
  },
  {
    title: 'CreatedAt',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (val: IUserModel['createdAt']) => TimeZoneService.format(val)
  },
  {
    title: 'Action',
    key: 'action',
    render: (_: string, record: IUserModel) => (
      <Space
        wrap
        size="small"
        direction="vertical"
      >

        <Button
          type="link"
          size="small"
          onClick={() => browserHistory.push(generate([ERoutes.HIRING_MANAGER_DETAIL, { id: record.id }]))}
        >
          View Detail
        </Button>

        {onDestroy && (
          <Popconfirm
            title="Are you sure to delete?"
            placement="left"
            onConfirm={() => onDestroy(record)}
            okText="Yes"
            cancelText="No"
          >
            <Button style={{ marginLeft: '8px' }} size="small" danger>
              Delete
            </Button>
          </Popconfirm>
        )}

        {onDelete && (
          <Popconfirm
            title='Are you absolutely positively, cross-your-heart-and-hope-to-never-see-it-again sure about "Avada Kedavra" this?'
            placement="left"
            onConfirm={() => onDelete(record)}
            okText="Yes"
            cancelText="No"
          >
            <Button style={{ marginLeft: '8px' }} size="small" danger>
              Avada Kedavra
            </Button>
          </Popconfirm>
        )}
      </Space>
    )
  }
]
