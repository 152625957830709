import { AxiosError } from 'axios'
import clsx from 'clsx'
import { FC, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import { EMPTY, catchError, finalize, from, map, switchMap, takeUntil } from 'rxjs'
import { CandidateApi } from 'src/api'
import { useUnsubscribe } from 'src/hooks'
import { ICandidateModel, IQuizResult } from 'src/interfaces'
import { QuizAmb, QuizCore } from 'src/partials'
import { BreadcrumbService } from 'src/services'
import { GlobalAction } from 'src/store'
import { NotifyUtils, UserUtils } from 'src/utils'
import { EPaths } from '../../routes.path'
import Style from './style.module.scss'

/**
 * First Person Point of View
 */
export const CandidateQuizResult: FC = () => {
  const unsubscribe$ = useUnsubscribe()
  const { id } = useParams<{ id: string }>()
  const [candidate, setCandidate] = useState<ICandidateModel>()
  const [quizResult, setQuizResult] = useState<IQuizResult>()

  useEffect(() => {
    GlobalAction.setLoading(true)

    from(CandidateApi.show(id))
      .pipe(
        takeUntil(unsubscribe$),
        switchMap(({ data }) => {
          setCandidate(data)
          return from(CandidateApi.getQuizResult(data.id, { pointOfView: 'firstPersonSingular' })).pipe(
            map(({ data }) => setQuizResult(data))
          )
        }),
        catchError((error: AxiosError) => {
          NotifyUtils.handleAxiosError(error)
          return EMPTY
        }),
        finalize(() => GlobalAction.setLoading(false))
      )
      .subscribe()
  }, [id, unsubscribe$])

  /**
   * breadcrumb
   */
  useEffect(() => {
    BreadcrumbService.items = [
      {
        route: EPaths.CANDIDATE,
        label: 'Candidates'
      },
      {
        route: [EPaths.CANDIDATE_QUIZ_RESULT, { id }],
        label: 'Quiz result'
      }
    ]
    return () => {
      BreadcrumbService.items = []
    }
  }, [id])

  const firstName = useMemo(
    () => UserUtils.getFirstName(candidate),
    [candidate]
  )

  return (
    <section className="fx-1 fx fx-column gap-4">
      <h2 className="text-center">{firstName}'s Profile (First Person Point of View)</h2>
      <p className={clsx('p-4 bg-white', Style.snippetContent)} property="description">This Candidate Profile is designed to help you identify job opportunities that are a good fit with your work style and intrinsic motivations. In preparing for interviews, think about examples from your experiences (prior work, internships, summer jobs, community involvement, volunteerism, etc.) that demonstrate how you’ve developed skills. It’s best to focus on those skills that reflect your interests, show personal growth and tell a story about how you’ve made an impact. And, don’t forget, the questions you ask in an interview are just as important as the answers you give. We suggest a few questions to ask below to get a sense for whether the job is right for you.</p>

      <div className="fx gap-4">
        <div className="fx-1 fx fx-column">
          <h3 className="text-center">My Work Style</h3>

          <div className={clsx('fx fx-column gap-2', Style.blockStats)}>
            <QuizCore coreScores={quizResult?.coreScores}/>
          </div>
        </div>

        <div className="fx-1 fx fx-column">
          <h3 className="text-center">My Drivers</h3>

          <div className={clsx('fx fx-column gap-2', Style.blockStats)}>
            <QuizAmb ambScores={quizResult?.ambScores}/>
          </div>
        </div>
      </div>

      <div className="fx gap-4">
        <div className={clsx('fx-1 fx fx-column p-4 bg-white', Style.snippetContent)} property="snippets">
          <ul className="m-0 ml-2">
            {quizResult?.workStylesBullets?.map((bullet, index) => (
              <li key={index}>{bullet}</li>
            ))}
          </ul>
        </div>

        <div className={clsx('fx-1 fx fx-column p-4 bg-white', Style.snippetContent)} property="snippets">
          <ul className="m-0 ml-2">
            {quizResult?.driversBullets?.map((bullet, index) => (
              <li key={index}>{bullet}</li>
            ))}
          </ul>
        </div>
      </div>

      <div className={clsx('fx-1 fx fx-column p-4 bg-white', Style.snippetContent)} property="snippets">
        <h2>My Strengths</h2>
        <ul className="m-0 ml-2">
          {quizResult?.strengthsBullets?.map((bullet, index) => (
            <li key={index}>{bullet}</li>
          ))}
        </ul>
      </div>

      <div className={clsx('fx-1 fx fx-column p-4 bg-white', Style.snippetContent)} property="snippets">
        <h2>Personal Branding - How to talk about myself</h2>
        <p className={clsx('p-4 bg-white', Style.snippetContent)} property="description">
          {quizResult?.elevatorPitchBullets?.join(' ')}
        </p>
      </div>

      <div className={clsx('fx-1 fx fx-column p-4 bg-white', Style.snippetContent)} property="snippets">
        <h2>Asking the Right Questions</h2>

        <div className="fx fx-column gap-4">
          <div className="fx gap-4">
            <div className="fx-1">Suggested Questions I Should Ask:</div>
            <div className="fx-1" style={{ color: '#ff6507' }}>What to Listen For:</div>
          </div>

          {quizResult?.interviewTipsBullets?.map(([question, tip], index) => (
            <div key={index} className="fx gap-4">
              <div className="fx-1">{index + 1}. {question}</div>
              <div className="fx-1" style={{ color: '#ff6507' }}>{tip}</div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
