import { Button, Checkbox, Input } from 'antd'
import moment from 'moment'
import { ChangeEvent, ComponentProps, FC, useCallback, useEffect, useState } from 'react'
import { finalize, from, takeUntil } from 'rxjs'
import { BlogPostApi } from 'src/api'
import { DatePicker, Editor, ImagePicker, ModalFullscreen, ModalMedia } from 'src/components'
import { useDidMountEffect, useUnsubscribe, useValidation } from 'src/hooks'
import { IBlogPostModel } from 'src/interfaces'
import { NotifyUtils } from 'src/utils'
import * as Yup from 'yup'
import { ErrorMessage } from '../error-message'
import { SelectBlogCategory } from '../select-blog-category'

const schema = Yup.object().shape({
  title: Yup.string().typeError('Title invalid').required('Title is required'),
  bannerImg: Yup.string().nullable(),
  thumbnailImg: Yup.string().nullable(),
  content: Yup.string().nullable()
})

const schemaUpdate = Yup.object().shape({
  title: Yup.string().typeError('Title invalid').required('Title is required'),
  slug: Yup.string().typeError('Slug invalid').required('Slug is required'),
  bannerImg: Yup.string().nullable(),
  thumbnailImg: Yup.string().nullable(),
  content: Yup.string().nullable()
})

export const ModalBlogPost: FC<Omit<
  ComponentProps<typeof ModalFullscreen>,
  'onOk' | 'afterClose'
> & {
  btnProps?: ComponentProps<typeof Button>
  id?: IBlogPostModel['id']
  // onOk?: () => any
  afterClose?: (saved: boolean, doc?: IBlogPostModel) => any
}> = ({
  btnProps,
  ...props
}) => {
  const unsubscribe$ = useUnsubscribe()
  const [loading, setLoading] = useState(false)
  const [invisible, setInvisible] = useState<boolean | IBlogPostModel>(true)
  const [formData, setFormData] = useState<Parameters<typeof BlogPostApi.store>[0]>({
    title: ''
  })

  const handleChangeInput = useCallback((e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
    e.persist()
    const { name, value } = e.target
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }))
  }, [])

  const { validate, errors, reset } = useValidation({
    data: formData,
    schema: props.id ? schemaUpdate : schema
  })

  const submit = useCallback(async () => {
    const { isValid } = await validate()
    if (!isValid) {
      return
    }

    if (props.id && formData.slug) {
      const checkSlug = await BlogPostApi.getBySlug(formData.slug)

      if (checkSlug?.data && checkSlug?.data?.id !== props.id) {
        NotifyUtils.warn({ message: 'Slug must be unique' })
        return
      }
    }

    setLoading(true)
    from(props.id ? BlogPostApi.update(props.id, formData) : BlogPostApi.store(formData))
      .pipe(
        takeUntil(unsubscribe$),
        finalize(() => setLoading(false))
      )
      .subscribe({
        next: ({ data }) => {
          NotifyUtils.success({ message: 'Save post successfully' })
          setInvisible(data)
        },
        error: NotifyUtils.handleAxiosError
      })
  }, [formData, props.id, unsubscribe$, validate])

  useDidMountEffect(() => {
    if (invisible) {
      setFormData({ title: '' })
      const isSaved = typeof invisible !== 'boolean'
      props.afterClose?.(isSaved, isSaved ? invisible : undefined)
    }

    reset()
  }, [invisible])

  useEffect(() => {
    if (invisible || !props.id) {
      return
    }

    setLoading(true)
    from(BlogPostApi.show(props.id))
      .pipe(
        takeUntil(unsubscribe$),
        finalize(() => setLoading(false))
      )
      .subscribe({
        next: ({ data }) => setFormData({
          title: data.title ?? '',
          content: data.content,
          postedAt: data.postedAt,
          bannerImg: data.bannerImg,
          thumbnailImg: data.thumbnailImg,
          isPublish: data.isPublish,
          isFeatured: data.isFeatured,
          categoryId: data.categoryId,
          slug: data.slug ?? ''
        }),
        error: NotifyUtils.handleAxiosError
      })
  }, [invisible, props.id, unsubscribe$])

  return (
    <>
      <Button
        type="primary"
        {...btnProps}
        onClick={() => setInvisible(false)}
      >
        {props.children || 'Open'}
      </Button>

      <ModalFullscreen
        title={props.title || 'Blog Post'}
        open={!invisible}
        closable={!loading} // display X icon
        keyboard={false} // disable close on press ESC
        maskClosable={false} // disable close on click outside
        onOk={submit}
        onCancel={() => setInvisible(true)}
        footer={(
          <div className="fx fx-ai-center fx-jc-space-between">
            <ModalMedia btnProps={{ disabled: loading }}>
              Media
            </ModalMedia>

            <div>
              <Button
                type="default"
                disabled={loading}
                onClick={() => setInvisible(true)}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                disabled={loading}
                onClick={submit}
              >
                Submit
              </Button>
            </div>
          </div>
        )}
      >
        <div className="fx fx-column fx-extend gap-4">
          <div className="fx fx-column gap-2">
            <Input
              required
              readOnly={loading}
              name="title"
              placeholder="Post title"
              value={formData.title}
              status={errors.hasError('title') ? 'error' : undefined}
              onChange={handleChangeInput}
            />
            <ErrorMessage>{errors.getError('title')}</ErrorMessage>
          </div>

          {props.id && (
            <div className="fx fx-column gap-2">
              <Input
                required
                readOnly={loading}
                name="slug"
                placeholder="Post slug"
                value={formData.slug}
                status={errors.hasError('slug') ? 'error' : undefined}
                onChange={handleChangeInput}
              />
              <ErrorMessage>{errors.getError('slug')}</ErrorMessage>
            </div>
          )}

          <Checkbox
            className="m-0"
            disabled={loading}
            checked={!!formData.isFeatured}
            onChange={(e) => setFormData((prev) => ({
              ...prev,
              isFeatured: e.target.checked
            }))}
          >
            Featured
          </Checkbox>

          <Checkbox
            className="m-0"
            disabled={loading}
            checked={!!formData.isPublish}
            onChange={(e) => setFormData((prev) => ({
              ...prev,
              isPublish: e.target.checked
            }))}
          >
            Publish
          </Checkbox>

          <div className="fx gap-4">
            <div className="fx fx-column">
              <label>Category</label>
              <SelectBlogCategory
                style={{ width: '300px' }}
                disabled={loading}
                value={formData.categoryId}
                onChange={(categoryId) => setFormData((prev) => ({
                  ...prev,
                  categoryId: categoryId as number
                }))}
              />
            </div>

            <div className="fx fx-column" style={{ width: '280px' }}>
              <label>Posted Date</label>
              <DatePicker
                // showTime={{ format: 'HH:mm' }}
                // disabledDate={(date) => date.toDate().getTime() > Date.now()}
                name="postedAt"
                disabled={loading}
                value={formData.postedAt ? moment(formData.postedAt) : undefined}
                onChange={(date) => setFormData((prev) => ({ ...prev, postedAt: date?.toDate().toISOString() }))}
                status={errors.hasError('postedAt') ? 'error' : undefined}
              />
              <ErrorMessage>{errors.getError('postedAt')}</ErrorMessage>
            </div>
          </div>

          <div className="fx gap-2">
            <ImagePicker
              value={formData.thumbnailImg}
              onChange={(thumbnailImg) => setFormData((prev) => ({
                ...prev,
                thumbnailImg
              }))}
            >
              Select/Upload thumbnail
            </ImagePicker>
            <ImagePicker
              value={formData.bannerImg}
              onChange={(bannerImg) => setFormData((prev) => ({
                ...prev,
                bannerImg
              }))}
            >
              Select/Upload banner
            </ImagePicker>
          </div>

          <div className="fx fx-column gap-2">
            <Editor
              disabled={loading}
              initialValue={formData.content}
              onChange={(content) => setFormData((prev) => ({
                ...prev,
                content
              }))}
            />
            <ErrorMessage>{errors.getError('content')}</ErrorMessage>
          </div>
        </div>
      </ModalFullscreen>
    </>
  )
}
