export class FileUtils {
  static isPdf(file?: Blob | string, useMimeType?: boolean): boolean {
    if (file instanceof Blob) {
      return file.type === 'application/pdf'
    }
    if (typeof file === 'string') {
      return useMimeType
        ? file === 'application/pdf'
        : /\.(pdf)$/gmi.test(file)
    }
    return false
  }

  static isPhoto(file?: Blob | string, useMimeType?: boolean): boolean {
    if (file instanceof Blob) {
      return file.type.startsWith('image/')
    }
    if (typeof file === 'string') {
      return useMimeType
        ? file.startsWith('image/')
        : /\.(jpeg|jpg|png|gif|bmp)$/gmi.test(file)
    }
    return false
  }

  static isAudio(file?: Blob | string, useMimeType?: boolean): boolean {
    if (file instanceof Blob) {
      return file.type.startsWith('audio/')
    }
    if (typeof file === 'string') {
      return useMimeType
        ? file.startsWith('audio/')
        : /\.(wav|mp3)$/gmi.test(file)
    }
    return false
  }

  static isVideo(file?: Blob | string, useMimeType?: boolean): boolean {
    if (file instanceof Blob) {
      return file.type.startsWith('video/')
    }
    if (typeof file === 'string') {
      return useMimeType
        ? file.startsWith('video/')
        : /\.(avi|wmv|flv|mpg|mp4|webm|mkv|vob|ogv|ogg|drc|gifv|mng|mov|qt|yuv|rm|rmvb|asf|amv|m4p|m4v|mp2|mpeg|mpe|mpv|svi|3gp|3g2|mxf|roq|nsv|f4v|f4p|f4a|f4b)$/gmi.test(file)
    }
    return false
  }
}
