import axios from 'axios'
import { IUserModel, TAxiosResponseData } from 'src/interfaces'

export class ProfileApi {
  static readonly _prefix = '/profile'

  static get(): TAxiosResponseData<IUserModel> {
    return axios.get(`${ProfileApi._prefix}`)
  }

  static changePassword(data: {
    currentPassword: string
    newPassword: string
  }): TAxiosResponseData<void> {
    return axios.put(`${ProfileApi._prefix}/password`, data)
  }
}
