import { Button, Form, Input, Select } from 'antd'
import { ChangeEvent, ComponentProps, FC, useCallback, useState } from 'react'
import { finalize, from, takeUntil } from 'rxjs'
import { QueueJobApi } from 'src/api'
import { ModalFullscreen } from 'src/components'
import { useAsRef, useDidMountEffect, useUnsubscribe, useValidation } from 'src/hooks'
import { EQueueName, IQueueJobModel, ScrapedJobProviderOptions } from 'src/interfaces'
import { NotifyUtils } from 'src/utils'
import { number, object, string } from 'yup'
import { ErrorMessage } from '../error-message'

const schema = object().shape({
  repeat: number().nullable(),
  provider: string().required('Provider is required'),
  location: string().required('Location is required'),
  keywords: string().required('Keywords is required'),
  postedTime: string(),
  workingType: number()
})

interface IProps extends Omit<ComponentProps<typeof ModalFullscreen>, 'onOk' | 'afterClose'> {
  // id?: IQueueJobModel['id']
  // onOk?: () => any
  afterClose?: (saved: boolean, doc?: IQueueJobModel) => any
  btnProps?: ComponentProps<typeof Button>
}

export const ModalQueueScrapeJobs: FC<IProps> = ({
  btnProps,
  ...props
}) => {
  const unsubscribe$ = useUnsubscribe()
  const [loading, setLoading] = useState(false)
  const [invisible, setInvisible] = useState<boolean | IQueueJobModel>(true)
  const [formData, setFormData] = useState<{
    repeat?: number | null
    provider?: string
    location?: string
    keywords?: string | number
    postedTime?: string | number
    workingType?: string | number
  }>({ repeat: null })

  const handleChangeInput = useCallback((e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
    e.persist()
    const { name, value } = e.target
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }))
  }, [])

  const { validate, errors, reset } = useValidation({
    data: formData,
    schema
  })

  const save = useCallback(async () => {
    const { isValid } = await validate()
    if (!isValid) {
      return
    }

    const promise = QueueJobApi.store({
      queueName: EQueueName.SCRAPE_JOBS_QUEUE,
      data: formData
    })

    setLoading(true)
    from(promise)
      .pipe(
        takeUntil(unsubscribe$),
        finalize(() => setLoading(false))
      )
      .subscribe({
        next: ({ data }) => {
          NotifyUtils.success({ message: 'Create queue job successfully' })
          setInvisible(data)
        },
        error: NotifyUtils.handleAxiosError
      })
  }, [formData, unsubscribe$, validate])

  const afterCloseRef = useAsRef(props.afterClose)
  useDidMountEffect(() => {
    reset()

    if (invisible) {
      setLoading(false)
      setFormData(() => ({}))

      const isSaved = typeof invisible !== 'boolean'
      afterCloseRef.current?.(isSaved, isSaved ? invisible : undefined)
    }
  }, [invisible])

  return (
    <>
      <Button
        type="primary"
        {...btnProps}
        onClick={() => setInvisible(false)}
      >
        {props.children || 'Create'}
      </Button>

      <ModalFullscreen
        // wrapClassName="__modal-queue-job"
        title="Create queue jobs"
        open={!invisible}
        closable={!loading} // display X icon
        keyboard={false} // disable close on press ESC
        maskClosable={false} // disable close on click outside
        okText="Save"
        cancelText="Cancel"
        onOk={save}
        onCancel={() => setInvisible(true)}
        okButtonProps={{ disabled: loading }}
        cancelButtonProps={{ disabled: loading }}
      >
        {!invisible && (
          <div className="fx fx-jc-center">
            <div className="fx fx-column gap-2" style={{ width: '400px' }}>
              <Form.Item
                label="Repeat every"
                labelAlign="left"
                labelCol={{ flex: '0 0 100px' }}
                className="m-0"
              >
                <Select
                  allowClear
                  disabled={loading}
                  placeholder="No repeat"
                  value={formData.repeat}
                  options={[
                    { label: '1 Day', value: 86400000 },
                    { label: '2 Days', value: 86400000 * 2 },
                    { label: '3 Days', value: 86400000 * 3 },
                    { label: '4 Days', value: 86400000 * 4 },
                    { label: '5 Days', value: 86400000 * 5 },
                    { label: '6 Days', value: 86400000 * 6 },
                    { label: '7 Days', value: 86400000 * 7 }
                  ]}
                  status={errors.hasError('repeat') ? 'error' : undefined}
                  onChange={(repeat) => setFormData((prev) => ({
                    ...prev,
                    repeat: repeat || null
                  }))}
                />
                <ErrorMessage>{errors.getError('repeat')}</ErrorMessage>
              </Form.Item>

              <Form.Item
                required
                label="Provider"
                labelAlign="left"
                labelCol={{ flex: '0 0 100px' }}
                className="m-0"
              >
                <Select
                  disabled={loading}
                  placeholder="Provider"
                  value={formData.provider}
                  options={[...ScrapedJobProviderOptions]}
                  status={errors.hasError('provider') ? 'error' : undefined}
                  onChange={(provider) => setFormData((prev) => ({
                    ...prev,
                    provider
                  }))}
                />
                <ErrorMessage>{errors.getError('provider')}</ErrorMessage>
              </Form.Item>

              <Form.Item
                required
                label="Location"
                labelAlign="left"
                labelCol={{ flex: '0 0 100px' }}
                className="m-0"
              >
                <Input
                  required
                  readOnly={loading}
                  disabled={loading}
                  name="location"
                  placeholder="Location"
                  value={formData.location || ''}
                  status={errors.hasError('location') ? 'error' : undefined}
                  onChange={handleChangeInput}
                />
                <ErrorMessage>{errors.getError('location')}</ErrorMessage>
              </Form.Item>

              <Form.Item
                required
                label="Keywords"
                labelAlign="left"
                labelCol={{ flex: '0 0 100px' }}
                className="m-0"
              >
                <Input
                  required
                  readOnly={loading}
                  disabled={loading}
                  name="keywords"
                  placeholder="Keywords"
                  value={formData.keywords || ''}
                  status={errors.hasError('keywords') ? 'error' : undefined}
                  onChange={handleChangeInput}
                />
                <ErrorMessage>{errors.getError('keywords')}</ErrorMessage>
              </Form.Item>

              <Form.Item
                label="Posted time"
                labelAlign="left"
                labelCol={{ flex: '0 0 100px' }}
                className="m-0"
              >
                <Select
                  allowClear
                  disabled={loading}
                  placeholder="Any time"
                  value={formData.postedTime}
                  options={[
                    { label: 'Past 24 hours', value: 'r86400' },
                    { label: 'Past week', value: 'r604800' },
                    { label: 'Past month', value: 'r2592000' }
                  ]}
                  status={errors.hasError('postedTime') ? 'error' : undefined}
                  onChange={(postedTime) => setFormData((prev) => ({
                    ...prev,
                    postedTime
                  }))}
                />
                <ErrorMessage>{errors.getError('postedTime')}</ErrorMessage>
              </Form.Item>

              <Form.Item
                label="Working type"
                labelAlign="left"
                labelCol={{ flex: '0 0 100px' }}
                className="m-0"
              >
                <Select
                  allowClear
                  disabled={loading}
                  placeholder="Any working type"
                  value={formData.workingType}
                  options={[
                    { label: 'On-site', value: 1 },
                    { label: 'Remote', value: 2 },
                    { label: 'Hybrid', value: 3 }
                  ]}
                  status={errors.hasError('workingType') ? 'error' : undefined}
                  onChange={(workingType) => setFormData((prev) => ({
                    ...prev,
                    workingType
                  }))}
                />
                <ErrorMessage>{errors.getError('workingType')}</ErrorMessage>
              </Form.Item>
            </div>
          </div>
        )}
      </ModalFullscreen>
    </>
  )
}
