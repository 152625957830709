import { BehaviorSubject } from 'rxjs'
import { NotificationApi } from 'src/api'
import { INoticeIconTabProps } from 'src/components/notice-icon/definition'
import { ENotificationTopicType } from 'src/constants/enum'
import { INotificationModel } from 'src/interfaces'
import { TimeZoneService } from './tz.service'

export interface INotificationTabData {
  list: INotificationModel[]
  total: number
  page: number
  pages: number
  limit: number
  loading: boolean
  title: string
  key: string
}

export const NotificationService = new (
  class _NotificationService {
    readonly submissionNotification$ = new BehaviorSubject<INotificationTabData>({
      list: [],
      total: 0,
      page: 0,
      pages: 0,
      limit: 10,
      loading: false,
      title: 'Submission',
      key: ENotificationTopicType.TRICORDER_SUBMISSION
    })

    async loadMoreSubmissionNotifications() {
      const { pages, page, limit, total } = this.submissionNotification$.getValue()
      if (pages !== 0 && pages <= page) {
        return
      }

      this.submissionNotification$.next({
        ...this.submissionNotification$.getValue(),
        loading: true
      })

      const { data } = await NotificationApi.index({
        topic: ENotificationTopicType.TRICORDER_SUBMISSION,
        page: page + 1,
        limit
      })

      this.submissionNotification$.next({
        ...this.submissionNotification$.getValue(),
        list: [...this.submissionNotification$.getValue().list, ...(data.rows || [])],
        page: data.page || page + 1,
        pages: data.pages || pages,
        total: data.total || total,
        loading: false
      })
    }

    transformSubmissionNotificationToTabData(data: INotificationTabData): INoticeIconTabProps<{ submissionId: number; campaignId: number }> {
      return {
        list: data.list.map((item) => ({
          avatar: item.imageUrl,
          title: item.title,
          description: item.body,
          datetime: item.receivedAt && TimeZoneService.formatNoTz(item.receivedAt, 'MM/DD/YYYY'),
          read: !!item.receivedAt,
          data: {
            campaignId: item.data?.campaignId,
            submissionId: item.data?.submissionId
          }
        })),
        title: data.title,
        key: data.key,
        totalListCount: data.total,
        showClear: true,
        emptyText: 'No submission found',
        emptyImage: 'https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg',
        loading: data.loading,
        onLoadMore: () => this.loadMoreSubmissionNotifications()
      }
    }

    readonly linkedinNotification$ = new BehaviorSubject<INotificationTabData>({
      list: [],
      total: 0,
      page: 0,
      pages: 0,
      limit: 10,
      loading: false,
      title: 'Linkedin',
      key: ENotificationTopicType.TRICORDER_LINKEDIN_URL
    })

    async loadMoreLinkedinNotifications() {
      const { pages, page, limit, total } = this.linkedinNotification$.getValue()
      if (pages !== 0 && pages <= page) {
        return
      }

      this.linkedinNotification$.next({
        ...this.linkedinNotification$.getValue(),
        loading: true
      })

      const { data } = await NotificationApi.index({
        topic: ENotificationTopicType.TRICORDER_LINKEDIN_URL,
        page: page + 1,
        limit
      })

      this.linkedinNotification$.next({
        ...this.linkedinNotification$.getValue(),
        list: [...this.linkedinNotification$.getValue().list, ...(data.rows || [])],
        page: data.page || page + 1,
        pages: data.pages || pages,
        total: data.total || total,
        loading: false
      })
    }

    transformLinkedinNotificationToTabData(data: INotificationTabData): INoticeIconTabProps<{ candidateId: number }> {
      return {
        list: data.list.map((item) => ({
          avatar: item.imageUrl,
          title: item.title,
          description: item.body,
          datetime: item.receivedAt && TimeZoneService.formatNoTz(item.receivedAt, 'MM/DD/YYYY'),
          read: !!item.receivedAt,
          data: {
            candidateId: item.data?.candidateId
          }
        })),
        title: data.title,
        key: data.key,
        totalListCount: data.total,
        showClear: true,
        emptyText: 'No linkedin found',
        emptyImage: 'https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg',
        loading: data.loading,
        onLoadMore: () => this.loadMoreSubmissionNotifications()
      }
    }
  }
)()
