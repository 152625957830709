import { useState } from 'react'
import ReactPlayer from 'react-player'
import { Video } from 'src/components'
import { IVideoModel } from 'src/interfaces'
import { VideoThumbPicker } from 'src/partials'

export const CandidateVideo = (props: { video: IVideoModel }) => {
  const [player, setPlayer] = useState<ReactPlayer>()

  return (
    <div key={props.video.id}>
      <Video
        video={props.video}
        playerProps={{
          onReady: (player) => setPlayer(player),
          style: {
            width: 300,
            height: 200
          }
        }}
      />

      <VideoThumbPicker
        className="fx-1"
        player={player}
        encoding={props.video.encoding}
        video={props.video}
      />
    </div>
  )
}
