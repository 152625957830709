import { IRouterOption } from 'src/router'
import { PromoCodes } from './components/list'
import { EPaths } from './routes.path'

export enum ERoutes {
  PROMO_CODE = 'PromoCode.List'
}

export const Routes: IRouterOption[] = [
  {
    path: EPaths.PROMO_CODE,
    component: PromoCodes,
    name: ERoutes.PROMO_CODE,
    exact: true,
    meta: {}
  }
]

export const PromoCodeModule = {
  ERoutes,
  Routes
}
