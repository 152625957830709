import { BehaviorSubject } from 'rxjs'
import { StorageUtils } from 'src/utils'

export interface IGlobalState {
  loading: boolean
  sidebar: {
    collapsed: boolean
  }
}

export const GlobalState = new BehaviorSubject<IGlobalState>({
  loading: false,
  sidebar: {
    collapsed: StorageUtils.getItem<boolean>('UI.sidebar.collapsed') ?? window.innerWidth < 992
  }
})
