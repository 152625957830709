import { IRouterOption } from 'src/router'
import { AnalyticsHM } from './components/hm'
import { AnalyticsMobile } from './components/mobile'
import { AnalyticsMobileSessions } from './components/mobile-sessions'
import { EPaths } from './routes.path'

export enum ERoutes {
  ANALYTIC_HM = 'Analytic.hm',
  ANALYTIC_MOBILE = 'Analytic.mobile',
  ANALYTIC_MOBILE_SESSIONS = 'Analytic.mobile.sessions'
}

export const Routes: IRouterOption[] = [
  {
    name: ERoutes.ANALYTIC_HM,
    path: EPaths.ANALYTIC_HM,
    component: AnalyticsHM,
    exact: true,
    meta: {}
  },
  {
    name: ERoutes.ANALYTIC_MOBILE,
    path: EPaths.ANALYTIC_MOBILE,
    component: AnalyticsMobile,
    exact: true,
    meta: {}
  },
  {
    name: ERoutes.ANALYTIC_MOBILE_SESSIONS,
    path: EPaths.ANALYTIC_MOBILE_SESSIONS,
    component: AnalyticsMobileSessions,
    exact: true,
    meta: {}
  }
]

export const AnalyticsModule = {
  ERoutes,
  Routes
}
