import { SyntheticEvent } from 'react'

export class EventUtils {
  static preventDefault(e?: Event | SyntheticEvent) {
    e?.preventDefault?.()
  }

  static stopPropagation(e?: Event | SyntheticEvent) {
    e?.stopPropagation?.()
  }
}
