import { FC } from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { AUTH_FALLBACK_KEY, ERoleType } from 'src/constants'
import { ERoutes, IRouterOption, generate, routes } from 'src/router'
import { AuthState, useAuthorization, useStore } from 'src/store'
import { Helpers, StorageUtils } from 'src/utils'

/**
 * @param {{ component: React.Component } & { [key in string]: any } }
 */
const AuthRoute: FC<{
  path: string
  exact?: boolean
  component: IRouterOption['component']
}> = ({ component: Component, ...props }) => {
  const location = useLocation()
  const { value: { isAuthenticated } } = useStore(AuthState)

  return (
    <Route
      path={props.path}
      exact={props.exact}
      render={routeProps => {
        if (isAuthenticated) {
          const RenderComponent = Component as FC<{ [key: string]: any }>
          return <RenderComponent {...routeProps}/>
        }

        StorageUtils.setItem(AUTH_FALLBACK_KEY, `${location.pathname}${location.search}`)
        return <Redirect to={generate(ERoutes.SIGN_IN)}/>
      }}
    />
  )
}

export const RouterView: FC = () => {
  const { value: { isAuthenticated } } = useStore(AuthState)
  const { is } = useAuthorization()

  return (
    <section className="router-view">
      {isAuthenticated !== null && (
        <Switch>
          {Helpers.ensureArray<IRouterOption>(routes)
            .filter(route => {
              if (route.superAdmin && !is(ERoleType.SUPER_ADMIN)) {
                return false
              }

              return true
            })
            .map(
              (item) => {
                const exact = Boolean(item.exact)
                const RenderComponent = item.component

                // default route required auth
                if (item.meta?.requireAuth !== false) {
                  return (
                    <AuthRoute
                      key={item.path}
                      path={item.path}
                      exact={exact}
                      component={RenderComponent}
                    />
                  )
                }

                return (
                  <Route
                    key={item.path}
                    path={item.path}
                    exact={exact}
                    component={RenderComponent}
                  />
                )
              })}
        </Switch>
      )}
    </section>
  )
}
