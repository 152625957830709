import axios, { AxiosRequestConfig } from 'axios'
import { IConversationModel, IPaginationQuery, TAxiosResponseData, TAxiosResponsePagination } from 'src/interfaces'

export class ConversationApi {
  static readonly _prefix = '/chat/conversations'

  static pull(config?: AxiosRequestConfig): TAxiosResponseData<{ conversations?: Record<string, number> }> {
    return axios.get(`${ConversationApi._prefix}/pull`, config)
  }

  static paginate(params: IPaginationQuery = {}, config?: AxiosRequestConfig): TAxiosResponsePagination<IConversationModel> {
    return axios.get(`${ConversationApi._prefix}`, { ...config, params })
  }

  static store(
    payload: {
      title?: string
      userIds: number[]
    },
    config?: AxiosRequestConfig
  ): TAxiosResponseData<IConversationModel> {
    return axios.post(`${ConversationApi._prefix}`, payload, config)
  }

  static update(
    id: IConversationModel['id'],
    payload: Parameters<typeof ConversationApi.store>[0],
    config?: AxiosRequestConfig
  ): TAxiosResponseData<IConversationModel> {
    return axios.put(`${ConversationApi._prefix}/${id}`, payload, config)
  }

  static get(id: IConversationModel['id'], config?: AxiosRequestConfig): TAxiosResponseData<IConversationModel> {
    return axios.get(`${ConversationApi._prefix}/${id}`, config)
  }
}
