import { Divider, Spin } from 'antd'
import { AxiosError } from 'axios'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { CompanyApi } from 'src/api'
import { useBehaviorMapper, useUnsubscribe, useUnsubscribeCallback } from 'src/hooks'
import { ICompanyModel, ICompanyYoutubeVideoModel } from 'src/interfaces'
import { BreadcrumbService, LoadMoreService } from 'src/services'
import { NotifyUtils } from 'src/utils'
import { EPaths } from '../../routes.path'
import { CompanyGeneral } from './general'
import { CompanyVideos } from './videos'

export const CompanyDashboard: FC = () => {
  const unsubscribe$ = useUnsubscribe()
  const { id } = useParams<{ id: string }>()
  const [company, setCompany] = useState<ICompanyModel>()

  const [videos, setVideos] = useState<ICompanyYoutubeVideoModel[]>([])

  const _loadMoreService = useMemo(() => new LoadMoreService<ICompanyYoutubeVideoModel>(
    { endpoint: `${CompanyApi._prefix}/${id}/video-channel` }
  ), [id])

  const loadingVideos = useBehaviorMapper(_loadMoreService?.loading$)

  const hasMore = useMemo(
    () => Boolean(_loadMoreService && _loadMoreService.hasMore),
    [_loadMoreService]
  )

  const next: any = useCallback(() => {
    _loadMoreService && _loadMoreService.loadMore({ limit: 100 })
  }, [_loadMoreService])

  useEffect(() => {
    if (_loadMoreService) {
      _loadMoreService
        .items$
        .pipe(takeUntil(unsubscribe$))
        .subscribe(setVideos)
    }
  }, [_loadMoreService, unsubscribe$])

  useEffect(() => {
    if (!id) {
      return
    }

    from(CompanyApi.show(id))
      .pipe(takeUntil(unsubscribe$))
      .subscribe({
        next: ({ data }) => setCompany(data),
        error: NotifyUtils.handleAxiosError
      })
  }, [id, unsubscribe$])

  const [loading, setLoading] = useState(false)

  const [isEditing, setIsEditing] = useState(false)
  const [channelId, setChannelId] = useState('')
  useEffect(() => {
    if (company) {
      setChannelId(company?.youtubeChannelId || '')
    }
  }, [company])

  const updateChannelId = useUnsubscribeCallback((unsubscribe$, channelId: string) => {
    if (!company?.id) {
      return
    }

    setLoading(true)
    from(CompanyApi.videoChannel(company.id, { channelId }))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error: AxiosError) => {
          NotifyUtils.handleAxiosError(error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(() => {
        NotifyUtils.success({ message: 'Update successfully' })
        setIsEditing(false)
        company.youtubeChannelId = channelId
        _loadMoreService.reset()
      })
  }, [company])

  /**
   * breadcrumb
   */
  useEffect(() => {
    BreadcrumbService.items = [
      {
        route: EPaths.COMPANY,
        label: 'Companies'
      },
      {
        route: [EPaths.COMPANY_DASHBOARD, { id }],
        label: 'Dashboard'
      }
    ]
    return () => {
      BreadcrumbService.items = []
    }
  }, [id])

  return (
    <section className="fx fx-column fx-extend gap-1">
      <CompanyGeneral
        company={company}
        isEditing={isEditing}
        loading={loading}
        channelId={channelId}
        setChannelId={setChannelId}
        setIsEditing={setIsEditing}
        updateChannelId={updateChannelId}
      />

      <Divider className="my-2" style={{ borderColor: 'black' }}/>

      {loadingVideos ? <Spin/> : <CompanyVideos company={company} videos={videos} next={next} hasMore={hasMore}/>}
    </section>
  )
}
