import { Button, Pagination, Select, Table } from 'antd'
import { omit } from 'lodash'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { takeUntil } from 'rxjs'
import { AdminApi, AdminPaginationParam } from 'src/api'
import { ERoleType } from 'src/constants/enum'
import { IPaginateCallback, useDidMountDebounce, usePaginateParams, useUnsubscribe } from 'src/hooks'
import { IPaginationResponse, IRoleModel, IUserModel } from 'src/interfaces'
import { BreadcrumbService, PaginationService } from 'src/services'
import { GlobalAction, GlobalState, useStore } from 'src/store'
import { NotifyUtils } from 'src/utils'
import { EPaths } from '../../routes.path'
import { renderColumns } from './columns'
import { ModalNewAdmin } from './components/modal-new-admin/modal-new-admin'

const PAGE_TITLE = 'Admin'

export const Admin: FC = () => {
  const unsubscribe$ = useUnsubscribe()
  const { value: { loading } } = useStore(GlobalState)
  const _paginationService = useMemo(() => new PaginationService(AdminApi), [])
  const [dataSource, setDataSource] = useState<IPaginationResponse<IUserModel>>({
    total: 1,
    rows: []
  })
  const [openModal, setOpenModal] = useState(false)

  const onCloseModal = useCallback(() => {
    setOpenModal(false)
  }, [])

  const onOpenModal = useCallback(() => {
    setOpenModal(true)
  }, [])

  const fetch = useCallback<IPaginateCallback>(
    (params) => _paginationService.paging(params),
    [_paginationService]
  )

  const handleNewAdmin = useCallback(async (email: string, roles: string[]) => {
    try {
      GlobalAction.setLoading(true)
      await AdminApi.assignRoles(email, roles)
      NotifyUtils.success({ message: 'Create new admin successfully' })
      fetch({ page: 1, limit: 10 })
      onCloseModal()
    } catch (error) {
      console.log(error)
    } finally {
      GlobalAction.setLoading(false)
    }
  }, [fetch, onCloseModal])

  const { pagination, pushPagination, setPagination } = usePaginateParams<AdminPaginationParam>(fetch)
  const [filter, setFilter] = useState<Omit<AdminPaginationParam, 'page' | 'limit' | 'offset'>>({
    ...omit(pagination, ['page', 'limit', 'offset']),
    role: pagination.role
  })

  useDidMountDebounce(() => {
    setPagination({ ...pagination, page: 1 })

    return pushPagination({
      ...pagination,
      page: 1,
      ...filter
    })
  }, 2000, [filter])

  useEffect(() => {
    _paginationService
      .loading$
      .pipe(takeUntil(unsubscribe$))
      .subscribe((value) => GlobalAction.setLoading(value))
    _paginationService
      .pagination$
      .pipe(takeUntil(unsubscribe$))
      .subscribe((data) => setDataSource(data))
  }, [_paginationService, unsubscribe$])

  /**
   * breadcrumb
   */
  useEffect(() => {
    BreadcrumbService.items = [{
      route: EPaths.ADMIN,
      label: PAGE_TITLE
    }]
    return () => {
      BreadcrumbService.items = []
    }
  }, [])

  const handleAddRole = async (user: IUserModel, newRole: string) => {
    try {
      if (!user.email) {
        return
      }

      GlobalAction.setLoading(true)

      const currentRoles = user.roles?.map((role) => role.type as string) || []
      if (currentRoles.includes(newRole)) return

      const newRoles = [...currentRoles, newRole]

      await AdminApi.assignRoles(user.email, newRoles)
      NotifyUtils.success({ message: 'Assign role successfully' })
      setDataSource({
        ...dataSource,
        rows: dataSource.rows?.map((item) => {
          if (item.id === user.id) {
            return {
              ...item,
              roles: newRoles.map((role) => ({ type: role }) as IRoleModel)
            }
          }
          return item
        })
      })
    } catch (error) {
      console.log('failed to assign roles', error)
    } finally {
      GlobalAction.setLoading(false)
    }
  }

  const handleRemoveRole = async (user: IUserModel, removingRole: string) => {
    try {
      if (!user.email) {
        return
      }

      GlobalAction.setLoading(true)

      const currentRoles = user.roles?.map((role) => role.type as string) || []
      if (!currentRoles.includes(removingRole)) return

      const newRoles = currentRoles.filter(role => role !== removingRole)

      await AdminApi.assignRoles(user.email, newRoles)
      NotifyUtils.success({ message: 'Removed role successfully' })
      setDataSource({
        ...dataSource,
        rows: dataSource.rows?.map((item) => {
          if (item.id === user.id) {
            return {
              ...item,
              roles: newRoles.map((role) => ({ type: role }) as IRoleModel)
            }
          }
          return item
        })
      })
    } catch (error) {
      console.log('failed to removed roles', error)
    } finally {
      GlobalAction.setLoading(false)
    }
  }

  return (
    <section className="fx fx-column fx-extend">
      <div className="fx fx-jc-space-between mb-2">
        <div className="fx fx-extend fx-ai-center gap-2">
          <Select
            allowClear
            placeholder="Select roles"
            style={{ flex: '0 0 10vw' }}
            value={filter.role}
            onChange={(role) => {
              setFilter((prev) => ({
                ...prev,
                role
              }))
            }}
            onClear={() => {
              setFilter((prev) => ({
                ...prev,
                role: undefined
              }))
            }}
          >
            <Select.Option value={ERoleType.ADMIN}>Admin</Select.Option>
            <Select.Option value={ERoleType.SUPER_ADMIN}>Super Admin</Select.Option>
          </Select>

          <Button onClick={onOpenModal}>
            Add new admin
          </Button>
        </div>
      </div>

      <Table
        bordered
        rowKey="id"
        className="fx-extend"
        loading={loading}
        columns={renderColumns({
          onRoleRemove: handleRemoveRole,
          onAddRole: handleAddRole
        })}
        dataSource={dataSource.rows}
        pagination={false}
      />

      <Pagination
        className="fx-as-end mt-3"
        disabled={loading}
        total={dataSource.total}
        current={pagination.page}
        pageSize={pagination.limit}
        {
          ...{
            ...pagination,
            onChange(page, limit) {
              pushPagination({
                ...pagination,
                page,
                limit
              })
            }
          }
        }
      />

      <ModalNewAdmin
        open={openModal}
        confirmLoading={loading}
        onOk={handleNewAdmin}
        onCancel={onCloseModal}
      />
    </section>
  )
}
