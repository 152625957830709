import axios from 'axios'
import { IContactFormModel, IPaginationQuery, TAxiosResponseData, TAxiosResponsePagination } from 'src/interfaces'

export class ContactFormApi {
  static readonly _prefix = '/contact-forms'

  static index(params: IPaginationQuery = {}): TAxiosResponsePagination<IContactFormModel> {
    return axios.get(`${ContactFormApi._prefix}`, { params })
  }

  static show(id: IContactFormModel['id']): TAxiosResponseData<IContactFormModel> {
    return axios.get(`${ContactFormApi._prefix}/${id}`)
  }
}
