import clsx from 'clsx'
import { FC, HTMLAttributes, useMemo } from 'react'
import { Avatar } from 'src/components'
import { IConversationModel } from 'src/interfaces'
import { MessengerService } from 'src/services'
import Style from './style.module.scss'

export const Header: FC<HTMLAttributes<HTMLDivElement> & { conversation: IConversationModel }> = ({
  conversation,
  ...props
}) => {
  const title = useMemo(
    () => MessengerService.getConversationTitle(conversation),
    [conversation]
  )
  return (
    <div
      {...props}
      className={clsx(props.className, 'pointer fx fx-ai-center gap-2', Style.conversation)}
    >
      <Avatar
        className="p-1"
        size={50}
        src="/images/avatar.jpg" // TODO: replace with conversation.avatar
      />
      <div
        className="fx fx-column fx-jc-center heading-6 oneline-text"
        style={{ width: 'calc(100% - 58px)' }}
        title={title}
      >
        {title}
      </div>
    </div>
  )
}
