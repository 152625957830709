import { Button } from 'antd'
import clsx from 'clsx'
import { ComponentProps, FC, InputHTMLAttributes, useRef } from 'react'

interface IProps extends ComponentProps<typeof Button> {
  inputProps?: InputHTMLAttributes<HTMLInputElement>
}

export const UploadButton: FC<IProps> = ({ inputProps, ...props }) => {
  const inputRef = useRef<HTMLInputElement>(null)

  return (
    <>
      <input
        {...inputProps}
        className={clsx('d-none', inputProps?.className)}
        type="file"
        ref={inputRef}
      />
      <Button
        {...props}
        onClick={() => inputRef.current?.click()}
      >
        {props.children || 'Upload'}
      </Button>
    </>
  )
}
