import axios from 'axios'
import { TAxiosResponseData } from 'src/interfaces'
import { IOpenAIModel } from 'src/interfaces/open-ai'

export class OpenAIApi {
  static readonly _prefix = '/open-ai'

  static models(): TAxiosResponseData<{ models: IOpenAIModel[] }> {
    return axios.get(`${OpenAIApi._prefix}/models`)
  }
}
