import { CloudUploadOutlined } from '@ant-design/icons'
import clsx from 'clsx'
import { EmojiClickData } from 'emoji-picker-react'
import { ComponentProps, FC, ReactNode, useCallback, useState } from 'react'
import { Editor, EmojiPicker } from 'src/components'
import { useAsRef } from 'src/hooks'
import { EventUtils } from 'src/utils'
import { FilePicker } from '../file-picker'

interface IProps extends ComponentProps<typeof Editor> {
  onChooseFiles?: (files: FileList) => void
  className?: string
  iconSlot?: ReactNode
}

type TEditor = Parameters<Required<Required<IProps>['init']>['setup']>[0]
type TEditorSelection = Required<TEditor>['selection']
// type TRange = ReturnType<TEditorSelection['getRng']>
type TBookmark = ReturnType<TEditorSelection['getBookmark']>

export const ChatEditor: FC<IProps> = ({
  className,
  onChooseFiles,
  iconSlot,
  ...props
}) => {
  const [editor, setEditor] = useState<TEditor>()
  const [bookmark, setBookmark] = useState<TBookmark>()
  const bookmarkRef = useAsRef(bookmark)

  const insertEmoji = useCallback((data: EmojiClickData) => {
    if (!editor) {
      return
    }

    if (bookmarkRef.current) {
      editor.selection.moveToBookmark(bookmarkRef.current)
    }

    editor.execCommand('mceInsertContent', true, data.emoji)
  }, [bookmarkRef, editor])

  return (
    <form
      onSubmit={(e) => EventUtils.preventDefault(e)}
      className={clsx(className, 'fx fx-ai-flex-end gap-1')}
    >
      <div className="fx-1">
        <Editor
          {...props}
          onClick={() => {
            // to hide emoji picker
            document.body.dispatchEvent(
              new Event('mousedown', {
                bubbles: true,
                cancelable: true
              })
            )
          }}
          onFocus={(_, _editor) => setBookmark(_editor.selection.getBookmark(2, true))}
          onBlur={(_, _editor) => setBookmark(_editor.selection.getBookmark(2, true))}
          onEditorChange={(_, _editor) => setBookmark(_editor.selection.getBookmark(2, true))}
          init={{
            toolbar: false,
            statusbar: false,
            browser_spellcheck: true,
            min_height: 60,
            max_height: 100,
            placeholder: 'Aa...',
            ...props.init,
            body_class: 'chat-editor',
            setup(_editor) {
              setEditor(_editor)
              props.init?.setup?.(_editor)
            }
          }}
        />
      </div>

      <div className="fx fx-column fx-ai-center fx-jc-flex-end gap-1">
        {iconSlot}

        <FilePicker
          className="pointer"
          inputProps={{
            accept: 'image/*,video/*'
            // multiple: true // TODO: only support single file for now
          }}
          onChoose={onChooseFiles}
        >
          <CloudUploadOutlined style={{ color: '#4a4aff', transform: 'scale(1.3)' }}/>
        </FilePicker>
        <EmojiPicker
          previewConfig={{ showPreview: false }}
          onEmojiClick={insertEmoji}
          onReactionClick={insertEmoji}
        />
      </div>
    </form>
  )
}
