import { Button } from 'antd'
import { ComponentProps, FC, useEffect, useState } from 'react'
import { ChatGPTMessages, ModalFullscreen } from 'src/components'
import { useAsRef } from 'src/hooks'
import { IOpenAIMessage } from 'src/interfaces'

interface IProps extends Omit<ComponentProps<typeof ModalFullscreen>, 'onOk' | 'afterClose'> {
  messages: IOpenAIMessage[]
  afterClose?: () => any
  btnProps?: ComponentProps<typeof Button>
}

export const ModalMessages: FC<IProps> = ({
  btnProps,
  ...props
}) => {
  const [open, setOpen] = useState(false)
  const [opened, setOpened] = useState(false)
  const afterCloseRef = useAsRef(props.afterClose)

  useEffect(() => {
    if (!open) {
      afterCloseRef.current?.()
    } else {
      setOpened(true)
    }
  }, [afterCloseRef, open])

  return (
    <>
      <Button
        type="primary"
        {...btnProps}
        onClick={() => setOpen(true)}
      >
        {props.children || 'View messages'}
      </Button>

      <ModalFullscreen
        title="Chat GPT messages"
        open={open}
        // closable={false} // display X icon
        keyboard={false} // disable close on press ESC
        maskClosable={false} // disable close on click outside
        okButtonProps={{ style: { display: 'none' } }}
        cancelText="Close"
        onCancel={() => setOpen(false)}
      >
        {opened && (
          <ChatGPTMessages messages={props.messages} className="fx-1 bg-white"/>
        )}
      </ModalFullscreen>
    </>
  )
}
