import { Button, Input, Pagination, Table } from 'antd'
import { omit } from 'lodash'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { CalendarApi } from 'src/api'
import { IPaginateCallback, IPaginateParams, useBehaviorMapper, usePaginateParams } from 'src/hooks'
import { IUserModel } from 'src/interfaces'
import { BreadcrumbService, PaginationService } from 'src/services'
import { EPaths } from '../../routes.path'
import { renderColumns } from './columns'

export const CalendarUsers: FC = () => {
  const _paginationService = useMemo(() => new PaginationService<IUserModel>(
    { endpoint: `${CalendarApi._prefix}/users` }
  ), [])
  const loading = useBehaviorMapper(_paginationService.loading$)
  const dataSource = useBehaviorMapper(_paginationService.pagination$)

  const fetch = useCallback<IPaginateCallback>(
    (params) => _paginationService.paging(params),
    [_paginationService]
  )

  const { pagination, pushPagination } = usePaginateParams(fetch)
  const [filter, setFilter] = useState<Omit<IPaginateParams, 'page' | 'limit' | 'offset'>>(omit(pagination, ['page', 'limit', 'offset']))

  /**
   * breadcrumb
   */
  useEffect(() => {
    BreadcrumbService.items = [{
      route: EPaths.CALENDAR_USERS,
      label: 'Calendar Users'
    }]
    return () => {
      BreadcrumbService.items = []
    }
  }, [])

  const handleOnSearch = useCallback(() => {
    return pushPagination({
      ...pagination,
      page: 1,
      ...filter
    })
  }, [filter, pagination, pushPagination])

  return (
    <section className="fx fx-column fx-extend">
      <div className="fx fx-jc-space-between gap-2 mb-2">
        <div className="fx fx-extend fx-ai-center fx-wrap-wrap gap-2">
          <Input
            allowClear
            placeholder="Search by name, email, phone..."
            readOnly={loading}
            value={filter.keyword as string || ''}
            onChange={(e) => setFilter((prev) => ({
              ...prev,
              keyword: e.target.value
            }))}
          />
        </div>

        <Button type="primary" onClick={handleOnSearch}>Search</Button>
      </div>

      <Table
        className="fx-extend"
        rowKey="id"
        loading={loading}
        columns={renderColumns()}
        dataSource={dataSource.rows}
        pagination={false}
      />

      <Pagination
        className="fx-as-end mt-3"
        disabled={loading}
        total={dataSource.total}
        current={pagination.page}
        pageSize={pagination.limit}
        {
          ...{
            ...pagination,
            onChange(page, limit) {
              pushPagination({
                ...pagination,
                page,
                limit
              })
            }
          }
        }
      />
    </section>
  )
}
