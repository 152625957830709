import { DownOutlined, ExclamationCircleOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import { Dropdown, Menu, Modal, Switch } from 'antd'
import { ComponentProps, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { takeUntil } from 'rxjs'
import { ModalChangePassword } from 'src/components'
import { NoticeIcon } from 'src/components/notice-icon'
import { INoticeIconData, INoticeIconTabProps } from 'src/components/notice-icon/definition'
import { ENotificationTopicType } from 'src/constants/enum'
import { useBehaviorMapper, useUnsubscribe } from 'src/hooks'
import { FirebaseNotificationService, NotificationService, TimeZoneService } from 'src/services'
import { AuthAction, AuthState, GlobalAction, GlobalState, useStore } from 'src/store'
import { LayoutBreadcrumb } from '../breadcrumb'

export const LayoutHeader = memo(
  () => {
    const unsubscribe$ = useUnsubscribe()
    const { value: { loading, sidebar } } = useStore(GlobalState)
    const { value: { user, isAuthenticated } } = useStore(AuthState)
    const history = useHistory()
    const [showPasswordDialog, setShowPasswordDialog] = useState(false)
    const [notificationActiveTabKey, setNotificationActiveTabKey] = useState<string>(ENotificationTopicType.TRICORDER_SUBMISSION)
    const [notificationTabs, setNotificationTabs] = useState<Record<string, INoticeIconTabProps>[]>([])

    useEffect(() => {
      NotificationService.submissionNotification$
        .pipe(takeUntil(unsubscribe$))
        .subscribe((data) => {
          setNotificationTabs((tabs) => ({
            ...tabs,
            [ENotificationTopicType.TRICORDER_SUBMISSION]: NotificationService.transformSubmissionNotificationToTabData(data)
          }))
        })

      NotificationService.linkedinNotification$
        .pipe(takeUntil(unsubscribe$))
        .subscribe((data) => {
          setNotificationTabs((tabs) => ({
            ...tabs,
            [ENotificationTopicType.TRICORDER_LINKEDIN_URL]: NotificationService.transformLinkedinNotificationToTabData(data)
          }))
        })
    }, [unsubscribe$])

    const handleOnNotificationTabChange = useCallback((notificationActiveTabKey: string) => {
      setNotificationActiveTabKey(notificationActiveTabKey)
      if (notificationActiveTabKey === ENotificationTopicType.TRICORDER_SUBMISSION) {
        return NotificationService.loadMoreSubmissionNotifications()
      }

      if (notificationActiveTabKey === ENotificationTopicType.TRICORDER_LINKEDIN_URL) {
        NotificationService.loadMoreLinkedinNotifications()
      }
    }, [setNotificationActiveTabKey])

    const handleOnNotificationPopupVisibleChange = useCallback((visible: boolean) => {
      if (notificationActiveTabKey === ENotificationTopicType.TRICORDER_SUBMISSION && visible) {
        return NotificationService.loadMoreSubmissionNotifications()
      }

      if (notificationActiveTabKey === ENotificationTopicType.TRICORDER_LINKEDIN_URL && visible) {
        NotificationService.loadMoreLinkedinNotifications()
      }
    }, [notificationActiveTabKey])

    const handleOnNotificationItemClick = useCallback((itemData: INoticeIconData, tabData: INoticeIconTabProps) => {
      if (tabData.key === ENotificationTopicType.TRICORDER_SUBMISSION) {
        // TODO: handle anchor link
        history.push(`/campaigns/${itemData.data.campaignId}/submissions`)
      }

      if (tabData.key === ENotificationTopicType.TRICORDER_LINKEDIN_URL) {
        // TODO: handle anchor link
        history.push(`/candidates/${itemData.data.candidateId}/dashboard`)
      }
    }, [history])

    const signOut = useCallback(() => {
      Modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined/>,
        content: 'Are you sure you want to sign out?',
        okText: 'Sign out',
        cancelText: 'Cancel',
        closable: false,
        okButtonProps: { danger: true },
        onOk() {
          GlobalAction.setLoading(true)
          AuthAction.signOut().finally(
            () => GlobalAction.setLoading(false)
          )
        }
      })
    }, [])

    useEffect(() => {
      if (isAuthenticated) {
        FirebaseNotificationService.bind()
      }
    }, [isAuthenticated])

    const ToggleIconComp = sidebar.collapsed
      ? MenuUnfoldOutlined
      : MenuFoldOutlined

    const dropdownItems = useMemo<ComponentProps<typeof Menu>['items']>(
      () => [
        {
          key: 'change-password',
          label: 'Change password',
          onClick: () => setShowPasswordDialog(!showPasswordDialog)
        },
        {
          key: 'sign-out',
          danger: true,
          disabled: loading,
          label: 'Sign out',
          onClick: signOut
        }
      ], [loading, signOut, showPasswordDialog]
    )

    const useUTC = useBehaviorMapper(TimeZoneService.useUTC$)
    const timezoneOffset = useBehaviorMapper(TimeZoneService.timezoneOffset$)
    const tzTxt = useMemo(
      () => timezoneOffset > 0 ? `+${timezoneOffset / 60}` : `-${timezoneOffset / 60}`,
      [timezoneOffset]
    )

    return (
      <section className="layout-header">
        <div className="fx fx-ai-center gap-4">
          <div className="layout-header__toggle-icon">
            <ToggleIconComp onClick={() => GlobalAction.setSidebarCollapsed(!sidebar.collapsed)}/>
          </div>

          <LayoutBreadcrumb/>
        </div>

        <div className="fx fx-ai-center gap-4">
          <Switch
            checkedChildren="UTC"
            unCheckedChildren={tzTxt}
            defaultChecked={useUTC}
            onChange={(checked) => (TimeZoneService.useUTC = checked)}
          />

          <NoticeIcon
            className="mb-2"
            tabs={Object.values(notificationTabs)}
            activeKey={notificationActiveTabKey}
            onTabChange={handleOnNotificationTabChange}
            onPopupVisibleChange={handleOnNotificationPopupVisibleChange}
            onItemClick={handleOnNotificationItemClick}
          />

          <Dropdown menu={{ items: dropdownItems }}>
            <div className="layout-header__user">
              <img className="layout-header__user__image" src={user?.pfp?.url || '/images/avatar.jpg'} alt="avatar"/>
              <div className="layout-header__user__name">
                {user?.username || user?.email}
              </div>

              <DownOutlined/>
            </div>
          </Dropdown>

          <ModalChangePassword
            open={showPasswordDialog}
            onCancel={() => setShowPasswordDialog(false)}
            onOk={() => setShowPasswordDialog(false)}
          />
        </div>
      </section>
    )
  }
)
