import { Layout, Menu } from 'antd'
import clsx from 'clsx'
import { ComponentProps, FC, useCallback, useState } from 'react'
import { NavLink, matchPath, useLocation } from 'react-router-dom'
import { EEnv } from 'src/constants'
import { ERoleType } from 'src/constants/enum'
import { GlobalAction, GlobalState, useAuthorization, useStore } from 'src/store'
import { Helpers } from 'src/utils'
import { IMenuItem, getOpenKeys, menus } from './menus'

export const LayoutSidebar: FC = (props) => {
  const { pathname } = useLocation()
  const { value: { sidebar } } = useStore(GlobalState)
  const [defaultOpenKeys] = useState(getOpenKeys(pathname))
  const { is } = useAuthorization()

  const handleResponsive = useCallback((broken: boolean) => {
    GlobalAction.setSidebarCollapsed(broken)
  }, [])

  const getActiveClassName = useCallback(
    (path = '') => {
      const _matchPath = matchPath(pathname, path)
      return _matchPath ? '__active' : ''
    },
    [pathname]
  )

  const getMenuItems = useCallback((items: IMenuItem[]) => Helpers.ensureArray(items).reduce<Required<ComponentProps<typeof Menu>>['items']>(
    (result, item) => {
      if (item.superAdmin && !is(ERoleType.SUPER_ADMIN)) {
        return result
      }

      if (item.subMenus) {
        const subMenus = getMenuItems(item.subMenus)
        if (!subMenus?.length) {
          return result
        }

        result.push({
          key: item.key,
          title: item.label,
          label: item.label,
          icon: item.icon && <item.icon/>,
          children: subMenus
        })
      } else {
        result.push({
          key: item.key,
          icon: item.icon && <item.icon/>,
          className: clsx('layout-sidebar__menu__item', getActiveClassName(item.pathname)),
          label: (
            <NavLink exact to={item.pathname || '/'}>
              {item.label}
            </NavLink>
          )
        }
        )
      }

      return result
    }, []
  ), [getActiveClassName, is])

  return (
    <Layout.Sider
      width={280}
      trigger={null}
      collapsible
      collapsed={sidebar.collapsed}
      collapsedWidth={0}
      className="layout-sidebar noselect"
      breakpoint="lg"
      onBreakpoint={handleResponsive}
    >
      <div style={{ height: 70, color: 'white' }} className="layout-sidebar__logo">
        <img src="/icons/logo.svg" alt={EEnv.APP_NAME}/>
      </div>

      <Menu
        theme="dark"
        mode="inline"
        className="hide-scrollbar layout-sidebar__menu"
        defaultOpenKeys={defaultOpenKeys}
        selectable={!true}
        items={getMenuItems(menus)}
      />
    </Layout.Sider>
  )
}
