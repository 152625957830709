import { Button, Space, TableProps } from 'antd'
import { isEmpty } from 'lodash'
import { ICampaignModel } from 'src/interfaces'
import { TimeZoneService } from 'src/services'
import { UserUtils } from 'src/utils'

export const renderColumns = ({
  onRestore
}: {
  onRestore?: (record: ICampaignModel) => any
} = {}): Required<TableProps<ICampaignModel>>['columns'] => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: 'Creator',
    dataIndex: 'author',
    key: 'author',
    render: (val?: ICampaignModel['author']) => UserUtils.getFullName(val)
  },
  {
    title: 'Name',
    dataIndex: 'jobTitle',
    key: 'jobTitle'
  },
  {
    title: 'Logo',
    dataIndex: 'logoUrl',
    key: 'logoUrl',
    render: (val: ICampaignModel['logoUrl']) => !val
      ? null
      : <img src={val} alt="icon" style={{ height: 50 }}/>
  },
  {
    title: 'Company Name',
    dataIndex: 'companyName',
    key: 'companyName'
  },
  {
    title: 'Company Website',
    dataIndex: 'companyWebsiteUrls',
    key: 'companyWebsiteUrls',
    render: (val: ICampaignModel['companyWebsiteUrls']) => !isEmpty(val)
      ? val?.map((item, index) => <span key={index} title={item} className="twoline-text" style={{ maxWidth: '300px' }}>{item}</span>)
      : ''
  },
  {
    title: 'Question',
    dataIndex: 'question',
    key: 'question',
    render: (val: ICampaignModel['question']) => (
      <span title={val} style={{ maxWidth: '300px' }}>{val}</span>
    )
  },
  {
    title: 'Submissions',
    dataIndex: 'countSubmissions',
    key: 'countSubmissions'
  },
  {
    title: 'ArchivedAt',
    dataIndex: 'deactivatedAt',
    key: 'deactivatedAt',
    render: (val: ICampaignModel['deactivatedAt']) => TimeZoneService.format(val)
  },
  {
    title: 'Action',
    key: 'action',
    render: (_, record: ICampaignModel) => (
      <Space
        wrap
        size="small"
        direction="vertical"
      >
        {!!onRestore && (
          <Button
            type="primary"
            size="small"
            onClick={() => onRestore?.(record)}
          >
            Restore
          </Button>
        )}
      </Space>
    )
  }
]
