import { IRouterOption } from 'src/router'
import { Hashtags } from './components/list'
import { EPaths } from './routes.path'

export enum ERoutes {
  HASHTAG = 'Hashtag.List'
}

export const Routes: IRouterOption[] = [
  {
    path: EPaths.HASHTAG,
    component: Hashtags,
    name: ERoutes.HASHTAG,
    exact: true,
    meta: {}
  }
]

export const HashtagModule = {
  ERoutes,
  Routes
}
