import clsx, { ClassValue } from 'clsx'
import { FC, ForwardedRef, forwardRef, MutableRefObject, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react'
// Temporary fix: typescript function component error
import _ReactPlayer from 'react-player'
import { ReactPlayerProps } from 'react-player/types/lib'
import { takeUntil } from 'rxjs'
import { useUnsubscribe } from 'src/hooks'
import { DateUtils, StyleUtils } from 'src/utils'
import { Control } from './control'
import { IconPlay, IconSpeakerOff, IconSpeakerOn } from './icons'
import { EHlsEvents } from './interfaces'
import { PlayerService } from './player.service'
import { Range } from './range'
import Style from './style.module.scss'

const ReactPlayer = _ReactPlayer as unknown as React.FC<ReactPlayerProps>

interface IProps {
  id?: string
  ref?: MutableRefObject<ReactPlayerProps['ref']> | ForwardedRef<ReactPlayerProps['ref']>
  url?: ReactPlayerProps['url'] | File | Blob
  className?: ClassValue
  mimeType?: string
  onReady?: (player: _ReactPlayer) => void
  muted?: boolean
  autoplay?: boolean
  image?: string
  animatedImage?: string
  tracks?: {
    label: string
    kind: 'alternative' | 'captions' | 'main' | 'sign' | 'subtitles' | 'commentary' | ''
    src: string
    srcLang: string
    default: boolean
  }[]
  style?: React.CSSProperties
}

interface IProgress {
  played: number
  playedSeconds: number
  loaded: number
  loadedSeconds: number
}

export const VideoPlayer: FC<IProps> = forwardRef<typeof ReactPlayer, IProps>(
  ({ style, tracks, ...props }, ref: ForwardedRef<typeof ReactPlayer>) => {
    const unsubscribe$ = useUnsubscribe()
    const playerNo = useMemo(() => PlayerService.genRef(), [])
    const placeholder = useMemo(
      () => props.image || props.animatedImage,
      [props.image, props.animatedImage]
    )
    const [showControls, setShowControls] = useState(false)
    const [isShowVideo, setIsShowVideo] = useState(props.autoplay || !placeholder)
    const [isMuted, setIsMuted] = useState(props.autoplay ?? false)
    const [playing, setPlaying] = useState(props.autoplay ?? false)

    const [isFlip, setIsFlip] = useState(false)
    const [player, setPlayer] = useState<_ReactPlayer>()
    const [playEnd, setPlayEnd] = useState(false)
    const [duration, setDuration] = useState(0)
    const [progress, setProgress] = useState<IProgress>({
      played: 0,
      playedSeconds: 0,
      loaded: 0,
      loadedSeconds: 0
    })

    const [url, setUrl] = useState<ReactPlayerProps['url']>()
    useEffect(() => {
      if ((props.url instanceof Blob) || (props.url instanceof File)) {
        const blobUrl = URL.createObjectURL(props.url)
        setUrl(blobUrl)
        // setIsFlip(props.url instanceof File && props.url.name === RECORD_FILE_NAME)
        return () => {
          URL.revokeObjectURL(blobUrl)
          // setIsFlip(false)
        }
      }

      setUrl(props.url)

      if (props.url && typeof props.url === 'string') {
        const url = new URL(props.url as string)
        if (url.searchParams.get('hflip')) {
          setIsFlip(true)
        }

        return () => {
          setIsFlip(false)
        }
      }
    }, [props.url])

    /**
     * Select best quality for HLS based on bandwidth estimate (latest bandwidth)
     */
    useEffect(() => {
      if (player) {
        const hslPlayer = player.getInternalPlayer('hls')
        if (hslPlayer && hslPlayer.levels?.length) {
          if (PlayerService.bandwidthEstimate) {
            hslPlayer.currentLevel = Math.max(
              (hslPlayer.levels as { bitrate: number }[]).findIndex(
                ({ bitrate }: { bitrate: number }) => bitrate >= PlayerService.bandwidthEstimate
              ),
              hslPlayer.levels.length - 1
            )
          } else {
            PlayerService.bandwidthEstimate = hslPlayer.bandwidthEstimate
          }

          hslPlayer.on(EHlsEvents.LEVEL_SWITCHED, () => {
            PlayerService.bandwidthEstimate = hslPlayer.bandwidthEstimate
          })
        }
      }
    }, [player, props.url])

    useEffect(() => {
      if (playing) {
        PlayerService.playing = playerNo
      }
    }, [playerNo, playing])

    useEffect(() => {
      if (isFlip || navigator.userAgent.includes('Firefox')) {
        return
      }

      const videoTag = player?.getInternalPlayer?.() as HTMLVideoElement

      // videoTag?.querySelectorAll('track').forEach((track) => {
      //   videoTag.removeChild(track)
      // })

      const tracksEl: HTMLTrackElement[] = []
      for (const track of (tracks || [])) {
        const trackEl = document.createElement('track')
        trackEl.src = track.src
        trackEl.kind = track.kind
        trackEl.label = track.label
        trackEl.srclang = track.srcLang
        trackEl.default = track.default
        trackEl.addEventListener('load', function () {
          trackEl.track.mode = 'showing'
        })

        videoTag?.appendChild(trackEl)
        tracksEl.push(trackEl)
      }

      return () => {
        for (const el of tracksEl) {
          el.track.mode = 'disabled'
          videoTag?.removeChild(el)
        }
      }
    }, [isFlip, player, tracks, url])

    useEffect(() => {
      if (!isShowVideo && (props.autoplay || !placeholder)) {
        setIsShowVideo(true)
      }
    }, [isShowVideo, props.autoplay, placeholder])

    useEffect(() => {
      PlayerService.playing$
        .pipe(takeUntil(unsubscribe$))
        .subscribe((no) => {
          if (no !== playerNo) {
            setPlaying(false)
          }
        })
    }, [playerNo, unsubscribe$])

    const [mimeType, setMimeType] = useState<'audio' | 'video'>()
    useEffect(() => {
      const video = player?.getInternalPlayer?.() as HTMLVideoElement
      if (progress.playedSeconds && video) {
        setMimeType(!video.videoWidth || !video.videoHeight ? 'audio' : 'video')
      }
    }, [player, progress.playedSeconds])

    const handlePlay = () => {
      if (!url) return

      if (!isShowVideo) {
        setIsShowVideo(true)
      }

      if (playEnd) {
        setPlayEnd(false)
      }

      setPlaying(!playing)
    }

    const handleMuted = (e: Event|SyntheticEvent) => {
      e.stopPropagation()
      setIsMuted(!isMuted)
    }

    const handlePlayEnd = useCallback(() => {
      setPlayEnd(true)
      setPlaying(false)
    }, [])

    return (
      <div
        className={clsx(Style.videoPlayer, {
          [Style.showControls]: showControls,
          [Style.blackWallpaper]: progress.playedSeconds && mimeType === 'video',
          [Style.flip]: isFlip
        }, props.className)}
        style={{
          ...style,
          zIndex: isShowVideo ? 0 : 2,
          backgroundImage: progress.playedSeconds && mimeType === 'video'
            ? undefined
            : StyleUtils.backgroundImage(placeholder)
        }}
        onClick={handlePlay}
      >
        {isShowVideo && (
          <div className={Style.videoPlayerContainer}>
            <ReactPlayer
              ref={ref}
              width="100%"
              height="100%"
              style={{ display: 'flex' }}
              url={url}
              playing={playing}
              muted={props.muted || isMuted}
              onReady={(_player) => { setPlayer(_player); props.onReady?.(_player) }}
              onDuration={(_duration) => setDuration(_duration)}
              onProgress={(_progress) => setProgress(_progress)}
              onEnded={handlePlayEnd}
              config={{
                file: {
                  forceAudio: props.mimeType ? props.mimeType.startsWith('audio') : false,
                  attributes: {
                    preload: 'auto',
                    crossOrigin: 'anonymous'
                  }
                  // tracks: tracks || []
                }
              }}
            />

            <div className={Style.videoPlayerSpeaker} onClick={handleMuted}>
              {isMuted ? <IconSpeakerOff/> : <IconSpeakerOn/>}
            </div>

            <Control onChange={val => setShowControls(!!val)}>
              <div>{DateUtils.formatSecondsToMinutes(progress.playedSeconds)}</div>
              <Range
                value={progress.played}
                onChange={(percent) => {
                  setProgress((prev) => ({
                    ...prev,
                    played: percent
                  }))
                  player?.seekTo(duration * percent)
                }}
              />
              <div>{DateUtils.formatSecondsToMinutes(duration)}</div>
            </Control>
          </div>
        )}

        <div className={Style.videoPlayerPlay} style={{ display: !playing || playEnd ? 'flex' : 'none' }}>
          <IconPlay/>
        </div>
      </div>
    )
  }
)

VideoPlayer.displayName = 'VideoPlayer'
