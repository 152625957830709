import { IVideoModel } from 'src/interfaces'

export class VideoUtils {
  static getVideoSource(video?: Partial<IVideoModel>) {
    return video?.urlVideoStandardQuality || video?.urlVideoSource
  }

  static captureSnapshot(video: HTMLVideoElement, secondAt: number) {
    video.currentTime = secondAt

    // Create a canvas element
    const canvas = document.createElement('canvas')
    const rect = video.getBoundingClientRect()
    canvas.width = video.videoWidth || rect.width
    canvas.height = video.videoHeight || rect.height

    // Get the 2D rendering context
    const context = canvas.getContext('2d')
    if (!context) {
      return Promise.reject(new Error('Failed to create 2D rendering context'))
    }

    // Draw the current frame of the video onto the canvas
    context.drawImage(video, 0, 0, canvas.width, canvas.height)

    return new Promise<File>((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (blob) {
          return resolve(new File([blob], `video-snapshot-${Date.now()}.png`, { type: blob.type || 'image/png' }))
        }
        reject(new Error('Failed to capture video snapshot'))
      }, 'image/png')
    })
  }
}
