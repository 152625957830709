import { IRouterOption } from 'src/router'
import { Campaign } from './components/campaign'
import { Campaigns } from './components/campaigns'
import { Dashboard } from './components/dashboard'
import { Submissions } from './components/submissions'
import { EPaths } from './routes.path'

export enum ERoutes {
  CAMPAIGN_DASHBOARD = 'Campaign.Dashboard',
  CAMPAIGN = 'Campaign.Campaigns',
  CAMPAIGN_DETAIL = 'Campaign.Campaign',
  CAMPAIGN_SUBMISSIONS = 'Campaign.Submissions'
}

export const Routes: IRouterOption[] = [
  {
    path: EPaths.CAMPAIGN_DASHBOARD,
    name: ERoutes.CAMPAIGN_DASHBOARD,
    component: Dashboard,
    exact: true,
    meta: {}
  },
  {
    path: EPaths.CAMPAIGN,
    name: ERoutes.CAMPAIGN,
    component: Campaigns,
    exact: true,
    meta: {}
  },
  {
    path: EPaths.CAMPAIGN_DETAIL,
    name: ERoutes.CAMPAIGN_DETAIL,
    component: Campaign,
    exact: true,
    meta: {}
  },
  {
    path: EPaths.CAMPAIGN_SUBMISSIONS,
    name: ERoutes.CAMPAIGN_SUBMISSIONS,
    component: Submissions,
    exact: true,
    meta: {}
  }
]

export const CampaignModule = {
  ERoutes,
  Routes
}
