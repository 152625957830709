import { FC } from 'react'
import { Button, Col, Form, Input } from 'antd'
import { NotAllowAuthenticated } from 'src/components'
import { useStore, GlobalState, AuthAction, GlobalAction } from 'src/store'

export const SignIn: FC = () => {
  const { value: { loading } } = useStore(GlobalState)

  const onFinish = async (values: {
    identify: string
    password: string
  }) => {
    GlobalAction.setLoading(true)
    await AuthAction.signIn(values)
    GlobalAction.setLoading(false)
  }

  const onFinishFailed = (errors: any) => {
    console.log('Failed:', errors)
  }

  return (
    <NotAllowAuthenticated>
      <section className="mod-auth-sign-in">
        <Col
          xs={24}
          sm={{ span: 16 /** , offset: 4 */ }}
          md={{ span: 12 /** , offset: 6 */ }}
          lg={{ span: 10 /** , offset: 7 */ }}
          xl={{ span: 8 /** , offset: 8 */ }}
          xxl={{ span: 6 /** , offset: 9 */ }}
        >
          <Form
            name="sing-in-form"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              name="identify"
              rules={[{ required: true, message: 'Please input your username!' }]}
            >
              <Input placeholder="Username"/>
            </Form.Item>

            <Form.Item
              name="password"
              rules={[{ required: true, message: 'Please input your password!' }]}
            >
              <Input.Password placeholder="Password"/>
            </Form.Item>

            <Form.Item wrapperCol={{ span: 24 }}>
              <Button
                type="primary"
                htmlType="submit"
                block
                disabled={loading}
              >
                SIGN IN
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </section>
    </NotAllowAuthenticated>
  )
}
