import { IRouterOption } from 'src/router'
import { NotificationTemplate } from './components/list'
import { Sent } from './components/sent'
import { EPaths } from './routes.path'

export enum ERoutes {
  NOTIFICATION_TEMPLATE = 'NotificationTemplate.List',
  NOTIFICATION_TEMPLATE_SENT = 'NotificationTemplate.Sent'
}

export const Routes: IRouterOption[] = [
  {
    path: EPaths.NOTIFICATION_TEMPLATE,
    component: NotificationTemplate,
    name: ERoutes.NOTIFICATION_TEMPLATE,
    exact: true,
    meta: {}
  },
  {
    path: EPaths.NOTIFICATION_TEMPLATE_SENT,
    component: Sent,
    name: ERoutes.NOTIFICATION_TEMPLATE_SENT,
    exact: true,
    meta: {}
  }
]

export const NotificationTemplateModule = {
  ERoutes,
  Routes
}
