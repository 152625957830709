import axios from 'axios'
import { EQueueName, IPaginationQuery, IQueueJobModel, TAxiosResponseData, TAxiosResponsePagination } from 'src/interfaces'

export class QueueJobApi {
  static readonly _prefix = '/queue-jobs'

  static index(params: IPaginationQuery = {}): TAxiosResponsePagination<IQueueJobModel> {
    return axios.get(`${QueueJobApi._prefix}`, { params })
  }

  static store(payload: {
    queueName: EQueueName
    data: { [key: string]: any }
  }): TAxiosResponseData<IQueueJobModel> {
    return axios.post(`${QueueJobApi._prefix}`, payload)
  }

  static retry(payload: { queueName: EQueueName }): TAxiosResponseData<void> {
    return axios.post(`${QueueJobApi._prefix}/retry`, payload)
  }

  static show(id: IQueueJobModel['id']): TAxiosResponseData<IQueueJobModel> {
    return axios.get(`${QueueJobApi._prefix}/${id}`)
  }
}
