import { IModel } from './model'

export enum ECampaignIndicatorKey {
  COUNT_BY_ROLE_LOCATION = 'countByRoleLocation',
  COUNT_BY_ROLE_MOTIVATOR = 'countByRoleMotivator',
  COUNT_BY_ROLE_WORKSTYLE = 'countByRoleWorkstyle'
}

export enum ECampaignIndicatorLocation {
  NEW_YORK_NY = 'New York, NY',
  LOS_ANGELES_CA = 'Los Angeles, CA',
  CHICAGO_IL = 'Chicago, IL',
  PHOENIX_AZ = 'Phoenix, AZ',
  SAN_DIEGO_CA = 'San Diego, CA',
  DALLAS_TX = 'Dallas, TX',
  AUSTIN_TX = 'Austin, TX',
  SAN_FRANCISCO_CA = 'San Francisco, CA',
  SEATTLE_WA = 'Seattle, WA',
  MIAMI_FL = 'Miami, FL',
  UNITED_STATES = 'United States'
}

export enum ECampaignIndicatorRole {
  CUSTOMER_SERVICE = 'Customer Service',
  FINANCE = 'Finance',
  INVESTMENT_BANKING = 'Investment Banking',
  ACCOUNTING = 'Accounting',
  SUPPLY_CHAIN_MANAGEMENT = 'Supply Chain Management',
  HR = 'HR',
  LAW = 'Law',
  MARKETING = 'Marketing',
  PUBLIC_RELATIONS = 'Public Relations',
  SALES = 'Sales',
  BUSINESS_DEVELOPMENT = 'Business Development'
}

export interface ICampaignIndicatorCount {
  location?: ECampaignIndicatorLocation
  jobRole?: ECampaignIndicatorRole
  minSalary: number
  maxSalary: number
  count: number
}

export type ICampaignIndicatorRoleMotivator = Record<ECampaignIndicatorRole, Record<string, number>>

export interface ICampaignIndicatorModel<T extends Array<ICampaignIndicatorCount> | ICampaignIndicatorRoleMotivator = Array<ICampaignIndicatorCount>> extends IModel {
  key: ECampaignIndicatorKey
  data: T
}
