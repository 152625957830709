import { FC, useEffect } from 'react'
import { BreadcrumbService } from 'src/services'
import { EPaths } from '../../routes.path'

const PAGE_TITLE = 'Vibe Check Videos'

export const VibeCheckVideo: FC = () => {
  /**
   * breadcrumb
   */
  useEffect(() => {
    BreadcrumbService.items = [{
      route: EPaths.VIBE_CHECK,
      label: PAGE_TITLE
    }]
    return () => {
      BreadcrumbService.items = []
    }
  }, [])

  return (
    <section>
      <b>Feature under development</b>
    </section>
  )
}
