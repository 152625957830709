import { Card, Form, Input } from 'antd'
import Meta from 'antd/lib/card/Meta'
import { ComponentProps, FC, useEffect, useState } from 'react'
import { finalize, from, takeUntil } from 'rxjs'
import { VideoApi } from 'src/api'
import { useCallbackUnsubscribe } from 'src/hooks'
import { IVideoModel } from 'src/interfaces'
import { NotifyUtils } from 'src/utils'
import { Loading } from '../loading'
import { ModalFullscreen } from '../modal-fullscreen'
import { formItemLayout } from './constant'

export const ModalEditVibeSharing: FC<Omit<ComponentProps<typeof ModalFullscreen>, 'onOk'> & {
  video?: IVideoModel
  onOk?: (video?: Pick<IVideoModel, 'title' | 'description'>) => any
}> = ({
  video,
  ...props
}) => {
  const [form] = Form.useForm()
  const title = Form.useWatch('title', form)
  const description = Form.useWatch('description', form)

  useEffect(() => {
    form.setFieldValue('title', video?.title || 'KnowMe Vibe Check')
    form.setFieldValue('description', video?.description)
  }, [video, form])

  const [loading, setLoading] = useState(false)

  const onSubmit = useCallbackUnsubscribe((unsubscribe$) => {
    const changedCallback = () => props.onOk && props.onOk({
      title,
      description
    })

    if (!video || (title === video.title && description === video.description)) {
      return changedCallback()
    }

    setLoading(true)
    from(VideoApi.updateSEOMetadata(video.id, { title, description }))
      .pipe(
        finalize(() => setLoading(false)),
        takeUntil(unsubscribe$)
      )
      .subscribe({
        next: () => changedCallback(),
        error: NotifyUtils.handleAxiosError
      })
  }, [video, title, description])

  return (
    <ModalFullscreen
      {...props}
      onOk={onSubmit}
    >
      {video && (
        <>
          <Loading show={loading}/>

          <div className="fx fx-jc-center mb-2" style={{ gap: 24 }}>
            <Card
              hoverable
              style={{ width: 240 }}
              cover={<img alt="example" src={video.urlVideoImageThumbnail}/>}
            >
              <Meta title={title} description={description || 'No description provided yet'}/>
            </Card>

            <Form
              {...formItemLayout}
              form={form}
              name="register"
              // onFinish={onFinish}
              layout="vertical"
              scrollToFirstError
              style={{ width: '400px' }}
            >
              <Form.Item
                name="title"
                label="Title"
                rules={[{ required: true, message: 'Please input title!' }]}
              >
                <Input placeholder="Candidate sharing vibe title"/>
              </Form.Item>

              <Form.Item
                name="description"
                label="Description"
                rules={[{ required: true, message: 'Please input description' }]}
              >
                <Input.TextArea showCount maxLength={255} rows={10} placeholder="Candidate sharing vibe description"/>
              </Form.Item>
            </Form>
          </div>
        </>
      )}
    </ModalFullscreen>
  )
}
