import { TableProps } from 'antd'
import { IContactFormModel } from 'src/interfaces'
import { TimeZoneService } from 'src/services'

export const renderColumns = ({
  onUpdate,
  onDelete
}: {
  onUpdate?: (record: IContactFormModel) => any
  onDelete?: (record: IContactFormModel) => any
} = {}): Required<TableProps<IContactFormModel>>['columns'] => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email'
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Content',
    dataIndex: 'content',
    key: 'content',
    render: (val: string) => (
      <span title={val} className="twoline-text" style={{ maxWidth: '300px' }}>{val}</span>
    )
  },
  {
    title: 'CreatedAt',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (val: string) => TimeZoneService.format(val)
  }
  // {
  //   title: 'UpdatedAt',
  //   dataIndex: 'updatedAt',
  //   key: 'updatedAt',
  //   render: (val: string) => TimeZoneService.format(val)
  // },
  // {
  //   title: 'Action',
  //   key: 'action',
  //   render: (text: string, record) => (
  //     <Space
  //       wrap
  //       size="small"
  //       direction="vertical"
  //     >
  //       {onDelete && (
  //         <Popconfirm
  //           title="Confirm to delete?"
  //           onConfirm={() => onDelete(record)}
  //           okText="Yes"
  //           cancelText="No"
  //         >
  //           <Button size="small" danger type="primary">Delete</Button>
  //         </Popconfirm>
  //       )}
  //     </Space>
  //   )
  // }
]
