
export class DateUtils {
  static formatSecondsToMinutes(time: number) {
    return ('0' + Math.floor(time / 60)) + ':' + ('0' + Math.floor(time % 60)).slice(-2)
  }

  static toLocaleString(date?: string) {
    if (date) {
      return new Date(date).toLocaleString()
    }
  }
}
