import { StarFilled } from '@ant-design/icons'
import { Avatar, Button, Card, Rate, Row, Space, Tooltip } from 'antd'
import { FC, useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import { finalize, from, takeUntil } from 'rxjs'
import { VideoApi } from 'src/api'
import { DialogAddToCampaign, DialogIncreaseViews, DialogVideoCampaigns, ModalSendNotification, Video } from 'src/components'
import { ERoleType } from 'src/constants/enum'
import { useBehaviorMapper, useCallbackUnsubscribe } from 'src/hooks'
import { IVideoModel } from 'src/interfaces'
import { ERoutes, generate } from 'src/router'
import { GodModeService } from 'src/services'
import { useAuthorization } from 'src/store'
import { NotifyUtils } from 'src/utils'

const mapRatingToTooltip: Record<number, string> = {
  1: 'Bad video',
  2: 'Poor video',
  3: 'OK video',
  4: 'Good video',
  5: 'Great video'
}

const customIcons: Record<number, React.ReactNode> = {
  1: <Tooltip title={mapRatingToTooltip[1]}><StarFilled/></Tooltip>,
  2: <Tooltip title={mapRatingToTooltip[2]}><StarFilled/></Tooltip>,
  3: <Tooltip title={mapRatingToTooltip[3]}><StarFilled/></Tooltip>,
  4: <Tooltip title={mapRatingToTooltip[4]}><StarFilled/></Tooltip>,
  5: <Tooltip title={mapRatingToTooltip[5]}><StarFilled/></Tooltip>
}

interface IProps {
  item: IVideoModel
  children?: React.ReactNode
}

export const VibeRatingVideo: FC<IProps> = ({ item, children }) => {
  const { is } = useAuthorization()
  const isGodMode = useBehaviorMapper(GodModeService.enable$)

  const [rateScore, setRateScore] = useState<Record<string, number | undefined>>({
    articulation: item.videoRatings?.[0]?.articulation,
    vocabulary: item.videoRatings?.[0]?.vocabulary,
    energy: item.videoRatings?.[0]?.energy,
    content: item.videoRatings?.[0]?.content
  })

  const [loading, setLoading] = useState(false)

  const onChangeRating = useCallback((name: string, value: number) => {
    setRateScore((prev) => ({
      ...prev,
      [name]: value
    }))
  }, [setRateScore])

  const onRatingSaved = useCallbackUnsubscribe((unsubscribe$) => {
    if (item.id) {
      setLoading(true)
      const promise = VideoApi.updateRate({
        videoId: item.id,
        ...rateScore
      })
      from(promise)
        .pipe(
          finalize(() => setLoading(false)),
          takeUntil(unsubscribe$)
        )
        .subscribe({
          next: () => NotifyUtils.success({ message: 'Rating saved' }),
          error: NotifyUtils.handleAxiosError
        })
    }
  }, [rateScore, item.id])

  return (
    <Card className="video-item" bodyStyle={{ padding: '8px' }}>
      {children}

      <div className="fx fx-jc-center p-1 px-2" style={{ width: '100%' }}>
        <Video
          className="mb-3"
          video={item}
        />
      </div>

      <div style={{ display: 'flex', paddingLeft: '4px', gap: '8px' }}>
        <Avatar size="large" style={{ verticalAlign: 'middle' }} src={item.author?.pfp?.url}>
          {item.author?.fullName}
        </Avatar>

        {/* {
          item.author?.pfp
            ? (<Avatar src={<Image src={item.author.pfp.url} style={{ width: 32 }}/>}/>)
            : (
              <Avatar style={{ verticalAlign: 'middle' }} size="large">
                {item.author?.fullName}
              </Avatar>
              )
        } */}

        <div className="fx fx-column">
          <span>{item.author?.fullName}</span>
          <span style={{ fontSize: '12px', color: '#bdc3c7', fontWeight: 400 }}>@{item.author?.username}</span>
        </div>
      </div>

      <div style={{ paddingRight: '12px', paddingLeft: '12px' }}>
        {Object.keys(rateScore).map((key) => (
          <Row key={key}>
            <div style={{ display: 'flex', alignContent: 'space-between', width: '100%', alignItems: 'center' }}>
              <span style={{ fontSize: '12px', color: '#bdc3c7', fontWeight: 400, textTransform: 'capitalize' }}>{key}</span>
              <div style={{ textAlign: 'right', width: '100%' }}>
                <Rate value={rateScore[key]} character={({ index }) => customIcons[(index ?? 0) + 1]} onChange={(value) => onChangeRating(key, value)}/>
              </div>
            </div>
          </Row>
        ))}

        <Space wrap>
          <Link to={generate([ERoutes.CANDIDATE_DASHBOARD, { id: item.userIdAuthor }])}>
            Dashboard
          </Link>

          <DialogVideoCampaigns
            btnProps={{ type: 'link', size: 'small' }}
            videoId={item.id}
          />

          <DialogAddToCampaign
            btnProps={{ type: 'link', size: 'small' }}
            videoId={item.id}
          />

          {isGodMode && (
            <DialogIncreaseViews
              ids={[item.id]}
              btnProps={{
                danger: true,
                type: 'primary',
                size: 'small'
              }}
            >
              Increase Views
            </DialogIncreaseViews>
          )}

          {is(ERoleType.SUPER_ADMIN) && item.userIdAuthor && (
            <ModalSendNotification
              id={item.userIdAuthor}
              btnProps={{
                type: 'ghost',
                size: 'small'
              }}
            >
              Send notification
            </ModalSendNotification>
          )}
        </Space>
      </div>

      <div style={{ padding: '4px', display: 'flex', flexDirection: 'row-reverse', width: '100%', marginTop: '12px' }}>
        <Button
          type="primary"
          style={{ width: '100%' }}
          loading={loading}
          onClick={onRatingSaved}
        >
          Save
        </Button>
      </div>
    </Card>
  )
}
