import { FileImageOutlined, FilePdfOutlined, FileUnknownOutlined, PlaySquareOutlined, VideoCameraOutlined } from '@ant-design/icons'
import clsx from 'clsx'
import { FC, useEffect, useMemo, useState } from 'react'
import { FileUtils } from 'src/utils'
import { ModalFullscreen } from '../modal-fullscreen'
import Style from './style.module.scss'

interface IProps {
  src?: string
  filename?: string
  mimeType?: string
  className?: string
}

export const FilePreviewIcon: FC<IProps> = (props) => {
  const [open, setOpen] = useState(false)
  const [filename, setFilename] = useState<string>()

  const fileKind = useMemo(() => ({
    isPdf: FileUtils.isPdf(props.mimeType || filename, !!props.mimeType),
    isPhoto: FileUtils.isPhoto(props.mimeType || filename, !!props.mimeType),
    isAudio: FileUtils.isAudio(props.mimeType || filename, !!props.mimeType),
    isVideo: FileUtils.isVideo(props.mimeType || filename, !!props.mimeType)
  }), [filename, props.mimeType])

  const isSupported = useMemo(
    () => Object.values(fileKind).some(Boolean),
    [fileKind]
  )

  console.log(fileKind, props.src)

  const ext = useMemo(() => String(filename).split('.').pop(), [filename])

  useEffect(() => {
    if (props.filename) {
      setFilename(props.filename.split('/').pop())
    } else if (props.src) {
      setFilename(props.src.split('/').pop())
    }
  }, [props.src, props.filename])

  if (!filename) {
    return null
  }

  return (
    <>
      <span
        className={clsx(Style.filePreview, props.className)}
        onClick={() => setOpen((prev) => !prev)}
      >
        {
          !isSupported
            ? <FileUnknownOutlined/>
            : (
              <>
                {fileKind.isPhoto && (
                  <FileImageOutlined/>
                )}

                {fileKind.isAudio && (
                  <PlaySquareOutlined/>
                )}

                {fileKind.isVideo && (
                  <VideoCameraOutlined/>
                )}

                {fileKind.isPdf && (
                  <FilePdfOutlined/>
                )}
              </>
            )
        }

        {filename}
      </span>

      {open && (
        <ModalFullscreen
          open
          centered
          closable
          maskClosable
          destroyOnClose
          footer={null}
          onCancel={() => setOpen(false)}
          afterClose={() => setOpen(false)}
        >
          <div className={Style.previewContent}>
            {
              !isSupported
                ? (
                  <div className={Style.notSupport}>
                    <b>Woops file preview not supported</b>: Please download the file to view it: <a href={props.src} download>Download</a>.
                  </div>
                )
                : (
                  <>
                    {fileKind.isPhoto && (
                      <img src={props.src} alt="preview"/>
                    )}

                    {fileKind.isAudio && (
                      <audio controls>
                        <source src={props.src} type="audio/mpeg"/>
                        Your browser does not support the audio element.
                      </audio>
                    )}

                    {fileKind.isVideo && (
                      <video controls>
                        <source src={props.src} type={`video/${ext}`}/>
                        Your browser does not support the video tag.
                      </video>
                    )}

                    {fileKind.isPdf && (
                      <object data={props.src} type="application/pdf">
                        <p><b>Example fallback content</b>: This browser does not support PDFs. Please download the PDF to view it: <a href={props.src} download>Download PDF</a>.</p>
                      </object>
                    )}
                  </>
                )
            }
          </div>
        </ModalFullscreen>
      )}
    </>
  )
}
