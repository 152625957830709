import { InfoCircleTwoTone, PlusCircleOutlined } from '@ant-design/icons'
import { Button, Popconfirm, Space, Switch, Tag } from 'antd'
import axios, { AxiosError } from 'axios'
import clsx from 'clsx'
import { CSSProperties, ComponentProps, FC, useCallback, useState } from 'react'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { VideoApi } from 'src/api'
import { EVideoPrivacy } from 'src/constants/enum'
import { useDidMountEffect, useUnsubscribe } from 'src/hooks'
import { IVideoModel } from 'src/interfaces'
import { GlobalAction } from 'src/store'
import { EntityColorUtils, NotifyUtils } from 'src/utils'
import { VideoPlayer } from '../video-player'
import { ModalVideoHashtags } from './modal-video-hashtags'
import { ModalVideoTags } from './modal-video-tags'
import { ModalIVideoTranscription } from './modal-video-transcription'

interface IProps {
  className?: string
  style?: CSSProperties
  video: IVideoModel
  playerProps?: ComponentProps<typeof VideoPlayer>
}
export const Video: FC<IProps> = (props) => {
  const unsubscribe$ = useUnsubscribe()
  const [loading, setLoading] = useState(false)
  const [video, setVideo] = useState<IVideoModel>(props.video)

  const onPrivacyChange = useCallback(() => {
    const privacy = video.privacy === EVideoPrivacy.PUBLIC
      ? EVideoPrivacy.PRIVATE
      : EVideoPrivacy.PUBLIC

    setLoading(true)
    const promise = VideoApi.updatePrivacy({
      videoId: video.id,
      privacy
    })
    from(promise)
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error: AxiosError) => {
          NotifyUtils.handleAxiosError(error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(() => (video.privacy = privacy) && setVideo(video))
  }, [unsubscribe$, video])

  const onDownload = useCallback(() => {
    GlobalAction.setLoading(true)
    const promise = axios({
      url: video.urlVideoSource,
      method: 'GET',
      responseType: 'blob'
    }).then((response) => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data as unknown as Blob)

      // create "a" HTML element with href to file & click
      const link = document.createElement('a')
      link.href = href
      link.setAttribute('download', `${video.title || Date.now()}.webm`)
      document.body.appendChild(link)
      link.click()

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link)
      URL.revokeObjectURL(href)
    })
    from(promise)
      .pipe(
        takeUntil(unsubscribe$),
        finalize(() => GlobalAction.setLoading(false))
      )
      .subscribe()
  }, [unsubscribe$, video.title, video.urlVideoSource])

  useDidMountEffect(() => {
    setVideo(props.video)
  }, [props.video])

  return (
    <div
      style={{
        ...props.style,
        maxWidth: '250px'
      }}
      className={clsx('fx fx-column gap-1', props.className)}
    >
      <VideoPlayer
        {...props.playerProps}
        className="mx-auto"
        tracks={video.tracks}
        url={video.urlVideoStandardQuality || video.urlVideoSource}
        image={video.urlVideoImageThumbnail}
        animatedImage={video.urlVideoAnimatedImage}
      />

      <div className="fx fx-wrap-wrap fx-ai-center gap-1">
        <strong>Origin:</strong>
        <Tag>{video.videoType}</Tag>
        <ModalIVideoTranscription
          className="ml-auto pointer"
          video={video}
          onOk={(video) => setVideo({ ...video })}
        >
          <InfoCircleTwoTone title="View Transcription"/>
        </ModalIVideoTranscription>
      </div>

      <div className="fx fx-column gap-1">
        <strong className="fx fx-jc-space-between">
          <span>Video Tags:</span>
          <ModalVideoTags
            video={video}
            onOk={(video) => setVideo({ ...video })}
          >
            <PlusCircleOutlined className="pointer"/>
          </ModalVideoTags>
        </strong>

        <div className="fx fx-wrap-wrap gap-1">
          {video.videoTags?.map((tName) => (
            <Tag key={tName}>{tName}</Tag>
          ))}
        </div>

        <strong className="fx fx-jc-space-between">
          <span>Hashtags:</span>
          <ModalVideoHashtags
            title="Edit hashtag of the following video"
            video={video}
            onOk={(video) => setVideo({ ...video })}
          >
            <PlusCircleOutlined className="pointer"/>
          </ModalVideoHashtags>
        </strong>

        <div className="fx fx-wrap-wrap gap-1">
          {video.hashtags?.map((hashtag) => (
            <Tag
              key={hashtag.id}
              color={EntityColorUtils.get('hashtag', hashtag.id)}
            >
              {hashtag.title}
            </Tag>
          ))}
        </div>

        <Space wrap>
          <Popconfirm
            disabled={loading}
            title="Are you sure to update privacy of this vibe?"
            okText="Yes"
            cancelText="No"
            onConfirm={onPrivacyChange}
          >
            <Switch
              checkedChildren="Public"
              unCheckedChildren="Private"
              checked={video.privacy === EVideoPrivacy.PUBLIC}
            />
          </Popconfirm>
          {video.urlVideoSource && (
            <Button
              type="link"
              disabled={loading}
              onClick={onDownload}
            >
              Download
            </Button>
          )}
        </Space>
      </div>
    </div>
  )
}
