import { isEqual } from 'lodash'
import { BehaviorSubject, from, tap } from 'rxjs'
import { ConversationApi, MessageApi } from 'src/api'
import { IConversationModel, IMessageModel } from 'src/interfaces'
import { AuthState } from 'src/store'
import { LoadMoreService } from './loadmore.service'

export const MessengerService = new (
  class {
    readonly _loadMoreConversations = new LoadMoreService<IConversationModel>(ConversationApi)

    private readonly _conversations$ = this._loadMoreConversations.items$
    get conversations$(): Omit<typeof this._conversations$, 'next' | 'complete'> {
      return this._conversations$
    }

    private readonly _conversation$ = new BehaviorSubject<IConversationModel | null>(null)
    get conversation$(): Omit<typeof this._conversation$, 'next' | 'complete'> {
      return this._conversation$
    }

    private readonly _metadata$ = new BehaviorSubject<Awaited<ReturnType<typeof ConversationApi.pull>>['data']>({})

    private _pulling = false
    private _pausePulling = false
    private _pull() {
      if (!this._pulling) {
        return
      }

      if (this._pausePulling) {
        return setTimeout(() => {
          this._pull()
        }, 3000)
      }

      ConversationApi.pull()
        .then(({ data }) => {
          if (!isEqual(data, this._metadata$.value)) {
            this._metadata$.next(data)
          }
        })
        .finally(() => {
          setTimeout(() => {
            this._pull()
          }, 3000)
        })
    }

    constructor() {
      this._conversation$.subscribe((conversation) => {
        if (!conversation) {
          return
        }

        if (this.isUnread(conversation)) {
          this.read(conversation)
        }

        if (this._conversations$.value.some(({ id }) => id === conversation.id)) {
          return
        }
        this._loadMoreConversations.addFirst([conversation])
      })

      this._metadata$.subscribe((metadata) => {
        if (metadata.conversations) {
          const conversations = this._conversations$.value
          for (const [conversationId, lastMessageTime] of Object.entries(metadata.conversations)) {
            const existed = conversations.find(({ id }) => id === +conversationId)
            if (
              !existed ||
              (existed.lastMessage?.updatedAt && new Date(existed.lastMessage.updatedAt).getTime() < lastMessageTime)
            ) {
              this._pausePulling = true
              return ConversationApi.paginate()
                .then(({ data }) => {
                  if (!data.rows?.length) {
                    return
                  }
                  this._loadMoreConversations.addFirst(data.rows)
                  if (!this.conversation$.value) {
                    return
                  }
                  for (const conversation of data.rows) {
                    if (conversation.id === this.conversation$.value.id) {
                      this.setConversation(conversation)
                    }
                  }
                })
                .finally(() => {
                  this._pausePulling = false
                })
            }
          }
        }
      })
    }

    pull() {
      if (!this._pulling) {
        this._pulling = true
        setTimeout(() => {
          this._pull()
        }, 3000)
      }
    }

    stopPull() {
      this._pulling = false
    }

    setConversation(conversation?: IConversationModel) {
      if (!conversation) {
        return this._conversation$.next(null)
      }
      // if (this._conversation$.value?.id === conversation.id) {
      //   // prevent re-render on messages
      //   return this._conversation$.next(Object.assign(this._conversation$.value, conversation))
      // }
      this._conversation$.next(conversation)
    }

    isUnread(conversation: IConversationModel) {
      const _userId = AuthState.value.user?.id
      if (!_userId) {
        return
      }
      return conversation.conversationUsers?.some(({ userId, readMessageId }) => {
        return userId === _userId && readMessageId !== conversation.lastMessageId
      })
    }

    read(
      conversation: IConversationModel,
      message?: IMessageModel,
      moveToTop?: boolean
    ) {
      const _userId = AuthState.value.user?.id
      if (!_userId) {
        return
      }
      conversation.updatedAt = new Date().toISOString()
      if (message) {
        conversation.updatedAt = message.updatedAt
        conversation.lastMessageId = message.id
        conversation.lastMessage = message
      }
      const conversationUser = conversation.conversationUsers?.find(
        ({ userId }) => userId === _userId
      )
      if (conversationUser) {
        conversationUser.readMessageId = conversation.lastMessageId
      }

      // force re-render
      if (moveToTop) {
        this._loadMoreConversations.addFirst(conversation)
      }
      this._loadMoreConversations.addFirst([])
    }

    getConversationTitle(conversation: IConversationModel) {
      if (conversation.title) {
        return conversation.title
      }
      return conversation.conversationUsers?.find(({ userId }) => userId !== AuthState.value.user?.id)?.alias
    }

    sendMessage(payload: Parameters<typeof MessageApi.create>[0]) {
      const conversation = this.conversation$.value
      if (!payload.conversationId && !conversation?.id) {
        return from(Promise.reject(new Error('No conversation selected')))
      }
      const promise = MessageApi.create({
        ...payload,
        conversationId: payload.conversationId || conversation?.id
      })
      return from(promise).pipe(
        tap(({ data }) => {
          const conversation = this.conversations$.value.find(({ id }) => id === data.conversationId)
          conversation && this.read(conversation, data, true)
        })
      )
    }
  }
)()
