import { TableProps } from 'antd'
import { IUserModel } from 'src/interfaces'
import { UserUtils } from 'src/utils'

export const renderColumns = (): TableProps<IUserModel>['columns'] => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (_, doc) => UserUtils.getFullName(doc)
  },
  {
    title: 'Status',
    dataIndex: 'connectedCalendar',
    key: 'connectedCalendar',
    render: (connectedCalendar) => connectedCalendar ? 'Connected' : 'Disconnected'
  },
  {
    title: 'Interview Event',
    dataIndex: 'totalInterviewTipsEvents',
    key: 'totalInterviewTipsEvents'
  },
  {
    title: 'Pitch Event',
    dataIndex: 'totalElevatorEvents',
    key: 'totalElevatorEvents'
  }
]
