import { Button, Modal, Select, Space, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { FC, useCallback, useEffect, useState } from 'react'
import { finalize, from, takeUntil } from 'rxjs'
import { CampaignSettingApi } from 'src/api'
import { useUnsubscribe } from 'src/hooks'
import { ICampaignSettingModel } from 'src/interfaces'
import { NotifyUtils } from 'src/utils'

interface IProps {
  open: boolean
  onOk: () => void
}

const valueOptions = [
  {
    label: '0h',
    value: 0
  },
  {
    label: '24h',
    value: 24
  },
  {
    label: '48h',
    value: 48
  },
  {
    label: '72h',
    value: 72
  }
]

export const CampaignSettingsModal: FC<IProps> = ({ open, onOk }) => {
  const [campaignSettings, setCampaignSettings] = useState<ICampaignSettingModel[]>([])
  const [loading, setLoading] = useState(false)
  const unsubscribe$ = useUnsubscribe()

  const loadCampaignSettings = useCallback(() => {
    setLoading(true)

    from(CampaignSettingApi.list())
      .pipe(
        takeUntil(unsubscribe$),
        finalize(() => setLoading(false))
      )
      .subscribe(({ data }) => setCampaignSettings(data))
  }, [unsubscribe$])

  useEffect(() => {
    loadCampaignSettings()
  }, [loadCampaignSettings])

  const changeSettingValue = (index: number, value: string) => {
    setCampaignSettings(campaignSettings => campaignSettings.map((campaignSetting, i) => {
      if (i === index) {
        return { ...campaignSetting, value }
      }
      return campaignSetting
    }))
  }

  const handleSaveCampaignSetting = (index: number) => {
    const campaignSetting = campaignSettings.at(index)
    if (!campaignSetting || !campaignSetting.key) {
      return
    }

    // if (!campaignSetting.id) {
    //   setLoading(true)
    //   from(CampaignSettingApi.create(campaignSetting))
    //     .pipe(
    //       takeUntil(unsubscribe$),
    //       finalize(() => setLoading(false))
    //     )
    //     .subscribe(({ data }) => {
    //       setCampaignSettings(campaignSettings => campaignSettings.map((item, i) => {
    //         if (i === index) {
    //           return { ...item, id: data.id }
    //         }
    //         return item
    //       }))

    //       NotifyUtils.success({ message: 'Campaign setting created successfully' })
    //     })

    //   return
    // }

    setLoading(true)

    from(CampaignSettingApi.update(campaignSetting.key, campaignSetting.value))
      .pipe(
        takeUntil(unsubscribe$),
        finalize(() => setLoading(false))
      )
      .subscribe(() => {
        NotifyUtils.success({ message: 'Campaign setting updated successfully' })
      })
  }

  return (
    <Modal
      title="Manage Campaigns Question"
      centered
      open={open}
      onCancel={onOk}
      footer={[
        <Button type="primary" key="1" onClick={onOk}>Close</Button>
      ]}
    >
      <Table
        pagination={false}
        loading={loading}
        dataSource={campaignSettings}
        columns={renderColumns({
          onChange: (index, value) => changeSettingValue(index, value),
          onSave: (index) => handleSaveCampaignSetting(index)
        })}
      />
    </Modal>
  )
}

const renderColumns = ({
  onChange,
  onSave
}: {
  onChange: (id: number, text: string) => void
  onSave: (index: number) => void
}): ColumnsType<ICampaignSettingModel> => {
  return [
    {
      title: 'Key',
      dataIndex: 'key',
      key: 'key'
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      render: (key, _, index) => (
        <Select
          style={{ width: '100%' }}
          placeholder="Please select"
          defaultValue={key}
          onChange={(value) => onChange(index, value)}
          options={valueOptions}
        />
      )
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record, index) => (
        <Space size="middle">
          <Button type="primary" onClick={() => onSave(index)}>Save</Button>
        </Space>
      )
    }
  ]
}
