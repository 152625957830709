import * as Yup from 'yup'

export const changePasswordSchema = Yup.object().shape({
  currentPassword: Yup.string().knowmePassword('current password'),
  newPassword: Yup.string().knowmePassword('new password')
    .when('currentPassword', (currentPassword, schema) => {
      return currentPassword
        ? Yup.string()
          .notOneOf([Yup.ref('currentPassword')], 'New password must be different from current password.')
        : schema
    }),
  passwordConfirmation: Yup.string().required('Please retype your password.')
    .when('newPassword', (val, schema) => {
      return val
        ? Yup.string().required('Please retype your password.')
          .oneOf([Yup.ref('newPassword'), ''], 'The Password you provided doesn’t match. Wanna try that again?')
        : schema
    })
})
