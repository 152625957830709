import axios from 'axios'
import { IPaginationQuery, IVideoImportModel, TAxiosResponseData, TAxiosResponsePagination } from 'src/interfaces'

export class VideoImportApi {
  static readonly _prefix = '/video-imports'

  static index(params: IPaginationQuery = {}): TAxiosResponsePagination<IVideoImportModel> {
    return axios.get(`${VideoImportApi._prefix}`, { params })
  }

  static store(payload: Pick<IVideoImportModel, 'provider' | 'url'> & { hashtagIds: number[] }): TAxiosResponseData<IVideoImportModel> {
    return axios.post(`${VideoImportApi._prefix}`, payload)
  }

  static show(id: IVideoImportModel['id']): TAxiosResponseData<IVideoImportModel> {
    return axios.get(`${VideoImportApi._prefix}/${id}`)
  }
}
