import { Pagination, Table } from 'antd'
import { omit } from 'lodash'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { AnalyticApi } from 'src/api'
import { SelectCandidate } from 'src/components'
import { IPaginateCallback, IPaginateParams, useBehaviorMapper, useDidMountDebounce, usePaginateParams } from 'src/hooks'
import { ISessionModel } from 'src/interfaces'
import { BreadcrumbService, PaginationService } from 'src/services'
import { EPaths } from '../../routes.path'
import { renderColumns } from './columns'

const PAGE_TITLE = 'Analytics Mobile Sessions'

export const AnalyticsMobileSessions: FC = () => {
  const _paginationService = useMemo(
    () => new PaginationService<ISessionModel>({ endpoint: `${AnalyticApi._prefix}/mobile/sessions` }),
    []
  )
  const loading = useBehaviorMapper(_paginationService.loading$)
  const dataSource = useBehaviorMapper(_paginationService.pagination$)

  const fetch = useCallback<IPaginateCallback>(
    (params) => _paginationService.paging(params),
    [_paginationService]
  )

  const { pagination, pushPagination, setPagination } = usePaginateParams(fetch)
  const [filter, setFilter] = useState<Omit<IPaginateParams, 'page' | 'limit' | 'offset'>>({
    ...omit(pagination, ['page', 'limit', 'offset']),
    userId: pagination.userId
  })

  useDidMountDebounce(() => {
    setPagination({ ...pagination, page: 1 })
    return pushPagination({
      ...pagination,
      page: 1,
      ...filter
    })
  }, 2000, [filter])

  /**
   * breadcrumb
   */
  useEffect(() => {
    BreadcrumbService.items = [{
      route: EPaths.ANALYTIC_MOBILE_SESSIONS,
      label: PAGE_TITLE
    }]
    return () => {
      BreadcrumbService.items = []
    }
  }, [])

  return (
    <section className="fx fx-column fx-extend">
      <div className="fx gap-2 mb-2">
        {/* <Input
          allowClear
          placeholder="Search..."
          style={{ flex: '0 0 20vw' }}
          readOnly={loading}
          value={filter.keyword as string}
          onChange={(e) => {
            e.persist()
            setFilter((prev) => ({
              ...prev,
              keyword: e.target.value
            }))
          }}
        /> */}

        <SelectCandidate
          style={{ flex: '0 0 20vw' }}
          label="fullName"
          value={filter.userId ? Number(filter.userId) : undefined}
          onChange={(userId) => setFilter((prev) => ({
            ...prev,
            userId: userId ? Number(userId) : undefined
          }))}
        />
      </div>

      <Table
        className="fx-extend"
        rowKey="id"
        loading={loading}
        columns={renderColumns()}
        dataSource={dataSource.rows}
        pagination={false}
      />

      <Pagination
        className="fx-as-end mt-3"
        disabled={loading}
        total={dataSource.total}
        current={pagination.page}
        pageSize={pagination.limit}
        {
          ...{
            ...pagination,
            onChange(page, limit) {
              pushPagination({
                ...pagination,
                page,
                limit
              })
            }
          }
        }
      />
    </section>
  )
}
