import { TableProps } from 'antd'
import moment from 'moment'
import { ISessionModel } from 'src/interfaces'
import { TimeZoneService } from 'src/services'
import { UserUtils } from 'src/utils'

export const renderColumns = (): Required<TableProps<ISessionModel>>['columns'] => [
  {
    title: 'User',
    dataIndex: 'user',
    key: 'user',
    render: (val: Required<ISessionModel>['user']) => UserUtils.getFullName(val)
  },
  {
    title: 'Session ID',
    dataIndex: 'session_id',
    key: 'session_id'
  },
  {
    title: 'StartTime',
    dataIndex: 'startTime',
    key: 'startTime',
    render: (val: Required<ISessionModel>['startTime']) => TimeZoneService.format(val, 'MMM D, y HH:mm:ss')
  },
  {
    title: 'EndTime',
    dataIndex: 'endTime',
    key: 'endTime',
    render: (val: Required<ISessionModel>['endTime']) => TimeZoneService.format(val, 'MMM D, y HH:mm:ss')
  },
  {
    title: 'Duration',
    dataIndex: 'duration',
    key: 'duration',
    render: (_, record) => moment.duration(moment(record.startTime).diff(moment(record.endTime))).humanize()
  }
]
