import axios from 'axios'
import { IPaginationQuery, IUserModel, TAxiosResponseData, TAxiosResponsePagination } from 'src/interfaces'
import { AxiosUtils, Helpers } from 'src/utils'

export class HiringManagerApi {
  static readonly _prefix = '/hiring-managers'

  static parseParams(params: IPaginationQuery = {}) {
    return {
      ...params,
      isActive: typeof params.isActive === 'undefined'
        ? undefined
        : Number(params.isActive) === 1
    }
  }

  static index(params: IPaginationQuery = {}): TAxiosResponsePagination<IUserModel> {
    return axios.get(`${HiringManagerApi._prefix}`, { params })
  }

  static download(params: IPaginationQuery = {}): Promise<void> {
    return axios.get(`${HiringManagerApi._prefix}/download`, {
      params,
      responseType: 'arraybuffer'
    }).then((response) => {
      const blob = new Blob([response.data], { type: response.headers['content-type'] })
      const url = URL.createObjectURL(blob)
      Helpers.download({
        href: url,
        filename: AxiosUtils.exposeFileNameFromContentDisposition(response.headers, 'hiring-manager.csv')
      })

      URL.revokeObjectURL(url)
    })
  }

  static destroy(id: number | string, force?: boolean): TAxiosResponseData<void> {
    return axios.delete(`${HiringManagerApi._prefix}/${id}`, { params: { force } })
  }

  static getDetail(id: number | string): TAxiosResponseData<IUserModel> {
    return axios.get(`${HiringManagerApi._prefix}/${id}`)
  }
}
