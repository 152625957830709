import { Button, Form, Input, Select } from 'antd'
import { ChangeEvent, ComponentProps, FC, useCallback, useState } from 'react'
import { finalize, from, takeUntil } from 'rxjs'
import { QueueJobApi } from 'src/api'
import { ModalFullscreen } from 'src/components'
import { useAsRef, useDidMountEffect, useUnsubscribe, useValidation } from 'src/hooks'
import { EQueueName, IQueueJobModel, ScrapedJobProviderOptions } from 'src/interfaces'
import { NotifyUtils } from 'src/utils'
import * as Yup from 'yup'
import { ErrorMessage } from '../error-message'

const schema = Yup.object().shape({
  role: Yup.string().required('Role is required'),
  provider: Yup.string().required('Provider is required'),
  url: Yup.string().url('URL invalid').required('URL is required')
})

interface IProps extends Omit<ComponentProps<typeof ModalFullscreen>, 'onOk' | 'afterClose'> {
  // id?: IQueueJobModel['id']
  // onOk?: () => any
  afterClose?: (saved: boolean, doc?: IQueueJobModel) => any
  btnProps?: ComponentProps<typeof Button>
}

export const ModalQueueScrapeJob: FC<IProps> = ({
  btnProps,
  ...props
}) => {
  const unsubscribe$ = useUnsubscribe()
  const [loading, setLoading] = useState(false)
  const [invisible, setInvisible] = useState<boolean | IQueueJobModel>(true)
  const [formData, setFormData] = useState<{
    role?: string
    provider?: string
    url?: string
  }>({})

  const handleChangeInput = useCallback((e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
    e.persist()
    const { name, value } = e.target
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }))
  }, [])

  const { validate, errors, reset } = useValidation({
    data: formData,
    schema
  })

  const save = useCallback(async () => {
    const { isValid } = await validate()
    if (!isValid) {
      return
    }

    const promise = QueueJobApi.store({
      queueName: EQueueName.SCRAPE_JOB_INFO_QUEUE,
      data: formData
    })

    setLoading(true)
    from(promise)
      .pipe(
        takeUntil(unsubscribe$),
        finalize(() => setLoading(false))
      )
      .subscribe({
        next: ({ data }) => {
          NotifyUtils.success({ message: 'Create queue job successfully' })
          setInvisible(data)
        },
        error: NotifyUtils.handleAxiosError
      })
  }, [formData, unsubscribe$, validate])

  const afterCloseRef = useAsRef(props.afterClose)
  useDidMountEffect(() => {
    reset()

    if (invisible) {
      setLoading(false)
      setFormData(() => ({}))

      const isSaved = typeof invisible !== 'boolean'
      afterCloseRef.current?.(isSaved, isSaved ? invisible : undefined)
    }
  }, [invisible])

  return (
    <>
      <Button
        type="primary"
        {...btnProps}
        onClick={() => setInvisible(false)}
      >
        {props.children || 'Create'}
      </Button>

      <ModalFullscreen
        // wrapClassName="__modal-queue-job"
        title="Create queue job"
        open={!invisible}
        closable={!loading} // display X icon
        keyboard={false} // disable close on press ESC
        maskClosable={false} // disable close on click outside
        okText="Save"
        cancelText="Cancel"
        onOk={save}
        onCancel={() => setInvisible(true)}
        okButtonProps={{ disabled: loading }}
        cancelButtonProps={{ disabled: loading }}
      >
        {!invisible && (
          <div className="fx fx-jc-center">
            <div className="fx fx-column gap-2" style={{ width: '400px' }}>
              <Form.Item
                required
                label="Role"
                labelAlign="left"
                labelCol={{ flex: '0 0 100px' }}
                className="m-0"
              >
                <Select
                  disabled={loading}
                  placeholder="Role"
                  style={{ flex: '0 0 120px' }}
                  value={formData.role}
                  options={[
                    { label: 'Customer success', value: 'customer success' },
                    { label: 'Customer support', value: 'customer support' },
                    { label: 'Customer service', value: 'customer service' },
                    { label: 'Tech support', value: 'tech support' },
                    { label: 'Technical support', value: 'technical support' },
                    { label: 'Sales development representative', value: 'sales development representative' },
                    { label: 'Business development representative', value: 'business development representative' },
                    { label: 'Call agent', value: 'call agent' }
                  ]}
                  status={errors.hasError('role') ? 'error' : undefined}
                  onChange={(role) => setFormData((prev) => ({
                    ...prev,
                    role
                  }))}
                />
                <ErrorMessage>{errors.getError('role')}</ErrorMessage>
              </Form.Item>

              <Form.Item
                required
                label="Source"
                labelAlign="left"
                labelCol={{ flex: '0 0 100px' }}
                className="m-0"
              >
                <Select
                  disabled={loading}
                  placeholder="Source"
                  style={{ flex: '0 0 120px' }}
                  value={formData.provider}
                  options={[...ScrapedJobProviderOptions]}
                  status={errors.hasError('provider') ? 'error' : undefined}
                  onChange={(provider) => setFormData((prev) => ({
                    ...prev,
                    provider
                  }))}
                />
                <ErrorMessage>{errors.getError('provider')}</ErrorMessage>
              </Form.Item>

              <Form.Item
                required
                label="URL"
                labelAlign="left"
                labelCol={{ flex: '0 0 100px' }}
                className="m-0"
              >
                <Input
                  required
                  readOnly={loading}
                  name="url"
                  placeholder="URL"
                  value={formData.url}
                  status={errors.hasError('url') ? 'error' : undefined}
                  onChange={handleChangeInput}
                />
                <ErrorMessage>{errors.getError('url')}</ErrorMessage>
              </Form.Item>
            </div>
          </div>
        )}
      </ModalFullscreen>
    </>
  )
}
