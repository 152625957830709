import { Button, Form, Input } from 'antd'
import { ComponentProps, FC, useCallback, useState } from 'react'
import { finalize, from, takeUntil } from 'rxjs'
import { SettingApi } from 'src/api'
import { ModalFullscreen } from 'src/components'
import { useDidMountEffect, useUnsubscribe, useValidation } from 'src/hooks'
import { ISettingModel } from 'src/interfaces'
import { NotifyUtils } from 'src/utils'
import * as Yup from 'yup'
import { ErrorMessage } from '../error-message'

const yupSchema = Yup.object().shape({
  value: Yup.string().required('Value is required')
})

export const ModalSetting: FC<Omit<
  ComponentProps<typeof ModalFullscreen>,
  'onOk' | 'afterClose'
> & {
  settingKey?: ISettingModel['key']
  value?: ISettingModel['value']
  afterClose?: (saved: boolean, doc?: ISettingModel) => any
  btnProps?: ComponentProps<typeof Button>
}> = ({
  btnProps,
  settingKey: key,
  ...props
}) => {
  const unsubscribe$ = useUnsubscribe()
  const [loading, setLoading] = useState(false)
  const [invisible, setInvisible] = useState<boolean | ISettingModel>(true)
  const [value, setValue] = useState<string | number>(props.value)

  const { validate, errors, reset } = useValidation({
    data: { value },
    schema: yupSchema
  })

  const save = useCallback(async () => {
    const { isValid } = await validate()
    if (!isValid || !key) {
      return
    }

    setLoading(true)
    from(SettingApi.update(key, value))
      .pipe(
        takeUntil(unsubscribe$),
        finalize(() => setLoading(false))
      )
      .subscribe({
        next: () => {
          NotifyUtils.success({ message: `${key ? 'Update' : 'Create'} setting successfully` })
          setInvisible({ key, value })
        },
        error: NotifyUtils.handleAxiosError
      })
  }, [key, value, validate, unsubscribe$])

  useDidMountEffect(() => {
    reset()

    if (invisible) {
      setLoading(false)
      const isSaved = typeof invisible !== 'boolean'
      props.afterClose?.(isSaved, isSaved ? invisible : undefined)
    }
  }, [invisible])

  useDidMountEffect(() => {
    if (!invisible && key) {
      setLoading(true)
      from(SettingApi.show(key))
        .pipe(
          takeUntil(unsubscribe$),
          finalize(() => setLoading(false))
        )
        .subscribe({
          next: ({ data }) => setValue(data.value),
          error: NotifyUtils.handleAxiosError
        })
    }
  }, [key, invisible])

  return (
    <>
      <Button
        type="primary"
        {...btnProps}
        onClick={() => setInvisible(false)}
      >
        {props.children || 'Edit'}
      </Button>

      <ModalFullscreen
        title="Edit Setting"
        open={!invisible}
        closable={!loading} // display X icon
        keyboard={false} // disable close on press ESC
        maskClosable={false} // disable close on click outside
        okText={key ? 'Update' : 'Save'}
        cancelText="Cancel"
        onOk={save}
        onCancel={() => setInvisible(true)}
        okButtonProps={{ disabled: loading }}
        cancelButtonProps={{ disabled: loading }}
      >
        {invisible
          ? null
          : (
            <div className="fx fx-column fx-extend gap-2">
              <Form.Item
                required
                label="Value"
                labelAlign="left"
                labelCol={{ flex: '0 0 90px' }}
                className="m-0"
              >
                <Input
                  required
                  readOnly={loading}
                  name="value"
                  placeholder="Value"
                  value={value || ''}
                  status={errors.hasError('value') ? 'error' : undefined}
                  onChange={(e) => setValue(e.target.value)}
                />
                <ErrorMessage>{errors.getError('value')}</ErrorMessage>
              </Form.Item>
            </div>
          )}
      </ModalFullscreen>
    </>
  )
}
