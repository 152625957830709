import { BehaviorSubject } from 'rxjs'

export const GodModeService = new (
  class {
    private readonly KEYS = [
      'Escape',
      'Shift'
    ]

    readonly enable$ = new BehaviorSubject<boolean>(false)
    get enable() {
      return this.enable$.getValue()
    }

    toggle() {
      this.enable$.next(!this.enable)
    }

    private keys = ''
    listener = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        this.keys = ''
      }

      if (this.KEYS.includes(e.key)) {
        this.keys += e.key
      } else {
        this.keys = ''
      }

      if (this.keys === 'EscapeShiftShift') {
        this.toggle()
      }
    }
  }
)()

document.removeEventListener('keydown', GodModeService.listener)
document.addEventListener('keydown', GodModeService.listener)
