export const EEnv = Object.freeze({
  APP_NAME: process.env.APP_NAME as string,
  NODE_ENV: process.env.NODE_ENV as string,
  REACT_APP_ENABLE_SW: process.env.REACT_APP_ENABLE_SW as string,
  REACT_APP_FIREBASE_CONFIG: process.env.REACT_APP_FIREBASE_CONFIG as string,
  REACT_APP_TINYMCE_KEY: process.env.REACT_APP_TINYMCE_KEY as string,
  REACT_APP_TRICORDER_API_DNS: process.env.REACT_APP_TRICORDER_API_DNS?.endsWith('/') ? process.env.REACT_APP_TRICORDER_API_DNS.slice(0, -1) : process.env.REACT_APP_TRICORDER_API_DNS,
  REACT_APP_LANDING_WEB_DNS: process.env.REACT_APP_LANDING_WEB_DNS?.endsWith('/') ? process.env.REACT_APP_LANDING_WEB_DNS.slice(0, -1) : process.env.REACT_APP_LANDING_WEB_DNS,
  REACT_APP_HIRING_WEB_DNS: process.env.REACT_APP_HIRING_WEB_DNS?.endsWith('/') ? process.env.REACT_APP_HIRING_WEB_DNS.slice(0, -1) : process.env.REACT_APP_HIRING_WEB_DNS
})
