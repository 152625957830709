import { AxiosError } from 'axios'
import { toast, ToastContent, ToastOptions } from 'react-toastify'
import { AxiosUtils } from './axios.utils'

const defaultPosition = toast.POSITION.BOTTOM_LEFT

export class NotifyUtils {
  static error({ message, ...options }: { message: ToastContent } & ToastOptions) {
    toast.error(message, {
      position: defaultPosition,
      ...options
    })
  }

  static success({ message, ...options }: { message: ToastContent } & ToastOptions) {
    toast.success(message, {
      position: defaultPosition,
      ...options
    })
  }

  static warn({ message, ...options }: { message: ToastContent } & ToastOptions) {
    toast.warn(message, {
      position: defaultPosition,
      ...options
    })
  }

  static info({ message, ...options }: { message: ToastContent } & ToastOptions) {
    toast.info(message, {
      position: defaultPosition,
      ...options
    })
  }

  static notify({ type = 'success', message = '' }: {
    type?: 'info' | 'success' | 'warning' | 'error'
    message?: ToastContent
  } = {}, options: ToastOptions = {}) {
    switch (type) {
      case 'info':
        return NotifyUtils.info({ ...options, message })
      case 'success':
        return NotifyUtils.success({ ...options, message })
      case 'warning':
        return NotifyUtils.warn({ ...options, message })
      case 'error':
        return NotifyUtils.error({ ...options, message })
      default:
        throw new Error('Notify type must be one of: [ \'info\', \'success\', \'warning\', \'error\' ]')
    }
  }

  static handleAxiosError(error: AxiosError) {
    return NotifyUtils.error({ message: AxiosUtils.getApiErrorMessage(error).replace(/^"(params|body|query)\./, '"') })
  }
}

/**
 * inject global event listeners
 */

window.addEventListener('offline', () => {
  NotifyUtils.info({
    position: 'top-center',
    message: 'You are offline.'
  })
})

window.addEventListener('online', () => {
  NotifyUtils.info({
    position: 'top-center',
    message: 'Internet connection restored.'
  })
})

window.addEventListener('message', (event) => {
  if (event.data === 'hadNewUpdate') {
    NotifyUtils.error({
      position: 'top-center',
      message: 'A new version is available, please reload your browser.',
      onClick() {
        window.location.reload()
      }
    })
  }
})
