import axios from 'axios'
import { IAffiliateFormModel, IPaginationQuery, TAxiosResponseData, TAxiosResponsePagination } from 'src/interfaces'

export class AffiliateFormApi {
  static readonly _prefix = '/affiliate-forms'

  static index(params: IPaginationQuery = {}): TAxiosResponsePagination<IAffiliateFormModel> {
    return axios.get(`${AffiliateFormApi._prefix}`, { params })
  }

  static show(id: IAffiliateFormModel['id']): TAxiosResponseData<IAffiliateFormModel> {
    return axios.get(`${AffiliateFormApi._prefix}/${id}`)
  }
}
