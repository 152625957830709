import { Modal, Select } from 'antd'
import { FC, useCallback, useState } from 'react'
import { ScrapedCompanyApi } from 'src/api/scraped-company'

interface IProps {
  open: boolean
  companyIds?: number[]
  onClose?: (shouldRefresh?: boolean) => any
}

export const ModalEditBlackList: FC<IProps> = (props) => {
  const [loading, setLoading] = useState(false)
  const [reason, setReason] = useState<string | null>(null)

  const handleEditBlacklist = useCallback(async () => {
    if (!props.companyIds || props.companyIds.length === 0) return

    setLoading(true)
    await ScrapedCompanyApi.updateBlacklist(props.companyIds, reason)
    setLoading(false)
    props.onClose?.(true)
  }, [props, reason])

  const onChange = (value: string | null) => {
    setReason(value)
  }

  return (
    <Modal
      open={props.open}
      title="Edit black list"
      onCancel={!loading ? () => props.onClose?.() : undefined}
      onOk={handleEditBlacklist}
      confirmLoading={loading}
      closable={!loading}
    >
      <div className="fx p-6" style={{ width: '100%' }}>
        <Select
          placeholder="Select a reason"
          optionFilterProp="children"
          style={{ width: '100%' }}
          onChange={onChange}
          options={[
            {
              value: null,
              label: 'Remove from black list'
            },
            {
              value: 'HRCompany',
              label: 'HR company'
            },
            {
              value: 'FakeJob',
              label: 'Fake Job'
            },
            {
              value: 'Others',
              label: 'Others'
            }
          ]}
        />

      </div>
    </Modal>
  )
}
