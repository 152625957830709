import { Subject } from 'rxjs'
import { DependencyList, useCallback, useEffect, useState } from 'react'

type Tail<T extends any[]> = T extends [unknown, ...infer Tail] ? Tail : never

// type OmitFirstArg<F> = F extends (x: any, ...args: infer P) => infer R ? (...args: P) => R : never
// type Tail<T extends any[]> = T extends [infer Head, ...infer Tail] ? Tail : never

export const useCallbackUnsubscribe = <T extends (unsubscribe$: Subject<void>, ...args: any[]) => any>(
  callback: T,
  deps: DependencyList
): ((...args: Tail<Parameters<T>>) => ReturnType<T>) => {
  const [unsubscribe$, setUnsubscribe$] = useState<Subject<void>>(new Subject())

  useEffect(() => {
    const unsubscribe$ = new Subject<void>()

    setUnsubscribe$(unsubscribe$)

    return () => {
      unsubscribe$.next()
      unsubscribe$.complete()
    }
  }, deps)

  return useCallback((...args) => {
    return callback(unsubscribe$, ...args)
  }, [...deps, unsubscribe$])
}
