import { BehaviorSubject } from 'rxjs'
import { IUserModel } from 'src/interfaces'

export interface IAuthState {
  isAuthenticated: boolean | null
  user?: IUserModel
}

export const AuthState = new BehaviorSubject<IAuthState>({
  isAuthenticated: null
})
