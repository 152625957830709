import axios from 'axios'
import { TAxiosResponseData } from 'src/interfaces'

export class CacheApi {
  static readonly _prefix = '/cache'

  static clear(): TAxiosResponseData<void> {
    return axios.delete(CacheApi._prefix)
  }
}
