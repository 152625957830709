import { IRouterOption } from 'src/router'
import { VideoBrowse } from './components/browse'
import { TikTokImport } from './components/tiktok-import'
import { EPaths } from './routes.path'

export enum ERoutes {
  VIDEO_BROWSE = 'Video.Browse',
  TIKTOK_IMPORT = 'TikTok.Import'
}

export const Routes: IRouterOption[] = [
  {
    path: EPaths.VIDEO_BROWSE,
    component: VideoBrowse,
    name: ERoutes.VIDEO_BROWSE,
    exact: true,
    meta: {}
  },
  {
    path: EPaths.TIKTOK_IMPORT,
    component: TikTokImport,
    name: ERoutes.TIKTOK_IMPORT,
    exact: true,
    meta: {}
  }
]

export const VideoModule = {
  ERoutes,
  Routes
}
