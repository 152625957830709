import { Button, Popconfirm, Space, TableProps } from 'antd'
import { JsonView } from 'src/components'
import { EQueueJobStatus, IQueueJobModel } from 'src/interfaces'
import { TimeZoneService } from 'src/services'
import { UserUtils } from 'src/utils'

export const renderColumns = ({ onRetry }: {
  onRetry?: (record: IQueueJobModel) => void
} = {}): TableProps<IQueueJobModel>['columns'] => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status'
  },
  {
    title: 'Input',
    dataIndex: 'data',
    key: 'data',
    render: (val: IQueueJobModel['data']) => Boolean(val && Object.keys(val).length) && (
      <div style={{ maxWidth: '25vw', wordBreak: 'break-all' }}>
        <JsonView
          name={null}
          src={val || {}}
          collapsed
          enableClipboard={false}
          displayDataTypes={false}
          displayObjectSize={false}
        />
      </div>
    )
  },
  {
    title: 'Output',
    dataIndex: 'output',
    key: 'output',
    render: (val: IQueueJobModel['output']) => Boolean(val && Object.keys(val).length) && (
      <div style={{ maxWidth: '25vw', wordBreak: 'break-all' }}>
        <JsonView
          name={null}
          src={val || {}}
          collapsed
          enableClipboard={false}
          displayDataTypes={false}
          displayObjectSize={false}
        />
      </div>
    )
  },
  {
    title: 'Creator',
    dataIndex: 'author',
    key: 'author',
    render: (val: IQueueJobModel['author']) => UserUtils.getFullName(val)
  },
  {
    title: 'CreatedAt',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (val: string) => TimeZoneService.format(val)
  },
  {
    title: 'UpdatedAt',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    render: (val: string) => TimeZoneService.format(val)
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    render: (_: string, record) => record.status === EQueueJobStatus.FAILED && (
      <Space>
        {onRetry && (
          <Popconfirm
            title="Confirm to retry?"
            onConfirm={() => onRetry(record)}
            okText="Yes"
            cancelText="No"
          >
            <Button
              danger
              size="small"
              type="primary"
            >
              Retry
            </Button>
          </Popconfirm>
        )}
      </Space>
    )
  }
]
