import { TableProps } from 'antd'
import { IFeedbackModel, IUserModel } from 'src/interfaces'
import { TimeZoneService } from 'src/services'
import { UserUtils } from 'src/utils'

export const renderColumns = ({
  onUpdate,
  onDelete
}: {
  onUpdate?: (record: IFeedbackModel) => any
  onDelete?: (record: IFeedbackModel) => any
} = {}): Required<TableProps<IFeedbackModel>>['columns'] => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: 'User',
    dataIndex: 'user',
    key: 'user',
    render: (val?: IUserModel) => UserUtils.getFullName(val)
  },
  {
    title: 'Related To',
    dataIndex: 'relateType',
    key: 'relateType'
  },
  {
    title: 'Score',
    dataIndex: 'score',
    key: 'score'
  },
  {
    title: 'Content',
    dataIndex: 'content',
    key: 'content',
    render: (val: string) => (
      <span title={val} className="twoline-text" style={{ maxWidth: '300px' }}>{val}</span>
    )
  },
  {
    title: 'CreatedAt',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (val: string) => TimeZoneService.format(val)
  },
  {
    title: 'UpdatedAt',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    render: (val: string) => TimeZoneService.format(val)
  }
  // {
  //   title: 'Action',
  //   key: 'action',
  //   render: (text: string, record) => (
  //     <Space
  //       wrap
  //       size="small"
  //       direction="vertical"
  //     >
  //       {onDelete && (
  //         <Popconfirm
  //           title="Confirm to delete?"
  //           onConfirm={() => onDelete(record)}
  //           okText="Yes"
  //           cancelText="No"
  //         >
  //           <Button size="small" danger type="primary">Delete</Button>
  //         </Popconfirm>
  //       )}
  //     </Space>
  //   )
  // }
]
