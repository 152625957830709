import { IRouterOption } from 'src/router'
import { ScrapedJobDetail } from './components/job-detail'
import { ScrapedJobs } from './components/jobs'
import { EPaths } from './routes.path'

export enum ERoutes {
  SCRAPED_JOBS = 'SCRAPED_JOBS',
  SCRAPED_JOB_DETAIL = 'SCRAPED_JOB_DETAIL'
}

export const Routes: IRouterOption[] = [
  {
    path: EPaths.SCRAPED_JOBS,
    component: ScrapedJobs,
    name: ERoutes.SCRAPED_JOBS,
    exact: true,
    meta: {}
  },
  {
    path: EPaths.SCRAPED_JOB_DETAIL,
    component: ScrapedJobDetail,
    name: ERoutes.SCRAPED_JOB_DETAIL,
    exact: true,
    meta: {}
  }
]

export const ScrapedJobModule = {
  ERoutes,
  Routes
}
