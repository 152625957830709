import axios from 'axios'
import { ICampaignQuestionModel, TAxiosResponseData } from 'src/interfaces'

export class CampaignQuestionApi {
  static readonly _prefix = '/campaigns/questions'

  static list(): TAxiosResponseData<ICampaignQuestionModel[]> {
    return axios.get(`${CampaignQuestionApi._prefix}`)
  }

  static create(text: string): TAxiosResponseData<ICampaignQuestionModel> {
    return axios.post(`${CampaignQuestionApi._prefix}`, {
      text
    })
  }

  static update(id: number, text: string): TAxiosResponseData<void> {
    return axios.put(`${CampaignQuestionApi._prefix}/${id}`, { text })
  }

  static destroy(id: number): TAxiosResponseData<void> {
    return axios.delete(`${CampaignQuestionApi._prefix}/${id}`)
  }
}
