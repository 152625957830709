import { Divider } from 'antd'
import { AxiosError } from 'axios'
import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { CampaignApi } from 'src/api'
import { useUnsubscribe } from 'src/hooks'
import { ICampaignModel } from 'src/interfaces'
import { BreadcrumbService } from 'src/services'
import { GlobalAction } from 'src/store'
import { NotifyUtils } from 'src/utils'
import { EPaths } from '../../routes.path'
import { CompanyDetail } from './company'
import { InfoDetail } from './info'

const PAGE_TITLE = 'Campaigns'

export const Campaign: FC = () => {
  const unsubscribe$ = useUnsubscribe()
  const { id } = useParams<{ id: string }>()
  const [campaign, setCampaign] = useState<ICampaignModel>()

  useEffect(() => {
    if (Number(id)) {
      GlobalAction.setLoading(true)
      from(CampaignApi.get(Number(id)))
        .pipe(
          takeUntil(unsubscribe$),
          catchError((error: AxiosError) => {
            NotifyUtils.handleAxiosError(error)
            return EMPTY
          }),
          finalize(() => GlobalAction.setLoading(false))
        )
        .subscribe(({ data }) => setCampaign(data))
    }
  }, [id, unsubscribe$])

  /**
   * breadcrumb
   */
  useEffect(() => {
    BreadcrumbService.items = [{
      route: EPaths.CAMPAIGN,
      label: PAGE_TITLE
    }]
    return () => {
      BreadcrumbService.items = []
    }
  }, [])

  return (
    <section className="fx fx-column fx-extend">
      <InfoDetail campaign={campaign}/>

      <Divider className="my-2" style={{ borderColor: 'black' }}/>

      <CompanyDetail campaign={campaign}/>
    </section>
  )
}
