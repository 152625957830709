import { Breadcrumb } from 'antd'
import { FC, useEffect, useState } from 'react'
import { takeUntil } from 'rxjs'
import { useUnsubscribe } from 'src/hooks'
import { browserHistory, generate } from 'src/router'
import { BreadcrumbService } from 'src/services'

export const LayoutBreadcrumb: FC = () => {
  const unsubscribe$ = useUnsubscribe()
  const [items, setItems] = useState(BreadcrumbService.items)

  useEffect(() => {
    BreadcrumbService.items$
      .pipe(takeUntil(unsubscribe$))
      .subscribe(value => setItems(value))
  }, [unsubscribe$])

  return items?.length
    ? (
      <Breadcrumb className="noselect">
        {items.map(
          (item, index) => (
            <Breadcrumb.Item
              key={item.key}
              className={item.route && 'pointer'}
              onClick={() => item.route && browserHistory.push(generate(item.route))}
            >
              {item.icon && <item.icon/>}
              {item.label && <span className={item.icon && 'pl-1'}>{item.label}</span>}
            </Breadcrumb.Item>
          )
        )}
      </Breadcrumb>
    )
    : null
}
