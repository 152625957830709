import { Button, Popconfirm, Space, TableProps } from 'antd'
import { ModalPromoCode, ModalPromoCodeClaimers } from 'src/components'
import { IPromoCodeModel } from 'src/interfaces'
import { TimeZoneService } from 'src/services'
import { UserUtils } from 'src/utils'

export const renderColumns = ({
  onUpdate,
  onDestroy
}: {
  onUpdate?: (record: IPromoCodeModel) => any
  onDestroy?: (record: IPromoCodeModel) => any
} = {}): Required<TableProps<IPromoCodeModel>>['columns'] => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: 'Creator',
    dataIndex: 'author',
    key: 'author',
    render: (val: Required<IPromoCodeModel>['author']) => UserUtils.getFullName(val)
  },
  {
    title: 'Code',
    dataIndex: 'code',
    key: 'code'
  },
  {
    title: 'CreatedAt',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (val: string) => TimeZoneService.format(val)
  },
  {
    title: 'UpdatedAt',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    render: (val: string) => TimeZoneService.format(val)
  },
  {
    title: 'Action',
    key: 'action',
    render: (_, record) => (
      <Space
        wrap
        size="small"
        direction="vertical"
      >
        {onUpdate && (
          <ModalPromoCode
            id={record.id}
            afterClose={(saved, doc) => saved && doc && onUpdate(doc)}
            btnProps={{
              type: 'ghost',
              size: 'small'
            }}
          >Edit
          </ModalPromoCode>
        )}

        {onDestroy && (
          <Popconfirm
            title="Confirm to delete?"
            onConfirm={() => onDestroy(record)}
            okText="Yes"
            cancelText="No"
          >
            <Button size="small" danger type="primary">Delete</Button>
          </Popconfirm>
        )}

        <ModalPromoCodeClaimers
          id={record.id}
          btnProps={{
            type: 'ghost',
            size: 'small'
          }}
        >
          View Claimers
        </ModalPromoCodeClaimers>
      </Space>
    )
  }
]
