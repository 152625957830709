import axios from 'axios'
import { IPaginationQuery, IScrapedContactModel, TAxiosResponseData, TAxiosResponsePagination } from 'src/interfaces'

export class ScrapedContactApi {
  static readonly _prefix = '/scraped-contact'

  static index(params: IPaginationQuery & { companyName?: string } = {}): TAxiosResponsePagination<IScrapedContactModel> {
    return axios.get(`${ScrapedContactApi._prefix}`, { params })
  }

  static upsert(contact: {
    id?: number
    companyId: number
    firstName?: string
    lastName?: string
    position?: string
    connectedAshley?: boolean
    connectedPhil?: boolean
    connectedDaniel?: boolean
    connectedCecilia?: boolean
    verifiedEmail?: string
  }): TAxiosResponseData<IScrapedContactModel[]> {
    return axios.post(`${ScrapedContactApi._prefix}`, contact)
  }

  static bulkUpsert(contacts: {
    id?: number
    companyId: number
    firstName?: string
    lastName?: string
    position?: string
    connectedAshley?: boolean
    connectedPhil?: boolean
    connectedDaniel?: boolean
    connectedCecilia?: boolean
    verifiedEmail?: string
  }[]) {
    return axios.post(`${ScrapedContactApi._prefix}/bulk`, { contacts })
  }

  static remove(contactId: number) {
    return axios.delete(`${ScrapedContactApi._prefix}/${contactId}`)
  }

  static exportContacts(params: any) {
    return axios.get(`${ScrapedContactApi._prefix}/export`, { responseType: 'blob', params })
  }

  static importVerifiedEmails(contacts: { id: number; verifiedEmail: string }[]) {
    return axios.post(`${ScrapedContactApi._prefix}/import-verified-emails`, { contacts })
  }
}
