import clsx from 'clsx'
import { ComponentProps, FC } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import Style from './style.module.scss'

const quillModules = {
  clipboard: {
    matchVisual: false
  },
  toolbar: [
    ['bold', 'italic', 'underline'],
    [{ align: null }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
    [{ header: [1, 2, 3, false] }],
    [{ list: 'bullet' }, { list: 'ordered' }]
  ]
}

interface IProps extends ComponentProps<typeof ReactQuill> {
  error?: string
}

export const QuillEditor: FC<IProps> = ({ error, ...props }) => {
  return (
    <div className="fx fx-column gap-2">
      <ReactQuill
        theme="snow"
        {...props}
        className={clsx(Style.editor, props.className)}
        modules={quillModules}
      />

      {error}
    </div>
  )
}
