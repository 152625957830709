import { Button, Pagination, Table } from 'antd'
import { ComponentProps, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { PromoCodeApi } from 'src/api'
import { ModalFullscreen } from 'src/components'
import { IPaginateCallback, useBehaviorMapper } from 'src/hooks'
import { IPromoCodeModel } from 'src/interfaces'
import { PaginationService } from 'src/services'
import { renderColumns } from './columns'

export const ModalPromoCodeClaimers: FC<Omit<
  ComponentProps<typeof ModalFullscreen>,
  'onOk' | 'afterClose'
> & {
  id: IPromoCodeModel['id']
  // onOk?: () => any
  afterClose?: () => any
  btnProps?: ComponentProps<typeof Button>
}> = ({
  btnProps,
  ...props
}) => {
  const [invisible, setInvisible] = useState<boolean | IPromoCodeModel>(true)
  const _paginationService = useMemo(
    () => new PaginationService<IPromoCodeModel>({ endpoint: `${PromoCodeApi._prefix}/${props.id}/used` }),
    [props.id]
  )
  const loading = useBehaviorMapper(_paginationService.loading$)
  const dataSource = useBehaviorMapper(_paginationService.pagination$)

  const fetch = useCallback<IPaginateCallback>(
    (params) => _paginationService.paging(params),
    [_paginationService]
  )

  useEffect(() => {
    if (!invisible) {
      fetch({}) // fetch on open
    }
  }, [fetch, invisible])

  return (
    <>
      <Button
        type="primary"
        {...btnProps}
        onClick={() => setInvisible(false)}
      >
        {props.children || 'Claimers'}
      </Button>

      <ModalFullscreen
        wrapClassName="__modal-promo-code-users"
        title="Promo Code Users"
        open={!invisible}
        closable={!loading} // display X icon
        keyboard={false} // disable close on press ESC
        maskClosable={false} // disable close on click outside
        okText="Ok"
        cancelText="Close"
        onOk={() => setInvisible(true)}
        onCancel={() => setInvisible(true)}
        okButtonProps={{ disabled: loading }}
        cancelButtonProps={{ disabled: loading }}
      >
        {!invisible && (
          <div className="fx fx-column fx-extend gap-2">
            <Table<IPromoCodeModel>
              bordered
              rowKey="id"
              className="fx-extend"
              loading={loading}
              columns={renderColumns()}
              dataSource={dataSource.rows}
              pagination={false}
            />

            <Pagination
              className="fx-as-end mt-3"
              disabled={loading}
              total={dataSource.total}
              current={dataSource.page}
              pageSize={dataSource.limit}
              onChange={(page, limit) => fetch({ page, limit })}
            />
          </div>
        )}
      </ModalFullscreen>
    </>
  )
}
