import { Select } from 'antd'
import { DefaultOptionType } from 'antd/lib/select'
import { uniqWith } from 'lodash'
import { ComponentProps, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { takeUntil } from 'rxjs'
import { BlogCategoryApi } from 'src/api'
import { useDebounce, useUnsubscribe } from 'src/hooks'
import { IBlogCategoryModel } from 'src/interfaces'
import { PaginationService } from 'src/services'

interface IProps extends Omit<
ComponentProps<typeof Select<number | number[], DefaultOptionType & {
  label: string
  row: IBlogCategoryModel
}>>,
'showSearch' |
'optionFilterProp' |
'filterOption' |
'filterSort'
> {
  tags?: boolean
  multiple?: boolean
}

export const SelectBlogCategory: FC<IProps> = ({
  tags,
  multiple,
  ...props
}) => {
  const unsubscribe$ = useUnsubscribe()
  const _paginationService = useMemo(() => new PaginationService<IBlogCategoryModel>(BlogCategoryApi), [])
  const [loading, setLoading] = useState(false)
  const [keyword, setKeyword] = useState('')
  const [options, setOptions] = useState<IProps['options']>([])

  const rowsToOptions = useCallback((rows: IBlogCategoryModel[]) => rows.map(
    (row) => ({
      value: row.id,
      label: row.name as string,
      row
    })
  ), [])

  useEffect(() => {
    const value = (
      Array.isArray(props.value)
        ? props.value
        : [props.value]
    ).filter(Boolean) as number[]
    if (value?.length) {
      for (const id of value) {
        BlogCategoryApi.show(id).then(({ data }) => {
          setOptions(
            (prev) => uniqWith(
              prev?.length
                ? [...prev, ...rowsToOptions([data])]
                : rowsToOptions([data]),
              (a, b) => a.value === b.value
            )
          )
        })
      }
    }
  }, [props.value, rowsToOptions])

  useDebounce(() => {
    _paginationService.paging({ keyword })
  }, 300, [keyword])

  useEffect(() => {
    _paginationService
      .loading$
      .pipe(takeUntil(unsubscribe$))
      .subscribe((value) => setLoading(value))
    _paginationService
      .pagination$
      .pipe(takeUntil(unsubscribe$))
      .subscribe(
        (data) => setOptions(
          (prev) => uniqWith(
            prev?.length
              ? [...prev, ...rowsToOptions(data.rows)]
              : rowsToOptions(data.rows),
            (a, b) => a.value === b.value
          )
        )
      )
  }, [_paginationService, rowsToOptions, unsubscribe$])

  const mode = useMemo(() => props.mode ?? (
    multiple
      ? 'multiple'
      : tags
        ? 'tags'
        : undefined
  ), [props.mode, tags, multiple])

  return (
    <Select
      {...props}
      mode={mode}
      placeholder={props.placeholder || 'Select category'}
      loading={loading || props.loading}
      showSearch
      onSearch={(k) => k && setKeyword(() => k)}
      optionFilterProp="children"
      filterOption={(input, option) => (option?.label ?? '').includes(input)}
      filterSort={(optionA, optionB) =>
        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())}
      options={options}
    />
  )
}
