import axios from 'axios'
import { TAxiosResponseData } from 'src/interfaces'
import { EOpenAIMessageRole } from 'src/interfaces/open-ai'

export class AIApi {
  static readonly _prefix = '/ai'

  static messaging(payload: {
    messages: {
      role: EOpenAIMessageRole
      content: string
    }[]
  }): TAxiosResponseData<{
    id: string
    role: EOpenAIMessageRole
    content: string
  }> {
    return axios.post(`${AIApi._prefix}/messaging`, payload)
  }
}
