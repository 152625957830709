import clsx from 'clsx'
import { FC, HTMLAttributes } from 'react'
import { IMultimediaModel } from 'src/interfaces'
import { StyleUtils } from 'src/utils'
import { VideoPlayer } from '../video-player'
import Style from './style.module.scss'

interface IProps extends HTMLAttributes<HTMLDivElement> {
  media: IMultimediaModel
  isSelected?: boolean
}

export const MediaItem: FC<IProps> = ({ media, isSelected, ...props }) => {
  return (
    <div
      {...props}
      className={clsx(
        Style.mediaItem,
        { [Style.isSelected]: isSelected },
        props.className
      )}
      style={{
        ...props.style,
        backgroundImage: StyleUtils.backgroundImage(media.publicUrl)
      }}
    >
      {media.mimetype?.startsWith?.('video') && (
        <VideoPlayer
          url={media.publicUrl}
          style={{
            width: '100%',
            height: '100%',
            minWidth: '100%',
            minHeight: '100%',
            maxWidth: '100%',
            maxHeight: '100%'
          }}
        />
      )}
    </div>
  )
}
