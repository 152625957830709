import { IRouterOption } from 'src/router'
import { Messenger } from './messenger'
import { EPaths } from './routes.path'

export enum ERoutes {
  MESSAGES = 'messages'
}

export const Routes: IRouterOption[] = [
  {
    path: EPaths.MESSAGES,
    component: Messenger,
    name: ERoutes.MESSAGES,
    exact: true,
    meta: {}
  }
]

export const MessengerModule = {
  ERoutes,
  Routes
}
