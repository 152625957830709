import { useCallback, useLayoutEffect, useState } from 'react'
import { useAsRef } from './useAsRef'

type WindowSize = {
  readonly innerHeight: number
  readonly innerWidth: number
  readonly outerHeight: number
  readonly outerWidth: number
}

function getWindowSize(): WindowSize {
  return {
    innerHeight: typeof window === 'undefined' ? 0 : window.innerHeight,
    innerWidth: typeof window === 'undefined' ? 0 : window.innerWidth,
    outerHeight: typeof window === 'undefined' ? 0 : window.outerHeight,
    outerWidth: typeof window === 'undefined' ? 0 : window.outerWidth
  }
}

/**
 * Retrieves the current size of the window
 * @param  {Function} callback
 * @return {WindowSize}
 */
export function useWindowSize(callback?: (...args: any) => any): WindowSize {
  const callbackRef = useAsRef(callback)
  const [windowSize, setWindowSize] = useState(getWindowSize())

  const resize = useCallback(() => {
    window.requestAnimationFrame(() => {
      const size = getWindowSize()
      callbackRef.current?.(size)
      setWindowSize(size)
    })
  }, [callbackRef])

  useLayoutEffect(() => {
    resize()
    window.addEventListener('resize', resize)
    return () => window.removeEventListener('resize', resize)
  }, [callbackRef, resize])

  return windowSize
}
