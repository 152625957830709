import clsx from 'clsx'
import { ComponentProps, FC, useId } from 'react'
import ReactInfiniteScrollComponent from 'react-infinite-scroll-component'
import { useDebounce } from 'src/hooks'
import Style from './style.module.scss'

interface IProps extends Omit<ComponentProps<typeof ReactInfiniteScrollComponent>, 'scrollableTarget'> {
  containerProps?: Omit<ComponentProps<'div'>, 'id'>
}

export const InfiniteScroll: FC<IProps> = (props) => {
  const scrollerId = useId()

  useDebounce(() => {
    if (props.hasMore) {
      const scrollerEl = document.getElementById(scrollerId)
      if (scrollerEl && scrollerEl.scrollHeight <= scrollerEl.clientHeight) {
        props.next()
      }
    }
  }, 300, [props.dataLength, props.hasMore])

  return (
    <div
      {...props.containerProps}
      className={clsx(Style.infiniteScroll, props.containerProps?.className)}
      id={scrollerId}
    >
      <ReactInfiniteScrollComponent
        {...props}
        scrollableTarget={scrollerId}
      >
        {props.children}
      </ReactInfiniteScrollComponent>
    </div>
  )
}
