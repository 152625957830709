import { Button, Divider } from 'antd'
import { ComponentProps, FC, useEffect, useMemo, useState } from 'react'
import sanitizeHtml from 'sanitize-html'
import { ModalFullscreen } from 'src/components'
import { useAsRef } from 'src/hooks'
import { IScrapedJobModel } from 'src/interfaces'
import Style from './style.module.scss'

interface IProps extends Omit<ComponentProps<typeof ModalFullscreen>, 'onOk' | 'afterClose'> {
  job: IScrapedJobModel
  afterClose?: () => any
  btnProps?: ComponentProps<typeof Button>
}

export const ModalLinkedInJob: FC<IProps> = ({
  btnProps,
  ...props
}) => {
  const [invisible, setInvisible] = useState(true)
  const afterCloseRef = useAsRef(props.afterClose)

  useEffect(() => {
    if (invisible) {
      afterCloseRef.current?.()
    }
  }, [afterCloseRef, invisible])

  const job = useMemo(() => props.job, [props.job])

  return (
    <>
      <Button
        type="primary"
        {...btnProps}
        onClick={() => setInvisible(false)}
      >
        {props.children || 'View'}
      </Button>

      <ModalFullscreen
        title="Job information"
        open={!invisible}
        // closable={false} // display X icon
        keyboard={false} // disable close on press ESC
        maskClosable={false} // disable close on click outside
        okButtonProps={{ style: { display: 'none' } }}
        cancelText="Close"
        onCancel={() => setInvisible(true)}
      >
        {!invisible && (
          <div className="fx fx-column gap-3">
            <div>
              <strong>Contact information</strong>
              <pre>{job.contactInfo || 'No contact info'}</pre>
            </div>

            <Divider className="m-0"/>

            <h1>{job.jobTitle}</h1>

            <h4>{job.jobAddress}</h4>

            <div className={Style.jobDescription} dangerouslySetInnerHTML={{ __html: sanitizeHtml(job.description || '') }}/>
          </div>
        )}
      </ModalFullscreen>
    </>
  )
}
