interface IOpenAIPermission {
  id: string
  object: string
  created: number
  allow_create_engine: boolean
  allow_sampling: boolean
  allow_logprobs: boolean
  allow_search_indices: boolean
  allow_view: boolean
  allow_fine_tuning: boolean
  organization: string
  group?: string | null
  is_blocking: boolean
}

export interface IOpenAIModel {
  id: string
  object: string
  created: number
  owned_by: string
  permission: IOpenAIPermission[]
  root: string
  parent?: string | null
}

export enum EOpenAIMessageRole {
  SYSTEM = 'system',
  USER = 'user',
  ASSISTANT = 'assistant'
}

export interface IOpenAIMessage {
  id: number | string
  role: EOpenAIMessageRole
  content: string
}
