import { CheckOutlined } from '@ant-design/icons'
import { Button, Popconfirm, Space, TableProps } from 'antd'
import { ModalBlogPost } from 'src/components'
import { IBlogPostModel } from 'src/interfaces'
import { TimeZoneService } from 'src/services'
import { UserUtils } from 'src/utils'

export const renderColumns = ({
  onUpdated,
  onDestroy
}: {
  onUpdated?: (record: IBlogPostModel) => void
  onDestroy?: (record: IBlogPostModel) => void
} = {}): TableProps<IBlogPostModel>['columns'] => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title'
  },
  {
    title: 'Publish',
    dataIndex: 'isPublish',
    key: 'isPublish',
    render: (val: boolean) => val && <CheckOutlined/>
  },
  {
    title: 'Featured',
    dataIndex: 'isFeatured',
    key: 'isFeatured',
    render: (val: boolean) => val && <CheckOutlined/>
  },
  {
    title: 'Category',
    dataIndex: 'category',
    key: 'category',
    render: (val: IBlogPostModel['category']) => val?.name
  },
  {
    title: 'Author',
    dataIndex: 'author',
    key: 'author',
    render: (val: IBlogPostModel['author']) => UserUtils.getFullName(val)
  },
  {
    title: 'CreatedAt',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (val: string) => TimeZoneService.format(val)
  },
  {
    title: 'UpdatedAt',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    render: (val: string) => TimeZoneService.format(val)
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    render: (_: string, record) => (
      <Space>
        <ModalBlogPost
          id={record.id}
          btnProps={{
            size: 'small',
            type: 'primary'
          }}
          afterClose={(saved, record) => saved && record && onUpdated?.(record)}
        >
          Edit
        </ModalBlogPost>

        {onDestroy && (
          <Popconfirm
            title="Confirm to delete?"
            onConfirm={() => onDestroy(record)}
            okText="Yes"
            cancelText="No"
          >
            <Button
              danger
              size="small"
              type="primary"
            >
              Delete
            </Button>
          </Popconfirm>
        )}
      </Space>
    )
  }
]
