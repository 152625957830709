import axios from 'axios'
import { IFeedbackModel, IPaginationQuery, TAxiosResponseData, TAxiosResponsePagination } from 'src/interfaces'

export class FeedbackApi {
  static readonly _prefix = '/feedbacks'

  static index(params: IPaginationQuery & {
    isPlaylist?: boolean
  } = {}): TAxiosResponsePagination<IFeedbackModel> {
    return axios.get(`${FeedbackApi._prefix}`, { params })
  }

  static show(id: IFeedbackModel['id']): TAxiosResponseData<IFeedbackModel> {
    return axios.get(`${FeedbackApi._prefix}/${id}`)
  }
}
