import { Button, Popconfirm, Space, TableProps } from 'antd'
import { ModalBlogCategory } from 'src/components'
import { IBlogCategoryModel } from 'src/interfaces'
import { TimeZoneService } from 'src/services'
import { UserUtils } from 'src/utils'

export const renderColumns = ({
  onUpdated,
  onDestroy
}: {
  onUpdated?: (record: IBlogCategoryModel) => void
  onDestroy?: (record: IBlogCategoryModel) => void
} = {}): TableProps<IBlogCategoryModel>['columns'] => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Author',
    dataIndex: 'author',
    key: 'author',
    render: (val: IBlogCategoryModel['author']) => UserUtils.getFullName(val)
  },
  {
    title: 'CreatedAt',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (val: string) => TimeZoneService.format(val)
  },
  {
    title: 'UpdatedAt',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    render: (val: string) => TimeZoneService.format(val)
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    render: (_: string, record) => (
      <Space>
        <ModalBlogCategory
          id={record.id}
          btnProps={{
            size: 'small',
            type: 'primary'
          }}
          afterClose={(saved, record) => saved && record && onUpdated?.(record)}
        >
          Edit
        </ModalBlogCategory>

        {onDestroy && (
          <Popconfirm
            title="Confirm to delete?"
            onConfirm={() => onDestroy(record)}
            okText="Yes"
            cancelText="No"
          >
            <Button
              danger
              size="small"
              type="primary"
            >
              Delete
            </Button>
          </Popconfirm>
        )}
      </Space>
    )
  }
]
