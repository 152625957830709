import { IRouterOption } from 'src/router'
import { Feedbacks } from './components/list'
import { EPaths } from './routes.path'

export enum ERoutes {
  FEEDBACK = 'Feedback.List'
}

export const Routes: IRouterOption[] = [
  {
    path: EPaths.FEEDBACK,
    component: Feedbacks,
    name: ERoutes.FEEDBACK,
    exact: true,
    meta: {}
  }
]

export const FeedbackModule = {
  ERoutes,
  Routes
}
