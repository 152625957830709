import * as Yup from 'yup'

const Pattern: Record<string, RegExp> = {
  // mustHaveLetterAndNumber: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d].+$/,
  // mustHaveLowercaseAndUppercase: /^(?=.*[a-z])(?=.*[A-Z])[a-zA-Z\d].+$/,
  // mustHaveSpecialCharacter: /^(?=.*?[#?!@$%^&*-]).+$/,
  mustHaveNumber: /^(?=.*\d).+$/,
  mustHaveUppercase: /^(?=.*[A-Z]).+$/
}

Yup.addMethod<Yup.StringSchema>(Yup.string, 'knowmePassword', function (label) {
  return this.required(`Please enter your ${label}.`)
    .min(8, 'Password must be at least 8 characters.')
    .test(
      'at-least-one-uppercase',
      'Password must be at least 1 uppercase.',
      (password) => password ? Pattern.mustHaveUppercase.test(password) : false)
    .test(
      'at-least-one-number',
      'Password must be at least 1 number',
      (password) => password ? Pattern.mustHaveNumber.test(password) : false)
})

Yup.addMethod<Yup.StringSchema>(
  Yup.string,
  'JSONString',
  function (message) {
    return this.test('unique', message, function (value) {
      if (!value) {
        return true
      }

      try {
        JSON.parse(value)
        return true
      } catch (error) {
        return false
      }
    })
  }
)

Yup.addMethod<Yup.ArraySchema<Yup.AnySchema>>(
  Yup.array,
  'unique',
  function (message, mapper?: (item: any) => any) {
    return this.test('unique', message, function (items) {
      if (!items) {
        return true
      }

      const uniqueSize = new Set(
        mapper
          ? items.map(mapper)
          : items
      ).size

      return items.length === uniqueSize
    })
  }
)
