import { RefObject, useEffect } from 'react'
import { useAsRef } from './useAsRef'

export function useClickOutside<T extends HTMLElement>(ref: RefObject<T>, handler: (e: Event) => void) {
  const handlerRef = useAsRef(handler)
  useEffect(
    () => {
      const listener = (e: Event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (ref.current?.contains?.(e.target as Node)) {
          return
        }
        handlerRef.current(e)
      }

      document.addEventListener('mousedown', listener)
      document.addEventListener('touchstart', listener)

      return () => {
        document.removeEventListener('mousedown', listener)
        document.removeEventListener('touchstart', listener)
      }
    },
    [ref, handlerRef]
  )
}
