import axios, { AxiosError, AxiosResponse } from 'axios'
import { BehaviorSubject } from 'rxjs'
import { IObject, IPaginationQuery, IPaginationResponse } from 'src/interfaces'
import { NotifyUtils } from 'src/utils'

export class PaginationService<T = any, P = IObject> {
  endpoint: string

  static get defaultData() {
    return {
      from: 1,
      to: 0,
      page: 0,
      limit: 10,
      offset: 0,
      total: 0,
      pages: 1,
      rows: []
    }
  }

  constructor(api: {
    _prefix?: string
    endpoint?: string
  }) {
    const endpoint = api._prefix || api.endpoint
    if (!endpoint) {
      throw new Error('api endpoint is required')
    }

    this.endpoint = endpoint
  }

  readonly loading$ = new BehaviorSubject(false)
  get loading() {
    return this.loading$.getValue()
  }

  readonly pagination$ = new BehaviorSubject<Required<IPaginationResponse<T>>>(PaginationService.defaultData)
  get pagination() {
    return this.pagination$.getValue()
  }

  reset() {
    this.loading$.next(false)
    this.pagination$.next(PaginationService.defaultData)
  }

  paging(params: IPaginationQuery & P) {
    this.loading$.next(true)
    return axios
      .get(this.endpoint, { params })
      .then(({ data }: AxiosResponse<IPaginationResponse<T>>) => {
        this.pagination$.next(data as Required<IPaginationResponse<T>>)
        return data as Required<IPaginationResponse<T>>
      })
      .catch((error: AxiosError) => {
        NotifyUtils.handleAxiosError(error)
        throw error
      })
      .finally(() => this.loading$.next(false))
  }
}
