import axios from 'axios'
import { IAnalyticModel, IPaginationQuery, ISessionModel, TAxiosResponseData, TAxiosResponsePagination } from 'src/interfaces'

export class AnalyticApi {
  static readonly _prefix = '/analytics'

  static hm(params: IPaginationQuery = {}): TAxiosResponsePagination<IAnalyticModel> {
    return axios.get(`${AnalyticApi._prefix}/hm`, { params })
  }

  static mobile(params: IPaginationQuery = {}): TAxiosResponsePagination<IAnalyticModel> {
    return axios.get(`${AnalyticApi._prefix}/mobile`, { params })
  }

  static mobileSessions(params: IPaginationQuery = {}): TAxiosResponsePagination<ISessionModel> {
    return axios.get(`${AnalyticApi._prefix}/mobile/sessions`, { params })
  }

  static mobileUserStats({ userId }: { userId: number }): TAxiosResponseData<{
    countSessions: number
    countVibeStarts: number
    countVideosRecorded: number
  }> {
    return axios.get(`${AnalyticApi._prefix}/mobile/${userId}`)
  }
}
