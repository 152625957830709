import { Badge, Button, Input, Pagination, Popconfirm, Table } from 'antd'
import { omit } from 'lodash'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { finalize, from, takeUntil } from 'rxjs'
import { HiringManagerApi } from 'src/api'
import { ERoleType } from 'src/constants/enum'
import { IPaginateCallback, IPaginateParams, useBehaviorMapper, useDidMountDebounce, usePaginateParams, useUnsubscribe } from 'src/hooks'
import { IUserModel } from 'src/interfaces'
import { BreadcrumbService, GodModeService, PaginationService } from 'src/services'
import { GlobalAction, useAuthorization } from 'src/store'
import { NotifyUtils } from 'src/utils'
import { EPaths } from '../../routes.path'
import { renderHiringsColumns } from './columns'

const PAGE_TITLE = 'HiringManagers'

export const HiringManagers: FC = () => {
  const unsubscribe$ = useUnsubscribe()
  const { is } = useAuthorization()
  const isGodMode = useBehaviorMapper(GodModeService.enable$)
  const _paginationService = useMemo(() => new PaginationService<IUserModel>(HiringManagerApi), [])
  const loading = useBehaviorMapper(_paginationService.loading$)
  const dataSource = useBehaviorMapper(_paginationService.pagination$)

  const fetch = useCallback<IPaginateCallback>(
    (params) => {
      GlobalAction.setLoading(true)
      _paginationService.paging(HiringManagerApi.parseParams(params))
        .finally(() => GlobalAction.setLoading(false))
    },
    [_paginationService]
  )

  const { pagination, pushPagination, setPagination } = usePaginateParams(fetch)
  const [filter, setFilter] = useState<{
    keyword?: string
    isActive?: number
  } & Omit<IPaginateParams, 'page' | 'limit' | 'offset'>>({
    ...omit(pagination, ['page', 'limit', 'offset']),
    hasLinkedinUrl: pagination.hasLinkedinUrl === 'true' || undefined
  })

  const onDownload = useCallback(
    (params: typeof filter) => {
      GlobalAction.setLoading(true)
      from(HiringManagerApi.download(HiringManagerApi.parseParams(params)))
        .pipe(
          takeUntil(unsubscribe$),
          finalize(() => GlobalAction.setLoading(false))
        )
        .subscribe({
          error: NotifyUtils.handleAxiosError
        })
    },
    [unsubscribe$]
  )

  const onDestroy = useCallback((record: IUserModel, force?: boolean) => {
    GlobalAction.setLoading(true)
    from(HiringManagerApi.destroy(record.id, force))
      .pipe(takeUntil(unsubscribe$))
      .subscribe({
        next: () => fetch(pagination),
        error: (error) => {
          NotifyUtils.handleAxiosError(error)
          GlobalAction.setLoading(false)
        }
      })
  }, [fetch, pagination, unsubscribe$])

  useDidMountDebounce(() => {
    setPagination({ ...pagination, page: 1 })
    return pushPagination({
      ...pagination,
      page: 1,
      ...filter
    })
  }, 2000, [filter])

  /**
   * breadcrumb
   */
  useEffect(() => {
    BreadcrumbService.items = [{
      route: EPaths.HIRING_MANAGER_LIST,
      label: PAGE_TITLE
    }]
    return () => {
      BreadcrumbService.items = []
    }
  }, [])

  return (
    <section className="fx fx-column fx-extend">
      <div className="fx fx-jc-space-between mb-2">
        <div className="fx fx-extend fx-ai-center gap-2">
          <Input
            allowClear
            placeholder="Search by id, email, username..."
            style={{ flex: '0 0 30vw' }}
            readOnly={loading}
            value={filter.keyword}
            onChange={(e) => {
              e.persist()
              setFilter((prev) => ({
                ...prev,
                keyword: e.target.value
              }))
            }}
          />
        </div>

        <Popconfirm
          title="Export as csv?"
          okText="Yes"
          cancelText="No"
          disabled={!dataSource.total}
          onConfirm={() => onDownload(filter)}
        >
          <Button type="primary" disabled={!dataSource.total}>Export</Button>
        </Popconfirm>

        <Badge showZero count={dataSource.total}/>
      </div>

      <Table
        bordered
        rowKey="id"
        className="fx-extend"
        loading={loading}
        columns={renderHiringsColumns({
          onDestroy: is(ERoleType.SUPER_ADMIN)
            ? onDestroy
            : undefined,
          onDelete: isGodMode && is(ERoleType.SUPER_ADMIN)
            ? (doc) => onDestroy(doc, true)
            : undefined
        })}
        dataSource={dataSource.rows}
        pagination={false}
      />

      <Pagination
        className="fx-as-end mt-3"
        disabled={loading}
        total={dataSource.total}
        current={pagination.page}
        pageSize={pagination.limit}
        {
          ...{
            ...pagination,
            onChange(page, limit) {
              pushPagination({
                ...pagination,
                page,
                limit
              })
            }
          }
        }
      />
    </section>
  )
}
