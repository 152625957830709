import { IRouterOption } from 'src/router'
import { ExpandYourArea } from './components/list'
import { EPaths } from './routes.path'

export enum ERoutes {
  EXPAND_YOUR_AREA = 'ExpandYourArea.List'
}

export const Routes: IRouterOption[] = [
  {
    path: EPaths.EXPAND_YOUR_AREA,
    component: ExpandYourArea,
    name: ERoutes.EXPAND_YOUR_AREA,
    exact: true,
    meta: {}
  }
]

export const ExpandYourAreaModule = {
  ERoutes,
  Routes
}
