import { Input, Modal, Space } from 'antd'
import { AxiosError } from 'axios'
import { cloneDeep } from 'lodash'
import { ChangeEvent, ComponentProps, FC, useCallback, useState } from 'react'
import { ProfileApi } from 'src/api'
import { useAsRef, useValidation } from 'src/hooks'
import { TMutable } from 'src/interfaces'
import { NotifyUtils } from 'src/utils'
import { ErrorMessage } from '../error-message'
import { changePasswordSchema } from './schema'

const defaultFormValues = Object.freeze({
  currentPassword: '',
  newPassword: '',
  passwordConfirmation: ''
})

interface IProps extends Omit<ComponentProps<typeof Modal>, 'onOk'> {
  onOk?: () => any
}

export const ModalChangePassword: FC<IProps> = (props) => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<TMutable<typeof defaultFormValues>>(cloneDeep(defaultFormValues))
  const { validate, errors } = useValidation({
    data: formData,
    schema: changePasswordSchema
  })
  const handleChangeInput = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    e.persist()
    const { name, value } = e.target
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }))
  }, [])

  const onOkRef = useAsRef(props.onOk)
  const handleSubmit = useCallback(async () => {
    try {
      const { isValid } = await validate()
      if (!isValid) {
        return
      }

      setLoading(true)
      await ProfileApi.changePassword(formData)
      NotifyUtils.success({ message: 'Change password successfully' })
      setFormData(cloneDeep(defaultFormValues))
      onOkRef.current?.()
    } catch (error) {
      NotifyUtils.handleAxiosError(error as AxiosError)
    } finally {
      setLoading(false)
    }
  }, [formData, onOkRef, validate])

  return (
    <Modal
      {...props}
      confirmLoading={loading}
      title="Change password"
      onOk={handleSubmit}
    >
      <Space className="fx" direction="vertical">
        <Input.Password
          name="currentPassword"
          value={formData.currentPassword}
          placeholder="Current password"
          status={errors.hasError('currentPassword') ? 'error' : undefined}
          onChange={handleChangeInput}
        />
        <ErrorMessage>{errors.getError('currentPassword')}</ErrorMessage>

        <Input.Password
          name="newPassword"
          value={formData.newPassword}
          placeholder="New password"
          status={errors.hasError('newPassword') ? 'error' : undefined}
          onChange={handleChangeInput}
        />
        <ErrorMessage>{errors.getError('newPassword')}</ErrorMessage>

        <Input.Password
          name="passwordConfirmation"
          value={formData.passwordConfirmation}
          placeholder="Confirm password"
          status={errors.hasError('passwordConfirmation') ? 'error' : undefined}
          onChange={handleChangeInput}
        />
        <ErrorMessage>{errors.getError('passwordConfirmation')}</ErrorMessage>
      </Space>
    </Modal>
  )
}
