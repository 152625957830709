import { Pagination, Select, Table } from 'antd'
import { omit } from 'lodash'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { QueueJobApi } from 'src/api'
import { DialogQueueCampaignInfo } from 'src/components'
import { IPaginateCallback, IPaginateParams, useBehaviorMapper, useDidMountDebounce, usePaginateParams } from 'src/hooks'
import { EQueueJobStatus, EQueueName, IQueueJobModel, QueueJobStatusOptions } from 'src/interfaces'
import { BreadcrumbService, PaginationService } from 'src/services'
import { EPaths } from '../../routes.path'
import { renderColumns } from '../columns'

const PAGE_TITLE = 'Queue Campaign Info'

export const QueueCampaignInfo: FC = () => {
  const _paginationService = useMemo(() => new PaginationService<IQueueJobModel>(QueueJobApi), [])
  const loading = useBehaviorMapper(_paginationService.loading$)
  const dataSource = useBehaviorMapper(_paginationService.pagination$)

  const fetch = useCallback<IPaginateCallback>(
    (params) => _paginationService.paging({
      ...params,
      queueName: EQueueName.CAMPAIGN_FEED_INFO
    }),
    [_paginationService]
  )

  const { pagination, pushPagination, setPagination } = usePaginateParams(fetch)
  const [filter, setFilter] = useState<{ status?: EQueueJobStatus } & Omit<IPaginateParams, 'page' | 'limit' | 'offset'>>(
    omit(pagination, ['page', 'limit', 'offset'])
  )

  useDidMountDebounce(() => {
    const { status, ...paging } = pagination
    setPagination({ ...paging, page: 1 })
    return pushPagination({
      ...paging,
      page: 1,
      ...filter
    })
  }, 2000, [filter])

  /**
   * breadcrumb
   */
  useEffect(() => {
    BreadcrumbService.items = [{
      route: EPaths.QUEUE_SCRAPE_JOBS,
      label: PAGE_TITLE
    }]
    return () => {
      BreadcrumbService.items = []
    }
  }, [])

  return (
    <section className="fx fx-column fx-extend">
      <div className="fx fx-jc-space-between mb-2">
        <Select
          style={{ width: '200px' }}
          allowClear
          placeholder="All status"
          options={QueueJobStatusOptions}
          value={filter.status}
          onChange={(value) => setFilter((prev) => ({
            ...prev,
            status: value as EQueueJobStatus
          }))}
        />

        <DialogQueueCampaignInfo afterClose={(saved) => saved && dataSource.page === 1 && fetch(pagination)}>
          Create
        </DialogQueueCampaignInfo>
      </div>

      <Table
        className="fx-extend"
        rowKey="id"
        loading={loading}
        columns={renderColumns()}
        dataSource={dataSource.rows}
        pagination={false}
      />

      <Pagination
        className="fx-as-end mt-3"
        disabled={loading}
        total={dataSource.total}
        current={pagination.page}
        pageSize={pagination.limit}
        {
          ...{
            ...pagination,
            onChange(page, limit) {
              pushPagination({
                ...pagination,
                page,
                limit
              })
            }
          }
        }
      />
    </section>
  )
}
