import { AxiosError } from 'axios'
import { FC, useEffect, useMemo, useState } from 'react'
import { Chart } from 'react-charts'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { CampaignIndicatorApi } from 'src/api'
import { useUnsubscribe } from 'src/hooks'
import { ECampaignIndicatorKey, ECampaignIndicatorLocation, ECampaignIndicatorRole, ICampaignIndicatorModel, ICampaignIndicatorRoleMotivator } from 'src/interfaces'
import { BreadcrumbService } from 'src/services'
import { GlobalAction } from 'src/store'
import { NotifyUtils } from 'src/utils'
import { EPaths } from '../../routes.path'

const PAGE_TITLE = 'Jobs Dashboard'

export const Dashboard: FC = () => {
  /**
   * breadcrumb
   */
  useEffect(() => {
    BreadcrumbService.items = [{
      route: EPaths.CAMPAIGN,
      label: PAGE_TITLE
    }]
    return () => {
      BreadcrumbService.items = []
    }
  }, [])

  const unsubscribe$ = useUnsubscribe()
  const [indicators, setIndicators] = useState<ICampaignIndicatorModel[]>([])
  useEffect(() => {
    GlobalAction.setLoading(true)
    from(CampaignIndicatorApi.index())
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error: AxiosError) => {
          NotifyUtils.handleAxiosError(error)
          return EMPTY
        }),
        finalize(() => GlobalAction.setLoading(false))
      )
      .subscribe((response) => {
        if (response.data.rows?.length) {
          setIndicators(response.data.rows)
        }
      })
  }, [unsubscribe$])

  const { countByRoleLocation, countByRoleMotivator, countByRoleWorkstyle } = useMemo(() => {
    const countByRoleLocation = indicators.find(
      (indicator) => indicator.key === ECampaignIndicatorKey.COUNT_BY_ROLE_LOCATION
    )
    const countByRoleMotivator = indicators.find(
      (indicator) => indicator.key === ECampaignIndicatorKey.COUNT_BY_ROLE_MOTIVATOR
    )
    const countByRoleWorkstyle = indicators.find(
      (indicator) => indicator.key === ECampaignIndicatorKey.COUNT_BY_ROLE_WORKSTYLE
    )
    return {
      countByRoleLocation,
      countByRoleMotivator: countByRoleMotivator as unknown as Voidable<ICampaignIndicatorModel<ICampaignIndicatorRoleMotivator>>,
      countByRoleWorkstyle: countByRoleWorkstyle as unknown as Voidable<ICampaignIndicatorModel<ICampaignIndicatorRoleMotivator>>
    }
  }, [indicators])

  const { groupByLocationRole, groupByRoleLocation } = useMemo(() => {
    const groupByLocationRole = {} as Record<ECampaignIndicatorLocation, Record<ECampaignIndicatorRole, {
      count: number
      minSalary: number
      maxSalary: number
    }>>
    const groupByRoleLocation = {} as Record<ECampaignIndicatorRole, Record<ECampaignIndicatorLocation, {
      count: number
      minSalary: number
      maxSalary: number
    }>>
    if (!countByRoleLocation) {
      return {
        groupByLocationRole,
        groupByRoleLocation
      }
    }
    for (const item of countByRoleLocation.data) {
      const _location = item.location
      const role = item.jobRole
      if (!_location || !role) {
        continue
      }
      // location/role
      const _exists = groupByLocationRole[_location]?.[role]
      groupByLocationRole[_location] = {
        ...groupByLocationRole[_location],
        [role]: {
          count: item.count + (_exists?.count || 0),
          minSalary: Math.min(...[item.minSalary || 0, _exists?.minSalary || 0].filter(Boolean)),
          maxSalary: Math.max(...[item.maxSalary || 0, _exists?.maxSalary || 0])
        }
      }
      // role/location
      const _existss = groupByRoleLocation[role]?.[_location]
      groupByRoleLocation[role] = {
        ...groupByRoleLocation[role],
        [_location]: {
          count: item.count + (_existss?.count || 0),
          minSalary: Math.min(...[item.minSalary || 0, _existss?.minSalary || 0].filter(Boolean)),
          maxSalary: Math.max(...[item.maxSalary || 0, _existss?.maxSalary || 0])
        }
      }
    }
    return {
      groupByLocationRole,
      groupByRoleLocation
    }
  }, [countByRoleLocation])

  const locationRoleStacked = useMemo(() => {
    const result = [] as Array<{
      label: ECampaignIndicatorRole
      data: Array<{
        primary: ECampaignIndicatorLocation
        secondary: number
      }>
    }>
    const locationHadData = Object.keys(groupByLocationRole)
    if (!locationHadData?.length) {
      return result
    }
    const locations = Object.values(ECampaignIndicatorLocation)
    return Object.values(ECampaignIndicatorRole).reduce((acc, role) => {
      acc.push({
        label: role,
        data: locations.reduce((_acc, location) => {
          if (locationHadData.includes(location)) {
            const _exists = groupByLocationRole[location]?.[role]
            _acc.push({
              primary: location,
              secondary: _exists?.count || 0
            })
          }
          return _acc
        }, [] as Array<{
          primary: ECampaignIndicatorLocation
          secondary: number
        }>)
      })
      return acc
    }, result)
  }, [groupByLocationRole])

  const roleLocationStacked = useMemo(() => {
    const result = [] as Array<{
      label: ECampaignIndicatorLocation
      data: Array<{
        primary: ECampaignIndicatorRole
        secondary: number
      }>
    }>
    const roleHadData = Object.keys(groupByRoleLocation)
    if (!roleHadData?.length) {
      return result
    }
    const roles = Object.values(ECampaignIndicatorRole)
    return Object.values(ECampaignIndicatorLocation).reduce((acc, location) => {
      acc.push({
        label: location,
        data: roles.reduce((_acc, role) => {
          if (roleHadData.includes(role)) {
            const _exists = groupByRoleLocation[role]?.[location]
            _acc.push({
              primary: role,
              secondary: _exists?.count || 0
            })
          }
          return _acc
        }, [] as Array<{
          primary: ECampaignIndicatorRole
          secondary: number
        }>)
      })
      return acc
    }, result)
  }, [groupByRoleLocation])

  const locationTopThree = useMemo(() => {
    const result = [] as Array<{
      location: ECampaignIndicatorLocation
      topThree: Array<{
        role: ECampaignIndicatorRole
        count: number
      }>
    }>
    return Object.entries(groupByLocationRole).reduce((acc, [location, roles]) => {
      acc.push({
        location: location as ECampaignIndicatorLocation,
        topThree: Object.entries(roles)
          .sort(([, a], [, b]) => b.count - a.count)
          .slice(0, 3)
          .map(([role, { count }]) => ({ role: role as ECampaignIndicatorRole, count }))
      })
      return acc
    }, result)
  }, [groupByLocationRole])

  const salaryByLocations = useMemo(() => {
    return Object.keys(groupByLocationRole).map((location) => {
      const _location = location as ECampaignIndicatorLocation
      return {
        location: _location,
        data: Object.keys(groupByLocationRole[_location]).reduce(([min, max], role) => {
          const _exists = groupByLocationRole[_location][role as ECampaignIndicatorRole]
          min.data.push({
            primary: `${_exists?.count || 0} ${role}`,
            secondary: _exists?.minSalary
          })
          max.data.push({
            primary: `${_exists?.count || 0} ${role}`,
            secondary: _exists?.maxSalary
          })
          return [min, max]
        }, [
          {
            label: 'Min Salary',
            data: [] as Array<{
              primary: string
              secondary: number
            }>
          },
          {
            label: 'Max Salary',
            data: [] as Array<{
              primary: string
              secondary: number
            }>
          }
        ])
      }
    })
  }, [groupByLocationRole])

  const topTriadsMotivatorByRole = useMemo(() => {
    const result = [] as Array<{
      role: ECampaignIndicatorRole
      topFive: Array<{
        motivator: string
        count: number
      }>
    }>
    if (!countByRoleMotivator?.data) {
      return result
    }
    return Object.entries(countByRoleMotivator.data).reduce((acc, [role, motivatorCount]) => {
      acc.push({
        role: role as ECampaignIndicatorRole,
        topFive: Object.entries(motivatorCount)
          .sort(([, a], [, b]) => b - a)
          .slice(0, 5)
          .map(([motivator, count]) => ({ motivator, count }))
      })
      return acc
    }, result)
  }, [countByRoleMotivator])

  const topWorkstyleQuartetsByRole = useMemo(() => {
    const result = [] as Array<{
      role: ECampaignIndicatorRole
      topFive: Array<{
        workstyle: string
        count: number
      }>
    }>
    if (!countByRoleWorkstyle?.data) {
      return result
    }
    return Object.entries(countByRoleWorkstyle.data).reduce((acc, [role, workstyleCount]) => {
      acc.push({
        role: role as ECampaignIndicatorRole,
        topFive: Object.entries(workstyleCount)
          .sort(([, a], [, b]) => b - a)
          .slice(0, 5)
          .map(([workstyle, count]) => ({ workstyle, count }))
      })
      return acc
    }, result)
  }, [countByRoleWorkstyle])

  console.log('index.tsx:232', { topTriadsMotivatorByRole, topWorkstyleQuartetsByRole })

  return (
    <section className="fx fx-column fx-extend gap-3">
      {!!locationRoleStacked.length && (
        <div
          className="p-1"
          style={{ border: '1px solid black' }}
        >
          <div>Jobs distribution per role and location</div>
          <div style={{ minHeight: 400 }}>
            <Chart
              options={{
                data: locationRoleStacked,
                primaryAxis: { getValue: (datum) => datum.primary },
                secondaryAxes: [{
                  hardMin: 0,
                  stacked: true,
                  getValue: (datum) => datum.secondary
                }]
              }}
            />
          </div>
        </div>
      )}

      {!!roleLocationStacked.length && (
        <div
          className="p-1"
          style={{ border: '1px solid black' }}
        >
          <div>Jobs distribution per role and location</div>
          <div style={{ minHeight: 400 }}>
            <Chart
              options={{
                data: roleLocationStacked,
                primaryAxis: { getValue: (datum) => datum.primary },
                secondaryAxes: [{
                  hardMin: 0,
                  stacked: true,
                  getValue: (datum) => datum.secondary
                }]
              }}
            />
          </div>
        </div>
      )}

      {!!locationTopThree?.length && (
        <div
          className="fx fx-wrap-wrap fx-jc-space-between gap-3 p-1"
          style={{ border: '1px solid black' }}
        >
          {locationTopThree.map((item) => (
            <div
              key={item.location}
              className="fx-1 fx fx-column p-1"
              style={{
                minWidth: '32%',
                border: '1px solid black'
              }}
            >
              <div>Top 3 roles in {item.location}</div>
              <div style={{ minHeight: 300 }}>
                <Chart
                  options={{
                    data: [{
                      label: item.location,
                      data: item.topThree.map((role) => ({
                        primary: role.role,
                        secondary: role.count
                      }))
                    }],
                    primaryAxis: { getValue: (datum) => datum.primary },
                    secondaryAxes: [{
                      hardMin: 0,
                      getValue: (datum) => datum.secondary
                    }]
                  }}
                />
              </div>
            </div>
          ))}
          <div className="fx-1" style={{ minWidth: '32%' }}/>
          <div className="fx-1" style={{ minWidth: '32%' }}/>
        </div>
      )}

      {!!salaryByLocations?.length && (
        <div
          className="fx fx-wrap-wrap fx-jc-space-between gap-3 p-1"
          style={{ border: '1px solid black' }}
        >
          {salaryByLocations.map(({ location, data }) => (
            <div
              key={location}
              className="fx-1 fx fx-column p-1"
              style={{
                minWidth: '49%',
                border: '1px solid black'
              }}
            >
              <div>Salary Range Per Role in {location}</div>
              <div style={{ minHeight: 320 }}>
                <Chart
                  options={{
                    data,
                    primaryAxis: { getValue: (datum) => datum.primary },
                    secondaryAxes: [{
                      hardMin: 0,
                      // stacked: true,
                      // stackOffset(series, order) {
                      //   console.log('index.tsx:296', { series, order })
                      // },
                      getValue: (datum) => datum.secondary
                      // getValue: (datum) => datum.secondary.toLocaleString('en-US', {
                      //   style: 'currency',
                      //   currency: 'USD',
                      // })
                    }]
                  }}
                />
              </div>
            </div>
          ))}
          <div className="fx-1" style={{ minWidth: '49%' }}/>
        </div>
      )}

      <div className="fx gap-3">
        {!!topTriadsMotivatorByRole?.length && (
          <div className="fx-1 fx fx-column gap-3">
            {topTriadsMotivatorByRole.map((item) => (
              <div
                key={item.role}
                className="fx-1 fx fx-column p-1"
                style={{ border: '1px solid black' }}
              >
                <div>Top 5 Motivator Triads of {item.role}</div>
                <div style={{ minHeight: 300 }}>
                  <Chart
                    options={{
                      data: [{
                        label: item.role,
                        data: item.topFive.reverse()
                      }],
                      primaryAxis: {
                        position: 'left',
                        getValue: (datum) => datum.motivator
                      },
                      secondaryAxes: [{
                        hardMin: 0,
                        position: 'bottom',
                        getValue: (datum) => datum.count
                      }]
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        )}

        {!!topWorkstyleQuartetsByRole?.length && (
          <div className="fx-1 fx fx-column gap-3">
            {topWorkstyleQuartetsByRole.map((item) => (
              <div
                key={item.role}
                className="fx-1 fx fx-column p-1"
                style={{ border: '1px solid black' }}
              >
                <div>Top 5 Workstyle Quartets of {item.role}</div>
                <div style={{ minHeight: 300 }}>
                  <Chart
                    options={{
                      data: [{
                        label: item.role,
                        data: item.topFive.reverse()
                      }],
                      primaryAxis: {
                        position: 'left',
                        getValue: (datum) => datum.workstyle
                      },
                      secondaryAxes: [{
                        hardMin: 0,
                        position: 'bottom',
                        getValue: (datum) => datum.count
                      }]
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  )
}
