import axios, { AxiosRequestConfig } from 'axios'

export type TS3PresignedKeyField = 'key' | 'bucket' | 'X-Amz-Algorithm' | 'X-Amz-Credential' | 'X-Amz-Date' | 'Policy' | 'X-Amz-Signature'

export interface IS3PresignedResult {
  url: string
  fields: Record<TS3PresignedKeyField, string>
}

export interface IS3PresignedMultipartResult {
  bucket: string
  key: string
  uploadId: string
  parts: {
    start: number
    end: number
    url: string
    partNumber: number
  }[]
}

const axiosInstance = axios.create()

export class S3Utils {
  /**
   * @deprecated use S3Utils.presignedMultipartUpload instead
   */
  static presignedUpload(presignedResult: IS3PresignedResult, file: File | Blob) {
    const form = new FormData()
    form.append('Content-Type', file.type.split(';')[0])
    for (const key of Object.keys(presignedResult.fields)) {
      form.append(key, presignedResult.fields[key as TS3PresignedKeyField])
    }
    form.append('file', file)
    return fetch(presignedResult.url, {
      method: 'POST',
      body: form
    })
  }

  /**
   * Upload a file to S3 using presigned multipart upload
   */
  static async presignedMultipartUpload(
    presignedResult: IS3PresignedMultipartResult,
    file: File | Blob,
    config?: AxiosRequestConfig
  ) {
    // const parts = await Promise.all(
    //   presignedResult.parts.map(
    //     (part) => axios.put(part.url, file.slice(part.start, part.end)).then(
    //       ({ headers }) => ({
    //         partNumber: part.partNumber,
    //         etag: headers.etag
    //       })
    //     )
    //   )
    // )

    if (config?.onUploadProgress) {
      let totalLoaded = 0
      let lastLoaded = 0
      const onUploadProgress = config.onUploadProgress
      config.onUploadProgress = (progressEvent) => {
        totalLoaded += (
          progressEvent.loaded - lastLoaded > 0
            ? progressEvent.loaded - lastLoaded
            : progressEvent.loaded
        )

        lastLoaded = progressEvent.loaded

        onUploadProgress({
          ...progressEvent,
          total: file.size,
          loaded: totalLoaded
        })
      }
    }

    const parts = []
    for (const part of presignedResult.parts) {
      const partResult = await axiosInstance.put(part.url, file.slice(part.start, part.end), config).then(
        ({ headers }) => ({
          partNumber: part.partNumber,
          etag: headers.etag
        })
      )

      parts.push(partResult)
    }

    return parts
  }
}
