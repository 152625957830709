import { Button, Popconfirm, Space } from 'antd'
import { ModalExpandYourAreaAction } from 'src/components'
import { EExpandYourReachAreaActions } from 'src/constants/enum'
import { IExpandYourAreaModel } from 'src/interfaces'
import { TimeZoneService } from 'src/services'

interface IUserAction<T = IExpandYourAreaModel> {
  onClickDelete?: (record: T) => any
  onUpdate?: (record: T) => any
}

const convertAction = (action: EExpandYourReachAreaActions | string) => {
  switch (action) {
    case EExpandYourReachAreaActions.ANSWER_QUESTION:
      return 'Answer Question'

    case EExpandYourReachAreaActions.WATCH_VIDEO:
      return 'Watch Video'

    case EExpandYourReachAreaActions.DO_QUIZZ:
      return 'Do Quizz'

    default:
      return ''
  }
}

export const renderCandidatesColumns = <T = IExpandYourAreaModel>({ onClickDelete, onUpdate }: IUserAction<T>) => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    render: (__: any, record: IExpandYourAreaModel) => convertAction(record.action)
  },
  {
    title: 'Action Item',
    dataIndex: 'item',
    key: 'item'
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description'
  },
  {
    title: 'Action URL',
    dataIndex: 'redirectURL',
    key: 'redirectURL'
  },
  {
    title: 'CreatedAt',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (val: IExpandYourAreaModel['createdAt']) => TimeZoneService.format(val)
  },
  {
    title: 'Action',
    key: 'action',
    render: (_: string, record: IExpandYourAreaModel) => (
      <Space
        wrap
        size="small"
        direction="vertical"
      >

        {onUpdate && (
          <ModalExpandYourAreaAction
            id={record.id}
            afterClose={(saved, doc) => saved && doc && onUpdate(doc as any)}
          >Update
          </ModalExpandYourAreaAction>
        )}

        {onClickDelete && (
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => onClickDelete(record as T)}
            okText="Yes"
            cancelText="No"
          >
            <Button size="small" danger>Delete</Button>
          </Popconfirm>
        )}
      </Space>
    )
  }
]
