import { useEffect } from 'react'
import { TimeZoneService } from 'src/services'
import { useBehaviorMapper } from './useBehaviorMapper'

export const useWatchTz = () => {
  const useUTC = useBehaviorMapper(TimeZoneService.useUTC$)

  useEffect(() => {
    console.log('Using timezone: ', useUTC ? 'UTC' : 'Local')
  }, [useUTC])
}
