import { CheckOutlined } from '@ant-design/icons'
import { Button, Popconfirm, Space, Switch } from 'antd'
import { Link } from 'react-router-dom'
import { IUserModel } from 'src/interfaces'
import { generate } from 'src/router'
import { TimeZoneService } from 'src/services'
import { UserUtils } from 'src/utils'
import { EPaths } from '../../routes.path'

interface IUserAction<T = IUserModel> {
  onClickDelete?: (record: T) => any
  onActiveChange?: (record: T) => any
}

export const renderCandidatesColumns = <T = IUserModel>({ onClickDelete, onActiveChange }: IUserAction<T>) => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (__: any, record: IUserModel) => UserUtils.getFullName(record)
  },
  {
    title: 'Username',
    dataIndex: 'username',
    key: 'username'
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email'
  },
  {
    title: 'Phone',
    dataIndex: 'userPhoneNo',
    key: 'userPhoneNo'
  },
  {
    title: 'Scoring classification',
    key: 'quizScores.coreClassification',
    dataIndex: ['quizScores', 'coreClassification']
  },
  {
    title: 'Linkedin Education',
    dataIndex: 'hadLinkedinEducation',
    key: 'hadLinkedinEducation',
    render: (val: IUserModel['hadLinkedinEducation']) => val && <CheckOutlined/>
  },
  {
    title: 'Linkedin Work Experience',
    dataIndex: 'hadLinkedinExperience',
    key: 'hadLinkedinExperience',
    render: (val: IUserModel['hadLinkedinExperience']) => val && <CheckOutlined/>
  },
  {
    title: 'Last Session',
    dataIndex: 'sessions',
    key: 'sessions',
    render: (val: IUserModel['sessions']) => val?.length
      ? TimeZoneService.format(val[val.length - 1].created)
      : 'N/A'
  },
  {
    title: 'CreatedAt',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (val: IUserModel['createdAt']) => TimeZoneService.format(val)
  },
  // {
  //   title: 'UpdatedAt',
  //   dataIndex: 'updatedAt',
  //   key: 'updatedAt',
  //   render: (val: IUserModel['updatedAt']) => TimeZoneService.format(val)
  // },
  {
    title: 'Action',
    key: 'action',
    render: (_: string, record: IUserModel) => (
      <Space
        wrap
        size="small"
        direction="vertical"
      >
        <Link to={generate([EPaths.CANDIDATE_DASHBOARD, { id: record.id }])}>
          Dashboard
        </Link>

        {!!record.quizScores && (
          <>
            <Link to={generate([EPaths.CANDIDATE_QUIZ_RESULT, { id: record.id }])}>
              Quiz 1st View
            </Link>
            <Link to={generate([EPaths.CANDIDATE_QUIZ_RESULT_THIRD_VIEW, { id: record.id }])}>
              Quiz 3rd View
            </Link>
          </>
        )}

        {onActiveChange && (
          <Popconfirm
            title={`Are you sure to ${record.isActive === false ? 'activate' : 'deactivate'} this candidate?`}
            onConfirm={() => onActiveChange(record as T)}
            onCancel={() => null}
            okText="Yes"
            cancelText="No"
          >
            <Switch
              checkedChildren="Active"
              unCheckedChildren="Inactive"
              checked={record.isActive !== false}
            />
          </Popconfirm>
        )}

        {onClickDelete && (
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => onClickDelete(record as T)}
            okText="Yes"
            cancelText="No"
          >
            <Button size="small" danger>Delete</Button>
          </Popconfirm>
        )}
      </Space>
    )
  }
]
