import { Input, Pagination, Table } from 'antd'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import { NotificationTemplateApi } from 'src/api'
import { IPaginateCallback, useBehaviorMapper, useDidMountDebounce, usePaginateParams } from 'src/hooks'
import { INotificationModel, INotificationTemplateModel } from 'src/interfaces'
import { BreadcrumbService, PaginationService, TimeZoneService } from 'src/services'
import { UserUtils } from 'src/utils'
import { EPaths } from '../../routes.path'

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: 'User',
    dataIndex: 'user',
    key: 'user',
    render: (val: INotificationModel['user']) => UserUtils.getFullName(val)
  },
  {
    title: 'CreatedAt',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (val: string) => TimeZoneService.format(val)
  },
  {
    title: 'Pushed At',
    dataIndex: 'pusheddAt',
    key: 'pusheddAt',
    render: (val: string) => val && TimeZoneService.format(val)
  },
  {
    title: 'ReceivedAt',
    dataIndex: 'receivedAt',
    key: 'receivedAt',
    render: (val: string) => val && TimeZoneService.format(val)
  },
  {
    title: 'SeenAt',
    dataIndex: 'seenAt',
    key: 'seenAt',
    render: (val: string) => val && TimeZoneService.format(val)
  },
  {
    title: 'Action At',
    dataIndex: 'actionAt',
    key: 'actionAt',
    render: (val: string) => val && TimeZoneService.format(val)
  }
]

export const Sent: FC = () => {
  const { id } = useParams<{ id: string }>()
  const [keyword, setKeyword] = useState('')
  const _paginationService = useMemo(
    () => new PaginationService<INotificationTemplateModel>({ endpoint: `${NotificationTemplateApi._prefix}/${id}/notifications` }),
    [id]
  )
  const loading = useBehaviorMapper(_paginationService.loading$)
  const dataSource = useBehaviorMapper(_paginationService.pagination$)

  const fetch = useCallback<IPaginateCallback>(
    (params) => _paginationService && _paginationService.paging({ ...params }),
    [_paginationService]
  )

  const { pagination, setPagination, pushPagination } = usePaginateParams(fetch)

  useEffect(() => {
    if (id) {
      fetch(pagination)
    }
  }, [id, fetch, pagination])

  useDidMountDebounce(() => {
    setPagination({ ...pagination, page: 1 })
    return pushPagination({
      ...pagination,
      page: 1,
      keyword
    })
  }, 2000, [keyword])

  /**
   * breadcrumb
   */
  useEffect(() => {
    BreadcrumbService.items = [
      {
        route: EPaths.NOTIFICATION_TEMPLATE,
        label: 'Notification Template'
      },
      {
        route: [EPaths.NOTIFICATION_TEMPLATE_SENT, { id }],
        label: 'Notifications'
      }
    ]
    return () => {
      BreadcrumbService.items = []
    }
  }, [id])

  return (
    <div className="fx fx-column gap-2">
      <strong>Notifications</strong>

      <div className="fx fx-jc-space-between mb-2">
        <Input
          allowClear
          placeholder="Search by user..."
          style={{ flex: '0 0 30vw' }}
          readOnly={loading}
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
        />
      </div>

      <Table
        rowKey="id"
        loading={loading}
        columns={columns}
        dataSource={dataSource.rows}
        pagination={false}
      />

      <Pagination
        className="fx-as-end mt-3"
        disabled={loading}
        total={dataSource.total}
        current={pagination.page}
        pageSize={pagination.limit}
        {
          ...{
            ...pagination,
            onChange(page, limit) {
              pushPagination({
                ...pagination,
                page,
                limit,
                keyword
              })
            }
          }
        }
      />
    </div>
  )
}
