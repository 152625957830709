import { Button, Space, TableProps } from 'antd'
import { isEmpty } from 'lodash'
import { ICampaignModel } from 'src/interfaces'
import { ERoutes } from 'src/modules/campaign/routes'
import { browserHistory, generate } from 'src/router'
import { TimeZoneService } from 'src/services'
import { UserUtils } from 'src/utils'

export const renderColumns = ({
  onChangeWarning
}: {
  onUpdate?: (record: ICampaignModel) => any
  onDelete?: (record: ICampaignModel) => any
  onChangeWarning?: (record: ICampaignModel) => any
} = {}): Required<TableProps<ICampaignModel>>['columns'] => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: 'Creator',
    dataIndex: 'author',
    key: 'author',
    render: (val?: ICampaignModel['author']) => UserUtils.getFullName(val)
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Logo',
    dataIndex: 'logoUrl',
    key: 'logoUrl',
    render: (val: ICampaignModel['logoUrl']) => !val
      ? null
      : <img src={val} alt="icon" style={{ height: 50 }}/>
  },
  {
    title: 'Company Name',
    dataIndex: 'companyName',
    key: 'companyName'
  },
  {
    title: 'Company Website',
    dataIndex: 'companyWebsiteUrls',
    key: 'companyWebsiteUrls',
    render: (val: ICampaignModel['companyWebsiteUrls']) => !isEmpty(val)
      ? val?.map((item, index) => <span key={index} title={item} className="twoline-text" style={{ maxWidth: '300px' }}>{item}</span>)
      : ''
  },
  {
    title: 'Question',
    dataIndex: 'question',
    key: 'question',
    render: (val: ICampaignModel['question']) => (
      <span title={val} style={{ maxWidth: '300px' }}>{val}</span>
    )
  },
  {
    title: 'Submissions',
    dataIndex: 'countSubmissions',
    key: 'countSubmissions'
  },
  {
    title: 'Status',
    dataIndex: 'deactivatedAt',
    key: 'deactivatedAt',
    render: (val?: ICampaignModel['deactivatedAt']) => isEmpty(val) ? 'Active' : 'Archive'
  },
  {
    title: 'CreatedAt',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (val: ICampaignModel['createdAt']) => TimeZoneService.format(val)
  },
  {
    title: 'Action',
    key: 'action',
    render: (_: string, record: ICampaignModel) => (
      <Space
        wrap
        size="small"
        direction="vertical"
      >
        <Button
          type="link"
          size="small"
          onClick={() => browserHistory.push(generate([ERoutes.CAMPAIGN_DETAIL, { id: record.id }]))}
        >View Detail
        </Button>
      </Space>
    )
  }
]
