import { IRouterOption } from 'src/router'
import { CandidateDashboard } from './components/dashboard'
import { Candidates } from './components/list'
import { CandidateQuizResult } from './components/quiz-result'
import { CandidateQuizResultThirdView } from './components/quiz-result/3rd-view'
import { EPaths } from './routes.path'

export enum ERoutes {
  CANDIDATE = 'Candidate.List',
  CANDIDATE_DASHBOARD = 'Candidate.Dashboard',
  CANDIDATE_QUIZ_RESULT = 'Candidate.QuizResult',
  CANDIDATE_QUIZ_RESULT_THIRD_VIEW = 'CANDIDATE_QUIZ_RESULT_THIRD_VIEW'
}

export const Routes: IRouterOption[] = [
  {
    path: EPaths.CANDIDATE,
    component: Candidates,
    name: ERoutes.CANDIDATE,
    exact: true,
    meta: {}
  },
  {
    path: EPaths.CANDIDATE_DASHBOARD,
    component: CandidateDashboard,
    name: ERoutes.CANDIDATE_DASHBOARD,
    exact: true,
    meta: {}
  },
  {
    path: EPaths.CANDIDATE_QUIZ_RESULT,
    component: CandidateQuizResult,
    name: ERoutes.CANDIDATE_QUIZ_RESULT,
    exact: true,
    meta: {}
  },
  {
    path: EPaths.CANDIDATE_QUIZ_RESULT_THIRD_VIEW,
    component: CandidateQuizResultThirdView,
    name: ERoutes.CANDIDATE_QUIZ_RESULT_THIRD_VIEW,
    exact: true,
    meta: {}
  }
]

export const CandidateModule = {
  ERoutes,
  Routes
}
