import { FC, useMemo } from 'react'
import { ToastContentProps } from 'react-toastify'
import { INotificationModel, INotificationTemplateModel } from 'src/interfaces'

export const NotificationPreview: FC<{
  notification: INotificationModel | INotificationTemplateModel
  toastProps?: ToastContentProps<any>
}> = (props) => {
  const { notification } = props
  const previewImg = useMemo(
    () => notification.imageUrl
      ? notification.imageUrl
      : '/logo.png', [notification]
  )

  return (
    <div className="fx fx-extend gap-2">
      <div>
        <img src={previewImg} alt="icon" style={{ height: 50 }}/>
      </div>

      <div className="fx fx-column fx-extend gap-1">
        <b>{notification.title}</b>
        <p className="threeline-text">{notification.body}</p>
      </div>
    </div>
  )
}
