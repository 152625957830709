import qs, { ParsedQs } from 'qs'
import { useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { browserHistory } from 'src/router'
import { Helpers } from 'src/utils'
import { useAsRef } from './useAsRef'
import { useDidMountEffect } from './useDidMountEffect'
import { useMountEffect } from './useMountEffect'
import { useQueryParams } from './useQueryParams'

const min = (value?: number, limit = 1) => {
  return Math.max(
    limit,
    Number(value) || limit
  )
}

export const usePaginateParams = <
  P extends {
    page?: number
    limit?: number
    offset?: number
    [key: string]: undefined | boolean | number | string | string[] | ParsedQs | ParsedQs[]
  },
  T extends (params: P) => any = (params: P) => any
>(callback: T) => {
  const history = useHistory()
  const queryParams = useQueryParams()
  const parsedParams = useMemo(() => ({
    ...queryParams,
    page: min(Number(queryParams.page), 1),
    limit: min(Number(queryParams.limit), 10),
    offset: min(Number(queryParams.offset), 0)
  } as P), [queryParams])

  const callbackRef = useAsRef(callback)
  const [pagination, setPagination] = useState(parsedParams)

  /**
   * Push URL parameters history
   */
  const pushPagination = useCallback((query: P) => {
    browserHistory.push(
      [
        history.location.pathname,
        qs.stringify(Helpers.ensureObject(query), {
          arrayFormat: 'repeat',
          sort: Helpers.alphabeticalSort
        })
      ].join('?')
    )
  }, [history.location.pathname])

  useDidMountEffect(() => {
    const paging = {
      ...pagination,
      ...parsedParams
    }

    setPagination(paging)
    callbackRef.current(paging)
  }, [parsedParams])

  useMountEffect(() => {
    callbackRef.current(pagination)
  })

  return {
    pagination,
    pushPagination,
    setPagination
  }
}

export type IPaginateCallback = Parameters<typeof usePaginateParams>[0]
export type IPaginateParams = Parameters<IPaginateCallback>[0]
