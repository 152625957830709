import PlusCircleOutlined from '@ant-design/icons/PlusCircleOutlined'
import { Button, Modal, Popconfirm, Space, Table } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { ColumnsType } from 'antd/lib/table'
import { FC, useCallback, useEffect, useState } from 'react'
import { finalize, from, takeUntil } from 'rxjs'
import { CampaignQuestionApi } from 'src/api'
import { useUnsubscribe } from 'src/hooks'
import { ICampaignQuestionModel } from 'src/interfaces'
import { NotifyUtils } from 'src/utils'

interface IProps {
  open: boolean
  onOk: () => void
}

export const QuestionModal: FC<IProps> = ({ open, onOk }) => {
  const [questions, setQuestions] = useState<ICampaignQuestionModel[]>([])
  const [loading, setLoading] = useState(false)
  const unsubscribe$ = useUnsubscribe()

  const loadQuestions = useCallback(() => {
    setLoading(true)

    from(CampaignQuestionApi.list())
      .pipe(
        takeUntil(unsubscribe$),
        finalize(() => setLoading(false))
      )
      .subscribe(({ data }) => setQuestions(data))
  }, [unsubscribe$])

  useEffect(() => {
    loadQuestions()
  }, [loadQuestions])

  const changeQuestionText = (index: number, text: string) => {
    setQuestions(questions => questions.map((question, i) => {
      if (i === index) {
        return { ...question, text }
      }
      return question
    }))
  }

  const handleDeleteQuestion = (index: number) => {
    const question = questions.at(index)
    if (!question) {
      return
    }

    if (!question.id) {
      setQuestions(questions => questions.filter((question, i) => i !== index))
      return
    }

    setLoading(true)

    from(CampaignQuestionApi.destroy(question.id))
      .pipe(
        takeUntil(unsubscribe$),
        finalize(() => setLoading(false))
      )
      .subscribe(() => {
        setQuestions(questions => questions.filter(({ id }) => question.id !== id))
        NotifyUtils.success({ message: 'Question deleted' })
      })
  }

  const handleSaveQuestion = (index: number) => {
    const question = questions.at(index)
    if (!question || !question.text) {
      return
    }

    if (!question.id) {
      setLoading(true)
      from(CampaignQuestionApi.create(question.text))
        .pipe(
          takeUntil(unsubscribe$),
          finalize(() => setLoading(false))
        )
        .subscribe(({ data }) => {
          setQuestions(questions => questions.map((item, i) => {
            if (i === index) {
              return { ...item, id: data.id }
            }
            return item
          }))

          NotifyUtils.success({ message: 'Question created successfully' })
        })

      return
    }

    setLoading(true)

    from(CampaignQuestionApi.update(question.id, question.text))
      .pipe(
        takeUntil(unsubscribe$),
        finalize(() => setLoading(false))
      )
      .subscribe(() => {
        NotifyUtils.success({ message: 'Question updated successfully' })
      })
  }

  const addQuestion = () => {
    setQuestions(questions => [...questions, { id: 0, text: '' }])
  }

  return (
    <Modal
      title="Manage Campaigns Question"
      centered
      open={open}
      onCancel={onOk}
      footer={[
        <Button type="primary" key="1" onClick={onOk}>Close</Button>
      ]}
    >
      <Table
        pagination={false}
        loading={loading}
        footer={() => [
          <Button type="primary" key="1" onClick={addQuestion}>
            <PlusCircleOutlined/> Add
          </Button>
        ]}
        dataSource={questions}
        columns={renderColumns({
          onChange: (index, text) => changeQuestionText(index, text),
          onDelete: (index) => handleDeleteQuestion(index),
          onSave: (index) => handleSaveQuestion(index)
        })}
      />
    </Modal>
  )
}

const renderColumns = ({
  onChange,
  onDelete,
  onSave
}: {
  onChange: (id: number, text: string) => void
  onDelete: (id: number) => void
  onSave: (index: number) => void
}): ColumnsType<ICampaignQuestionModel> => {
  return [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      render: (id) => <span>{id || ''}</span>
    },
    {
      title: 'Question',
      dataIndex: 'text',
      key: 'text',
      render: (text, _, index) => <TextArea value={text} onChange={(e) => onChange(index, e.target.value)}/>
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record, index) => (
        <Space size="middle">
          <Popconfirm
            title="Delete the question?"
            onConfirm={() => onDelete(index)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="dashed" danger>Delete</Button>
          </Popconfirm>
          <Button type="primary" onClick={() => onSave(index)}>Save</Button>
        </Space>
      )
    }
  ]
}
