import { IObject } from '../object.interface'
import { IModel } from './model'
import { INotificationModel } from './notification.model'
import { IUserModel } from './user.model'

export enum ENotificationAction {
  GO_TO_VIBE_TUTORIAL = 'goToVibeTutorial',
  GO_TO_VIBE_CHECK = 'goToVibeCheck',
  GO_TO_QUIZ = 'goToQuiz',
  GO_TO_DASHBOARD = 'goToDashboard',
  GO_TO_PROFILE_SCREEN = 'goToProfileScreen',
  GO_TO_SUMMARY_SCREEN = 'goToSummaryScreen',
  GO_TO_AUDIO_VIBE_CHECK_SCREEN = 'goToAudioVibeCheckScreen'
}

export const ENotificationActionText = Object.freeze({
  [ENotificationAction.GO_TO_VIBE_TUTORIAL]: 'Go to vibe tutorial',
  [ENotificationAction.GO_TO_VIBE_CHECK]: 'Go to vibe check',
  [ENotificationAction.GO_TO_QUIZ]: 'Go to quiz',
  [ENotificationAction.GO_TO_DASHBOARD]: 'Go to dashboard',
  [ENotificationAction.GO_TO_PROFILE_SCREEN]: 'Go to profile screen',
  [ENotificationAction.GO_TO_SUMMARY_SCREEN]: 'Go to summary screen',
  [ENotificationAction.GO_TO_AUDIO_VIBE_CHECK_SCREEN]: 'Go to audio vibe check screen'
})

export enum ENotificationGroupUser {
  TEST_USERS = 'testUsers',
  HAD_VIBE_NOT_LINKEDIN = 'hadVibeNotLinkedin',
  VIBE_NOT_COMPLETED = 'vibeNotCompleted',
  QUIZ_NOT_COMPLETED = 'quizNotCompleted',
  VIBE_COMPLETED = 'vibeCompleted'
}

export const ENotificationGroupUserText = Object.freeze({
  [ENotificationGroupUser.TEST_USERS]: 'Test Users',
  [ENotificationGroupUser.HAD_VIBE_NOT_LINKEDIN]: 'Users have vibe check and don\'t have linkedIn URL',
  [ENotificationGroupUser.QUIZ_NOT_COMPLETED]: 'Users who have not completed a quiz',
  [ENotificationGroupUser.VIBE_NOT_COMPLETED]: 'Users who have not completed a vibe check',
  [ENotificationGroupUser.VIBE_COMPLETED]: 'Users who have completed a vibe check'
})

export interface INotificationTemplateModel extends IModel {
  userId: number
  user?: IUserModel
  action?: ENotificationAction
  title?: string
  body?: string
  imageUrl?: string
  countSent?: number
  groupUser?: ENotificationGroupUser
  joinFromDate?: string
  joinToDate?: string
  data?: IObject

  users?: IUserModel[]
  notifications?: INotificationModel[]
  nextExecutionDate?: Date
  intervalMilliseconds?: number
}
