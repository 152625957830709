import clsx from 'clsx'
import { isEmpty } from 'lodash'
import { FC } from 'react'
import { ICampaignModel } from 'src/interfaces'
import { CampaignVideo } from './videos'

interface IProps {
  campaign?: ICampaignModel
  className?: string
}

export const InfoDetail: FC<IProps> = ({ campaign, className }) => {
  return (
    <div className={clsx('fx-1 fx fx-column', className)}>
      <h2><strong>Job Detail</strong></h2>

      <div className="fx gap-2 fx-ai-center">
        <strong>Job Name:</strong>
        {campaign?.jobTitle && (
          <span>{campaign?.jobTitle}</span>
        )}
      </div>

      <div className="fx gap-2 fx-ai-center">
        <strong>Job Title:</strong>
        {campaign?.jobTitle && (
          <span>{campaign?.jobTitle}</span>
        )}
      </div>

      <div className="fx gap-2 fx-ai-center">
        <strong>Salary:</strong>
        {campaign?.salaryValue && (
          <span>${campaign?.salaryValue?.min} - ${campaign?.salaryValue?.max} ({campaign?.salaryRate})</span>
        )}
      </div>

      <div className="fx gap-2 fx-ai-center">
        <strong>Location:</strong>
        {campaign?.location && (
          <span>{campaign?.location}</span>
        )}
      </div>

      <div className="fx gap-2 fx-ai-center">
        <strong>Allow Remote Workers:</strong>
        <span>{campaign?.allowRemote ? 'Yes' : 'No'}</span>
      </div>

      <div className="fx gap-2 fx-ai-center">
        <strong>Require Resume:</strong>
        <span>{campaign?.requiredResume ? 'Yes' : 'No'}</span>
      </div>

      <div className="fx gap-2 fx-ai-center">
        <strong>Require LinkedIn Profile:</strong>
        <span>{campaign?.requireLinkedInUrl ? 'Yes' : 'No'}</span>
      </div>

      <div className="fx gap-2 fx-ai-center">
        <strong>Zip Code of Residence:</strong>
        <span>{campaign?.requireZipCode ? 'Yes' : 'No'}</span>
      </div>

      <div className="fx gap-2 fx-ai-center">
        <strong>Share on KnowMe Marketplace:</strong>
        <span>{campaign?.shareOnMarket ? 'Yes' : 'No'}</span>
      </div>

      <div className="fx gap-2 fx-ai-center">
        <strong>Question:</strong>
        {campaign?.question && (
          <span>{campaign?.question}</span>
        )}
      </div>

      {!isEmpty(campaign?.jdFileUrl) && (
        <div className="fx gap-2 fx-ai-center">
          <strong>JD File:</strong>
          <span><a href={campaign?.jdFileUrl} target="_blank" rel="noreferrer">{campaign?.jdFileUrl?.split('/').pop()}</a></span>
        </div>
      )}
      <div className="fx gap-2">
        <strong>Job Description:</strong>
        {campaign?.briefDescription && (
          <span dangerouslySetInnerHTML={{ __html: campaign?.briefDescription }}/>
        )}
      </div>

      {campaign?.uploadVideo && (
        <>
          <strong>Video</strong>
          <CampaignVideo campaign={campaign}/>
        </>
      )}
    </div>
  )
}
