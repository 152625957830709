import axios from 'axios'
import { IPaginationQuery, IUserModel, TAxiosResponsePagination } from 'src/interfaces'

export type AdminPaginationParam = Omit<IPaginationQuery, 'keyword'> & { role?: string }

export class AdminApi {
  static readonly _prefix = '/admin'

  static index(params: AdminPaginationParam = {}): TAxiosResponsePagination<IUserModel> {
    return axios.get(`${AdminApi._prefix}`, { params })
  }

  static assignRoles(email: string, roles: string[]): Promise<any> {
    return axios.post(`${AdminApi._prefix}/assign-roles`, { email, roles })
  }
}
