import { Button, Form, Input } from 'antd'
import { AxiosError } from 'axios'
import moment, { Moment } from 'moment'
import { ChangeEvent, ComponentProps, FC, useCallback, useMemo, useState } from 'react'
import { EMPTY, Subject, catchError, finalize, from, takeUntil } from 'rxjs'
import { PromoCodeApi } from 'src/api'
import { ModalFullscreen, RangePicker } from 'src/components'
import { useDidMountDebounce, useDidMountEffect, useUnsubscribe, useValidation } from 'src/hooks'
import { IPromoCodeModel } from 'src/interfaces'
import { AxiosUtils, NotifyUtils } from 'src/utils'
import * as Yup from 'yup'
import { ErrorMessage } from '../error-message'

const yupSchema = Yup.object().shape({
  code: Yup.string().required('Code is required'),
  introCredit: Yup.number().integer().positive().required('Intro credit is required'),
  validFrom: Yup.date()/** .matches(/^$/, 'Invalid Date') */.nullable(),
  validTo: Yup.date()/** .matches(/^$/, 'Invalid Date') */.nullable(),
  validFor: Yup.string().nullable(),
  limit: Yup.number().integer().positive().nullable(),
  limitPerUser: Yup.number().integer().positive().nullable()
})

export const ModalPromoCode: FC<Omit<
  ComponentProps<typeof ModalFullscreen>,
  'onOk' | 'afterClose'
> & {
  id?: IPromoCodeModel['id']
  // onOk?: () => any
  afterClose?: (saved: boolean, doc?: IPromoCodeModel) => any
  btnProps?: ComponentProps<typeof Button>
}> = ({
  btnProps,
  ...props
}) => {
  const unsubscribe$ = useUnsubscribe()
  const [loading, setLoading] = useState(false)
  const [invisible, setInvisible] = useState<boolean | IPromoCodeModel>(true)
  const [formData, setFormData] = useState<Parameters<typeof PromoCodeApi.store>[0]>({})

  const payload = useMemo(() => ({
    ...formData
  }), [formData])

  const handleChangeInput = useCallback((e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
    e.persist()
    const { name, value } = e.target
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }))
  }, [])

  const { validate, errors, reset } = useValidation({
    data: formData,
    schema: yupSchema
  })

  const save = useCallback(async () => {
    const { isValid } = await validate()
    if (!isValid) {
      return
    }

    const apiHandler = props.id
      ? PromoCodeApi.update(props.id, payload)
      : PromoCodeApi.store(payload)

    setLoading(true)
    from(apiHandler)
      .pipe(
        takeUntil(unsubscribe$),
        finalize(() => setLoading(false))
      )
      .subscribe({
        next: ({ data }) => {
          NotifyUtils.success({ message: `${props.id ? 'Update' : 'Create'} promo code successfully` })
          setInvisible(data)
        },
        error: NotifyUtils.handleAxiosError
      })
  }, [validate, props.id, payload, unsubscribe$])

  useDidMountDebounce(() => {
    if (payload.code && !props.id) {
      const unsubscribe$ = new Subject<void>()
      from(PromoCodeApi.validate(payload.code))
        .pipe(
          takeUntil(unsubscribe$),
          catchError((error: AxiosError) => {
            errors.setError('code', AxiosUtils.getApiErrorMessage(error))
            return EMPTY
          })
        )
        .subscribe(() => errors.clearError('code'))
      return () => {
        unsubscribe$.next()
        unsubscribe$.complete()
      }
    }
  }, 2000, [props.id, payload.code])

  useDidMountEffect(() => {
    reset()

    if (invisible) {
      setLoading(false)
      setFormData(() => ({}))

      const isSaved = typeof invisible !== 'boolean'
      props.afterClose?.(isSaved, isSaved ? invisible : undefined)
    }
  }, [invisible])

  useDidMountEffect(() => {
    if (!invisible && props.id) {
      setLoading(true)
      from(PromoCodeApi.show(props.id))
        .pipe(
          takeUntil(unsubscribe$),
          finalize(() => setLoading(false))
        )
        .subscribe({
          next: ({ data }) => {
            setFormData(() => ({
              ...data
            }))
          },
          error: NotifyUtils.handleAxiosError
        })
    }
  }, [props.id, invisible])

  return (
    <>
      <Button
        type="primary"
        {...btnProps}
        onClick={() => setInvisible(false)}
      >
        {props.children || 'Create'}
      </Button>

      <ModalFullscreen
        wrapClassName="__modal-promo-code"
        title={`${props.id ? 'Edit' : 'Create'} Promo Code`}
        open={!invisible}
        closable={!loading} // display X icon
        keyboard={false} // disable close on press ESC
        maskClosable={false} // disable close on click outside
        okText={props.id ? 'Update' : 'Save'}
        cancelText="Cancel"
        onOk={save}
        onCancel={() => setInvisible(true)}
        okButtonProps={{ disabled: loading }}
        cancelButtonProps={{ disabled: loading }}
      >
        {!invisible && (
          <div className="fx fx-column fx-extend gap-2">
            <Form.Item
              required
              label="Code"
              labelAlign="left"
              labelCol={{ flex: '0 0 100px' }}
              className="m-0"
            >
              <Input
                required
                readOnly={loading}
                name="code"
                placeholder="Code"
                disabled={!!props.id}
                value={formData.code}
                status={errors.hasError('code') ? 'error' : undefined}
                onChange={handleChangeInput}
              />
              <ErrorMessage>{errors.getError('code')}</ErrorMessage>
            </Form.Item>

            <Form.Item
              required
              label="Intro Credit"
              labelAlign="left"
              labelCol={{ flex: '0 0 100px' }}
              className="m-0"
            >
              <Input
                required
                readOnly={loading}
                name="introCredit"
                placeholder="Intro Credit"
                value={formData.introCredit}
                status={errors.hasError('introCredit') ? 'error' : undefined}
                onChange={handleChangeInput}
              />
              <ErrorMessage>{errors.getError('introCredit')}</ErrorMessage>
            </Form.Item>

            <Form.Item
              label="Quantity"
              labelAlign="left"
              labelCol={{ flex: '0 0 100px' }}
              className="m-0"
            >
              <Input
                readOnly={loading}
                name="limit"
                placeholder="Quantity"
                value={formData.limit}
                status={errors.hasError('limit') ? 'error' : undefined}
                onChange={handleChangeInput}
              />
              <ErrorMessage>{errors.getError('limit')}</ErrorMessage>
            </Form.Item>

            <Form.Item
              label="Limit per user"
              labelAlign="left"
              labelCol={{ flex: '0 0 100px' }}
              className="m-0"
            >
              <Input
                readOnly={loading}
                name="limitPerUser"
                placeholder="Limit per user"
                value={formData.limitPerUser}
                status={errors.hasError('limitPerUser') ? 'error' : undefined}
                onChange={handleChangeInput}
              />
              <ErrorMessage>{errors.getError('limitPerUser')}</ErrorMessage>
            </Form.Item>

            <Form.Item
              label="Valid From - To"
              labelAlign="left"
              labelCol={{ flex: '0 0 100px' }}
              className="m-0"
            >
              <RangePicker
                allowClear
                disabledDate={(current: Moment) => current && current < moment().startOf('day')}
                value={[
                  formData.validFrom ? moment(formData.validFrom) : null,
                  formData.validTo ? moment(formData.validTo) : null
                ]}
                onChange={(values, dates) => setFormData((prev) => ({
                  ...prev,
                  validFrom: values?.[0]?.startOf('day').toISOString(),
                  validTo: values?.[1]?.endOf('day').toISOString()
                }))}
              />
              <ErrorMessage>{errors.getError('validFrom') || errors.getError('validTo')}</ErrorMessage>
            </Form.Item>
          </div>
        )}
      </ModalFullscreen>
    </>
  )
}
