import { Modal, Select } from 'antd'
import { AxiosError } from 'axios'
import { FC, ReactNode, useCallback, useMemo, useState } from 'react'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { VideoApi } from 'src/api'
import { EVideoType } from 'src/constants/enum'
import { useAsRef, useDidMountEffect, useUnsubscribe } from 'src/hooks'
import { IVideoModel } from 'src/interfaces'
import { NotifyUtils } from 'src/utils'

interface IProps {
  video: IVideoModel
  onOk?: (video: IVideoModel) => any
  children?: ReactNode
}

export const ModalVideoTags: FC<IProps> = ({
  video,
  ...props
}) => {
  const unsubscribe$ = useUnsubscribe()
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState(video.videoTags || [])
  const options = useMemo(() => Object.values(EVideoType).map(
    (value) => ({
      value,
      label: value
    })
  ), [])

  useDidMountEffect(() => {
    setValue(video.videoTags || [])
  }, [video.videoTags])

  const onOkRef = useAsRef(props.onOk)
  const onSubmit = useCallback(() => {
    setLoading(true)
    from(VideoApi.update(video.id, { videoTags: value }))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error: AxiosError) => {
          NotifyUtils.handleAxiosError(error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(() => {
        video.videoTags = value
        onOkRef.current?.(video)
        setOpen(false)
      })
  }, [video, value, unsubscribe$, onOkRef])

  return (
    <>
      <span onClick={() => setOpen(true)}>
        {props.children || 'Open'}
      </span>

      {open && (
        <Modal
          open
          centered
          closable={!loading}
          maskClosable
          destroyOnClose
          title="Edit Video Tags"
          okButtonProps={{ disabled: loading }}
          cancelButtonProps={{ disabled: loading }}
          onOk={onSubmit}
          onCancel={() => setOpen(false)}
          afterClose={() => setOpen(false)}
        >
          <div className="fx fx-column">
            <Select
              mode="multiple"
              placeholder="Select video tags"
              options={options}
              disabled={loading}
              value={value}
              onChange={(selected) => setValue(selected as EVideoType[])}
            />
          </div>
        </Modal>
      )}
    </>
  )
}
