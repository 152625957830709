import { HomeOutlined } from '@ant-design/icons'
import { ExoticComponent } from 'react'
import { BehaviorSubject } from 'rxjs'
import { Helpers } from 'src/utils'

export interface IBreadcrumbItem {
  icon?: ExoticComponent
  key?: string
  label?: string
  route?: string | [string, { [key in string]: string | number }]
}

const defaultItems: IBreadcrumbItem = {
  icon: HomeOutlined,
  key: Math.random().toString(36).substr(2, 9),
  route: '/'
}

export const BreadcrumbService = new (
  class {
    create(items: IBreadcrumbItem | IBreadcrumbItem[]) {
      return Helpers.ensureArray<IBreadcrumbItem>(items).map(
        item => ({
          ...item,
          key: item.key || Math.random().toString(36).substr(2, 9)
        })
      )
    }

    constructor(items: IBreadcrumbItem[] = []) {
      this.items = items
    }

    readonly items$ = new BehaviorSubject<IBreadcrumbItem[]>([])
    get items() {
      return this.items$.getValue()
    }

    set items(items: IBreadcrumbItem[]) {
      this.items$.next(this.create([defaultItems, ...items]))
    }
  }
)()
