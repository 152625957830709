import { Button, Input } from 'antd'
import { ChangeEvent, ComponentProps, FC, useCallback, useEffect, useState } from 'react'
import { finalize, from, takeUntil } from 'rxjs'
import { BlogCategoryApi } from 'src/api'
import { ModalFullscreen } from 'src/components'
import { useDidMountEffect, useUnsubscribe, useValidation } from 'src/hooks'
import { IBlogCategoryModel } from 'src/interfaces'
import { NotifyUtils } from 'src/utils'
import * as Yup from 'yup'
import { ErrorMessage } from '../error-message'

const schema = Yup.object().shape({
  name: Yup.string().typeError('Name invalid').required('Name is required'),
  description: Yup.string().nullable()
})

export const ModalBlogCategory: FC<Omit<
  ComponentProps<typeof ModalFullscreen>,
  'onOk' | 'afterClose'
> & {
  btnProps?: ComponentProps<typeof Button>
  id?: IBlogCategoryModel['id']
  // onOk?: () => any
  afterClose?: (saved: boolean, doc?: IBlogCategoryModel) => any
}> = ({
  btnProps,
  ...props
}) => {
  const unsubscribe$ = useUnsubscribe()
  const [loading, setLoading] = useState(false)
  const [invisible, setInvisible] = useState<boolean | IBlogCategoryModel>(true)
  const [formData, setFormData] = useState<Parameters<typeof BlogCategoryApi.store>[0]>({
    name: ''
  })

  const handleChangeInput = useCallback((e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
    e.persist()
    const { name, value } = e.target
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }))
  }, [])

  const { validate, errors, reset } = useValidation({
    data: formData,
    schema
  })

  const submit = useCallback(async () => {
    const { isValid } = await validate()
    if (!isValid) {
      return
    }

    setLoading(true)
    from(props.id ? BlogCategoryApi.update(props.id, formData) : BlogCategoryApi.store(formData))
      .pipe(
        takeUntil(unsubscribe$),
        finalize(() => setLoading(false))
      )
      .subscribe({
        next: ({ data }) => {
          NotifyUtils.success({ message: 'Save category successfully' })
          setInvisible(data)
        },
        error: NotifyUtils.handleAxiosError
      })
  }, [formData, props.id, unsubscribe$, validate])

  useDidMountEffect(() => {
    if (invisible) {
      setFormData({ name: '' })
      const isSaved = typeof invisible !== 'boolean'
      props.afterClose?.(isSaved, isSaved ? invisible : undefined)
    }

    reset()
  }, [invisible])

  useEffect(() => {
    if (invisible || !props.id) {
      return
    }

    setLoading(true)
    from(BlogCategoryApi.show(props.id))
      .pipe(
        takeUntil(unsubscribe$),
        finalize(() => setLoading(false))
      )
      .subscribe({
        next: ({ data }) => setFormData({
          name: data.name ?? '',
          description: data.description
        }),
        error: NotifyUtils.handleAxiosError
      })
  }, [invisible, props.id, unsubscribe$])

  return (
    <>
      <Button
        type="primary"
        {...btnProps}
        onClick={() => setInvisible(false)}
      >
        {props.children || 'Open'}
      </Button>

      <ModalFullscreen
        title={props.title || 'Blog Category'}
        open={!invisible}
        closable={!loading} // display X icon
        keyboard={false} // disable close on press ESC
        maskClosable={false} // disable close on click outside
        okText="Submit"
        cancelText="Cancel"
        okButtonProps={{ disabled: loading }}
        cancelButtonProps={{ disabled: loading }}
        onOk={submit}
        onCancel={() => setInvisible(true)}
      >
        <div className="fx fx-column fx-extend gap-3">
          <div className="fx fx-column gap-2">
            <Input
              required
              readOnly={loading}
              name="name"
              placeholder="Category name"
              value={formData.name}
              status={errors.hasError('name') ? 'error' : undefined}
              onChange={handleChangeInput}
            />
            <ErrorMessage>{errors.getError('name')}</ErrorMessage>
          </div>

          <div className="fx fx-column gap-2">
            <Input.TextArea
              rows={4}
              name="description"
              placeholder="Enter description"
              value={formData.description}
              status={errors.hasError('description') ? 'error' : undefined}
              readOnly={loading}
              onChange={handleChangeInput}
            />
            <ErrorMessage>{errors.getError('description')}</ErrorMessage>
          </div>
        </div>
      </ModalFullscreen>
    </>
  )
}
