import { BehaviorSubject } from 'rxjs'

export const PlayerService = new (
  class {
    private playerRef = 0
    genRef() {
      return ++this.playerRef
    }

    readonly playing$ = new BehaviorSubject<number | string | undefined>(undefined)
    get playing() {
      return this.playing$.getValue()
    }

    set playing(refNo) {
      this.playing$.next(refNo)
    }

    stop() {
      this.playing = undefined
    }

    readonly bandwidthEstimate$ = new BehaviorSubject<number>(0)
    get bandwidthEstimate() {
      return this.bandwidthEstimate$.getValue()
    }

    set bandwidthEstimate(refNo) {
      this.bandwidthEstimate$.next(refNo)
    }
  }
)()
