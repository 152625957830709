import { IRouterOption } from 'src/router'
import { SignIn } from './components/sign-in'
import { EPaths } from './routes.path'

export enum ERoutes {
  SIGN_IN = 'Auth.SignIn'
}

export const Routes: IRouterOption[] = [
  {
    path: EPaths.SIGN_IN,
    component: SignIn,
    name: ERoutes.SIGN_IN,
    exact: true,
    meta: {
      requireAuth: false
    }
  }
]

export const AuthModule = {
  ERoutes,
  Routes
}
