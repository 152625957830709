import axios from 'axios'
import { IPaginationQuery, IScrapedCompanyModel, TAxiosResponsePagination } from 'src/interfaces'

export class ScrapedCompanyApi {
  static readonly _prefix = '/scraped-company'

  static index(params: IPaginationQuery = {}): TAxiosResponsePagination<IScrapedCompanyModel> {
    return axios.get(`${ScrapedCompanyApi._prefix}`, { params })
  }

  static update(id: number, payload: IScrapedCompanyModel) {
    const clean = { ...payload, id: undefined }
    return axios.put(`${ScrapedCompanyApi._prefix}/${id}`, clean)
  }

  static updateBlacklist(ids: number[], reason: string | null) {
    return axios.put(`${ScrapedCompanyApi._prefix}/black-list`, { companyIds: ids, reason })
  }
}
