import { Button, Checkbox, Input, Pagination, Switch, Table } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { cloneDeep, debounce, isObject, set } from 'lodash'
import _omit from 'lodash/omit'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ScrapedCompanyApi } from 'src/api/scraped-company'
import { IPaginateCallback, useBehaviorMapper, usePaginateParams } from 'src/hooks'
import { IScrapedCompanyModel } from 'src/interfaces'
import { PaginationService } from 'src/services'
import { NotifyUtils } from 'src/utils'
import { renderColumns } from './columns'
import { ModalEditBlackList } from './modal-edit-blacklist'
import { ModalOpenContact } from './modal-open-contact'
import Style from './style.module.scss'

export const ScrapedCompanies = () => {
  const _paginationService = useMemo(() => new PaginationService<IScrapedCompanyModel>(ScrapedCompanyApi), [])
  const loading = useBehaviorMapper(_paginationService.loading$)
  const dataSource = useBehaviorMapper(_paginationService.pagination$)
  const [rows, setRows] = useState<typeof dataSource['rows']>([])
  useEffect(() => setRows(dataSource.rows.map(row => ({
    ...cloneDeep(row),
    key: row.id
  }))), [dataSource])
  const [showNote, setShowNote] = useState(true)
  const [hideBlackList, setHideBlacklist] = useState(false)

  // row selection
  // TODO: move to a hook
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  }

  const hasSelected = selectedRowKeys.length > 0

  const [openEditBlackList, setOpenEditBlackList] = useState(false)
  const handleBlackList = useCallback(() => {
    setOpenEditBlackList(true)
  }, [])

  // end row selection

  const fetch = useCallback<IPaginateCallback>(
    (params) => _paginationService.paging(params),
    [_paginationService]
  )

  const { pagination, pushPagination, setPagination } = usePaginateParams(fetch)
  const hasEmailDomainRef = useRef<boolean>(false)
  const hasContactRef = useRef<boolean>(false)

  const [loadingSearchCompany, setLoadingSearchCompany] = useState(false)
  const handleSearchCompanyName = useMemo(() => {
    const handleSearch = async (companyName: string) => {
      setLoadingSearchCompany(true)
      const params = companyName
        ? {
          name: companyName.trim()
        }
        : {}

      const newPagination = {
        ...(_omit(pagination || {}, 'name')),
        hasEmailDomain: hasEmailDomainRef.current,
        hasContact: hasContactRef.current,
        hideBlackList,
        page: 1,
        ...params
      }

      setPagination(newPagination)
      await fetch(newPagination)
      setLoadingSearchCompany(false)
    }

    return debounce(handleSearch, 500)
  }, [fetch, hideBlackList, pagination, setPagination])

  const handleCloseEditBlackList = useCallback((shouldRefresh?: boolean) => {
    setOpenEditBlackList(false)
    if (shouldRefresh) {
      setSelectedRowKeys([])
      fetch(pagination || {})
    }
  }, [fetch, pagination])

  const handleCompanyChange = useCallback((contact: IScrapedCompanyModel, value: any, fieldName: string) => {
    const matchIndex = rows.findIndex(row => row === contact)
    if (matchIndex === -1) return

    const match = rows[matchIndex]
    set(match, fieldName, isObject(value) ? { ...value } : value)

    setRows(rows => [...rows.slice(0, matchIndex), match, ...rows.slice(matchIndex + 1)])
  }, [rows])

  const handleOnSaveClicked = useCallback((company: IScrapedCompanyModel) => {
    const upsertContact = async () => {
      await ScrapedCompanyApi.update(company.id, company)
      NotifyUtils.success({ message: 'Saved successfully' })
    }

    upsertContact()
  }, [])

  const [openContactModal, setOpenContactModal] = useState(false)
  const [openContactForCompany, setOpenContactForCompany] = useState<IScrapedCompanyModel>()

  const handleOpenContactModal = (company: IScrapedCompanyModel) => {
    setOpenContactModal(true)
    setOpenContactForCompany(company)
  }

  const handleCloseContactModal = useCallback(() => {
    setOpenContactModal(false)
    setOpenContactForCompany(undefined)
  }, [])

  const [loadingJobId, setLoadingJobId] = useState(false)
  const handleSearchJobId = useMemo(() => {
    const handleSearch = async (jobId: string) => {
      setLoadingJobId(true)
      const params = jobId ? { jobId: jobId.trim() } : {}
      const newPagination = {
        ...(pagination || {}),
        page: 1,
        hasEmailDomain: hasEmailDomainRef.current,
        hasContact: hasContactRef.current,
        ...params
      }
      setPagination(newPagination)
      await fetch(newPagination)
      setLoadingJobId(false)
    }

    return debounce(handleSearch, 500)
  }, [fetch, pagination, setPagination])

  const [loadingNumberOfEmployees, setLoadingNumberOfEmployees] = useState(false)
  const [maxEmployee, setMaxEmployee] = useState<number>()
  const [minEmployee, setMinEmployee] = useState<number>()

  const handleFilterNumberOfEmployees = useCallback(async () => {
    try {
      const params = {
        ..._omit(pagination || {}, ['minEmployee', 'maxEmployee']),
        page: 1,
        ...(minEmployee ? { minEmployee } : {}),
        ...(maxEmployee ? { maxEmployee } : {})
      }
      setLoadingNumberOfEmployees(true)
      setPagination(params)
      await fetch(params)
    } catch (err) {
      console.error(err)
    } finally {
      setLoadingNumberOfEmployees(false)
    }
  }, [fetch, maxEmployee, minEmployee, pagination, setPagination])

  const handleChangeCheckboxEmailDomain = useCallback(async (e: CheckboxChangeEvent) => {
    const params = {
      ..._omit(pagination || {}, ['hasEmailDomain']),
      page: 1,
      ...(e.target.checked ? { hasEmailDomain: true } : { hasEmailDomain: false })
    }
    setPagination(params)
    hasEmailDomainRef.current = e.target.checked
    await fetch(params)
  }, [fetch, pagination, setPagination])

  const handleChangeCheckboxContact = useCallback(async (e: CheckboxChangeEvent) => {
    const params = {
      ..._omit(pagination || {}, ['hasContact']),
      page: 1,
      ...(e.target.checked ? { hasContact: true } : { hasContact: false })
    }
    setPagination(params)
    hasContactRef.current = e.target.checked
    await fetch(params)
  }, [fetch, pagination, setPagination])

  const handleChangeCheckboxBlacklist = useCallback(async (e: CheckboxChangeEvent) => {
    const params = {
      ..._omit(pagination || {}, ['hideBlackList']),
      page: 1,
      ...(e.target.checked ? { hideBlackList: true } : { hideBlackList: false })
    }
    setPagination(params)
    setSelectedRowKeys([])
    setHideBlacklist(e.target.checked)
    await fetch(params)
  }, [fetch, pagination, setPagination])

  return (
    <div className={Style.container}>
      <div className={Style.toolbox}>
        <Input.Search
          placeholder="Search by company name"
          onSearch={handleSearchCompanyName}
          loading={loadingSearchCompany}
        />

        <Input.Search
          placeholder="Search by job id"
          onSearch={handleSearchJobId}
          loading={loadingJobId}
        />

        <div className="fx gap-2" style={{ width: '800px' }}>
          <Input
            placeholder="Min employees"
            type="number"
            onChange={(e) => setMinEmployee(Number(e.target.value) || 0)}
            value={minEmployee}
          />
          <Input
            placeholder="Max employees"
            type="number"
            width={500}
            value={maxEmployee}
            onChange={(e) => setMaxEmployee(Number(e.target.value) || 0)}
          />
          <Button loading={loadingNumberOfEmployees} onClick={handleFilterNumberOfEmployees} type="primary">Go</Button>
        </div>
      </div>

      <div className="fx flex-row gap-1 fx-jc-space-between">
        <div className="fx flex-row gap-1">
          <Checkbox onChange={handleChangeCheckboxEmailDomain}>Had email domain</Checkbox>
          <Checkbox onChange={handleChangeCheckboxContact}>Had contact</Checkbox>
          <Checkbox onChange={handleChangeCheckboxBlacklist}>Hide blacklist</Checkbox>
        </div>
        <div className="fx flex-row gap-2">
          <Switch
            checkedChildren="Showing Note"
            unCheckedChildren="Hiding Note"
            checked={showNote}
            onChange={(checked) => { setShowNote(checked) }}
          />
        </div>
      </div>

      <div>
        <Button type="primary" onClick={handleBlackList} disabled={!hasSelected} loading={loading}>
          Edit Blacklist
        </Button>
        <span style={{ marginLeft: 8 }}>
          {hasSelected ? `Selected ${selectedRowKeys.length} companies` : ''}
        </span>
      </div>

      <Table
        rowSelection={rowSelection}
        className="fx-extend"
        rowKey="id"
        loading={loading}
        columns={renderColumns({
          disabled: loading,
          hideBlackList,
          onChange: handleCompanyChange,
          onSaveClicked: handleOnSaveClicked,
          openContactModal: handleOpenContactModal,
          showNote
        })}
        dataSource={rows}
        pagination={false}
        scroll={{ x: 'max-content' }}
      />

      <Pagination
        className="fx-as-end"
        disabled={loading}
        showQuickJumper
        total={dataSource.total}
        current={pagination.page}
        pageSize={pagination.limit}
        {
          ...{
            ...pagination,
            onChange(page, limit) {
              pushPagination({
                ...pagination,
                page,
                limit
              })
            }
          }
        }
      />
      <ModalOpenContact
        open={openContactModal}
        company={openContactForCompany}
        onClose={handleCloseContactModal}
      />

      <ModalEditBlackList
        open={openEditBlackList}
        companyIds={selectedRowKeys.map(key => Number(key))}
        onClose={handleCloseEditBlackList}
      />
    </div>
  )
}
