import { Button, message } from 'antd'
import { ChangeEvent, ComponentProps, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { MultimediaApi } from 'src/api'
import { InfiniteScroll, ModalFullscreen } from 'src/components'
import { useBehaviorMapper, useDidMountEffect, useUnsubscribe } from 'src/hooks'
import { IMultimediaModel } from 'src/interfaces'
import { LoadMoreService } from 'src/services'
import { EventUtils, Helpers, NotifyUtils } from 'src/utils'
import { MediaItem } from './media-item'

export const ModalMedia: FC<Omit<
  ComponentProps<typeof ModalFullscreen>,
  'onOk' | 'afterClose'
> & {
  btnProps?: ComponentProps<typeof Button>
  // onOk?: () => any
  afterClose?: (doc?: IMultimediaModel) => any
}> = ({
  btnProps,
  ...props
}) => {
  const unsubscribe$ = useUnsubscribe()
  const inputRef = useRef<HTMLInputElement>(null)
  const _loadMoreService = useMemo(() => new LoadMoreService<IMultimediaModel>(MultimediaApi), [])
  const loading = useBehaviorMapper(_loadMoreService.loading$)
  const items = useBehaviorMapper(_loadMoreService.items$)
  const [invisible, setInvisible] = useState<boolean | IMultimediaModel>(true)
  const [uploading, setUploading] = useState(false)
  const [selectedItem, setSelectedItem] = useState<IMultimediaModel>()

  useEffect(() => {
    setInvisible(!props.open)
  }, [props.open])

  useDidMountEffect(() => {
    if (invisible) {
      _loadMoreService.reset()
      setSelectedItem(undefined)
      props.afterClose?.(
        typeof invisible !== 'boolean'
          ? invisible
          : undefined
      )
    }
  }, [_loadMoreService, invisible])

  const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    EventUtils.preventDefault(e)

    setUploading(true)
    const promise = (async () => {
      for (const file of Array.from(e.target.files || []).reverse() as File[]) {
        if (!/^(image\/|video\/)/.test(file.type)) {
          return message.error('Invalid file type')
        }

        const { data: item } = await MultimediaApi.upload({ file })
        _loadMoreService.addFirst(item)
      }
    })()

    from(promise)
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          NotifyUtils.handleAxiosError(error)
          return EMPTY
        }),
        finalize(() => setUploading(false))
      )
      .subscribe(() => NotifyUtils.success({ message: 'Upload successfully' }))
  }, [_loadMoreService, unsubscribe$])

  return (
    <>
      <Button
        type="primary"
        {...btnProps}
        onClick={() => setInvisible(false)}
      >
        {props.children || 'Media'}
      </Button>

      <ModalFullscreen
        title={props.title || 'Media'}
        open={!invisible}
        closable={!loading && !uploading} // display X icon
        keyboard={false} // disable close on press ESC
        maskClosable={false} // disable close on click outside
        // onOk={submit}
        onCancel={() => setInvisible(true)}
        footer={(
          <div className="fx fx-ai-center fx-jc-space-between">
            <input
              ref={inputRef}
              type="file"
              name="file"
              accept="image/*,video/*"
              value=""
              id="file"
              className="d-none"
              onChange={onChange}
            />
            <div>
              <Button
                type="primary"
                disabled={uploading}
                onClick={() => inputRef.current?.click?.()}
              >
                Upload
              </Button>
              <Button
                type="primary"
                disabled={!selectedItem}
                onClick={() => selectedItem?.publicUrl && Helpers.copy(selectedItem.publicUrl).then(
                  () => NotifyUtils.success({ message: `Copied to clipboard: ${selectedItem.publicUrl}` })
                )}
              >
                Copy URL
              </Button>
            </div>

            <div>
              <Button
                type="default"
                disabled={loading || uploading}
                onClick={() => setInvisible(true)}
              >
                Close
              </Button>
              <Button
                type="primary"
                disabled={loading || uploading}
                onClick={() => setInvisible(selectedItem || true)}
              >
                Ok
              </Button>
            </div>
          </div>
        )}
      >
        {!invisible && (
          <InfiniteScroll
            className="fx fx-extend fx-wrap-wrap gap-2"
            loader={null}
            scrollThreshold={0.95} // 95% scrollHeight
            dataLength={items.length}
            next={() => _loadMoreService.loadMore({ limit: 20 })}
            hasMore={_loadMoreService.hasMore}
          >
            {items.map((item) => (
              <MediaItem
                key={item.id}
                media={item}
                isSelected={selectedItem?.id === item.id}
                onClick={() => setSelectedItem((prev) => prev?.id === item.id ? undefined : item)}
              />
            ))}
          </InfiniteScroll>
        )}
      </ModalFullscreen>
    </>
  )
}
