import axios from 'axios'
import { ICampaignSettingModel, ICampaignSettingPayload, TAxiosResponseData } from 'src/interfaces'

export class CampaignSettingApi {
  static readonly _prefix = '/campaign-settings'

  static list(): TAxiosResponseData<ICampaignSettingModel[]> {
    return axios.get(`${CampaignSettingApi._prefix}`)
  }

  static create(payload: ICampaignSettingPayload): TAxiosResponseData<ICampaignSettingModel> {
    return axios.post(`${CampaignSettingApi._prefix}`, payload)
  }

  static update(key: ICampaignSettingModel['key'], value: ICampaignSettingModel['value']): TAxiosResponseData<void> {
    return axios.put(`${CampaignSettingApi._prefix}/${key}`, { value })
  }
}
