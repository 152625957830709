import { Alert, Input, Modal, Table } from 'antd'
import { debounce } from 'lodash'
import { FC, useCallback, useMemo, useRef, useState } from 'react'
import { ScrapedContactApi } from 'src/api'
import { IScrapedCompanyModel } from 'src/interfaces'

interface IProps {
  open: boolean
  company?: IScrapedCompanyModel
  onClose?: () => any
}

type Contact = { firstName: string; lastName: string; position: string }

export const ModalOpenContact: FC<IProps> = (props) => {
  const [loading, setLoading] = useState(false)
  const textAreaRef = useRef<HTMLTextAreaElement>(null)

  const [dataSource, setDataSource] = useState<Contact[]>([])
  const handleRawData = useMemo(() => {
    const _func = (rawData: string) => {
      try {
        const rawContacts = rawData.split(/\n\s*\n/)

        const data = rawContacts.map((rawContact) => {
          const lines = rawContact.split('\n')
          return { firstName: lines[0].split(' ')[0], lastName: lines[0].split(' ').slice(1).join(' '), position: lines[2] }
        })

        setDataSource(data)
      } catch (error) {
        console.error(error)
      }
    }

    return debounce(_func, 500)
  }, [])

  const handleChangeText = (record: Contact, fieldName: keyof Contact, value: string) => {
    const matchIndex = dataSource.findIndex(row => row === record)
    if (matchIndex === -1) return

    const match = dataSource[matchIndex]
    match[fieldName] = value

    setDataSource(dataSource => [...dataSource.slice(0, matchIndex), match, ...dataSource.slice(matchIndex + 1)])
  }

  const handleAddContacts = useCallback(async () => {
    if (!props.company) return
    const companyId = props.company.id

    setLoading(true)
    await ScrapedContactApi.bulkUpsert(
      dataSource.map(contact => ({
        companyId,
        connectedAshley: false,
        connectedCecilia: false,
        connectedDaniel: false,
        connectedPhilip: false,
        firstName: contact.firstName,
        lastName: contact.lastName,
        position: contact.position,
        verifiedEmail: ''
      }))
    )
    setLoading(false)
    if (textAreaRef.current) {
      textAreaRef.current.value = ''
    }

    setDataSource([])

    props.onClose?.()
  }, [dataSource, props])

  return (
    <Modal
      width={1000}
      open={props.open}
      title="New Contacts"
      onCancel={!loading ? props.onClose : undefined}
      onOk={handleAddContacts}
      confirmLoading={loading}
      closable={!loading}
    >
      <Alert
        description={(
          <div>
            <b>⚠️ Copy and paste the contacts from the website to the text area below. The format should be like this:</b>
            <span>
              <pre>
                [Full Name]
                <br/>
                [Relationship]
                <br/>
                [Position]
              </pre>
            </span>
            <b>⚠️ Only edit the data in table when you finished copy and paste</b>
          </div>
        )}
        style={{ marginBottom: '20px' }}
        type="info"
        closable
      />
      <div className="fx flex-row gap-4 w-100">
        <textarea
          ref={textAreaRef}
          rows={5}
          style={{ width: '40%' }}
          onChange={e => handleRawData(e.target.value)}
        />
        <Table
          style={{ width: '100%' }}
          dataSource={dataSource}
          columns={[
            {
              title: 'First Name',
              dataIndex: 'firstName',
              key: 'firstName',
              render: (text, record) => <Input value={text} onChange={(e) => handleChangeText(record, 'firstName', e.target.value)}/>
            },
            {
              title: 'Last Name',
              dataIndex: 'lastName',
              key: 'lastName',
              render: (text, record) => <Input value={text} onChange={(e) => handleChangeText(record, 'lastName', e.target.value)}/>
            },
            {
              title: 'Position',
              dataIndex: 'position',
              key: 'position',
              render: (text, record) => <Input value={text} onChange={(e) => handleChangeText(record, 'position', e.target.value)}/>
            }
          ]}
        />
      </div>
    </Modal>
  )
}
