import axios from 'axios'
import { IBlogPostModel, IPaginationQuery, TAxiosResponseData, TAxiosResponsePagination } from 'src/interfaces'

export class BlogPostApi {
  static readonly _prefix = '/blogs/posts'

  static index(params: IPaginationQuery = {}): TAxiosResponsePagination<IBlogPostModel> {
    return axios.get(`${BlogPostApi._prefix}`, { params })
  }

  static store(payload: {
    title?: string
    content?: string
    postedAt?: string
    bannerImg?: string
    thumbnailImg?: string
    isPublish?: boolean
    isFeatured?: boolean
    categoryId?: number
    slug?: string
  }): TAxiosResponseData<IBlogPostModel> {
    return axios.post(`${BlogPostApi._prefix}`, payload)
  }

  static show(id: IBlogPostModel['id']): TAxiosResponseData<IBlogPostModel> {
    return axios.get(`${BlogPostApi._prefix}/${id}`)
  }

  static update(id: IBlogPostModel['id'], payload: {
    title?: string
    bannerImg?: string
    thumbnailImg?: string
    content?: string
    postedAt?: string
    isPublish?: boolean
    isFeatured?: boolean
    categoryId?: number
  }): TAxiosResponseData<IBlogPostModel> {
    return axios.put(`${BlogPostApi._prefix}/${id}`, payload)
  }

  static destroy(id: IBlogPostModel['id']): TAxiosResponseData<void> {
    return axios.delete(`${BlogPostApi._prefix}/${id}`)
  }

  static getBySlug(slug: IBlogPostModel['slug']): TAxiosResponseData<IBlogPostModel> {
    return axios.get(`${BlogPostApi._prefix}/${slug}/unique`)
  }
}
