import { Button, Popconfirm, Space, TableProps } from 'antd'
import { IUserModel } from 'src/interfaces'
import { UserUtils } from 'src/utils'

export const renderColumns = ({
  onClickDelete
}: {
  onClickDelete?: (record: IUserModel) => any
} = {}): Required<TableProps<IUserModel>>['columns'] => [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (__: any, record: IUserModel) => UserUtils.getFullName(record)
  },
  {
    title: 'Username',
    dataIndex: 'username',
    key: 'username'
  },
  {
    title: 'Phone',
    dataIndex: 'userPhoneNo',
    key: 'userPhoneNo'
  },
  {
    title: 'Action',
    key: 'action',
    render: (text: string, record) => (
      <Space
        wrap
        size="small"
        direction="vertical"
      >
        {onClickDelete && (
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => onClickDelete(record)}
            okText="Yes"
            cancelText="No"
          >
            <Button size="small" danger>Delete</Button>
          </Popconfirm>
        )}
      </Space>
    )
  }
]
