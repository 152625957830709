import { IRouterOption } from 'src/router'
import { HiringManagerDetail } from './components/detail'
import { HiringManagers } from './components/list'
import { EPaths } from './routes.path'

export enum ERoutes {
  HIRING_MANAGER_LIST = 'HiringManager.List',
  HIRING_MANAGER_DETAIL= 'HiringManager.Detail'
}

export const Routes: IRouterOption[] = [
  {
    path: EPaths.HIRING_MANAGER_LIST,
    component: HiringManagers,
    name: ERoutes.HIRING_MANAGER_LIST,
    exact: true,
    meta: {}
  },
  {
    path: EPaths.HIRING_MANAGER_DETAIL,
    component: HiringManagerDetail,
    name: ERoutes.HIRING_MANAGER_DETAIL,
    exact: true,
    meta: {}
  }
]

export const HiringManagerModule = {
  ERoutes,
  Routes
}
