import axios from 'axios'
import { ICompanyModel, ICompanyYoutubeVideoModel, IPaginationQuery, TAxiosResponseData, TAxiosResponsePagination } from 'src/interfaces'

export class CompanyApi {
  static readonly _prefix = '/company'

  static index(params: IPaginationQuery = {}): TAxiosResponsePagination<ICompanyModel> {
    return axios.get(`${CompanyApi._prefix}`, { params })
  }

  static show(id: number | string): TAxiosResponseData<ICompanyModel> {
    return axios.get(`${CompanyApi._prefix}/${id}`)
  }

  static videoChannel(id: number | string, payload: {
    channelId: string
  }): TAxiosResponseData<ICompanyModel> {
    return axios.put(`${CompanyApi._prefix}/${id}/video-channel`, payload)
  }

  static videos(id: number | string, params: IPaginationQuery = {}): TAxiosResponsePagination<ICompanyYoutubeVideoModel> {
    return axios.get(`${CompanyApi._prefix}/${id}/video-channel`, { params })
  }
}
