import { TableProps } from 'antd'
import { IPromoCodeUsedModel } from 'src/interfaces'
import { TimeZoneService } from 'src/services'
import { UserUtils } from 'src/utils'

export const renderColumns = (): Required<TableProps<IPromoCodeUsedModel>>['columns'] => [
  {
    title: 'Name',
    dataIndex: 'user.name',
    key: 'user.name',
    render: (__: any, record: IPromoCodeUsedModel) => UserUtils.getFullName(record.user)
  },
  {
    title: 'Username',
    dataIndex: 'user.username',
    key: 'user.username',
    render: (__: any, record: IPromoCodeUsedModel) => record.user?.username
  },
  {
    title: 'Claim At',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (val: IPromoCodeUsedModel['createdAt']) => TimeZoneService.format(val)
  }
]
