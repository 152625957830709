import { Select } from 'antd'
import { uniqWith } from 'lodash'
import { ComponentProps, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { takeUntil } from 'rxjs'
import { HashtagApi } from 'src/api'
import { useDebounce, useUnsubscribe } from 'src/hooks'
import { IHashtagModel } from 'src/interfaces'
import { PaginationService } from 'src/services'

interface IProps extends Omit<
ComponentProps<typeof Select>,
'showSearch' |
'optionFilterProp' |
'filterOption' |
'filterSort' |
'options'
> {
  currentHashtags?: IHashtagModel[]
  tags?: boolean
  multiple?: boolean
  isPlaylist?: boolean
}

export const SelectHashtag: FC<IProps> = ({
  tags,
  multiple,
  isPlaylist,
  ...props
}) => {
  const unsubscribe$ = useUnsubscribe()
  const _paginationService = useMemo(() => new PaginationService<IHashtagModel>(HashtagApi), [])
  const [loading, setLoading] = useState(false)
  const [keyword, setKeyword] = useState('')
  const [options, setOptions] = useState<ComponentProps<typeof Select>['options']>([])

  const hashtagsToOptions = useCallback((hashtags: IHashtagModel[]) => hashtags.map(
    hashtag => ({
      value: hashtag.id,
      label: hashtag.title,
      hashtag
    })
  ), [])

  const compareStrs = useCallback((a: string, b: string) => {
    return a.toLowerCase().includes(b.toLowerCase())
  }, [])

  useEffect(() => {
    if (props.currentHashtags?.length) {
      const currentHashtags = props.currentHashtags as IHashtagModel[]
      setOptions(
        (prev) => uniqWith(
          prev
            ? [...prev, ...hashtagsToOptions(currentHashtags)]
            : hashtagsToOptions(currentHashtags),
          (a, b) => a.value === b.value
        )
      )
    }
  }, [hashtagsToOptions, props.currentHashtags])

  useDebounce(() => {
    _paginationService.paging({
      limit: 100,
      keyword,
      isPlaylist
    })
  }, 300, [keyword, isPlaylist])

  useEffect(() => {
    _paginationService
      .loading$
      .pipe(takeUntil(unsubscribe$))
      .subscribe((value) => setLoading(value))
    _paginationService
      .pagination$
      .pipe(takeUntil(unsubscribe$))
      .subscribe(
        (data) => {
          return setOptions(
            (prev) => uniqWith(
              prev
                ? [...prev, ...hashtagsToOptions(data.rows)]
                : hashtagsToOptions(data.rows),
              (a, b) => a.value === b.value
            )
          )
        }
      )
  }, [hashtagsToOptions, _paginationService, unsubscribe$])

  const mode = useMemo(() => props.mode ?? (
    multiple
      ? 'multiple'
      : tags
        ? 'tags'
        : undefined
  ), [props.mode, tags, multiple])

  return (
    <Select
      {...props}
      mode={mode}
      placeholder={props.placeholder || 'Select hashtag'}
      loading={loading || props.loading}
      showSearch
      onSearch={(k) => k && setKeyword(() => k)}
      optionFilterProp="children"
      filterOption={(input, option) => compareStrs(String(option?.label || ''), input) || compareStrs(String(option?.hashtag?.tag || ''), input)}
      filterSort={(optionA, optionB) =>
        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())}
      options={options}
    />
  )
}
