import { AxiosError } from 'axios'
import clsx from 'clsx'
import { FC, useMemo, useState } from 'react'
import { EMPTY, catchError, from, merge, takeUntil } from 'rxjs'
import { CandidateApi } from 'src/api'
import { useUnsubscribe, useUnsubscribeEffect } from 'src/hooks'
import { ICandidateModel, IUserStatsModel } from 'src/interfaces'
import { NotifyUtils } from 'src/utils'

interface IProps {
  candidate?: ICandidateModel
  className?: string
  onDisabled?: (val: boolean) => void
}

export const CandidateStats: FC<IProps> = ({ candidate, className, ...props }) => {
  const unsubscribe$ = useUnsubscribe()
  const [stats, setStats] = useState<IUserStatsModel>()
  const candidateId = useMemo(() => candidate?.id, [candidate])

  useUnsubscribeEffect((_unsubscribe$) => {
    if (candidateId) {
      from(CandidateApi.stats(candidateId))
        .pipe(
          takeUntil(merge(unsubscribe$, _unsubscribe$)),
          catchError((error: AxiosError) => {
            NotifyUtils.handleAxiosError(error)
            return EMPTY
          })
        )
        .subscribe(({ data }) => setStats(data))
    }
  }, [candidateId])

  return (
    <div className={clsx('fx gap-2', className)}>
      <div className="fx-1 fx fx-column gap-2">
        <strong>Stats</strong>

        <div className="ml-3 fx-1 fx fx-column">
          <div className="fx gap-2 fx-ai-center">
            <strong>Views:</strong>
            <span>{stats?.countViews}</span>
          </div>
          <div className="fx gap-2 fx-ai-center">
            <strong>Likes:</strong>
            <span>{stats?.countLikes}</span>
          </div>
          <div className="fx gap-2 fx-ai-center">
            <strong>SuperLikes:</strong>
            <span>{stats?.countSuperLikes}</span>
          </div>
          <div className="fx gap-2 fx-ai-center">
            <strong>Bookmarks:</strong>
            <span>{stats?.countBookmarks}</span>
          </div>
          <div className="fx gap-2 fx-ai-center">
            <strong>Share:</strong>
            <span>{stats?.countShare}</span>
          </div>
          <div className="fx gap-2 fx-ai-center">
            <strong>Comments:</strong>
            <span>{stats?.countComments}</span>
          </div>
        </div>
      </div>
    </div>
  )
}
