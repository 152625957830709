import axios from 'axios'
import { ICampaignModel, IPaginationQuery, TAxiosResponsePagination } from 'src/interfaces'

export class CampaignSubmissionApi {
  static readonly _prefix = '/campaigns-submissions'

  static index(params: IPaginationQuery & {
    campaignId?: number
    authorId?: number
    hasResume?: boolean
    hasPortfolioUrl?: boolean
  } = {}): TAxiosResponsePagination<ICampaignModel> {
    return axios.get(`${CampaignSubmissionApi._prefix}`, { params })
  }
}
