import { Button, Form, Input } from 'antd'
import { ChangeEvent, ComponentProps, FC, useCallback, useMemo, useState } from 'react'
import { finalize, from, takeUntil } from 'rxjs'
import { HashtagApi } from 'src/api'
import { ModalFullscreen } from 'src/components'
import { useDidMountEffect, useUnsubscribe, useValidation } from 'src/hooks'
import { IHashtagModel } from 'src/interfaces'
import { NotifyUtils } from 'src/utils'
import * as Yup from 'yup'
import { ErrorMessage } from '../error-message'

const yupSchema = Yup.object().shape({
  tag: Yup.string().required('Tag is required'),
  title: Yup.string().required('Title is required'),
  weight: Yup.number().integer().positive().required('Weight is required')
})

const defaultFormData = {
  tag: '',
  title: '',
  weight: 1000
}

export const ModalHashtag: FC<Omit<
  ComponentProps<typeof ModalFullscreen>,
  'onOk' | 'afterClose'
> & {
  id?: IHashtagModel['id']
  // onOk?: () => any
  afterClose?: (saved: boolean, doc?: IHashtagModel) => any
  btnProps?: ComponentProps<typeof Button>
}> = ({
  btnProps,
  ...props
}) => {
  const unsubscribe$ = useUnsubscribe()
  const [loading, setLoading] = useState(false)
  const [invisible, setInvisible] = useState<boolean | IHashtagModel>(true)
  const [formData, setFormData] = useState<Parameters<typeof HashtagApi.store>[0]>({ ...defaultFormData })

  const payload = useMemo(() => ({
    ...formData
  }), [formData])

  const handleChangeInput = useCallback((e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
    e.persist()
    const { name, value } = e.target
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }))
  }, [])

  const { validate, errors, reset } = useValidation({
    data: formData,
    schema: yupSchema
  })

  const save = useCallback(async () => {
    const { isValid } = await validate()
    if (!isValid) {
      return
    }

    const apiHandler = props.id
      ? HashtagApi.update(props.id, payload)
      : HashtagApi.store(payload)

    setLoading(true)
    from(apiHandler)
      .pipe(
        takeUntil(unsubscribe$),
        finalize(() => setLoading(false))
      )
      .subscribe({
        next: ({ data }) => {
          NotifyUtils.success({ message: `${props.id ? 'Update' : 'Create'} hashtag successfully` })
          setInvisible(data)
        },
        error: NotifyUtils.handleAxiosError
      })
  }, [validate, props.id, payload, unsubscribe$])

  useDidMountEffect(() => {
    reset()

    if (invisible) {
      setLoading(false)
      setFormData(() => ({ ...defaultFormData }))

      const isSaved = typeof invisible !== 'boolean'
      props.afterClose?.(isSaved, isSaved ? invisible : undefined)
    }
  }, [invisible])

  useDidMountEffect(() => {
    if (!invisible && props.id) {
      setLoading(true)
      from(HashtagApi.show(props.id))
        .pipe(
          takeUntil(unsubscribe$),
          finalize(() => setLoading(false))
        )
        .subscribe({
          next: ({ data: hashtag }) => {
            setFormData(() => ({
              title: hashtag.title,
              tag: hashtag.tag,
              weight: hashtag.weight
            }))
          },
          error: NotifyUtils.handleAxiosError
        })
    }
  }, [props.id, invisible])

  return (
    <>
      <Button
        type="primary"
        {...btnProps}
        onClick={() => setInvisible(false)}
      >
        {props.children || 'Create'}
      </Button>

      <ModalFullscreen
        wrapClassName="__modal-hashtag"
        title={`${props.id ? 'Edit' : 'Create'} Hashtag`}
        open={!invisible}
        closable={!loading} // display X icon
        keyboard={false} // disable close on press ESC
        maskClosable={false} // disable close on click outside
        okText={props.id ? 'Update' : 'Save'}
        cancelText="Cancel"
        onOk={save}
        onCancel={() => setInvisible(true)}
        okButtonProps={{ disabled: loading }}
        cancelButtonProps={{ disabled: loading }}
      >
        {invisible
          ? null
          : (
            <div className="fx fx-column fx-extend gap-2">
              <Form.Item
                required
                label="Title"
                labelAlign="left"
                labelCol={{ flex: '0 0 90px' }}
                className="m-0"
              >
                <Input
                  required
                  readOnly={loading}
                  name="title"
                  placeholder="Title"
                  value={formData.title}
                  status={errors.hasError('title') ? 'error' : undefined}
                  onChange={handleChangeInput}
                />
                <ErrorMessage>{errors.getError('title')}</ErrorMessage>
              </Form.Item>

              <Form.Item
                required
                label="Tag"
                labelAlign="left"
                labelCol={{ flex: '0 0 90px' }}
                className="m-0"
              >
                <Input
                  required
                  readOnly={loading}
                  name="tag"
                  placeholder="Tag"
                  value={formData.tag}
                  status={errors.hasError('tag') ? 'error' : undefined}
                  onChange={handleChangeInput}
                />
                <ErrorMessage>{errors.getError('tag')}</ErrorMessage>
              </Form.Item>

              <Form.Item
                required
                label="Weight"
                labelAlign="left"
                labelCol={{ flex: '0 0 90px' }}
                className="m-0"
              >
                <Input
                  type="number"
                  step="1"
                  required
                  readOnly={loading}
                  name="weight"
                  placeholder="Weight"
                  value={formData.weight}
                  status={errors.hasError('weight') ? 'error' : undefined}
                  onChange={handleChangeInput}
                />
                <ErrorMessage>{errors.getError('weight')}</ErrorMessage>
              </Form.Item>
            </div>
          )}
      </ModalFullscreen>
    </>
  )
}
