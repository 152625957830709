import { TableProps, Tag } from 'antd'
import { EVideoImportStatus } from 'src/constants/enum'
import { IVideoImportModel } from 'src/interfaces'
import { TimeZoneService } from 'src/services'
import { UserUtils } from 'src/utils'

export const renderColumns = (): Required<TableProps<IVideoImportModel>>['columns'] => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: 'Importer',
    dataIndex: 'user',
    key: 'user',
    render: (val: Required<IVideoImportModel>['user']) => UserUtils.getFullName(val)
  },
  {
    title: 'URL',
    dataIndex: 'url',
    key: 'url'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (val: Required<IVideoImportModel>['status']) => {
      const text = {
        [EVideoImportStatus.PROCESSING]: 'Processing',
        [EVideoImportStatus.SUCCEEDED]: 'Succeeded',
        [EVideoImportStatus.FAILED]: 'Failed'
      }
      const colors = {
        [EVideoImportStatus.PROCESSING]: 'processing',
        [EVideoImportStatus.SUCCEEDED]: 'success',
        [EVideoImportStatus.FAILED]: 'error'
      }
      return (
        <Tag
          color={colors[val]}
          title={text[val]}
        >
          {text[val]}
        </Tag>
      )
    }
  },
  {
    title: 'CreatedAt',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (val: string) => TimeZoneService.format(val)
  },
  {
    title: 'UpdatedAt',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    render: (val: string) => TimeZoneService.format(val)
  }
]
