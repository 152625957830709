import { StorageUtils } from 'src/utils'
import { GlobalState } from '../state'

export class GlobalAction {
  static setLoading(loading: boolean) {
    GlobalState.next({
      ...GlobalState.value,
      loading
    })
  }

  static setSidebarCollapsed(collapsed: boolean) {
    const { sidebar } = GlobalState.value
    StorageUtils.setItem('UI.sidebar.collapsed', collapsed)
    GlobalState.next({
      ...GlobalState.value,
      sidebar: {
        ...sidebar,
        collapsed
      }
    })
  }
}
