import { DependencyList, EffectCallback, useEffect } from 'react'

export const useDebounce = (effect: EffectCallback, wait = 500, deps?: DependencyList) => {
  useEffect(() => {
    const timer = setTimeout(effect, wait)
    return () => {
      clearTimeout(timer)
    }
  }, deps)
}
