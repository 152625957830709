import { IModel } from './model'
import { IUserModel } from './user.model'

export enum EQueueName {
  CAMPAIGN_CLOSE = 'TRANSCRIPT_VIDEO_QUEUE',
  CAMPAIGN_FEED_INFO = 'CAMPAIGN_FEED_INFO',
  ENCODING_VIDEO_QUEUE = 'ENCODING_VIDEO_QUEUE',
  SCRAPE_JOB_ANALYTICS = 'SCRAPE_JOB_ANALYTICS',
  SCRAPE_JOB_INFO_QUEUE = 'SCRAPE_JOB_INFO_QUEUE',
  SCRAPE_JOBS_QUEUE = 'SCRAPE_JOBS_QUEUE',
  SCRAPE_LINKEDIN_QUEUE = 'SCRAPE_LINKEDIN_QUEUE',
  TRANSCRIPT_VIDEO_QUEUE = 'TRANSCRIPT_VIDEO_QUEUE'
}

export enum EQueueJobStatus {
  CANCELED ='canceled',
  CREATED ='created',
  PROCESSING ='processing',
  SUCCEEDED ='succeeded',
  FAILED ='failed'
}

export const QueueJobStatusOptions = [
  {
    value: EQueueJobStatus.CREATED,
    label: 'Created'
  },
  {
    value: EQueueJobStatus.PROCESSING,
    label: 'Processing'
  },
  {
    value: EQueueJobStatus.SUCCEEDED,
    label: 'Succeeded'
  },
  {
    value: EQueueJobStatus.CANCELED,
    label: 'Canceled'
  },
  {
    value: EQueueJobStatus.FAILED,
    label: 'Failed'
  }
]

export interface IQueueJobModel extends IModel {
  queueName: EQueueName
  jobId: number
  status: EQueueJobStatus
  refModel?: string
  refId?: number
  data?: { [key: string]: any }
  output?: { [key: string]: any }
  author?: IUserModel
}
