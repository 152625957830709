import { Avatar } from 'antd'
import clsx from 'clsx'
import { FC } from 'react'
import { ICompanyModel } from 'src/interfaces'

interface IProps {
  company?: ICompanyModel
  className?: string
  isEditing: boolean
  loading: boolean
  channelId?: string
  setChannelId: (val: string) => void
  setIsEditing: (val: any) => void
  updateChannelId: (val: string) => void
}

export const CompanyGeneral: FC<IProps> = ({ company, className, ...props }) => {
  return (
    <div className={clsx('fx fx-ai-center gap-4 fx-jc-space-between', className)}>
      <div className="fx-1 fx fx-ai-center gap-4">
        <Avatar size={56} src={company?.logo?.url}>
          {company?.name}
        </Avatar>

        <div className="fx-1 fx fx-column">
          <div className="fx gap-2 fx-ai-center">
            <strong>Name:</strong>
            <span>{company?.name}</span>
          </div>

          <div className="fx gap-2 fx-ai-center">
            <strong>Domain:</strong>
            <span>{company?.domain}</span>
          </div>

          <div className="fx gap-2 fx-ai-center">
            <strong>Urls:</strong>
            {company?.urls?.length
              ? (
                <div className="fx fx-column">
                  {company.urls.map(url => (
                    <a
                      key={url}
                      href={url}
                      target="_blank"
                      rel="noreferrer"
                    >{url}
                    </a>
                  )
                  )}
                </div>
              )
              : <div> </div>}
          </div>

          <div className="fx gap-2 fx-ai-center">
            <strong>Channel Id:</strong>

            {company?.youtubeChannelId && (
              <span>{company?.youtubeChannelId}</span>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
