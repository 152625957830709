import axios from 'axios'
import { IUserModel, TAxiosResponseData } from 'src/interfaces'

export class UserApi {
  static readonly _prefix = '/users'

  static resetAvatar(id: IUserModel['id']): TAxiosResponseData<void> {
    return axios.delete(`${UserApi._prefix}/${id}/avatar`)
  }

  static uploadAvatar(id: IUserModel['id'], file: File): TAxiosResponseData<{ pfp: IUserModel['pfp'] }> {
    const formData = new FormData()
    formData.append('avatar', file, file.name)
    return axios.post(`${UserApi._prefix}/${id}/avatar`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }
}
