import { ContactsFilled, ExportOutlined, ImportOutlined, LoadingOutlined } from '@ant-design/icons'
import { Button, Checkbox, Dropdown, Input, MenuProps, Pagination, Table, Tooltip } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { cloneDeep, debounce, isObject, set } from 'lodash'
import _omit from 'lodash/omit'
import { ChangeEventHandler, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ScrapedContactApi } from 'src/api'
import { DragScroll } from 'src/components'
import { IPaginateCallback, useBehaviorMapper, usePaginateParams } from 'src/hooks'
import { PaginationService } from 'src/services'
import { NotifyUtils } from 'src/utils'
import { renderColumns } from './columns'
import { ModalTableToImport } from './modal-table-to-import'
import Style from './style.module.scss'
import { Contact } from './type'
import { _ETRACKING_STATUS, newScrapedContact } from './utils'

export const ScrapedContacts: FC = () => {
  const _paginationService = useMemo(() => new PaginationService<Contact>(ScrapedContactApi), [])
  const importButtonRef = useRef<HTMLInputElement>(null)
  const loading = useBehaviorMapper(_paginationService.loading$)
  const dataSource = useBehaviorMapper(_paginationService.pagination$)
  const [rows, setRows] = useState<(typeof dataSource)['rows']>([])
  useEffect(() => setRows(dataSource.rows.map(row => cloneDeep(row))), [dataSource])

  const fetchData = useCallback<IPaginateCallback>(
    (params) => _paginationService.paging(params),
    [_paginationService]
  )

  const { pagination, pushPagination, setPagination } = usePaginateParams(fetchData)

  const addNewContact = useCallback(() => {
    if (rows.some(row => row.$status === _ETRACKING_STATUS.NEW)) {
      NotifyUtils.error({ message: 'Please save the new contact before adding new one' })
      return
    }

    setRows(rows => [newScrapedContact(), ...rows])
  }, [rows])

  const handleContactDeleted = useCallback(async (contact: Contact) => {
    const matchIndex = rows.findIndex(row => row === contact)
    if (matchIndex === -1) return
    const match = rows[matchIndex]

    setRows(rows => [...rows.slice(0, matchIndex), ...rows.slice(matchIndex + 1)])

    if (match.id) {
      await ScrapedContactApi.remove(match.id)
    }

    NotifyUtils.success({ message: 'Deleted successfully' })
  }, [rows])

  const handleContactChange = useCallback((contact: Contact, value: any, fieldName: string) => {
    const matchIndex = rows.findIndex(row => row === contact)
    if (matchIndex === -1) return

    const match = rows[matchIndex]
    set(match, fieldName, isObject(value) ? { ...value } : value)
    set(match, '$status', match.$status === _ETRACKING_STATUS.NEW ? _ETRACKING_STATUS.NEW : _ETRACKING_STATUS.MODIFIED)

    setRows(rows => [...rows.slice(0, matchIndex), match, ...rows.slice(matchIndex + 1)])
  }, [rows])

  const handleOnSaveClicked = useCallback((contact: Contact) => {
    const matchIndex = rows.findIndex(row => row === contact)
    if (matchIndex === -1) return

    const match = rows[matchIndex]

    if (!match.company?.id) {
      NotifyUtils.error({ message: 'Please select company' })
      return
    }

    const upsertContact = async () => {
      await ScrapedContactApi.upsert({
        id: match.id,
        companyId: match.company.id,
        firstName: match.firstName,
        lastName: match.lastName,
        position: match.position,
        connectedAshley: match.connectedAshley,
        connectedPhil: match.connectedPhil,
        connectedCecilia: match.connectedCecilia,
        connectedDaniel: match.connectedDaniel,
        verifiedEmail: match.verifiedEmail
      })

      const savedContact: Contact = { ...cloneDeep(match), $status: _ETRACKING_STATUS.TRACKED }

      setRows(rows => {
        const newRows = [...rows]
        newRows[matchIndex] = savedContact
        return newRows
      })

      NotifyUtils.success({ message: 'Saved successfully' })
      // setIsSaving(false)
    }

    upsertContact()
  }, [rows])

  const [loadingSearchCompany, setLoadingSearchCompany] = useState(false)
  const handleSearchCompanyName = useMemo(() => {
    const handleSearch = async (companyName: string) => {
      setLoadingSearchCompany(true)
      const params = {
        ..._omit(pagination || {}, ['name']),
        ...(companyName ? { name: companyName.trim() } : {}),
        page: 1
      }

      setPagination(params)
      await fetchData(params)
      setLoadingSearchCompany(false)
    }

    return debounce(handleSearch, 500)
  }, [fetchData, pagination, setPagination])

  const [importedFile, setImportedFile] = useState<File>()
  const [openImportModal, setOpenImportModal] = useState(false)
  const [loadingImport, setLoadingImport] = useState(false)

  const closeImportModal = useCallback(() => {
    setOpenImportModal(false)
    setImportedFile(undefined)
    setLoadingImport(false)
  }, [])

  const onSaveImportedData = useCallback(async (data: { id: number; verifiedEmail: string }[]) => {
    try {
      setLoadingImport(true)
      await ScrapedContactApi.importVerifiedEmails(data)
      fetchData({ ...pagination })
      NotifyUtils.success({ message: 'Import successfully' })
      setImportedFile(undefined)
      setOpenImportModal(false)
    } catch (error) {
      console.log(error)
      NotifyUtils.error({ message: 'Import failed' })
    } finally {
      setLoadingImport(false)
    }
  }, [fetchData, pagination])

  const handleImportFileChange: ChangeEventHandler<HTMLInputElement> = useCallback(async (e) => {
    if (e.target.files) {
      try {
        const file = e.target.files[0]
        setImportedFile(file)
        setOpenImportModal(true)
        e.target.value = ''
      } catch (error) {
        console.error(error)
      }
    }
  }, [])

  const [loadingNumberOfEmployees, setLoadingNumberOfEmployees] = useState(false)
  const [maxEmployee, setMaxEmployee] = useState<number>()
  const [minEmployee, setMinEmployee] = useState<number>()

  const handleFilterNumberOfEmployees = useCallback(async () => {
    try {
      const params = {
        ..._omit(pagination || {}, ['minEmployee', 'maxEmployee']),
        page: 1,
        ...(minEmployee ? { minEmployee } : {}),
        ...(maxEmployee ? { maxEmployee } : {})
      }
      setLoadingNumberOfEmployees(true)
      setPagination(params)
      await fetchData(params)
    } catch (err) {
      console.error(err)
    } finally {
      setLoadingNumberOfEmployees(false)
    }
  }, [fetchData, maxEmployee, minEmployee, pagination, setPagination])

  const handleChangeCheckboxVerifiedEmail = useCallback(async (e: CheckboxChangeEvent) => {
    try {
      const params = {
        ..._omit(pagination || {}, ['hasVerifiedEmail']),
        page: 1,
        ...(e.target.checked ? { hasVerifiedEmail: true } : {})
      }
      setPagination(params)
      await fetchData(params)
    } catch (err) {
      console.error(err)
    }
  }, [fetchData, pagination, setPagination])

  const [exporting, setExporting] = useState(false)

  const exportUnverifiedEmails = useCallback(async () => {
    try {
      setExporting(true)
      const response = await ScrapedContactApi.exportContacts({
        ..._omit(pagination || {}, ['page', 'limit']),
        ...(minEmployee ? { minEmployee } : {}),
        ...(maxEmployee ? { maxEmployee } : {}),
        ...(pagination?.companyName ? { companyName: pagination?.companyName } : {}),
        hasVerifiedEmail: pagination?.hasVerifiedEmail
      })

      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'unverified-emails.csv')
      document.body.appendChild(link)
      link.click()
    } finally {
      setExporting(false)
    }
  }, [maxEmployee, minEmployee, pagination])

  const exportMyContacts = useCallback(async () => {
    try {
      setExporting(true)
      const response = await ScrapedContactApi.exportContacts({
        ..._omit(pagination || {}, ['page', 'limit']),
        ...(minEmployee ? { minEmployee } : {}),
        ...(maxEmployee ? { maxEmployee } : {}),
        ...(pagination?.companyName ? { companyName: pagination?.companyName } : {}),
        hasVerifiedEmail: pagination?.hasVerifiedEmail,
        myContact: true
      })

      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'unverified-emails.csv')
      document.body.appendChild(link)
      link.click()
    } finally {
      setExporting(false)
    }
  }, [maxEmployee, minEmployee, pagination])

  const exportMenu: MenuProps = useMemo(() => {
    return {
      items: [
        {
          key: 'default-export',
          label: (
            <span onClick={exportUnverifiedEmails}>
              Default Export
            </span>
          )
        },
        {
          key: 'export-by-user',
          label: (
            <span onClick={exportMyContacts}>
              Export My Contacts
            </span>
          )
        }
      ]
    }
  }, [exportMyContacts, exportUnverifiedEmails])

  return (
    <div className={Style.container}>
      <div className={Style.toolbox}>
        <Button type="primary" onClick={addNewContact}><ContactsFilled/> New Contact</Button>
        <Input.Search
          width="200px"
          placeholder="Search by company name"
          onSearch={handleSearchCompanyName}
          loading={loadingSearchCompany}
        />
        <Tooltip title="Export">
          <Dropdown menu={exportMenu} placement="bottomLeft" arrow>
            <Button disabled={exporting} type="primary">
              {exporting ? <LoadingOutlined/> : <ExportOutlined/>}
            </Button>
          </Dropdown>
        </Tooltip>
        <input type="file" hidden ref={importButtonRef} onChange={handleImportFileChange}/>
        <Tooltip title="Import">
          <Button type="primary" onClick={() => importButtonRef.current?.click()}>
            <ImportOutlined/>
          </Button>
        </Tooltip>

        <div className="fx gap-2" style={{ width: '800px' }}>
          <Input
            placeholder="Min employees"
            type="number"
            onChange={(e) => setMinEmployee(Number(e.target.value) || 0)}
            value={minEmployee}
          />
          <Input
            placeholder="Max employees"
            type="number"
            width={500}
            value={maxEmployee}
            onChange={(e) => setMaxEmployee(Number(e.target.value) || 0)}
          />
          <Button loading={loadingNumberOfEmployees} onClick={handleFilterNumberOfEmployees} type="primary">Go</Button>
        </div>
      </div>

      <div className="fx flex-row gap-1">
        <Checkbox onChange={handleChangeCheckboxVerifiedEmail}>Had verified email</Checkbox>
      </div>

      <DragScroll>
        <Table
          className="fx-extend"
          rowKey="id"
          loading={loading}
          columns={renderColumns({
            disabled: loading,
            onChange: handleContactChange,
            onDelete: handleContactDeleted,
            onSaveClicked: handleOnSaveClicked
          })}
          dataSource={rows}
          pagination={false}
        />
      </DragScroll>

      <Pagination
        className="fx-as-end"
        showQuickJumper
        disabled={loading}
        total={dataSource.total}
        current={pagination.page}
        pageSize={pagination.limit}
        {...{
          ...pagination,
          onChange(page, limit) {
            pushPagination({
              ...pagination,
              page,
              limit
            })
          }
        }}
      />

      <ModalTableToImport
        open={openImportModal}
        file={importedFile}
        loading={loadingImport}
        onCancel={closeImportModal}
        onSave={onSaveImportedData}
      />
    </div>
  )
}
