import { FilePdfOutlined, FileUnknownOutlined, PlayCircleTwoTone } from '@ant-design/icons'
import clsx from 'clsx'
import { CSSProperties, FC, useEffect, useMemo, useState } from 'react'
import { FileUtils } from 'src/utils'
import { VideoPlayer } from '../video-player'
import Style from './style.module.scss'

interface IProps {
  size?: number | string
  src?: string
  mimeType?: string
  file?: File | Blob
  allowInteraction?: boolean
  className?: string
  style?: CSSProperties
}

export const FilePreview: FC<IProps> = ({
  size = 50,
  src,
  mimeType,
  file,
  allowInteraction,
  ...props
}) => {
  const fileKind = useMemo(() => ({
    isPdf: FileUtils.isPdf(file || mimeType || src, !!mimeType),
    isPhoto: FileUtils.isPhoto(file || mimeType || src, !!mimeType),
    isAudio: FileUtils.isAudio(file || mimeType || src, !!mimeType),
    isVideo: FileUtils.isVideo(file || mimeType || src, !!mimeType)
  }), [file, mimeType, src])
  const sizeNumber = useMemo(() => typeof size == 'number' ? size : 50, [size])

  const isSupported = useMemo(
    () => Object.values(fileKind).some(Boolean),
    [fileKind]
  )

  const [previewUrl, setPreviewUrl] = useState<string>()
  useEffect(() => {
    if (src || !file) {
      return
    }
    const url = URL.createObjectURL(file)
    setPreviewUrl(url)
    return () => URL.revokeObjectURL(url)
  }, [src, file])

  const displayTxt = useMemo(
    () => (file as File)?.name || previewUrl,
    [file, previewUrl]
  )

  const previewContent = useMemo(() => {
    return !isSupported
      ? <FileUnknownOutlined style={{ fontSize: sizeNumber / 2 }}/>
      : (
        <>
          {fileKind.isPhoto && (
            <img src={src || previewUrl} alt="preview"/>
          )}

          {fileKind.isAudio && (
            <audio src={src || previewUrl}/>
          )}

          {fileKind.isVideo && (
            allowInteraction
              ? (
                <VideoPlayer
                  url={src || previewUrl}
                  style={{
                    width: 'auto',
                    height: '100%',
                    maxWidth: '100%',
                    maxHeight: '100%'
                  }}
                />
              )
              : (
                <>
                  <video
                    preload="metadata"
                    style={{ maxWidth: '100%', maxHeight: '100%' }}
                    ref={(video) => video?.load()}
                  >
                    <source
                      src={src || previewUrl}
                      type={mimeType || file?.type}
                    />
                    Your browser does not support the video tag.
                  </video>
                  <PlayCircleTwoTone
                    style={{
                      color: '#fff',
                      transform: 'scale(1.3)',
                      position: 'absolute'
                    }}
                  />
                </>
              )
          )}

          {fileKind.isPdf && (
            <FilePdfOutlined style={{ fontSize: sizeNumber / 2 }}/>
          )}
        </>
      )
  }, [isSupported, sizeNumber, fileKind, src, allowInteraction, previewUrl, mimeType, file])

  return (
    <span
      title={displayTxt}
      style={{
        ...props.style,
        width: size,
        height: size
      }}
      className={clsx(Style.FilePreview, props.className)}
    >
      {previewContent}
    </span>
  )
}
