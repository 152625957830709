import { Dropdown, Input, MenuProps, Modal, Space, Tag } from 'antd'
import { FC, useState } from 'react'
import { ERoleType } from 'src/constants/enum'
import { PlusOutlined } from '@ant-design/icons'
import _uniq from 'lodash/uniq'

const items: MenuProps['items'] = [
  {
    key: ERoleType.ADMIN,
    label: 'Admin'
  },
  {
    key: ERoleType.SUPER_ADMIN,
    label: 'Super Admin'
  }
]

interface IModalNewAdmin {
  open: boolean
  confirmLoading: boolean
  onOk: (email: string, roles: string[]) => Promise<void>
  onCancel: () => void
}

export const ModalNewAdmin: FC<IModalNewAdmin> = ({ open, onOk, onCancel, confirmLoading }) => {
  const [email, setEmail] = useState<string>('')
  const [roles, setRoles] = useState<string[]>([
    ERoleType.ADMIN,
    ERoleType.SUPER_ADMIN
  ])

  return (
    <Modal
      title="Title"
      open={open}
      onOk={() => onOk(email, roles)}
      confirmLoading={confirmLoading}
      onCancel={onCancel}
    >
      <Space direction="vertical">
        <label>Email</label>
        <Input value={email} onChange={(e) => setEmail(e.target.value)}/>
        <label>Roles</label>
        <Space>
          {roles.map((role) => (
            <Tag
              key={role}
              color={role === ERoleType.ADMIN ? 'green' : 'blue'}
              closable
              onClose={() => setRoles(roles.filter(type => type !== role))}
            >
              {role}
            </Tag>
          ))}
          <Dropdown
            menu={{
              items,
              onClick: ({ key }) => setRoles(_uniq([...roles, key]))
            }}
          >
            <Tag style={{ borderStyle: 'dashed', cursor: 'pointer' }}>
              <PlusOutlined/> Add Role
            </Tag>
          </Dropdown>
        </Space>
      </Space>
    </Modal>
  )
}
