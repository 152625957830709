import clsx from 'clsx'
import moment from 'moment'
import { ComponentProps, FC, useMemo, useRef, useState } from 'react'
import sanitizeHtml from 'sanitize-html'
import { Avatar, FilePreview, InfiniteScroll } from 'src/components'
import { useBehaviorMapper, useWindowSize } from 'src/hooks'
import { IMessageModel } from 'src/interfaces'
import { MessengerService } from 'src/services'
import { AuthState, useStore } from 'src/store'
import Style from './style.module.scss'

const Message: FC<{ message: IMessageModel }> = ({ message }) => {
  const { value: { user } } = useStore(AuthState)
  const conversation = useBehaviorMapper(MessengerService.conversation$)

  const conversationUser = useMemo(
    () => conversation?.conversationUsers?.find(({ userId }) => userId === message.userId),
    [conversation?.conversationUsers, message.userId]
  )

  const isSender = message.userId === user?.id
  const title = useMemo(
    () => moment(message.createdAt).format('dddd ⋅ MMM DD, YYYY ⋅ HH:mm A'),
    [message.createdAt]
  )
  const contentClasses = useMemo(
    () => clsx(Style.message, { [Style.ofOther]: isSender }),
    [isSender]
  )

  const renderContent = useMemo(() => {
    if (message.attachments?.length) {
      return (
        <span title={title} className={clsx(contentClasses, Style.attachments)}>
          <div className={clsx('fx gap-1', { 'fx-jc-flex-end': isSender })}>
            {message.attachments.map((attachment) => (
              <FilePreview
                key={attachment.id}
                src={attachment.url}
                mimeType={attachment.contentType}
                allowInteraction
                size={
                  attachment.contentType?.startsWith('video/')
                    ? 'unset'
                    : 150
                }
                style={{
                  overflow: 'hidden',
                  maxWidth: 'max(60%, 300px)',
                  maxHeight: '400px',
                  borderRadius: '10px'
                }}
              />
            ))}
          </div>
        </span>
      )
    }
    if (message.content) {
      return (
        <span
          title={title}
          className={contentClasses}
          dangerouslySetInnerHTML={{ __html: sanitizeHtml(message.content) }}
        />
      )
    }
  }, [contentClasses, isSender, message, title])

  return (
    <div
      className={clsx('fx fx-ai-flex-end gap-1', {
        'ml-auto': isSender,
        'fx-jc-flex-end': isSender
      })}
      style={{ width: '80%' }}
    >
      {message.userId !== user?.id && (
        <Avatar
          size={20}
          title={conversationUser?.alias}
          name={conversationUser?.alias}
        />
      )}

      {renderContent}
    </div>
  )
}

export const Messages: FC<Pick<ComponentProps<typeof InfiniteScroll>, 'hasMore' | 'next'> & {
  messages: IMessageModel[]
}> = ({
  messages,
  hasMore,
  next
}) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [contentHeight, setContentHeight] = useState(0)
  useWindowSize(() => {
    if (containerRef.current) {
      setContentHeight(containerRef.current.getBoundingClientRect().height)
    }
  })

  return (
    <InfiniteScroll
      className="fx-1 fx"
      style={{ flexDirection: 'column-reverse' }}
      loader={null}
      inverse
      scrollThreshold={0.95} // 95% scrollHeight
      dataLength={messages.length}
      hasMore={hasMore}
      next={next}
      containerProps={{
        ref: containerRef,
        className: 'fx-1 fx fx-column gap-1',
        style: {
          maxHeight: contentHeight || 'unset',
          height: contentHeight || 'unset',
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column-reverse'
        }
      }}
    >
      <div
        className="fx-1 fx fx-column gap-1 p-1"
        style={{
          flexDirection: 'column-reverse',
          minHeight: contentHeight
        }}
      >
        {messages.map((item) => (
          <Message
            key={item.id}
            message={item}
          />
        ))}
      </div>
    </InfiniteScroll>
  )
}
