import { Space, TableProps } from 'antd'
import { ModalSetting } from 'src/components'
import { ISettingModel } from 'src/interfaces'
import { TimeZoneService } from 'src/services'

export const renderColumns = ({ onUpdate }: {
  onUpdate?: (record: ISettingModel) => any
} = {}): Required<TableProps<ISettingModel>>['columns'] => [
  {
    title: 'Key',
    dataIndex: 'key',
    key: 'key'
  },
  {
    title: 'Value',
    dataIndex: 'value',
    key: 'value'
  },
  {
    title: 'CreatedAt',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (val: string) => TimeZoneService.format(val)
  },
  {
    title: 'UpdatedAt',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    render: (val: string) => TimeZoneService.format(val)
  },
  {
    title: 'Action',
    key: 'action',
    render: (text: string, record) => (
      <Space
        wrap
        size="small"
        direction="vertical"
      >
        {onUpdate && (
          <ModalSetting
            settingKey={record.key}
            value={record.value}
            afterClose={(saved, doc) => saved && doc && onUpdate(doc)}
            btnProps={{
              type: 'ghost',
              size: 'small'
            }}
          >
            Edit
          </ModalSetting>
        )}
      </Space>
    )
  }
]
