import { DependencyList, EffectCallback, useEffect, useRef } from 'react'

export const useDidMountDebounce = (effect: EffectCallback, wait = 500, deps?: DependencyList) => {
  const didMount = useRef(false)

  useEffect(() => {
    if (didMount.current) {
      const timer = setTimeout(effect, wait)
      return () => {
        clearTimeout(timer)
      }
    } else {
      didMount.current = true
    }
  }, deps)
}
