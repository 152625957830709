import { isNil } from 'lodash'
import { IScrapedContactModel } from 'src/interfaces'
import { Contact } from './type'

export enum _ETRACKING_STATUS {
  NEW = 'NEW',
  MODIFIED = 'MODIFIED',
  TRACKED = 'TRACKED'
}

export const newScrapedContact = (scrapedContact: Partial<IScrapedContactModel> = {}): Contact => {
  return {
    id: 0,
    $status: _ETRACKING_STATUS.NEW,
    company: {
      id: 0,
      name: '',
      domain: '',
      employeesFrom: 0,
      employeesTo: 0,
      jobs: []
    },
    firstName: '',
    lastName: '',
    position: '',
    connectedAshley: false,
    connectedPhil: false,
    connectedDaniel: false,
    connectedCecilia: false,
    verifiedEmail: '',
    ...scrapedContact
  }
}

export const isNewContact = (contact: Contact): boolean => {
  return contact.$status === _ETRACKING_STATUS.NEW
}

export const isModifiedContact = (contact: Contact): boolean => {
  return contact.$status === _ETRACKING_STATUS.MODIFIED
}

export const displayEmployees = (contact: Contact): string => {
  if (isNil(contact.company.employeesFrom) && isNil(contact.company.employeesTo)) {
    return 'N/A'
  }

  return [contact.company.employeesFrom, contact.company.employeesTo].filter(n => !isNil(n)).join('-')
}
