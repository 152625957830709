import axios, { AxiosRequestConfig } from 'axios'
import { IConversationModel, IMessageModel, IPaginationQuery, TAxiosResponseData, TAxiosResponsePagination } from 'src/interfaces'

export class MessageApi {
  static readonly _prefix = '/chat/messages'

  static paginate(params: IPaginationQuery & {
    conversationId: IConversationModel['id']
  }, config?: AxiosRequestConfig): TAxiosResponsePagination<IMessageModel> {
    return axios.get(`${MessageApi._prefix}`, { ...config, params })
  }

  static create(
    payload: Pick<IMessageModel, 'conversationId' | 'content'> & {
      contentType?: string
    },
    config?: AxiosRequestConfig
  ): TAxiosResponseData<IMessageModel> {
    return axios.post(`${MessageApi._prefix}`, payload, config)
  }
}
