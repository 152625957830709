import { Button, Input, Modal } from 'antd'
import { ChangeEvent, ComponentProps, FC, useCallback, useState } from 'react'
import { finalize, from, takeUntil } from 'rxjs'
import { VideoImportApi } from 'src/api'
import { EVideoImportProvider } from 'src/constants/enum'
import { useDidMountEffect, useUnsubscribe, useValidation } from 'src/hooks'
import { IVideoImportModel } from 'src/interfaces'
import { GlobalAction, GlobalState, useStore } from 'src/store'
import { NotifyUtils } from 'src/utils'
import * as Yup from 'yup'
import { ErrorMessage } from '../error-message'
import { SelectHashtag } from '../select-hashtag'

const yupSchema = Yup.object().shape({
  url: Yup.string()
    .url('TikTok URL must be a valid')
    .required('TikTok URL is required')
    .matches(
      /tiktok\.com/,
      'Invalid TikTok URL'
    ),
  hashtagIds: Yup.array()
    .required('At least 1 hashtag is required')
    .min(1, 'At least 1 hashtag is required')
    .unique('Hashtag is duplicated')
    .of(Yup.number().integer().min(1, 'Invalid hashtag'))
})

export const ModalImportTikTok: FC<Omit<
  ComponentProps<typeof Modal>,
  'onOk' | 'afterClose'
> & {
  // onOk?: () => any
  afterClose?: (saved: boolean, doc?: IVideoImportModel) => any
}> = ({
  ...props
}) => {
  const unsubscribe$ = useUnsubscribe()
  const { value: { loading } } = useStore(GlobalState)
  const [invisible, setInvisible] = useState<boolean | IVideoImportModel>(true)
  const [formData, setFormData] = useState<Parameters<typeof VideoImportApi.store>[0] & {
    hashtagIds: number[]
  }>({
    provider: EVideoImportProvider.TIKTOK,
    url: '',
    hashtagIds: []
  })

  const handleChangeInput = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    e.persist()
    const { name, value } = e.target
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }))
  }, [])

  const { validate, errors, reset } = useValidation({
    data: formData,
    schema: yupSchema
  })

  const save = useCallback(async () => {
    const { isValid } = await validate()
    if (!isValid) {
      return
    }

    GlobalAction.setLoading(true)
    from(VideoImportApi.store(formData))
      .pipe(
        takeUntil(unsubscribe$),
        finalize(() => GlobalAction.setLoading(false))
      )
      .subscribe({
        next: ({ data }) => {
          NotifyUtils.success({ message: 'Import successfully' })
          setInvisible(data)
        },
        error: NotifyUtils.handleAxiosError
      })
  }, [formData, unsubscribe$, validate])

  useDidMountEffect(() => {
    if (invisible) {
      setFormData((prev) => ({
        ...prev,
        hashtagIds: [],
        url: ''
      }))

      const isSaved = typeof invisible !== 'boolean'
      props.afterClose?.(isSaved, isSaved ? invisible : undefined)
    }

    reset()
  }, [invisible])

  return (
    <>
      <Button onClick={() => setInvisible(false)} type="primary">
        {props.children || 'Import'}
      </Button>

      <Modal
        title="TikTok Import"
        open={!invisible}
        // closable={false} // display X icon
        keyboard={false} // disable close on press ESC
        maskClosable={false} // disable close on click outside
        okText="Save"
        cancelText="Cancel"
        onOk={save}
        onCancel={() => setInvisible(true)}
      >
        <div className="fx fx-column fx-extend gap-2">
          <SelectHashtag
            multiple
            status={errors.hasError('hashtagIds') ? 'error' : undefined}
            value={formData.hashtagIds}
            onChange={(values) => setFormData(
              (prev) => ({
                ...prev,
                hashtagIds: values as number[]
              })
            )}
          />
          <ErrorMessage>{errors.getError('hashtagIds')}</ErrorMessage>

          <Input
            required
            readOnly={loading}
            name="url"
            placeholder="TikTok video url"
            value={formData.url}
            status={errors.hasError('url') ? 'error' : undefined}
            onChange={handleChangeInput}
          />
          <ErrorMessage>{errors.getError('url')}</ErrorMessage>
        </div>
      </Modal>
    </>
  )
}
