import { IRouterOption } from 'src/router'
import { Submissions } from './components/submissions'
import { EPaths } from './routes.path'

export enum ERoutes {
  CAMPAIGNS_SUBMISSIONS = 'CAMPAIGNS_SUBMISSIONS'
}

export const Routes: IRouterOption[] = [
  {
    path: EPaths.CAMPAIGNS_SUBMISSIONS,
    name: ERoutes.CAMPAIGNS_SUBMISSIONS,
    component: Submissions,
    exact: true,
    meta: {}
  }
]

export const CampaignSubmissionModule = {
  ERoutes,
  Routes
}
