import { IRouterOption } from 'src/router'
import { Settings } from './components/list'
import { EPaths } from './routes.path'

export enum ERoutes {
  SETTINGS = 'Settings'
}

export const Routes: IRouterOption[] = [
  {
    path: EPaths.SETTINGS,
    component: Settings,
    name: ERoutes.SETTINGS,
    exact: true,
    meta: {}
  }
]

export const SettingModule = {
  ERoutes,
  Routes
}
