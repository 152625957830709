import { Button, Input, InputNumber, TableProps, Tooltip } from 'antd'
import { IScrapedCompanyModel } from 'src/interfaces'

export const renderColumns = (props: {
  disabled?: boolean
  onChange?: (doc: IScrapedCompanyModel, value: any, key: string) => any
  onSaveClicked?: (doc: IScrapedCompanyModel) => any
  openContactModal?: (doc: IScrapedCompanyModel) => any
  showNote?: boolean
  hideBlackList?: boolean
}): TableProps<IScrapedCompanyModel>['columns'] => {
  return [
    {
      title: 'Company Name',
      render: (record: IScrapedCompanyModel) => (
        <Input
          value={record.name}
          onChange={(e: { target: { value: any } }) => props.onChange?.(record, e.target.value, 'name')}
          style={{ minWidth: '200px' }}
        />
      )
    },
    {
      title: 'Employee From',
      render: (record: IScrapedCompanyModel) => (
        <InputNumber
          value={record.employeesFrom}
          onChange={(value: any) => props.onChange?.(record, value, 'employeesFrom')}
          style={{ minWidth: '80px' }}
        />
      )
    },
    {
      title: 'Employee To',
      render: (record: IScrapedCompanyModel) => (
        <InputNumber
          value={record.employeesTo}
          onChange={(value: any) => props.onChange?.(record, value, 'employeesTo')}
          style={{ minWidth: '80px' }}
        />
      )
    },
    {
      title: 'Email Domain',
      render: (record: IScrapedCompanyModel) => (
        <Input
          value={record.domain}
          onChange={(e: { target: { value: any } }) => props.onChange?.(record, e.target.value, 'emailDomain')}
          style={{ minWidth: '100px' }}
        />
      )
    },
    {
      title: 'Note',
      render: (record: IScrapedCompanyModel) => (
        <Input.TextArea
          value={record.daNotes || ''}
          onChange={(e: { target: { value: any } }) => props.onChange?.(record, e.target.value, 'daNotes')}
          style={{ minWidth: '100px' }}
        />
      )
    },
    {
      title: 'Black List',
      render: (record: IScrapedCompanyModel) => (
        <span>{record.daBlackListReason ? record.daBlackListReason : 'No'}</span>
      )
    },
    {
      title: 'Job Ids',
      render: (record: IScrapedCompanyModel) => (
        <div className="fx fx-column gap-1">
          {record.jobs?.map(job => job.jobUrl
            ? (
              <a href={job.jobUrl} target="_blank" key={job.id} rel="noreferrer">{job.id}</a>
            )
            : (
              <Tooltip key={job.id} title="No job url found">
                <span>{job.id}</span>
              </Tooltip>
            ))}
        </div>
      )
    },
    {
      title: 'Action',
      render: (record: IScrapedCompanyModel) => {
        return (
          <div className="fx fx-row gap-2 fx-ai-center">
            <Button
              type="primary"
              onClick={() => props.onSaveClicked?.(record)}
            >
              Save
            </Button>

            <Button
              type="primary"
              onClick={() => props.openContactModal?.(record)}
            >
              Add Contacts
            </Button>
          </div>
        )
      }
    }
  ].filter(column => (props.showNote ? true : column.title !== 'Note') && (!props.hideBlackList ? true : column.title !== 'Black List'))
}
