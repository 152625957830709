import axios from 'axios'
import { IHashtagModel, IPaginationQuery, TAxiosResponseData, TAxiosResponsePagination } from 'src/interfaces'

export class HashtagApi {
  static readonly _prefix = '/hashtags'

  static index(params: IPaginationQuery & {
    isPlaylist?: boolean
  } = {}): TAxiosResponsePagination<IHashtagModel> {
    return axios.get(`${HashtagApi._prefix}`, { params })
  }

  static store(payload: Pick<IHashtagModel, 'tag' | 'title' | 'weight'>): TAxiosResponseData<IHashtagModel> {
    return axios.post(`${HashtagApi._prefix}`, payload)
  }

  static show(id: IHashtagModel['id']): TAxiosResponseData<IHashtagModel> {
    return axios.get(`${HashtagApi._prefix}/${id}`)
  }

  static update(id: IHashtagModel['id'], payload: Pick<IHashtagModel, 'tag' | 'title' | 'weight'>): TAxiosResponseData<IHashtagModel> {
    return axios.put(`${HashtagApi._prefix}/${id}`, payload)
  }

  static destroy(id: IHashtagModel['id']): TAxiosResponseData<void> {
    return axios.delete(`${HashtagApi._prefix}/${id}`)
  }

  static toggleIsPlaylist(id: number): TAxiosResponseData<void> {
    return axios.patch(`${HashtagApi._prefix}/${id}/is-playlist`)
  }
}
