import axios from 'axios'
import { EVideoPrivacy, EVideoType } from 'src/constants/enum'
import { IPaginationQuery, IVideoModel, IVideoTranscription, TAxiosResponseData, TAxiosResponsePagination } from 'src/interfaces'

export interface IUpdateRateParams {
  videoId: number
  articulation?: number | null
  vocabulary?: number | null
  energy?: number | null
  content?: number | null
  [key: string]: any
}

export interface IUpdatePrivacyParams {
  videoId: number
  privacy: EVideoPrivacy
}

export class VideoApi {
  static readonly _prefix = '/videos'

  static index(params: IPaginationQuery & {
    hashtagIds?: number[]
    includes?: ('author' | 'curators' | 'hashtags' | 'videoRatings')[]
  } = {}): TAxiosResponsePagination<IVideoModel> {
    return axios.get(`${VideoApi._prefix}`, { params })
  }

  static geTranscription(id: number): TAxiosResponseData<IVideoTranscription> {
    return axios.get(`${VideoApi._prefix}/${id}/transcription`)
  }

  static update(id: number, payload: {
    videoTags: EVideoType[]
  }): TAxiosResponseData<void> {
    return axios.put(`${VideoApi._prefix}/${id}`, payload)
  }

  static updateHashtags(id: number, payload: {
    hashtagIds: number[]
  }): TAxiosResponseData<void> {
    return axios.patch(`${VideoApi._prefix}/${id}/hashtags`, payload)
  }

  static updateSEOMetadata(id: number, payload: {
    title: string
    description: string
  }): TAxiosResponseData<void> {
    return axios.patch(`${VideoApi._prefix}/${id}/seo-metadata`, payload)
  }

  static updateRate(params: IUpdateRateParams): TAxiosResponseData<void> {
    const data = { ...params }
    Object.keys(data).forEach(key => {
      if (data[key] === undefined || data[key] === null) {
        delete data[key]
      }
    })

    return axios.put(`${VideoApi._prefix}/${data.videoId}/rate`, data)
  }

  static updatePrivacy(params: IUpdatePrivacyParams): TAxiosResponseData<void> {
    return axios.put(`${VideoApi._prefix}/${params.videoId}/privacy`, {
      privacy: params.privacy
    })
  }

  static increaseViews(payload: {
    ids: number[]
    count: number
  }): TAxiosResponseData<void> {
    return axios.post(`${VideoApi._prefix}/increase-views`, payload)
  }

  static saveThumbnail(id: number, secondAt: number): TAxiosResponseData<void> {
    return axios.post(`${VideoApi._prefix}/${id}/thumbnail`, {
      secondAt
    })
  }
}
