import axios from 'axios'
import { IPaginationQuery, ISettingModel, TAxiosResponseData, TAxiosResponsePagination } from 'src/interfaces'

export class SettingApi {
  static readonly _prefix = '/settings'

  static index(params: IPaginationQuery & {
    isPlaylist?: boolean
  } = {}): TAxiosResponsePagination<ISettingModel> {
    return axios.get(`${SettingApi._prefix}`, { params })
  }

  static show(key: ISettingModel['key']): TAxiosResponseData<ISettingModel> {
    return axios.get(`${SettingApi._prefix}/${key}`)
  }

  static update(key: ISettingModel['key'], value: ISettingModel['value']): TAxiosResponseData<void> {
    return axios.put(`${SettingApi._prefix}/${key}`, { value })
  }
}
