import { useCallback, useMemo } from 'react'
import { ERoleType } from 'src/constants/enum'
import { AuthState } from '../state'
import { useStore } from './useStore'

export const useAuthorization = () => {
  const { value: { user } } = useStore(AuthState)
  const authRoles = useMemo(
    () => user?.roles?.map((role) => role.type) || [],
    [user]
  )

  const is = useCallback(
    (...roles: ERoleType[]) => {
      if (authRoles.includes(ERoleType.SUPER_ADMIN)) {
        return true
      }

      for (const role of roles) {
        if (authRoles.includes(role)) {
          return true
        }
      }

      return false
    },
    [authRoles]
  )

  return {
    is
  }
}
