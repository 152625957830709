import { Button, Form, Input, Modal } from 'antd'
import layout from 'antd/lib/layout'
import { ChangeEvent, useCallback, useState } from 'react'
import { finalize, from, takeUntil } from 'rxjs'
import { CompanyApi } from 'src/api'
import { ErrorMessage } from 'src/components'
import { useDidMountEffect, useUnsubscribe, useValidation } from 'src/hooks'
import { ICompanyModel } from 'src/interfaces'
import { NotifyUtils, extractChannelId } from 'src/utils'
import { editChannelIdSchema } from './validation'

interface IProps {
  id: ICompanyModel['id']
  children?: any
  afterClose?: (saved: boolean, doc?: ICompanyModel) => any
}

export const ModalEditYoutubeChannel = ({ id, children, afterClose }: IProps) => {
  const unsubscribe$ = useUnsubscribe()
  const [form] = Form.useForm()

  const [formData, setFormData] = useState({} as ICompanyModel)
  const [loading, setLoading] = useState(false)
  const [invisible, setInvisible] = useState<boolean | ICompanyModel>(true)

  const { validate, errors, reset } = useValidation({
    data: formData,
    schema: editChannelIdSchema
  })

  const handleChangeInput = useCallback((e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
    e.persist()
    const { name, value } = e.target
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }))
  }, [])

  const save = useCallback(async () => {
    if (!formData?.youtubeChannelId) {
      return
    }

    const { isValid } = await validate()
    if (!isValid) {
      return
    }

    const channelId = extractChannelId(formData?.youtubeChannelId || '')
    const apiHandler = CompanyApi.videoChannel(id, { channelId })

    setLoading(true)
    from(apiHandler)
      .pipe(
        takeUntil(unsubscribe$),
        finalize(() => setLoading(false))
      )
      .subscribe({
        next: ({ data }) => {
          NotifyUtils.success({ message: 'Update Youtube channel ID successfully' })
          setInvisible(data || { ...formData, id })
        },
        error: NotifyUtils.handleAxiosError
      })
  }, [validate, formData, id, unsubscribe$])

  useDidMountEffect(() => {
    reset()

    if (invisible) {
      setLoading(false)
      setFormData(() => ({} as ICompanyModel))

      const isSaved = typeof invisible !== 'boolean'
      afterClose?.(isSaved, isSaved ? invisible : undefined)
    }
  }, [invisible])

  useDidMountEffect(() => {
    if (!invisible && id) {
      setLoading(true)
      from(CompanyApi.show(id))
        .pipe(
          takeUntil(unsubscribe$),
          finalize(() => setLoading(false))
        )
        .subscribe({
          next: ({ data: company }) => {
            setFormData(() => ({
              youtubeChannelId: company?.youtubeChannelId
            } as ICompanyModel))
          },
          error: NotifyUtils.handleAxiosError
        })
    }
  }, [id, invisible])

  return (
    <>
      <Button
        type="primary"
        onClick={() => setInvisible(false)}
      >
        {children || 'Create'}
      </Button>

      <Modal
        title="Update Youtube channel ID"
        centered
        open={!invisible}
        closable={!loading} // display X icon
        keyboard={false} // disable close on press ESC
        maskClosable={false} // disable close on click outside
        okText={id ? 'Update' : 'Save'}
        cancelText="Cancel"
        onOk={save}
        onCancel={() => setInvisible(true)}
        okButtonProps={{ disabled: loading }}
        cancelButtonProps={{ disabled: loading }}
      >
        <Form
          {...layout}
          form={form}
          name="control-hooks"
        // onFinish={onFinish}
        >
          <div className="fx fx-column fx-extend gap-2">
            <Form.Item
              label="Channel URL/ID"
              labelAlign="left"
              labelCol={{ flex: '0 0 100px' }}
              className="m-0"
            >
              <Input
                name="youtubeChannelId"
                placeholder="Enter youtube channel url or channel id"
                value={formData.youtubeChannelId}
                status={errors.hasError('youtubeChannelId') ? 'error' : undefined}
                readOnly={loading}
                onChange={handleChangeInput}
              />
              <ErrorMessage>{errors.getError('youtubeChannelId')}</ErrorMessage>
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </>
  )
}
