import axios from 'axios'
import { IUserModel, TAxiosResponseData } from 'src/interfaces'

export class AuthApi {
  static readonly _prefix = '/auth'

  static signIn(
    payload: {
      identify: string
      password: string
    }
  ): TAxiosResponseData<{
    id: number
    token: string
    tokenType: string
    tokenExpireAt: string
    tokenExpireIn: number
    profile: IUserModel
  }> {
    return axios.post(`${AuthApi._prefix}/sign-in`, payload)
  }

  static signOut(): TAxiosResponseData<void> {
    return axios.delete(`${AuthApi._prefix}/sign-out`)
  }
}
