let _storage: Storage = window.localStorage || window.sessionStorage

/**
 * Get storage item
 */
const getItem = <T = any>(key: string): T | void => {
  try {
    const val = _storage.getItem(key)
    return val ? JSON.parse(val) : undefined
  } catch (error) {
    console.error(error)
  }
}

/**
 * Set storage item
 */
const setItem = (key: string, value?: any) => {
  return _storage.setItem(
    key,
    JSON.stringify(value)
  )
}

/**
 * Remove Storage item
 */
const removeItem = (key: string) => {
  return _storage.removeItem(key)
}

export class StorageUtils {
  static getItem = getItem
  static setItem = setItem
  static removeItem = removeItem
  static useLocalStorage() {
    _storage = window.localStorage
  }

  static uesSessionStorage() {
    _storage = window.sessionStorage
  }
}
