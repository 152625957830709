import { Avatar } from 'antd'
import clsx from 'clsx'
import { FC, useCallback } from 'react'
import { IUserModel } from 'src/interfaces'
import { Helpers, NotifyUtils, UserUtils } from 'src/utils'

interface IProps {
  hiringManager?: IUserModel
  className?: string
  onDisabled?: (val: boolean) => void
}

export const HiringManagerGeneral: FC<IProps> = ({ hiringManager, className }) => {
  const copyAndToast = useCallback((content: any) => {
    Helpers.copy(String(content)).then(
      () => NotifyUtils.success({ message: `Copied to clipboard: ${content}` })
    )
  }, [])

  return (
    <div className={clsx('fx fx-ai-center gap-4 fx-jc-space-between', className)}>
      <div className="fx-1 fx fx-ai-center gap-4">
        <Avatar size={56} src={hiringManager?.pfp?.url}>
          {UserUtils.getFullName(hiringManager)}
        </Avatar>

        <div className="fx-1 fx fx-column">
          <div className="fx gap-2 fx-ai-center">
            <strong>Full Name:</strong>
            <span>{UserUtils.getFullName(hiringManager)}</span>
          </div>

          <div className="fx gap-2 fx-ai-center">
            <strong>Telephone:</strong>
            {hiringManager?.userPhoneNo && (
              <span
                className="pointer"
                onClick={() => copyAndToast(hiringManager.userPhoneNo)}
              >{UserUtils.formatPhoneUSA(hiringManager.userPhoneNo)}
              </span>
            )}
          </div>

          <div className="fx gap-2 fx-ai-center">
            <strong>Email:</strong>
            {hiringManager?.email && (
              <span
                className="pointer"
                onClick={() => copyAndToast(hiringManager.email)}
              >{UserUtils.formatPhoneUSA(hiringManager.email)}
              </span>
            )}
          </div>

          <div className="fx gap-2 fx-ai-center">
            <strong>LinkedIn URL:</strong>

            {
              hiringManager?.linkedInUrl && (
                <a
                  href={hiringManager.linkedInUrl}
                  rel="noreferrer"
                  target="_blank"
                >{hiringManager.linkedInUrl}
                </a>
              )

            }
          </div>
        </div>
      </div>
    </div>
  )
}
