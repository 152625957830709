import { Alert, Badge, Button, Divider, Input } from 'antd'
import { AxiosError } from 'axios'
import { ComponentProps, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { AIApi } from 'src/api'
import { ModalFullscreen } from 'src/components'
import { ignore } from 'src/constants'
import { useAsRef, useUnsubscribe } from 'src/hooks'
import { EOpenAIMessageRole, IOpenAIMessage, IScrapedJobModel } from 'src/interfaces'
import { OpenAIService } from 'src/services'
import { AxiosUtils, Helpers, NotifyUtils } from 'src/utils'
import { ModalMessages } from '../modal-messages'
import Style from './style.module.scss'

interface IProps extends Omit<ComponentProps<typeof ModalFullscreen>, 'onOk' | 'afterClose'> {
  jobs?: IScrapedJobModel[]
  afterClose?: () => any
  btnProps?: ComponentProps<typeof Button>
}

export const ModalAnalyzeCGPTLinkedinJobs: FC<IProps> = ({
  btnProps,
  ...props
}) => {
  const unsubscribe$ = useUnsubscribe()
  const jobs = useMemo(() => props.jobs || [], [props.jobs])
  const [open, setOpen] = useState(false)
  const [opened, setOpened] = useState(false)
  const afterCloseRef = useAsRef(props.afterClose)

  useEffect(() => {
    if (!open) {
      afterCloseRef.current?.()
    } else {
      setOpened(true)
    }
  }, [afterCloseRef, open])

  const [loading, setLoading] = useState(false)
  const [prompt, setPrompt] = useState('')
  const [result, setResult] = useState<{ [key: string]: IOpenAIMessage[] }>({})

  const onSubmit = useCallback(() => {
    setResult({})

    const promise = (async () => {
      const model = await OpenAIService.getModels().then((models) => models[0].id)
      for (const job of jobs) {
        const jobDescription = Helpers.htmlToTxt(job.description)
          .split('\n')
          .reduce<string[]>((acc, item, index, arr) => {
            const line = item.trim()

            if (line && !line.startsWith('About the job') && !line.startsWith('This job is sourced from') && !line.startsWith('Learn more')) {
              acc.push(line)
            }

            return acc
          }, [])
          .join('\n')

        const payload = {
          model,
          messages: [{
            id: 0,
            role: EOpenAIMessageRole.USER,
            content: prompt.replace(/\[JOB_DESCRIPTION\]/g, jobDescription)
          }]
        }

        await AIApi.messaging(payload)
          .then(({ data }) => setResult((prev) => ({
            ...prev,
            [job.id]: [
              ...payload.messages,
              {
                id: 1,
                role: EOpenAIMessageRole.ASSISTANT,
                content: data.content
              }
            ]
          })))
          .catch((error: AxiosError) => {
            setResult((prev) => ({
              ...prev,
              [job.id]: [
                ...payload.messages,
                {
                  id: 1,
                  role: EOpenAIMessageRole.ASSISTANT,
                  content: `<span style="color: red;">${AxiosUtils.getApiErrorMessage(error)}</span>`
                }
              ]
            }))
          })
      }
    })()

    setLoading(true)
    from(promise)
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error: AxiosError) => {
          NotifyUtils.handleAxiosError(error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(ignore)
  }, [jobs, prompt, unsubscribe$])

  return (
    <>
      <span className={Style.modalScrapedJobsCGPT}>
        <Button
          type="primary"
          {...btnProps}
          disabled={btnProps?.disabled || !jobs.length}
          onClick={() => setOpen(true)}
        >
          {props.children || 'Try CGPT Prompt'}
        </Button>
        <Badge
          showZero
          count={jobs.length}
        />
      </span>

      <ModalFullscreen
        title="Try Feed Chat GPT"
        // wrapClassName={Style.modalScrapedJobsCGPT}
        open={open}
        closable={!loading} // display X icon
        keyboard={false} // disable close on press ESC
        maskClosable={false} // disable close on click outside
        okButtonProps={{ disabled: !prompt || loading }}
        cancelButtonProps={{ disabled: loading }}
        okText="Submit"
        cancelText="Close"
        onOk={onSubmit}
        onCancel={() => setOpen(false)}
      >
        {opened && (
          <div className="fx fx-column gap-3">
            <div className="fx fx-column gap-2">
              <Input.TextArea
                // showCount
                // maxLength={500}
                style={{ height: 120, resize: 'none' }}
                placeholder="CGPT Prompt..."
                disabled={loading}
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
              />

              <Alert
                type="info"
                message="Use [JOB_DESCRIPTION] then scripts will replace with job information"
              />
            </div>

            <div className="fx fx-column gap-2">
              {jobs.map((job) => (
                <div key={job.id}>
                  <Divider/>
                  <div className="fx gap-2">
                    <div className="fx-1">{job.id}</div>
                    <div className="fx-1">{job.jobId}</div>
                    <div className="fx-1">{job.jobTitle}</div>
                    <div className="fx-1 fx fx-jc-flex-end">
                      {!!result[job.id]?.length && <ModalMessages messages={result[job.id] || []}/>}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </ModalFullscreen>
    </>
  )
}
