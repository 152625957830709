import { TableProps } from 'antd'
import { JsonView } from 'src/components'
import { IAnalyticModel } from 'src/interfaces'
import { TimeZoneService } from 'src/services'
import { UserUtils } from 'src/utils'

export const renderColumns = (): Required<TableProps<IAnalyticModel>>['columns'] => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: 'User',
    dataIndex: 'user',
    key: 'user',
    render: (val: Required<IAnalyticModel>['user']) => UserUtils.getFullName(val)
  },
  {
    title: 'Session ID',
    dataIndex: 'session_id',
    key: 'session_id'
  },
  {
    title: 'Screen',
    dataIndex: 'screen',
    key: 'screen'
  },
  {
    title: 'Event',
    dataIndex: 'event',
    key: 'event'
  },
  {
    title: 'Data',
    dataIndex: 'data',
    key: 'data',
    render: (val: IAnalyticModel['data']) => Boolean(val && Object.keys(val).length) && (
      <JsonView
        name={null}
        src={val || {}}
        collapsed
        enableClipboard={false}
        displayDataTypes={false}
        displayObjectSize={false}
      />
    )
  },
  {
    title: 'CreatedAt',
    dataIndex: 'created',
    key: 'created',
    render: (val: string) => TimeZoneService.format(val, 'MMM D, y HH:mm:ss')
  }
]
