import { PlusOutlined } from '@ant-design/icons'
import type { MenuProps } from 'antd'
import { Dropdown, Space, Tag } from 'antd'
import { ERoleType } from 'src/constants/enum'
import { IUserModel } from 'src/interfaces'
import { UserUtils } from 'src/utils'

interface IUserAction<T = IUserModel> {
  onRoleRemove: (record: T, removingRole: string) => any
  onAddRole: (record: T, role: string) => any
}

const items: MenuProps['items'] = [
  {
    key: ERoleType.ADMIN,
    label: 'Admin'
  },
  {
    key: ERoleType.SUPER_ADMIN,
    label: 'Super Admin'
  }
]

export const renderColumns = ({ onAddRole, onRoleRemove }: IUserAction) => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (__: any, record: IUserModel) => UserUtils.getFullName(record)
  },
  {
    title: 'Username',
    dataIndex: 'username',
    key: 'username'
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email'
  },
  {
    title: 'Roles',
    key: 'action',
    render: (_: string, record: IUserModel) => (
      <Space>
        {record.roles?.map(role => (
          <Tag
            key={role.id}
            color={role.type === ERoleType.ADMIN ? 'green' : 'blue'}
            closable
            onClose={() => onRoleRemove(record, role.type)}
          >
            {role.type}
          </Tag>
        ))}
        <Dropdown
          menu={{
            items,
            onClick: ({ key }) => onAddRole(record, key)
          }}
        >
          <Tag style={{ borderStyle: 'dashed', cursor: 'pointer' }}>
            <PlusOutlined/> Add Role
          </Tag>
        </Dropdown>
      </Space>
    )
  }
]
