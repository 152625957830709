import clsx from 'clsx'
import { FC, useEffect, useMemo, useState } from 'react'
import { takeUntil } from 'rxjs'
import { CandidateApi } from 'src/api'
import { FilePreviewIcon } from 'src/components'
import { useUnsubscribe } from 'src/hooks'
import { ICandidateModel, IFileUploadModel } from 'src/interfaces'
import { LoadMoreService } from 'src/services'

interface IProps {
  candidate?: ICandidateModel
  className?: string
}

export const CandidateDocuments: FC<IProps> = ({ candidate, className }) => {
  const unsubscribe$ = useUnsubscribe()
  const candidateId = useMemo(() => candidate?.id, [candidate])
  const [documents, setDocuments] = useState<IFileUploadModel[]>([])

  const _loadMoreService = useMemo(
    () => candidateId && new LoadMoreService({
      endpoint: `${CandidateApi._prefix}/${candidateId}/documents`
    }),
    [candidateId]
  )

  useEffect(() => {
    if (_loadMoreService) {
      _loadMoreService
        .items$
        .pipe(takeUntil(unsubscribe$))
        .subscribe((docs) => {
          setDocuments(docs)
          if (_loadMoreService.hasMore) {
            _loadMoreService.loadMore({ limit: 100 })
          }
        })
    }
  }, [_loadMoreService, unsubscribe$])

  return (
    <div className={clsx('fx fx-column gap-2', className)}>
      <strong>Documents</strong>

      <div className="fx fx-wrap-wrap gap-2">
        {documents.map((doc) => (
          <FilePreviewIcon
            key={doc.id}
            className="p-1 text-center"
            src={doc.url}
            filename={doc.filename}
          />
        ))}
      </div>
    </div>
  )
}
