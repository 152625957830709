import { PresetColorTypes } from 'antd/lib/_util/colors'

export const EntityColorUtils = new (
  class {
    private countKeys = 0
    private readonly colors: { [key: string]: string } = {}

    get(entity: string, id: string | number): string {
      const key = `${entity}-${id}`

      if (!this.colors[key]) {
        if (this.countKeys === 1000) {
          const deleteKey = Object.keys(this.colors)[0]
          this.colors[key] = this.colors[deleteKey]
          delete this.colors[deleteKey]
        } else {
          this.countKeys++
          this.colors[key] = PresetColorTypes.random()
        }
      }

      return this.colors[key]
    }
  })()
