import { Badge, Button, Checkbox, Input, Pagination, Popconfirm, Select, Table } from 'antd'
import { omit } from 'lodash'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { finalize, from, takeUntil } from 'rxjs'
import { CandidateApi } from 'src/api'
import { ERoleType } from 'src/constants/enum'
import { IPaginateCallback, IPaginateParams, usePaginateParams, useUnsubscribe } from 'src/hooks'
import { ICandidateModel, IPaginationResponse } from 'src/interfaces'
import { BreadcrumbService, PaginationService } from 'src/services'
import { GlobalAction, GlobalState, useAuthorization, useStore } from 'src/store'
import { NotifyUtils } from 'src/utils'
import { EPaths } from '../../routes.path'
import { renderCandidatesColumns } from './columns'

const PAGE_TITLE = 'Candidates'

export const Candidates: FC = () => {
  const unsubscribe$ = useUnsubscribe()
  const { is } = useAuthorization()
  const { value: { loading } } = useStore(GlobalState)
  const _paginationService = useMemo(() => new PaginationService(CandidateApi), [])
  const [dataSource, setDataSource] = useState<IPaginationResponse<ICandidateModel>>({
    total: 1,
    rows: []
  })

  const fetch = useCallback<IPaginateCallback>(
    (params) => _paginationService.paging(CandidateApi.parseParams(params)),
    [_paginationService]
  )

  const { pagination, pushPagination, setPagination } = usePaginateParams(fetch)
  const [filter, setFilter] = useState<{
    keyword?: string
    isActive?: number
  } & Omit<IPaginateParams, 'page' | 'limit' | 'offset'>>({
    ...omit(pagination, ['page', 'limit', 'offset']),
    hasLinkedinUrl: pagination.hasLinkedinUrl === 'true' || undefined
  })

  const onDownload = useCallback(
    (params: typeof filter) => {
      GlobalAction.setLoading(true)
      from(CandidateApi.download(CandidateApi.parseParams(params)))
        .pipe(
          takeUntil(unsubscribe$),
          finalize(() => GlobalAction.setLoading(false))
        )
        .subscribe({
          error: NotifyUtils.handleAxiosError
        })
    },
    [unsubscribe$]
  )

  const onDelete = useCallback((record: ICandidateModel) => {
    GlobalAction.setLoading(true)
    from(CandidateApi.destroy(record.id))
      .pipe(
        takeUntil(unsubscribe$),
        finalize(() => GlobalAction.setLoading(false))
      )
      .subscribe({
        next: () => fetch(pagination),
        error: NotifyUtils.handleAxiosError
      })
  }, [fetch, pagination, unsubscribe$])

  const onActiveChange = useCallback((record: ICandidateModel) => {
    GlobalAction.setLoading(true)
    from(CandidateApi.toggleIsActive(record.id))
      .pipe(
        takeUntil(unsubscribe$),
        finalize(() => GlobalAction.setLoading(false))
      )
      .subscribe({
        next() {
        // if isActive = NULL => isActive = false
        // if isActive = true => isActive = false
        // if isActive = undefined => isActive = false
        // if isActive = false => isActive = true
          record.isActive = ![null, undefined, true].includes(record.isActive)
          setDataSource((prev) => ({ ...prev }))
        },
        error: NotifyUtils.handleAxiosError
      })
  }, [unsubscribe$])

  useEffect(() => {
    _paginationService
      .loading$
      .pipe(takeUntil(unsubscribe$))
      .subscribe((value) => GlobalAction.setLoading(value))
    _paginationService
      .pagination$
      .pipe(takeUntil(unsubscribe$))
      .subscribe((data) => setDataSource(data))
  }, [_paginationService, unsubscribe$])

  /**
   * breadcrumb
   */
  useEffect(() => {
    BreadcrumbService.items = [{
      route: EPaths.CANDIDATE,
      label: PAGE_TITLE
    }]
    return () => {
      BreadcrumbService.items = []
    }
  }, [])

  const handleOnSearch = useCallback(() => {
    setPagination({ ...pagination, page: 1 })

    return pushPagination({
      ...pagination,
      page: 1,
      ...filter
    })
  }, [filter, pagination, pushPagination, setPagination])

  const handleOnClear = useCallback(() => {
    console.log('handle clear')

    setFilter({
      keyword: '',
      isActive: 0,
      hasLinkedinUrl: ''
    })
  }, [])

  return (
    <section className="fx fx-column fx-extend">
      <div className="fx fx-jc-space-between mb-2">
        <div className="fx fx-extend fx-ai-center gap-2">
          <Input
            allowClear
            placeholder="Search by id, email, phone, username..."
            style={{ flex: '0 0 30vw' }}
            readOnly={loading}
            value={filter.keyword}
            onChange={(e) => {
              e.persist()
              setFilter((prev) => ({
                ...prev,
                keyword: e.target.value
              }))
            }}
          />

          <Select
            allowClear
            placeholder="Select status"
            style={{ flex: '0 0 10vw' }}
            value={filter.isActive ? Number(filter.isActive) : undefined}
            onChange={(isActive) => {
              setFilter((prev) => ({
                ...prev,
                isActive
              }))
            }}
          >
            <Select.Option value={1}>Active</Select.Option>
            <Select.Option value={2}>Inactive</Select.Option>
          </Select>

          <Checkbox
            checked={!!filter.hasLinkedinUrl}
            onChange={(e) => setFilter((prev) => ({
              ...prev,
              hasLinkedinUrl: e.target.checked
            }))}
          >Has linkedin url
          </Checkbox>
        </div>

        <Popconfirm
          title="Export as csv?"
          okText="Yes"
          cancelText="No"
          disabled={!dataSource.total}
          onConfirm={() => onDownload(filter)}
        >
          <Button type="primary" disabled={!dataSource.total}>Export</Button>
        </Popconfirm>

        <Badge showZero count={dataSource.total}/>
      </div>

      <div className="fx fx-jc-flex-end gap-2">
        <Button onClick={handleOnClear}>Clear All</Button>
        <Button type="primary" onClick={handleOnSearch}>Search</Button>
      </div>

      <Table
        bordered
        rowKey="id"
        className="fx-extend"
        loading={loading}
        columns={renderCandidatesColumns({
          onClickDelete: is(ERoleType.SUPER_ADMIN)
            ? onDelete
            : undefined,
          onActiveChange: is(ERoleType.SUPER_ADMIN)
            ? onActiveChange
            : undefined
        })}
        dataSource={dataSource.rows}
        pagination={false}
      />

      <Pagination
        className="fx-as-end mt-3"
        disabled={loading}
        total={dataSource.total}
        current={pagination.page}
        pageSize={pagination.limit}
        {
          ...{
            ...pagination,
            onChange(page, limit) {
              pushPagination({
                ...pagination,
                page,
                limit
              })
            }
          }
        }
      />
    </section>
  )
}
