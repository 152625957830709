import axios from 'axios'
import { ICampaignIndicatorModel, IPaginationQuery, TAxiosResponsePagination } from 'src/interfaces'

export class CampaignIndicatorApi {
  static readonly _prefix = '/campaign-indicators'

  static index(params: IPaginationQuery = {}): TAxiosResponsePagination<ICampaignIndicatorModel> {
    return axios.get(CampaignIndicatorApi._prefix, { params })
  }
}
