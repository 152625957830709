import axios from 'axios'
import { IBlogCategoryModel, IPaginationQuery, TAxiosResponseData, TAxiosResponsePagination } from 'src/interfaces'

export class BlogCategoryApi {
  static readonly _prefix = '/blogs/categories'

  static index(params: IPaginationQuery = {}): TAxiosResponsePagination<IBlogCategoryModel> {
    return axios.get(`${BlogCategoryApi._prefix}`, { params })
  }

  static store(payload: {
    name: string
    description?: string
  }): TAxiosResponseData<IBlogCategoryModel> {
    return axios.post(`${BlogCategoryApi._prefix}`, payload)
  }

  static show(id: IBlogCategoryModel['id']): TAxiosResponseData<IBlogCategoryModel> {
    return axios.get(`${BlogCategoryApi._prefix}/${id}`)
  }

  static update(id: IBlogCategoryModel['id'], payload: {
    name: string
    description?: string
  }): TAxiosResponseData<IBlogCategoryModel> {
    return axios.put(`${BlogCategoryApi._prefix}/${id}`, payload)
  }

  static destroy(id: IBlogCategoryModel['id']): TAxiosResponseData<void> {
    return axios.delete(`${BlogCategoryApi._prefix}/${id}`)
  }
}
