import clsx from 'clsx'
import { FC, useMemo } from 'react'
import { IQuizResult } from 'src/interfaces'
import Style from './style.module.scss'

interface IProps {
  coreScores?: IQuizResult['coreScores']
}

export const QuizCore: FC<IProps> = ({ coreScores }) => {
  const formattedCoreScores = useMemo(() => {
    return coreScores?.reduce<{
      left: {
        label: string
        score: number
      }
      right: {
        label: string
        score: number
      }
    }[]>((acc, [left, right]) => {
      acc.push({
        left: {
          label: left.label,
          score: left.score
        },
        right: {
          label: right.label,
          score: right.score
        }
      })

      return acc
    }, [])
  }, [coreScores])
  return (
    <>
      {formattedCoreScores?.map(({ left, right }, index) => (
        <div key={index} className="fx fx-column">
          <div className="fx fx-jc-space-between">
            <strong className={Style.blueTxt}>{left.label}</strong>
            <strong className={Style.orangeTxt}>{right.label}</strong>
          </div>

          <div className="fx gap-1">
            <div className={clsx('text-center', Style.blueBg)} style={{ flex: `0 1 ${left.score}%` }}>
              {left.score}
            </div>
            <div className={clsx('fx-1 text-center', Style.orangeBg)}>
              {right.score}
            </div>
          </div>
        </div>
      ))}
    </>
  )
}
