import clsx from 'clsx'
import ReactEmojiPicker from 'emoji-picker-react'
import { ComponentProps, FC, ReactNode, useEffect, useRef, useState } from 'react'
import { useAsRef, useClickOutside } from 'src/hooks'
import Style from './style.module.scss'

interface IProps extends ComponentProps<typeof ReactEmojiPicker> {
  children?: ReactNode
  open?: boolean
  onOpen?: () => void
}

export const EmojiPicker: FC<IProps> = (props) => {
  const [open, setOpen] = useState(props.open)
  const pickerRef = useRef<HTMLDivElement>(null)
  const onOpenRef = useAsRef(props.onOpen)

  useEffect(() => {
    setOpen(props.open)
  }, [props.open])

  useEffect(() => {
    if (open) {
      onOpenRef.current?.()
    }
  }, [onOpenRef, open])

  useClickOutside(pickerRef, () => setOpen(false))

  return (
    <div className={clsx('fx fx-center', Style.container)}>
      <span
        className="fx pointer"
        onMouseDown={(e) => e.stopPropagation()}
        onTouchStart={(e) => e.stopPropagation()}
        onClick={(e) => {
          e.stopPropagation()
          setOpen((prev) => !prev)
        }}
      >
        {props.children
          ? props.children
          : (
            <svg height="20px" viewBox="0 0 38 38" width="20px">
              <g fill="none" fillRule="evenodd">
                <g transform="translate(-893.000000, -701.000000)">
                  <g transform="translate(709.000000, 314.000000)">
                    <g>
                      <path
                        d="M210.5,405 C209.121,405 208,403.879 208,402.5 C208,401.121 209.121,400 210.5,400 C211.879,400 213,401.121 213,402.5 C213,403.879 211.879,405 210.5,405 M212.572,411.549 C210.428,413.742 206.938,415 203,415 C199.062,415 195.572,413.742 193.428,411.549 C192.849,410.956 192.859,410.007 193.451,409.428 C194.045,408.85 194.993,408.859 195.572,409.451 C197.133,411.047 199.909,412 203,412 C206.091,412 208.867,411.047 210.428,409.451 C211.007,408.859 211.956,408.85 212.549,409.428 C213.141,410.007 213.151,410.956 212.572,411.549 M195.5,400 C196.879,400 198,401.121 198,402.5 C198,403.879 196.879,405 195.5,405 C194.121,405 193,403.879 193,402.5 C193,401.121 194.121,400 195.5,400 M203,387 C192.523,387 184,395.523 184,406 C184,416.477 192.523,425 203,425 C213.477,425 222,416.477 222,406 C222,395.523 213.477,387 203,387"
                        fill="#6a248a"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          )}
      </span>

      {open && (
        <div ref={pickerRef} className={Style.picker}>
          <ReactEmojiPicker {...props}/>
        </div>
      )}
    </div>
  )
}
