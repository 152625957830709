import { Button, Checkbox, Input, TableProps } from 'antd'
import { SearchAndSelectCompany } from '../search-and-select-company'
import { Contact } from './type'
import { displayEmployees, isModifiedContact, isNewContact } from './utils'

export const renderColumns = (props: {
  disabled?: boolean
  onChange?: (doc: Contact, value: any, key: string) => any
  onDelete?: (doc: Contact) => any
  onSaveClicked?: (doc: Contact) => any
}): TableProps<Contact>['columns'] => {
  return [
    {
      title: 'CompanyId',
      render: (record: Contact) => (
        <span
          className="oneline-text"
          style={{ maxWidth: '200px' }}
        >
          {record.company.id}
        </span>
      )
    },
    {
      title: 'Company Name',
      render: (record: Contact) => (
        <SearchAndSelectCompany
          value={{ label: record.company?.name, value: record.company?.id, ...(record.company || {}) }}
          placeholder="Search company"
          onChange={(value) => props.onChange?.(record, value, 'company')}
          style={{ minWidth: '200px' }}
        />
      )
    },
    {
      title: 'Company Size',
      render: (record: Contact) => (
        <span
          className="oneline-text"
          style={{ maxWidth: '200px' }}
        >
          {displayEmployees(record)}
        </span>
      )
    },
    {
      title: 'Email Domain',
      render: (record: Contact) => (
        <span
          className="oneline-text"
          style={{ maxWidth: '200px' }}
        >
          {record.company.domain}
        </span>
      )
    },
    {
      title: 'First Name',
      render: (record: Contact) => (
        <Input
          placeholder="First Name"
          style={{ minWidth: '200px' }}
          value={record.firstName}
          onChange={(e) => props.onChange?.(record, e.target.value || '', 'firstName')}
        />
      )
    },
    {
      title: 'Last Name',
      render: (record: Contact) => (
        <Input
          placeholder="Last Name"
          style={{ minWidth: '200px' }}
          value={record.lastName}
          onChange={(e) => props.onChange?.(record, e.target.value || '', 'lastName')}
        />
      )
    },
    {
      title: 'Contact Name',
      render: (record: Contact) => (
        <span
          className="oneline-text"
          style={{ minWidth: '200px' }}
        >
          {[record.firstName, record.lastName].join(' ')}
        </span>
      )
    },
    {
      title: 'Position',
      render: (record: Contact) => (
        <Input
          placeholder="Position"
          style={{ minWidth: '200px' }}
          value={record.position}
          onChange={e => props.onChange?.(record, e.target.value || '', 'position')}
        />
      )
    },
    {
      title: 'Verified Email',
      render: (record: Contact) => (
        <Input
          placeholder="Verified Email"
          style={{ minWidth: '200px' }}
          value={record.verifiedEmail}
          onChange={(e) => props.onChange?.(record, e.target.value || '', 'verifiedEmail')}
        />
      )
    },
    {
      title: 'Connected',
      render: (record: Contact) => (
        <div
          className="fx fx-column gap-1 fx-ai-start"
          style={{ maxWidth: '200px' }}
        >
          <div>
            <Checkbox
              checked={record.connectedAshley}
              onChange={e => props.onChange?.(record, e.target.checked, 'connectedAshley')}
            >Ashley
            </Checkbox>
          </div>
          <div>
            <Checkbox
              checked={record.connectedPhil}
              onChange={e => props.onChange?.(record, e.target.checked, 'connectedPhil')}
            >Phil
            </Checkbox>
          </div>
          <div>
            <Checkbox
              checked={record.connectedDaniel}
              onChange={e => props.onChange?.(record, e.target.checked, 'connectedDaniel')}
            >Daniel
            </Checkbox>
          </div>
          <div>
            <Checkbox
              checked={record.connectedCecilia}
              onChange={e => props.onChange?.(record, e.target.checked, 'connectedCecilia')}
            >Cecilia
            </Checkbox>
          </div>
        </div>
      )
    },
    {
      title: 'first@',
      render: (record: Contact) => (
        <span
          className="oneline-text"
          style={{ maxWidth: '200px' }}
        >
          {[record.firstName, record.company.domain].join('@').toLowerCase()}
        </span>
      )
    },
    {
      title: 'firstlast@',
      render: (record: Contact) => (
        <span
          className="oneline-text"
          style={{ maxWidth: '200px' }}
        >
          {[[record.firstName, record.lastName].join(''), record.company.domain].join('@').toLowerCase()}
        </span>
      )
    },
    {
      title: 'first.last@',
      render: (record: Contact) => (
        <span
          className="oneline-text"
          style={{ maxWidth: '200px' }}
        >
          {[[record.firstName, record.lastName].join('.'), record.company.domain].join('@').toLowerCase()}
        </span>
      )
    },
    {
      title: 'flast@',
      render: (record: Contact) => (
        <span
          className="oneline-text"
          style={{ maxWidth: '200px' }}
        >
          {[[record.firstName?.[0], record.lastName].join(''), record.company.domain].join('@').toLowerCase()}
        </span>
      )
    },
    {
      title: 'firstl@',
      render: (record: Contact) => (
        <span
          className="oneline-text"
          style={{ maxWidth: '200px' }}
        >{[[record.firstName, record.lastName?.[0]].join(''), record.company.domain].join('@').toLowerCase()}
        </span>
      )
    },
    {
      title: 'Action',
      fixed: 'right',
      render: (record: Contact) => {
        return (
          <div className="fx fx-row gap-2 fx-ai-center">
            <Button
              type="primary"
              disabled={!(isNewContact(record) || isModifiedContact(record))}
              onClick={() => props.onSaveClicked?.(record)}
            >
              {(isNewContact(record) || isModifiedContact(record)) ? 'Save' : 'Saved'}
            </Button>

            <Button
              type="primary"
              color="danger"
              onClick={() => props.onDelete?.(record)}
            >
              Delete
            </Button>
          </div>
        )
      }
    }
  ]
}
