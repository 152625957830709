import clsx from 'clsx'
import { FC } from 'react'
import { IQuizResult } from 'src/interfaces'
import Style from './style.module.scss'

interface IProps {
  ambScores?: IQuizResult['ambScores']
}

export const QuizAmb: FC<IProps> = ({ ambScores }) => {
  return (
    <>
      {/* sort by score desc */}
      {ambScores?.sort((a, b) => b.score - a.score).map(({ label, score, description }, index) => (
        <div key={index} className="fx fx-ai-center gap-2">
          <strong
            className={clsx('text-right', Style[`ambTxt${index}`])}
            style={{ width: '110px' }}
          >
            {label}
          </strong>
          <div
            className={clsx('fx-1 px-2 white', Style[`ambBar${index}`])}
            style={{ '--score-value': `${score}%` } as React.CSSProperties}
          >
            <span style={{ zIndex: 1, position: 'absolute' }}>{score}</span>
          </div>
        </div>
      ))}
    </>
  )
}
