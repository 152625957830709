import { Button, Form, Input, Modal, Select } from 'antd'
import layout from 'antd/lib/layout'
import { ChangeEvent, useCallback, useState } from 'react'
import { finalize, from, takeUntil } from 'rxjs'
import { ExpandYourAreaApi } from 'src/api'
import { ErrorMessage } from 'src/components'
import { EExpandYourReachAreaActions } from 'src/constants/enum'
import { useDidMountEffect, useUnsubscribe, useValidation } from 'src/hooks'
import { EExpandYourAreaActionsText, IExpandYourAreaModel } from 'src/interfaces'
import { NotifyUtils } from 'src/utils'
import { expandYourAreaSchema } from './validation'

const actionOptions = Object.values(EExpandYourReachAreaActions).map((value) => ({
  label: EExpandYourAreaActionsText[value],
  value
}))

interface IActionExpandYourAreaProps {
  id?: IExpandYourAreaModel['id']
  children?: any
  afterClose?: (saved: boolean, doc?: IExpandYourAreaModel) => any
}

export const ModalExpandYourAreaAction = ({ id, children, afterClose }: IActionExpandYourAreaProps) => {
  const unsubscribe$ = useUnsubscribe()
  const [form] = Form.useForm()

  const [formData, setFormData] = useState({} as IExpandYourAreaModel)
  const [loading, setLoading] = useState(false)
  const [invisible, setInvisible] = useState<boolean | IExpandYourAreaModel>(true)

  const { validate, errors, reset } = useValidation({
    data: formData,
    schema: expandYourAreaSchema
  })

  const handleChangeInput = useCallback((e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
    e.persist()
    const { name, value } = e.target
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }))
  }, [])

  const save = useCallback(async () => {
    const { isValid } = await validate()
    if (!isValid) {
      return
    }

    const apiHandler = id
      ? ExpandYourAreaApi.update(id, formData)
      : ExpandYourAreaApi.store(formData)

    setLoading(true)
    from(apiHandler)
      .pipe(
        takeUntil(unsubscribe$),
        finalize(() => setLoading(false))
      )
      .subscribe({
        next: ({ data }) => {
          NotifyUtils.success({ message: `${id ? 'Update' : 'Create'} expand your area successfully` })
          setInvisible(data || { ...formData, id })
        },
        error: NotifyUtils.handleAxiosError
      })
  }, [validate, formData, id, unsubscribe$])

  useDidMountEffect(() => {
    reset()

    if (invisible) {
      setLoading(false)
      setFormData(() => ({} as IExpandYourAreaModel))

      const isSaved = typeof invisible !== 'boolean'
      afterClose?.(isSaved, isSaved ? invisible : undefined)
    }
  }, [invisible])

  useDidMountEffect(() => {
    if (!invisible && id) {
      setLoading(true)
      from(ExpandYourAreaApi.show(id))
        .pipe(
          takeUntil(unsubscribe$),
          finalize(() => setLoading(false))
        )
        .subscribe({
          next: ({ data: expandYourArea }) => {
            setFormData(() => ({
              action: expandYourArea.action,
              item: expandYourArea.item,
              description: expandYourArea.description,
              redirectURL: expandYourArea.redirectURL || ''
            } as IExpandYourAreaModel))
          },
          error: NotifyUtils.handleAxiosError
        })
    }
  }, [id, invisible])

  return (
    <>
      <Button
        type="primary"
        onClick={() => setInvisible(false)}
      >
        {children || 'Create'}
      </Button>

      <Modal
        title={`${id ? 'Update' : 'Create'} Expand Your Area`}
        centered
        open={!invisible}
        closable={!loading} // display X icon
        keyboard={false} // disable close on press ESC
        maskClosable={false} // disable close on click outside
        okText={id ? 'Update' : 'Save'}
        cancelText="Cancel"
        onOk={save}
        onCancel={() => setInvisible(true)}
        okButtonProps={{ disabled: loading }}
        cancelButtonProps={{ disabled: loading }}
      >
        <Form
          {...layout}
          form={form}
          name="control-hooks"
        // onFinish={onFinish}
        >
          <div className="fx fx-column fx-extend gap-2">
            <Form.Item
              label="Action"
              labelAlign="left"
              labelCol={{ flex: '0 0 100px' }}
              className="m-0"
            >
              <Select
                allowClear
                disabled={loading}
                placeholder="Select action"
                value={formData.action}
                options={actionOptions}
                status={errors.hasError('action') ? 'error' : undefined}
                onChange={(action) => setFormData((prev) => ({
                  ...prev,
                  action: action ?? null
                }))}
              />
              <ErrorMessage>{errors.getError('action')}</ErrorMessage>
            </Form.Item>

            <Form.Item
              label="Action Item"
              labelAlign="left"
              labelCol={{ flex: '0 0 100px' }}
              className="m-0"
              rules={[{ required: true }]}
            >
              <Input
                name="item"
                placeholder="Enter action item"
                value={formData.item}
                status={errors.hasError('item') ? 'error' : undefined}
                readOnly={loading}
                onChange={handleChangeInput}
              />
              <ErrorMessage>{errors.getError('item')}</ErrorMessage>
            </Form.Item>

            <Form.Item
              label="Description"
              labelAlign="left"
              labelCol={{ flex: '0 0 100px' }}
              className="m-0"
            >
              <Input.TextArea
                rows={4}
                name="description"
                placeholder="Enter description"
                value={formData.description}
                status={errors.hasError('description') ? 'error' : undefined}
                readOnly={loading}
                onChange={handleChangeInput}
              />
              <ErrorMessage>{errors.getError('description')}</ErrorMessage>
            </Form.Item>

            <Form.Item
              label="Action URL"
              labelAlign="left"
              labelCol={{ flex: '0 0 100px' }}
              className="m-0"
            >
              <Input
                name="redirectURL"
                placeholder="Enter action url"
                value={formData.redirectURL}
                status={errors.hasError('redirectURL') ? 'error' : undefined}
                readOnly={loading}
                onChange={handleChangeInput}
              />
              <ErrorMessage>{errors.getError('redirectURL')}</ErrorMessage>
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </>
  )
}
