import { IRouterOption } from 'src/router'
import { ArchivedCampaign } from './components/campaign'
import { ArchivedCampaigns } from './components/campaigns'
import { EPaths } from './routes.path'

export enum ERoutes {
  ARCHIVED_CAMPAIGN = 'ArchivedCampaign.ArchivedCampaigns',
  ARCHIVED_CAMPAIGN_DETAIL = 'ArchivedCampaign.ArchivedCampaign'
}

export const Routes: IRouterOption[] = [
  {
    path: EPaths.ARCHIVED_CAMPAIGN,
    name: ERoutes.ARCHIVED_CAMPAIGN,
    component: ArchivedCampaigns,
    exact: true,
    meta: {}
  },
  {
    path: EPaths.ARCHIVED_CAMPAIGN_DETAIL,
    name: ERoutes.ARCHIVED_CAMPAIGN_DETAIL,
    component: ArchivedCampaign,
    exact: true,
    meta: {}
  }
]

export const ArchivedCampaignModule = {
  ERoutes,
  Routes
}
