import { BellOutlined } from '@ant-design/icons'
import { Badge, Popover, Tabs } from 'antd'
import classNames from 'clsx'
import React, { FC, useEffect, useState } from 'react'
import { takeUntil } from 'rxjs'
import { useUnsubscribe } from 'src/hooks'
import { FirebaseNotificationService } from 'src/services'
import { NoticeList } from '../notice-list'
import { INoticeIconData, INoticeIconTabProps } from './definition'
import Style from './style.module.scss'

const { TabPane } = Tabs

export interface INoticeIconProps {
  bell?: React.ReactNode
  className?: string
  onClear?: (tableTile: string) => void
  onItemClick?: (item: INoticeIconData, tabProps: INoticeIconTabProps) => void
  onTabChange?: (key: string) => void
  popupAlign?: {
    points?: [string, string]
    offset?: [number, number]
    targetOffset?: [number, number]
    overflow?: any
    useCssRight?: boolean
    useCssBottom?: boolean
    useCssTransform?: boolean
  }
  style?: React.CSSProperties
  onPopupVisibleChange?: (visible: boolean) => void
  popupVisible?: boolean
  locale?: { emptyText: string; clear: string }
  emptyImage?: string
  tabs?: INoticeIconTabProps[]
  activeKey?: string
}

export const NoticeIcon: FC<INoticeIconProps> = (props = {
  onItemClick: () => {},
  onPopupVisibleChange: () => {},
  onTabChange: () => {},
  onClear: () => {},
  locale: {
    emptyText: 'No notifications',
    clear: 'Clear'
  },
  emptyImage: 'https://gw.alipayobjects.com/zos/rmsportal/wAhyIChODzsoKIOBHcBk.svg',
  tabs: []
}) => {
  const onItemClick = (item: INoticeIconData, tabProps: INoticeIconTabProps) => {
    const { onItemClick } = props
    onItemClick?.(item, tabProps)
  }

  const unsubscribe$ = useUnsubscribe()
  const [count, setCount] = useState<string>('')

  // subscribe to notification service
  useEffect(() => {
    FirebaseNotificationService.firebaseNotification$
      .pipe(takeUntil(unsubscribe$))
      .subscribe((data) => {
        const numberNotification = data.countNotifications || 0
        if (numberNotification === 0) {
          return setCount('')
        }

        setCount(numberNotification > 9 ? '9+' : numberNotification.toString())
      })
  }, [setCount, unsubscribe$])

  const handleOnTabChange = (tabKey: string) => {
    const { onTabChange } = props
    onTabChange?.(tabKey)
  }

  const getNotificationBox = () => {
    const { tabs, activeKey, locale, onClear } = props
    if (!tabs?.length) {
      return null
    }

    const panes = tabs.map(tab => {
      const tabList = tab.list || []
      const totalNotificationCount = tab.totalListCount || 0
      const title =
        totalNotificationCount > 0
          ? `${tab.title} (${totalNotificationCount})`
          : tab.title

      return (
        <TabPane tab={title} key={tab.key}>
          <NoticeList
            {...tab}
            data={tabList}
            onClick={item => onItemClick(item, tab)}
            onClear={() => onClear?.(tab.title || '')}
            hasMore={tabList.length < totalNotificationCount}
            loadMore={tab.onLoadMore}
            title={tab.title || ''}
            locale={locale}
          />
        </TabPane>
      )
    })

    return (
      <Tabs
        activeKey={activeKey}
        onChange={handleOnTabChange}
      >
        {panes}
      </Tabs>
    )
  }

  const { className, popupAlign, popupVisible, onPopupVisibleChange, bell } = props
  const noticeButtonClass = classNames(className, 'notice-button')
  const notificationBox = getNotificationBox()
  const NoticeBellIcon = bell || <BellOutlined className={Style.bellIcon}/>
  const trigger = (
    <span className={noticeButtonClass}>
      <Badge count={count} style={{ boxShadow: 'none' }}>
        {NoticeBellIcon}
      </Badge>
    </span>
  )

  if (!notificationBox) {
    return trigger
  }
  const popOverProps: any = {}
  if ('popupVisible' in props) {
    popOverProps.visible = popupVisible
  }

  return (
    <Popover
      placement="bottomRight"
      content={notificationBox}
      popupClassName={Style.popover}
      trigger="click"
      arrowPointAtCenter
      popupAlign={popupAlign}
      onOpenChange={onPopupVisibleChange}
      {...popOverProps}
    >
      {trigger}
    </Popover>
  )
}
