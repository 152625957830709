import { IRouterOption } from 'src/router'
import { CalendarUsers } from './components'
import { EPaths } from './routes.path'

export enum ERoutes {
  CALENDAR_USERS = 'Calendar.Users'
}

export const Routes: IRouterOption[] = [
  {
    path: EPaths.CALENDAR_USERS,
    component: CalendarUsers,
    name: ERoutes.CALENDAR_USERS,
    exact: true,
    meta: {}
  }
]

export const CalendarModule = {
  ERoutes,
  Routes
}
