import { Modal } from 'antd'
import { FC, ReactNode, useState } from 'react'
import { ICampaignSubmissionModel } from 'src/interfaces'

interface IProps {
  title?: string
  metadata: ICampaignSubmissionModel['metadata']
  children?: ReactNode
}

export const ModalCampaignSubmissionMetadata: FC<IProps> = ({
  metadata,
  ...props
}) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <span onClick={() => setOpen(true)}>
        {props.children || 'Add'}
      </span>

      {open && (
        <Modal
          open
          centered
          closable
          maskClosable
          destroyOnClose
          title={props.title || 'Submission metadata'}
          onOk={() => setOpen(false)}
          onCancel={() => setOpen(false)}
          afterClose={() => setOpen(false)}
        >
          {!!metadata && Object.entries(metadata).map(([key, value]) => (
            <div key={key} className="fx fx-jc-space-between gap-2">
              <strong>{key}</strong>
              <span className="text-right">{value}</span>
            </div>
          ))}
        </Modal>
      )}
    </>
  )
}
