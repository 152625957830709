import { IRouterOption } from 'src/router'
import { ContactForms } from './components/list'
import { EPaths } from './routes.path'

export enum ERoutes {
  CONTACT_FORM = 'CONTACT_FORM'
}

export const Routes: IRouterOption[] = [
  {
    path: EPaths.CONTACT_FORM,
    component: ContactForms,
    name: ERoutes.CONTACT_FORM,
    exact: true,
    meta: {}
  }
]

export const ContactFormModule = {
  ERoutes,
  Routes
}
