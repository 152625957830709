import { Input, Pagination, Table } from 'antd'
import { AxiosError } from 'axios'
import clsx from 'clsx'
import { omit } from 'lodash'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import { EMPTY, catchError, from, takeUntil } from 'rxjs'
import { CampaignApi } from 'src/api'
import { IPaginateCallback, IPaginateParams, useDidMountDebounce, usePaginateParams, useUnsubscribe } from 'src/hooks'
import { ICampaignModel, IPaginationResponse } from 'src/interfaces'
import { BreadcrumbService, PaginationService } from 'src/services'
import { GlobalAction, GlobalState, useStore } from 'src/store'
import { NotifyUtils } from 'src/utils'
import { EPaths } from '../../routes.path'
import { renderColumns } from './columns'

const PAGE_TITLE = 'HiringManagers'

export const Campaigns: FC = () => {
  const unsubscribe$ = useUnsubscribe()
  const { id } = useParams<{ id: string }>()

  const { value: { loading } } = useStore(GlobalState)
  const _paginationService = useMemo(() => new PaginationService<ICampaignModel>(CampaignApi), [])
  const [dataSource, setDataSource] = useState<IPaginationResponse<ICampaignModel>>({
    total: 1,
    rows: []
  })

  const fetch = useCallback<IPaginateCallback>(
    (params) => _paginationService.paging({
      ...params,
      order: 'id-DESC',
      authorId: id
    }),
    [_paginationService, id]
  )

  const { pagination, pushPagination, setPagination } = usePaginateParams(fetch)
  const [filter, setFilter] = useState<Omit<IPaginateParams, 'page' | 'limit' | 'offset'>>(
    omit(pagination, ['page', 'limit', 'offset'])
  )

  useDidMountDebounce(() => {
    setPagination({ ...pagination, page: 1 })
    return pushPagination({
      ...pagination,
      page: 1,
      ...filter
    })
  }, 2000, [filter])

  useEffect(() => {
    _paginationService
      .loading$
      .pipe(takeUntil(unsubscribe$))
      .subscribe((value) => GlobalAction.setLoading(value))
    _paginationService
      .pagination$
      .pipe(takeUntil(unsubscribe$))
      .subscribe((data) => setDataSource(data))
  }, [_paginationService, unsubscribe$])

  /**
   * breadcrumb
   */
  useEffect(() => {
    BreadcrumbService.items = [{
      route: EPaths.HIRING_MANAGER_LIST,
      label: PAGE_TITLE
    },
    {
      route: [EPaths.CAMPAIGN, { id }],
      label: 'Campaigns'
    }
    ]
    return () => {
      BreadcrumbService.items = []
    }
  }, [id])

  const updateWarning = (campaignId: number, showWarning: boolean) => {
    setDataSource((prev) => ({
      ...prev,
      rows: prev.rows?.map((campaign) => {
        if (campaign.id === campaignId) {
          return {
            ...campaign,
            showWarning
          }
        }
        return campaign
      })
    }))
  }

  const handleChangeWarning = (campaign: ICampaignModel) => {
    const currentWarning = campaign.showWarning
    updateWarning(campaign.id, !currentWarning)
    from(CampaignApi.setWarning(campaign.id, !currentWarning))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error: AxiosError) => {
          updateWarning(campaign.id, currentWarning)
          NotifyUtils.handleAxiosError(error)
          return EMPTY
        })
      )
      .subscribe()
  }

  return (
    <div className={clsx('fx fx-column gap-2')}>
      <strong>Campaigns</strong>
      <section className="fx fx-column fx-extend">
        <div className="fx fx-jc-space-between mb-2">
          <div className="fx fx-extend gap-2">
            <Input
              allowClear
              placeholder="Search by content..."
              style={{ flex: '0 0 30vw' }}
              readOnly={loading}
              value={filter.keyword as string}
              onChange={(e) => {
                e.persist()
                setFilter((prev) => ({
                  ...prev,
                  keyword: e.target.value
                }))
              }}
            />
          </div>
        </div>

        <Table
          className="fx-extend"
          rowKey="id"
          loading={loading}
          columns={renderColumns({
            onChangeWarning: handleChangeWarning
          })}
          dataSource={dataSource.rows}
          pagination={false}
        />

        <Pagination
          className="fx-as-end mt-3"
          disabled={loading}
          total={dataSource.total}
          current={pagination.page}
          pageSize={pagination.limit}
          {
            ...{
              ...pagination,
              onChange(page, limit) {
                pushPagination({
                  ...pagination,
                  page,
                  limit
                })
              }
            }
          }
        />
      </section>
    </div>
  )
}
