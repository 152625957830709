import { Space } from 'antd'
import { isEmpty } from 'lodash'
import { Link } from 'react-router-dom'
import { ModalEditYoutubeChannel } from 'src/components'
import { ICompanyModel, IUserModel } from 'src/interfaces'
import { generate } from 'src/router'
import { TimeZoneService } from 'src/services'
import { EPaths } from '../../routes.path'

export const renderCompanyColumns = ({
  onUpdated
}: {
  onUpdated?: (record: ICompanyModel) => void
} = {}) => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Domain',
    dataIndex: 'domain',
    key: 'domain'
  },
  {
    title: 'Url',
    dataIndex: 'urls',
    key: 'urls',
    render: (val: ICompanyModel['urls']) => !isEmpty(val)
      ? val?.map((item, index) => <span key={index} title={item} className="twoline-text" style={{ maxWidth: '300px' }}>{item}</span>)
      : ''
  },
  {
    title: 'EmployeesFrom',
    dataIndex: 'employeesFrom',
    key: 'employeesFrom'
  },
  {
    title: 'EmployeesTo',
    dataIndex: 'employeesTo',
    key: 'employeesTo'
  },
  {
    title: 'Youtube Channel ID',
    dataIndex: 'youtubeChannelId',
    key: 'youtubeChannelId'
  },
  {
    title: 'CreatedAt',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (val: IUserModel['createdAt']) => TimeZoneService.format(val)
  },
  {
    title: 'Action',
    key: 'action',
    render: (_: string, record: IUserModel) => (
      <Space
        wrap
        size="small"
        direction="vertical"
      >
        <ModalEditYoutubeChannel
          id={record.id}
          afterClose={(saved: any, record) => saved && record && onUpdated?.(record)}
        >
          Youtube channel
        </ModalEditYoutubeChannel>

        <Link to={generate([EPaths.COMPANY_DASHBOARD, { id: record.id }])}>
          Dashboard
        </Link>
      </Space>
    )
  }
]
