import axios from 'axios'
import { ICampaignModel, IPaginationQuery, TAxiosResponseData, TAxiosResponsePagination } from 'src/interfaces'

export class CampaignApi {
  static readonly _prefix = '/campaigns'

  static index(params: IPaginationQuery = {}): TAxiosResponsePagination<ICampaignModel> {
    return axios.get(`${CampaignApi._prefix}`, { params })
  }

  static get(id: number): TAxiosResponseData<ICampaignModel> {
    return axios.get(`${CampaignApi._prefix}/${id}`)
  }

  static setWarning(id: number, showWarning: boolean): TAxiosResponseData<void> {
    return axios.post(`${CampaignApi._prefix}/${id}/warning`, { showWarning })
  }

  static destroy(id: number): TAxiosResponseData<void> {
    return axios.delete(`${CampaignApi._prefix}/${id}`)
  }

  static update(id: number, payload: Partial<ICampaignModel>): TAxiosResponseData<void> {
    return axios.patch(`${CampaignApi._prefix}/${id}`, payload)
  }

  static addSubmission(id: number, payload: { videoId: number}): TAxiosResponseData<void> {
    return axios.post(`${CampaignApi._prefix}/${id}/submissions`, payload)
  }
}
