import { IRouterOption } from 'src/router'
import { Forbidden } from './components/403'
import { NotFound } from './components/404'
import { InternalServerError } from './components/500'
import { Dashboard } from './components/dashboard'
import { Home } from './components/home'
import { EPaths } from './routes.path'

export enum ERoutes {
  HOME = 'Core.home',
  ERROR_403 = 'Core.403',
  ERROR_404 = 'Core.404',
  ERROR_500 = 'Core.500',
  DASHBOARD = 'Core.dashboard'
}

export const Routes: IRouterOption[] = [
  {
    path: EPaths.HOME,
    exact: true,
    name: ERoutes.HOME,
    component: Home
  },
  {
    path: EPaths.ERROR_403,
    exact: true,
    name: ERoutes.ERROR_403,
    component: Forbidden
  },
  {
    path: EPaths.ERROR_404,
    exact: true,
    name: ERoutes.ERROR_404,
    component: NotFound
  },
  {
    path: EPaths.ERROR_500,
    exact: true,
    name: ERoutes.ERROR_500,
    component: InternalServerError
  },
  {
    path: EPaths.DASHBOARD,
    exact: true,
    name: ERoutes.DASHBOARD,
    component: Dashboard
  }
]

export const CoreModule = {
  ERoutes,
  Routes
}
