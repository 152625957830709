import { IRouterOption } from 'src/router'
import { ScrapedCompanies } from './components/companies'
import { ScrapedContacts } from './components/contacts/ScrapedContacts'
import { ScrapedSheets } from './components/scraped-sheets'
import { EPaths } from './routes.path'

export enum ERoutes {
  SCRAPED_CONTACTS = 'SCRAPED_CONTACTS',
  SCRAPED_COMPANIES = 'SCRAPED_COMPANIES',
  SCRAPED_SHEETS = 'SCRAPED_SHEETS'
}

export const Routes: IRouterOption[] = [
  {
    path: EPaths.SCRAPED_CONTACTS,
    component: ScrapedContacts,
    name: ERoutes.SCRAPED_CONTACTS,
    exact: true,
    meta: {}
  },
  {
    path: EPaths.SCRAPED_COMPANIES,
    component: ScrapedCompanies,
    name: ERoutes.SCRAPED_COMPANIES,
    exact: true,
    meta: {}
  },
  {
    path: EPaths.SCRAPED_SHEETS,
    component: ScrapedSheets,
    name: ERoutes.SCRAPED_SHEETS,
    exact: true,
    meta: {}
  }
]

export const ScrapedContactModule = {
  ERoutes,
  Routes
}
