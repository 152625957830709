import { CheckOutlined, EditOutlined } from '@ant-design/icons'
import { Input } from 'antd'
import clsx from 'clsx'
import { isEmpty } from 'lodash'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { CampaignApi } from 'src/api'
import { ErrorMessage } from 'src/components'
import { useUnsubscribe, useValidation } from 'src/hooks'
import { ICampaignModel } from 'src/interfaces'
import { GlobalAction, GlobalState, useStore } from 'src/store'
import { NotifyUtils } from 'src/utils'
import { object, string } from 'yup'
import { CampaignVideo } from './videos'

const schema = object().shape({
  hmQuestion: string().typeError('Hiring Manager Question invalid').required('Hiring Manager Question is required')
})

interface IProps {
  campaign?: ICampaignModel
  className?: string
}

export const InfoDetail: FC<IProps> = ({ campaign, className }) => {
  const unsubscribe$ = useUnsubscribe()
  const { value: { loading } } = useStore(GlobalState)
  const [hmQuestion, setHMQuestion] = useState<string>()
  const [isEdit, setIsEdit] = useState(false)

  useEffect(() => {
    setHMQuestion(campaign?.hmQuestion)
  }, [campaign])

  const { validate, errors, reset } = useValidation({
    data: useMemo(() => ({ hmQuestion }), [hmQuestion]),
    schema
  })

  const toggleEditQuestion = useCallback(() => {
    setHMQuestion(campaign?.hmQuestion)
    setIsEdit(prev => !prev)
    reset()
  }, [campaign, reset])

  const submit = useCallback(async () => {
    if (hmQuestion === campaign?.hmQuestion) {
      return toggleEditQuestion()
    }

    const { isValid } = await validate()
    if (!isValid || !campaign?.id) {
      return
    }

    GlobalAction.setLoading(true)
    from(CampaignApi.update(campaign.id, { hmQuestion }))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          NotifyUtils.handleAxiosError(error)
          return EMPTY
        }),
        finalize(() => GlobalAction.setLoading(false))
      )
      .subscribe(() => {
        campaign.hmQuestion = hmQuestion
        NotifyUtils.success({ message: 'Update successfully' })
        toggleEditQuestion()
      })
  }, [campaign, hmQuestion, toggleEditQuestion, unsubscribe$, validate])

  return (
    <div className={clsx('fx-1 fx fx-column', className)}>
      <h2><strong>Job Detail</strong></h2>

      <div className="fx gap-2 fx-ai-center">
        <strong>Job Name:</strong>
        <span>{campaign?.jobTitle}</span>
      </div>

      <div className="fx gap-2 fx-ai-center">
        <strong>Job Title:</strong>
        <span>{campaign?.jobTitle}</span>
      </div>

      <div className="fx gap-2 fx-ai-center">
        <strong>Salary:</strong>
        {campaign?.salaryValue && (
          <span>${campaign?.salaryValue?.min} - ${campaign?.salaryValue?.max} ({campaign?.salaryRate})</span>
        )}
      </div>

      <div className="fx gap-2 fx-ai-center">
        <strong>Location:</strong>
        <span>{campaign?.location}</span>
      </div>

      <div className="fx gap-2 fx-ai-center">
        <strong>Allow Remote Workers:</strong>
        <span>{campaign?.allowRemote ? 'Yes' : 'No'}</span>
      </div>

      <div className="fx gap-2 fx-ai-center">
        <strong>Require Resume:</strong>
        <span>{campaign?.requiredResume ? 'Yes' : 'No'}</span>
      </div>

      <div className="fx gap-2 fx-ai-center">
        <strong>Require LinkedIn Profile:</strong>
        <span>{campaign?.requireLinkedInUrl ? 'Yes' : 'No'}</span>
      </div>

      <div className="fx gap-2 fx-ai-center">
        <strong>Zip Code of Residence:</strong>
        <span>{campaign?.requireZipCode ? 'Yes' : 'No'}</span>
      </div>

      <div className="fx gap-2 fx-ai-center">
        <strong>Share on KnowMe Marketplace:</strong>
        <span>{campaign?.shareOnMarket ? 'Yes' : 'No'}</span>
      </div>

      <div className="fx gap-2 fx-ai-center">
        <strong>Hiring Manager Question:</strong>
        {!isEdit
          ? <span>{campaign?.hmQuestion}</span>
          : (
            <div style={{ width: '50%' }}>
              <Input
                required
                name="hmQuestion"
                placeholder="Hiring Manager Question..."
                value={hmQuestion}
                status={errors.hasError('hmQuestion') ? 'error' : undefined}
                onChange={(e) => setHMQuestion(e.target.value)}
                disabled={loading}
              />
              <ErrorMessage>{errors.getError('hmQuestion')}</ErrorMessage>
            </div>
          )}
        {!isEdit
          ? !loading && <EditOutlined className="pointer" onClick={toggleEditQuestion}/>
          : !loading && <CheckOutlined className="pointer" onClick={submit}/>}
      </div>

      {!isEmpty(campaign?.jdFileUrl) && (
        <div className="fx gap-2 fx-ai-center">
          <strong>JD File:</strong>
          <span><a href={campaign?.jdFileUrl} target="_blank" rel="noreferrer">{campaign?.jdFileUrl?.split('/').pop()}</a></span>
        </div>
      )}
      <div className="fx gap-2">
        <strong>Job Description:</strong>
        {campaign?.briefDescription && (
          <span dangerouslySetInnerHTML={{ __html: campaign?.briefDescription }}/>
        )}
      </div>

      {campaign?.uploadVideo && (
        <>
          <strong>Video</strong>
          <CampaignVideo campaign={campaign}/>
        </>
      )}
    </div>
  )
}
