import { LoadingOutlined } from '@ant-design/icons'
import clsx from 'clsx'
import { FC, HTMLAttributes, useCallback, useEffect, useId, useMemo, useState } from 'react'
import { EMPTY, catchError, finalize, from, switchMap, takeUntil } from 'rxjs'
import { MessageApi, UploadApi } from 'src/api'
import { ChatEditor } from 'src/components'
import { useAsRef, useBehaviorMapper, useUnsubscribe } from 'src/hooks'
import { IMessageModel } from 'src/interfaces'
import { LoadMoreService, MessengerService } from 'src/services'
import { NotifyUtils } from 'src/utils'
import { Editor } from 'tinymce'
import { Header } from './header'
import { Messages } from './messages'
import Style from './style.module.scss'

export const MessagesBox: FC<HTMLAttributes<HTMLDivElement>> = (props) => {
  const unsubscribe$ = useUnsubscribe()

  const _loadMoreService = useMemo(() => new LoadMoreService<IMessageModel>(MessageApi), [])
  const messages = useBehaviorMapper(_loadMoreService.items$)

  const conversation = useBehaviorMapper(MessengerService.conversation$)
  const loadMore = useCallback(() => {
    if (conversation?.id && _loadMoreService.hasMore) {
      return _loadMoreService.loadMore({
        conversationId: conversation.id,
        limit: 10
      })
    }
  }, [_loadMoreService, conversation])

  useEffect(() => {
    if (!conversation?.id) {
      return
    }

    _loadMoreService.reset()
    loadMore()
  }, [_loadMoreService, conversation?.id, loadMore])

  useEffect(() => {
    if (!conversation?.id || !messages[0] || conversation.id !== messages[0]?.conversationId) {
      return
    }

    const lastMessage = messages[0]
    if (
      lastMessage.updatedAt &&
      conversation.lastMessage?.updatedAt &&
      new Date(conversation.lastMessage.updatedAt).getTime() > new Date(lastMessage.updatedAt).getTime()
    ) {
      from(MessageApi.paginate({ conversationId: conversation.id }))
        .pipe(
          takeUntil(unsubscribe$),
          catchError((error) => {
            NotifyUtils.handleAxiosError(error)
            return EMPTY
          })
        )
        .subscribe(({ data }) => {
          if (data.rows?.length) {
            _loadMoreService.addFirst(data.rows)
            MessengerService.read(conversation)
          }
        })
    }
  }, [_loadMoreService, conversation, messages, unsubscribe$])

  const editorId = useId()
  const [editor, setEditor] = useState<Editor>()
  useEffect(() => {
    if (!editor) {
      return
    }
    editor.setContent('', { format: 'text' })
  }, [editor, conversation?.id])

  const [loading, setLoading] = useState(false)

  const [attachments, setAttachments] = useState<Array<{
    file: File
    conversationId: number
    uploading?: boolean
  }>>([])
  const onChooseFiles = useCallback((files: FileList, conversationId: number) => {
    setAttachments((prev) => [
      ...prev,
      ...Array.from(files).map((file) => ({ file, conversationId }))
    ])
  }, [])
  // const onRemoveAttachment = useCallback((index: number) => {
  //   setAttachments((prev) => prev.filter((_, i) => i !== index))
  // }, [])
  const uploadAttachments = useCallback((_attachments: typeof attachments) => {
    if (!_attachments[0] || _attachments[0]?.uploading) {
      return
    }
    const attachment = _attachments[0]
    const promise = UploadApi.upload({
      entity: 'Message',
      file: attachment.file
    })
    attachment.uploading = true
    const contentType = attachment.file.type
    from(promise)
      .pipe(
        takeUntil(unsubscribe$),
        switchMap((url) => {
          return MessengerService.sendMessage({ contentType, content: url })
            .pipe(takeUntil(unsubscribe$))
        }),
        catchError((error) => {
          NotifyUtils.handleAxiosError(error)
          return EMPTY
        }),
        finalize(() => setAttachments((prev) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const [_, ...keep] = prev
          return keep
        }))
      )
      .subscribe(({ data }) => _loadMoreService.addFirst(data))
  }, [_loadMoreService, unsubscribe$])
  useEffect(() => {
    uploadAttachments(attachments)
  }, [attachments, uploadAttachments])

  const onSubmit = useCallback((content: string, editor: Editor) => {
    if (!conversation) {
      return
    }

    setLoading(true)
    MessengerService.sendMessage({ content })
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          NotifyUtils.handleAxiosError(error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(({ data }) => {
        _loadMoreService.addFirst(data)
        editor.setContent('', { format: 'text' })
      })
  }, [_loadMoreService, conversation, unsubscribe$])

  const onSubmitRef = useAsRef(onSubmit)

  if (!conversation) {
    return (
      <div {...props} className={clsx(props.className, 'fx-1 fx fx-column p-1', Style.messagesBox)}>
        <h3 className="px-2 py-1">Select a conversation</h3>
      </div>
    )
  }

  return (
    <div {...props} className={clsx(props.className, 'fx-1 fx fx-column p-1', Style.messagesBox)}>
      <Header conversation={conversation}/>

      <Messages
        messages={messages}
        hasMore={_loadMoreService.hasMore}
        next={() => loadMore()}
      />

      {/* <Attachments
        attachments={attachments}
        onRemoveAttachment={onRemoveAttachment}
      /> */}

      <ChatEditor
        id={editorId}
        disabled={loading}
        init={{
          auto_focus: editorId,
          // async images_upload_handler(blob, onProgress) {
          //   console.log({ blob, onProgress }, blob.blob(), blob.name(), blob.filename())
          //   await Promise.sleep(10000)
          //   // return 'https://via.placeholder.com/150'
          //   return blob.blobUri()
          // },
          paste_preprocess(editor, preProcess) {
            if (preProcess.content.startsWith('<img')) {
              preProcess.content = ''
            }
          },
          setup(editor) {
            setEditor(editor)
            editor.on('keydown', (e) => {
              if (e.key === 'Enter' && !e.shiftKey && !e.ctrlKey && !e.altKey && !e.metaKey) {
                e.preventDefault()
                e.stopPropagation()
                const content = editor.getContent({ format: 'text' })
                if (content.trim()) {
                  onSubmitRef.current(editor.getContent(), editor)
                } else {
                  editor.setContent('', { format: 'text' })
                }
              }
            })
          }
        }}
        iconSlot={!!attachments?.length && <LoadingOutlined className="spinner"/>}
        onChooseFiles={(files) => onChooseFiles(files, conversation.id)}
      />
    </div>
  )
}
