import { CloseCircleOutlined, SearchOutlined } from '@ant-design/icons'
import { Input } from 'antd'
import clsx from 'clsx'
import moment from 'moment'
import { FC, HTMLAttributes, useMemo, useState } from 'react'
import { Avatar, InfiniteScroll } from 'src/components'
import { useBehaviorMapper } from 'src/hooks'
import { IConversationModel } from 'src/interfaces'
import { ModalConversation } from 'src/partials'
import { MessengerService } from 'src/services'
import { EventUtils } from 'src/utils'
import Style from './style.module.scss'

const Conversation: FC<HTMLAttributes<HTMLDivElement> & { conversation: IConversationModel }> = ({
  conversation,
  ...props
}) => {
  const activeConversation = useBehaviorMapper(MessengerService.conversation$)
  const isActivated = activeConversation?.id === conversation.id
  const isUnread = useMemo(
    () => MessengerService.isUnread(conversation),
    [conversation]
  )
  const title = useMemo(
    () => MessengerService.getConversationTitle(conversation),
    [conversation]
  )
  const previewContent = useMemo(
    () => conversation?.lastMessage?.content?.replace(/<[^>]+>/g, ''),
    [conversation?.lastMessage?.content]
  )

  return (
    <div
      {...props}
      className={clsx(
        props.className,
        'pointer fx fx-ai-center gap-2 p-1',
        Style.conversation,
        { [Style.activate]: isActivated },
        { [Style.unread]: !isActivated && isUnread }
      )}
      onClick={() => MessengerService.setConversation(conversation)}
    >
      <Avatar
        className="p-1"
        size={50}
        src="/images/avatar.jpg"
      />
      <div
        className="fx fx-column fx-jc-center"
        style={{
          lineHeight: '16px',
          width: 'calc(100% - 58px)'
        }}
      >
        <div
          className="oneline-text"
          title={title}
        >
          {title}
        </div>
        {conversation.lastMessage && (
          <div className="fx paragraph-4" style={{ color: '#676767' }}>
            {conversation.lastMessage.conversationUser?.alias}:&nbsp;
            <span
              className="oneline-text"
              style={{ maxWidth: '100px' }}
              title={previewContent}
            >
              {previewContent}
            </span>&nbsp;·&nbsp;
            {moment(conversation.lastMessage.createdAt).fromNow()}
          </div>
        )}
      </div>
    </div>
  )
}

export const Conversations: FC<HTMLAttributes<HTMLDivElement>> = (props) => {
  const _loadMoreConversations = MessengerService._loadMoreConversations
  const conversations = useBehaviorMapper(MessengerService.conversations$)

  const [keyword, setKeyword] = useState('')
  const filteredConversations = useMemo(() => {
    if (!keyword) {
      return conversations
    }
    return conversations.filter(
      (item) => item.title.toLowerCase().includes(keyword.toLowerCase())
    )
  }, [conversations, keyword])

  return (
    <div {...props} className={clsx(props.className, 'p-1 fx fx-column gap-1', Style.conversations)}>
      <div className="fx gap-1">
        <form onSubmit={e => EventUtils.preventDefault(e)} className="fx-1 fx fx-column">
          <Input
            required
            name="keyword"
            placeholder="Search conversation..."
            value={keyword || ''}
            onChange={(e) => setKeyword(e.target.value)}
            prefix={<SearchOutlined/>}
            suffix={keyword ? <CloseCircleOutlined className="pointer" onClick={() => setKeyword('')}/> : null}
          />
        </form>

        <ModalConversation
          afterClose={(saved, conversation) => {
            if (saved) {
              MessengerService.setConversation(conversation)
            }
          }}
        />
      </div>

      <div className="fx-1 fx fx-column gap-1" style={{ overflow: 'auto' }}>
        <InfiniteScroll
          className="fx-1 fx fx-column gap-1"
          loader={null}
          scrollThreshold={0.95} // 95% scrollHeight
          dataLength={conversations.length}
          hasMore={_loadMoreConversations.hasMore}
          next={() => _loadMoreConversations.loadMore({ limit: 10 })}
        >
          {filteredConversations.map((item) => (
            <Conversation
              key={item.id + (item.updatedAt || '')}
              conversation={item}
            />
          ))}
        </InfiniteScroll>
      </div>
    </div>
  )
}
