import { EScrapedJobProvider, IScrapedJobModel } from 'src/interfaces'

export class ScrapedJobUtils {
  static getJobLink(job: IScrapedJobModel) {
    if (job.jobUrl) {
      return job.jobUrl
    }

    switch (job.provider) {
      case EScrapedJobProvider.LINKEDIN:
        return `https://www.linkedin.com/jobs/view/${job.jobId}`
      case EScrapedJobProvider.INDEED:
        return `https://www.indeed.com/viewjob?jk=${job.jobId}`
      case EScrapedJobProvider.GLASSDOOR:
        return `https://www.glassdoor.com/job-listing/${job.jobId}`
      case EScrapedJobProvider.ZIPRECRUITER:
        return `https://www.ziprecruiter.com/jobs/${job.jobId}`
      default:
        return '#'
    }
  }
}
