import { memo } from 'react'
import clsx, { ClassValue } from 'clsx'

export const ErrorMessage = memo(
  ({ message, children, className }: {
    message?: string
    children?: React.ReactNode
    className?: ClassValue
  }) => {
    const displayMessage = message || children
    return (
      <div className={clsx('__error-message', displayMessage ? 'show' : 'hide', className)}>
        {displayMessage}
      </div>
    )
  }
)
