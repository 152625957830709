import { Modal } from 'antd'
import { AxiosError } from 'axios'
import clsx from 'clsx'
import { FC, ReactNode, useEffect, useMemo, useState } from 'react'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { VideoApi } from 'src/api'
import { useUnsubscribeEffect } from 'src/hooks'
import { IVideoModel } from 'src/interfaces'
import { NotifyUtils } from 'src/utils'
import { Loading } from '../loading'

interface IProps {
  className?: string
  video: IVideoModel
  onOk?: (video: IVideoModel) => any
  children?: ReactNode
}

export const ModalIVideoTranscription: FC<IProps> = (props) => {
  const video = useMemo(() => props.video, [props.video])

  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [transcription, setTranscription] = useState(video.videoTranscription)

  useEffect(() => {
    if (video.videoTranscription) {
      setTranscription(video.videoTranscription)
    }
  }, [video])

  useUnsubscribeEffect((unsubscribe$) => {
    if (open && !transcription && video.id) {
      setLoading(true)
      from(VideoApi.geTranscription(video.id))
        .pipe(
          takeUntil(unsubscribe$),
          catchError((error: AxiosError) => {
            NotifyUtils.handleAxiosError(error)
            return EMPTY
          }),
          finalize(() => setLoading(false))
        )
        .subscribe(({ data: videoTranscription }) => {
          video.videoTranscription = videoTranscription
          setTranscription(videoTranscription)
          props.onOk?.(video)
        })
    }
  }, [props, open, video, transcription])

  return (
    <>
      <span
        className={props.className}
        onClick={() => setOpen(true)}
      >
        {props.children || 'Open'}
      </span>

      {open && (
        <Modal
          open
          centered
          closable={!loading}
          maskClosable
          destroyOnClose
          title="Video Transcription"
          okButtonProps={{ disabled: loading }}
          cancelButtonProps={{ disabled: loading }}
          onOk={() => setOpen(false)}
          onCancel={() => setOpen(false)}
          afterClose={() => setOpen(false)}
        >
          <Loading show={loading}/>

          <div className={clsx('fx fx-column gap-2', { 'd-none': loading })}>
            <div>
              <strong>Transcription</strong>
              <pre className="text-justify">{transcription?.content || 'No transcription'}</pre>
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}
