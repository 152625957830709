import { Button, Pagination, Table } from 'antd'
import { FC, useCallback, useMemo, useState } from 'react'
import { ScrapedSheetApi } from 'src/api'
import { IPaginateCallback, useBehaviorMapper, usePaginateParams } from 'src/hooks'
import { IScrapedSheetModel } from 'src/interfaces'
import { PaginationService } from 'src/services'
import { renderColumns } from './column'

export const ScrapedSheets: FC = () => {
  const _paginationService = useMemo(() => new PaginationService<IScrapedSheetModel>(ScrapedSheetApi), [])
  const loading = useBehaviorMapper(_paginationService.loading$)
  const dataSource = useBehaviorMapper(_paginationService.pagination$)
  const [clickedStartUp, setClickedStartup] = useState(false)
  const [clickedConnection, setClickedConnection] = useState(false)

  const fetchData = useCallback<IPaginateCallback>(
    (params) => _paginationService.paging(params),
    [_paginationService]
  )

  const { pagination, pushPagination } = usePaginateParams(fetchData)

  const handleClickConnection = useCallback(() => {
    setClickedConnection(true)
    ScrapedSheetApi.linkedInConnection().then(() => {
      pushPagination({ ...pagination, page: 1 })
    })
  }, [pagination, pushPagination])

  const handleClickStartUp = useCallback(() => {
    setClickedStartup(true)
    ScrapedSheetApi.startupCompany().then(() => {
      pushPagination({ ...pagination, page: 1 })
    })
  }, [pagination, pushPagination])

  return (
    <div className="fx fx-column gap-4">
      <div className="fx fx-row gap-2">
        <Button disabled={clickedStartUp} onClick={handleClickStartUp} type="primary">Sync With Start Up Company Sheet</Button>
        <Button disabled={clickedConnection} onClick={handleClickConnection} type="primary">Sync With Connection Sheet</Button>
      </div>
      <div className="relative fx fx-column gap-4">
        <Table
          className="fx-extend"
          rowKey="id"
          loading={loading}
          columns={renderColumns()}
          dataSource={dataSource.rows}
          pagination={false}
          scroll={{ x: 'max-content' }}
        />

        <Pagination
          className="fx-as-end"
          disabled={loading}
          showQuickJumper
          total={dataSource.total}
          current={pagination.page}
          pageSize={pagination.limit}
          {
            ...{
              ...pagination,
              onChange(page, limit) {
                pushPagination({
                  ...pagination,
                  page,
                  limit
                })
              }
            }
          }
        />
      </div>
    </div>
  )
}
