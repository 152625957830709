import qs from 'qs'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Helpers } from 'src/utils'

export const useQueryParams = () => {
  const history = useHistory()
  const [queryParams, setQueryParams] = useState(
    qs.parse(history.location?.search.replace(/^\?+/, ''))
  )

  useEffect(() => {
    const params = qs.parse(history.location?.search?.replace(/^\?+/, ''))
    if (!Helpers.compareObj(queryParams, params)) {
      setQueryParams(params)
    }
  }, [history.location?.search, queryParams])

  return queryParams
}
