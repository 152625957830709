import { CloseCircleOutlined } from '@ant-design/icons'
import clsx from 'clsx'
import { ClassAttributes, ComponentPropsWithoutRef, HTMLAttributes, InputHTMLAttributes, forwardRef, useCallback, useMemo, useRef } from 'react'
import { NotifyUtils, StyleUtils } from 'src/utils'
import Style from './style.module.scss'

function generateHexColor(chars: string): string {
  const vibrantColors = [
    '#4285F4', // Blue
    '#34A853', // Green
    '#FBBC05', // Yellow
    '#EA4335', // Red
    '#7FBB00', // Lime
    '#FF4081', // Pink
    '#0F9D58', // Teal
    '#AB47BC' // Purple
  ]

  // Convert each character to its corresponding ASCII code
  const char1Code = chars.charCodeAt(0) || 0
  const char2Code = chars.charCodeAt(1) || 0
  const colorIndex = (char1Code + char2Code) % vibrantColors.length

  return vibrantColors[colorIndex]
}

interface IProps {
  name?: string
  src?: string
  size?: number
  disabled?: boolean
  onChange?: (file: File) => void
}

const _Avatar = ({
  src,
  name,
  size = 80,
  onChange,
  ...props
}: IProps & Omit<HTMLAttributes<HTMLDivElement>, keyof IProps>, ref?: ClassAttributes<HTMLDivElement>['ref']) => {
  const chars = useMemo(() => name?.slice(0, 2) || '', [name])
  const color = useMemo(() => generateHexColor(chars), [chars])
  const inputRef = useRef<HTMLInputElement>(null)

  const onChangeWrapped = useCallback<Required<InputHTMLAttributes<HTMLInputElement>>['onChange']>((e) => {
    const file = e.target.files?.[0]
    if (!file || !file?.type || !/^image\//.test(file.type)) {
      return NotifyUtils.error({ message: 'Invalid file type' })
    }
    onChange?.(file)
    e.target.value = ''
  }, [onChange])

  return (
    <>
      {onChange && (
        <input
          ref={inputRef}
          type="file"
          accept="image/*"
          style={{ display: 'none' }}
          onChange={onChangeWrapped}
        />
      )}

      <div
        {...props}
        ref={ref}
        className={clsx(props.className, Style.avtContainer)}
        style={{
          ...props.style,
          width: `${size}px`,
          height: `${size}px`,
          // borderRadius: `${size * 6.5}px`,
          // backgroundColor: '#DADBE7',
          // backgroundSize: 'cover',
          backgroundImage: StyleUtils.backgroundImage(src)
        }}
      >
        {!src && (
          <span
            className={clsx(Style.txt, 'noselect')}
            style={{
              color,
              fontSize: `${size * 0.4}px`
              // fontWeight: 700,
              // textTransform: 'uppercase',
              // position: 'absolute',
              // top: '50%',
              // left: '50%',
              // transform: 'translate(-50%, -50%)'
            }}
          >
            {chars}
          </span>
        )}

        {props.children}

        {onChange && (
          <div className={clsx(Style.iconChange, 'fx fx-center')} onClick={() => inputRef?.current?.click()}>
            <CloseCircleOutlined/>
          </div>
        )}
      </div>
    </>
  )
}

export const Avatar = forwardRef<HTMLDivElement, ComponentPropsWithoutRef<typeof _Avatar>>(_Avatar)
