import { LoadingOutlined } from '@ant-design/icons'
import { TableProps, Tooltip } from 'antd'
import moment from 'moment'
import { IScrapedSheetModel } from 'src/interfaces'

export const renderColumns = (): TableProps<IScrapedSheetModel>['columns'] => {
  return [
    {
      title: 'Status',
      render: (record: IScrapedSheetModel) => (
        <span>{record.errorMessage ? '❌' : record.endAt ? '✅' : (<LoadingOutlined style={{ color: '#1967d2' }}/>)}</span>
      )
    },
    {
      title: 'Type',
      render: (record: IScrapedSheetModel) => (
        <span>{record.type}</span>
      )
    },
    {
      title: 'Run At',
      render: (record: IScrapedSheetModel) => (
        <span>{moment(record.runAt).format('MM-DD-YYYY hh:mm:ss')}</span>
      )
    },
    {
      title: 'End At',
      render: (record: IScrapedSheetModel) => (
        <span>{record.endAt ? moment(record.endAt).format('MM-DD-YYYY hh:mm:ss') : ''}</span>
      )
    },
    {
      title: 'Run By',
      render: (record: IScrapedSheetModel) => (
        <span>{record.author?.email}</span>
      )
    },
    {
      title: 'Error Message',
      render: (record: IScrapedSheetModel) => (
        <Tooltip title={record.errorMessage}>
          <span>{record.errorMessage?.slice(0, 10)}</span>
        </Tooltip>
      )
    }
  ]
}
