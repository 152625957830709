import { BehaviorSubject } from 'rxjs'

export class ChatGPTAnalyzeService<T extends { id: number }> {
  private readonly _items$ = new BehaviorSubject<T[]>([])
  get items$() {
    return this._items$
  }

  constructor(items = []) {
    this._items$.next(items)
  }

  add(item: T) {
    const items = this._items$.getValue()
    if (items.find((j) => j.id === item.id)) {
      return this
    }

    items.push(item)
    this._items$.next([...items])
    return this
  }

  remove(item: T) {
    const items = this._items$.getValue()
    const index = items.findIndex((j) => j.id === item.id)
    if (index === -1) {
      return this
    }

    items.splice(index, 1)
    this._items$.next([...items])
    return this
  }

  toggle(item: T) {
    const items = this._items$.getValue()
    const index = items.findIndex((j) => j.id === item.id)
    if (index === -1) {
      items.push(item)
    } else {
      items.splice(index, 1)
    }

    this._items$.next([...items])
    return this
  }
}
