import axios from 'axios'
import { IPaginationQuery, IPromoCodeModel, IPromoCodeUsedModel, TAxiosResponseData, TAxiosResponsePagination } from 'src/interfaces'

type TPromoPayload = Pick<
  IPromoCodeModel,
  'code' |
  'validFrom' |
  'validTo' |
  'validFor' |
  'limit' |
  'limitPerUser' |
  'introCredit'
>

export class PromoCodeApi {
  static readonly _prefix = '/promo-codes'

  static index(params: IPaginationQuery = {}): TAxiosResponsePagination<IPromoCodeModel> {
    return axios.get(`${PromoCodeApi._prefix}`, { params })
  }

  static validate(code: IPromoCodeModel['code']): TAxiosResponseData<void> {
    return axios.get(`${PromoCodeApi._prefix}/validate`, { params: { code } })
  }

  static store(payload: TPromoPayload): TAxiosResponseData<IPromoCodeModel> {
    return axios.post(`${PromoCodeApi._prefix}`, payload)
  }

  static show(id: number): TAxiosResponseData<IPromoCodeModel> {
    return axios.get(`${PromoCodeApi._prefix}/${id}`)
  }

  static update(id: number, payload: TPromoPayload): TAxiosResponseData<IPromoCodeModel> {
    return axios.put(`${PromoCodeApi._prefix}/${id}`, payload)
  }

  static destroy(id: number): TAxiosResponseData<void> {
    return axios.delete(`${PromoCodeApi._prefix}/${id}`)
  }

  static used(id: number, params: IPaginationQuery = {}): TAxiosResponsePagination<IPromoCodeUsedModel> {
    return axios.get(`${PromoCodeApi._prefix}/${id}/used`, { params })
  }
}
