import { useEffect, useState } from 'react'
import { BehaviorSubject, Observer, takeUntil } from 'rxjs'
import { useDidMountEffect, useUnsubscribe } from 'src/hooks'

export const useStore = <T = any>(state: BehaviorSubject<T>) => {
  const unsubscribe$ = useUnsubscribe()
  const [value, setValue] = useState<T>(state.value)

  useEffect(() => {
    state
      .pipe(takeUntil(unsubscribe$))
      .subscribe((value: T) => setValue(value))
  }, [state, unsubscribe$])

  useDidMountEffect(() => {
    state.next(value)
  }, [value])

  return {
    value,
    setValue,
    bind: (
      ...subscribers: [((value: typeof state.value) => void)]
      | [
        ((value: typeof state.value) => void) | undefined,
        ((error: any) => void) | undefined,
        (() => void) | undefined
      ] | [Partial<Observer<typeof state.value>>]
    ) => {
      const [next, error, complete] = subscribers
      return state
        .pipe(takeUntil(unsubscribe$))
        .subscribe(typeof next === 'function' ? { next, error, complete } : next)
    }
  }
}
