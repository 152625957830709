import { BehaviorSubject } from 'rxjs'
import { OpenAIApi } from 'src/api'
import { IOpenAIModel } from 'src/interfaces/open-ai'

export const OpenAIService = new (
  class {
    readonly models$ = new BehaviorSubject<IOpenAIModel[]>([])
    get models() {
      return this.models$.getValue()
    }

    getModels() {
      if (this.models.length) {
        return Promise.resolve(this.models)
      }

      return OpenAIApi.models().then(({ data }) => {
        const models = data.models.filter((model) => model.id.includes('gpt'))
        this.models$.next(models)
        return models
      })
    }
  }
)()
