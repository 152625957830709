import 'src/polyfills'

import { createRoot } from 'react-dom/client'
import { App } from './App'
import reportWebVitals from './reportWebVitals'

import 'src/utils/axios.config'
import { EEnv } from './constants'
import { register, unregister } from './registerServiceWorker'
import './style'

createRoot(document.getElementById('root') as HTMLDivElement).render(<App/>)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

if (EEnv.NODE_ENV === 'production' && EEnv.REACT_APP_ENABLE_SW) {
  register()
} else {
  unregister()
}
