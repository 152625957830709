import clsx from 'clsx'
import { FC } from 'react'
import { Video } from 'src/components'
import { ICampaignModel } from 'src/interfaces'

interface IProps {
  campaign?: ICampaignModel
  className?: string
  isCompany?: boolean
}

export const CampaignVideo: FC<IProps> = ({ campaign, className, isCompany = false }) => {
  return (
    <div className={clsx('fx fx-column gap-2', className)}>

      {isCompany
        ? campaign?.companyVideo && (
          <Video
            key={campaign?.id}
            video={campaign.companyVideo}
            playerProps={{
              style: {
                width: 300,
                height: 200
              }
            }}
          />
        )
        : campaign?.uploadVideo && (
          <Video
            key={campaign?.id}
            video={campaign.uploadVideo}
            playerProps={{
              style: {
                width: 300,
                height: 200
              }
            }}
          />
        )}
    </div>
  )
}
