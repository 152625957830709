import MarkdownPreview from '@uiw/react-markdown-preview'
import clsx from 'clsx'
import { FC, useEffect, useMemo, useRef } from 'react'
import { EOpenAIMessageRole, IOpenAIMessage } from 'src/interfaces'
import Style from './style.module.scss'

const Message: FC<{ className: string; message: IOpenAIMessage }> = ({ className, message }) => {
  const classes = useMemo(
    () => clsx(Style.message, { [Style.ofClient]: message.role === EOpenAIMessageRole.USER }),
    [message]
  )

  return (
    <div className={clsx(className, { 'text-right': message.role === EOpenAIMessageRole.USER })}>
      {message.role === EOpenAIMessageRole.USER
        ? <pre className={classes}>{message.content}</pre>
        : <MarkdownPreview className={classes} source={message.content}/>}
    </div>
  )
}

export const ChatGPTMessages: FC<{ className: string; messages: IOpenAIMessage[] }> = (props) => {
  const ref = useRef<HTMLDivElement>(null)
  const messages = useMemo(() => props.messages, [props.messages])

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = ref.current.scrollHeight
    }
  }, [messages])

  return (
    <div
      ref={ref}
      className={clsx('fx fx-column gap-1 p-1', props.className)}
      style={{ overflow: 'auto' }}
    >
      {messages.map((item, index) => (
        <Message
          key={item.id}
          message={item}
          className={clsx({ 'mt-auto': index === 0 })}
        />
      ))}
    </div>
  )
}

// import clsx from 'clsx'
// import { FC, useEffect, useState } from 'react'
// import { takeUntil } from 'rxjs'
// import { useUnsubscribe } from 'src/hooks'
// import { IOpenAIMessage } from 'src/interfaces/open-ai'
// import { OpenAIChatService } from 'src/services'
// import VirtualScroller from 'virtual-scroller/react'

// export const Messages: FC<{ className: string }> = (props) => {
//   const unsubscribe$ = useUnsubscribe()
//   const [messages, setMessages] = useState(OpenAIChatService.messages)

//   useEffect(() => {
//     OpenAIChatService.messages$
//       .pipe(takeUntil(unsubscribe$))
//       .subscribe(setMessages)
//   }, [])

//   return (
//     <VirtualScroller
//       className={clsx('fx fx-column fx-jc-flex-end gap-1', props.className)}
//       items={messages}
//       itemComponent={(({ item }: { item: IOpenAIMessage }) => (<pre className="m-0">{item.content}</pre>))}
//     />
//   )
// }
