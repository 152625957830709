import { Layout } from 'antd'
import { FC } from 'react'
import { ToastContainer } from 'react-toastify'
import { Loading } from 'src/components'
import { useWatchTz } from 'src/hooks'
import { AuthState, GlobalState, useStore } from 'src/store'
import { LayoutHeader } from './header'
import { RouterView } from './router-view'
import { LayoutSidebar } from './sidebar'
import Style from './style.module.scss'

export const AppLayout: FC = (props) => {
  useWatchTz()

  const { value: { loading } } = useStore(GlobalState)
  const { value: { isAuthenticated } } = useStore(AuthState)

  return (
    <Layout className={Style.appLayout}>
      {isAuthenticated && <LayoutSidebar/>}

      <Layout.Content className="fx-1 fx fx-column">
        {isAuthenticated && <LayoutHeader/>}
        <RouterView/>
      </Layout.Content>

      <Loading show={loading || isAuthenticated === null}/>

      <ToastContainer/>
    </Layout>
  )
}
