import { Button, Modal } from 'antd'
import { ComponentProps, FC, useEffect, useState } from 'react'
import { useDidMountEffect } from 'src/hooks'
import { ICandidateModel } from 'src/interfaces'
import { Helpers, NotifyUtils } from 'src/utils'

export const DialogCopy: FC<Omit<
  ComponentProps<typeof Modal>,
  'onOk' | 'afterClose'
> & {
  content: string
  // onOk?: () => any
  afterClose?: () => any
  btnProps?: ComponentProps<typeof Button>
}> = ({
  btnProps,
  ...props
}) => {
  const [invisible, setInvisible] = useState<boolean | ICandidateModel>(true)

  useEffect(() => {
    setInvisible(!props.open)
  }, [props.open])

  useDidMountEffect(() => {
    if (invisible) {
      props.afterClose?.()
    }
  }, [invisible])

  return (
    <>
      {/* <Button
        type="primary"
        {...btnProps}
        onClick={() => setInvisible(false)}
      >
        {props.children || 'Copy'}
      </Button> */}

      <Modal
        title={props.title || 'Copy'}
        open={!invisible}
        // closable={false} // display X icon
        keyboard={false} // disable close on press ESC
        maskClosable={false} // disable close on click outside
        okText="Copy"
        cancelText="Close"
        onOk={() => Helpers.copy(props.content).then(() => NotifyUtils.success({ message: `Copied: ${props.content}` }))}
        onCancel={() => setInvisible(true)}
      >
        {invisible
          ? null
          : (
            <div className="fx fx-column fx-extend gap-2">
              <pre>{props.content}</pre>
            </div>
          )}
      </Modal>
    </>
  )
}
