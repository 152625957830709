import { Button, Form, Input, Modal } from 'antd'
import { ChangeEvent, ComponentProps, FC, useCallback, useState } from 'react'
import { finalize, from, takeUntil } from 'rxjs'
import { VideoApi } from 'src/api'
import { useDidMountEffect, useUnsubscribe, useValidation } from 'src/hooks'
import { ICandidateModel } from 'src/interfaces'
import { NotifyUtils } from 'src/utils'
import * as Yup from 'yup'
import { ErrorMessage } from '../error-message'

const yupSchema = Yup.object().shape({
  count: Yup.number().integer().min(1).required('Count is required')
})

export const DialogIncreaseViews: FC<Omit<
  ComponentProps<typeof Modal>,
  'onOk' | 'afterClose'
> & {
  ids: number[]
  // onOk?: () => any
  afterClose?: () => any
  btnProps?: ComponentProps<typeof Button>
}> = ({
  btnProps,
  ...props
}) => {
  const unsubscribe$ = useUnsubscribe()
  const [loading, setLoading] = useState(false)
  const [invisible, setInvisible] = useState<boolean | ICandidateModel>(true)
  const [formData, setFormData] = useState<{ count: number }>({ count: 1 })

  const handleChangeInput = useCallback((e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
    e.persist()
    const { name, value } = e.target
    setFormData((prev) => ({
      ...prev,
      [name]: Number(value) || 0
    }))
  }, [])

  const { validate, errors, reset } = useValidation({
    data: formData,
    schema: yupSchema
  })

  const save = useCallback(async () => {
    const { isValid } = await validate()
    if (!isValid) {
      return
    }

    setLoading(true)

    const promise = VideoApi.increaseViews({
      ...formData,
      ids: props.ids
    })

    from(promise)
      .pipe(
        takeUntil(unsubscribe$),
        finalize(() => setLoading(false))
      )
      .subscribe({
        next: () => {
          NotifyUtils.success({ message: 'Notification had been sent' })
          setInvisible(true)
        },
        error: NotifyUtils.handleAxiosError
      })
  }, [props.ids, formData, validate, unsubscribe$])

  useDidMountEffect(() => {
    reset()

    if (invisible) {
      setLoading(false)
      setFormData({ count: 1 })
      props.afterClose?.()
    }
  }, [invisible])

  return (
    <>
      <Button
        type="primary"
        {...btnProps}
        onClick={() => setInvisible(false)}
      >
        {props.children || 'Create'}
      </Button>

      <Modal
        title="Increase Views"
        open={!invisible}
        closable={!loading} // display X icon
        keyboard={false} // disable close on press ESC
        maskClosable={false} // disable close on click outside
        okText="Send"
        cancelText="Cancel"
        onOk={save}
        onCancel={() => setInvisible(true)}
        okButtonProps={{ disabled: loading }}
        cancelButtonProps={{ disabled: loading }}
      >
        {invisible
          ? null
          : (
            <div className="fx fx-column fx-extend gap-2">
              <Form.Item
                required
                label="Count"
                labelAlign="left"
                labelCol={{ flex: '0 0 70px' }}
                className="m-0"
              >
                <Input
                  required
                  type="number"
                  step={1}
                  readOnly={loading}
                  name="count"
                  placeholder="Count"
                  value={formData.count}
                  status={errors.hasError('count') ? 'error' : undefined}
                  onChange={handleChangeInput}
                />
                <ErrorMessage>{errors.getError('count')}</ErrorMessage>
              </Form.Item>
            </div>
          )}
      </Modal>
    </>
  )
}
