import { SettingOutlined } from '@ant-design/icons'
import IconQuestionCircleFilled from '@ant-design/icons/QuestionCircleFilled'
import { Button, Input, Pagination, Table } from 'antd'
import { omit } from 'lodash'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { takeUntil } from 'rxjs'
import { CampaignApi } from 'src/api'
import { IPaginateCallback, IPaginateParams, useBehaviorMapper, useDidMountDebounce, usePaginateParams, useUnsubscribe } from 'src/hooks'
import { ICampaignModel, IPaginationResponse } from 'src/interfaces'
import { BreadcrumbService, PaginationService } from 'src/services'
import { EPaths } from '../../routes.path'
import { renderColumns } from './columns'
import { QuestionModal } from './questions'
import { CampaignSettingsModal } from './settings'

const PAGE_TITLE = 'Campaigns'

export const Campaigns: FC = () => {
  const unsubscribe$ = useUnsubscribe()
  const _paginationService = useMemo(() => new PaginationService<ICampaignModel>(CampaignApi), [])
  const loading = useBehaviorMapper(_paginationService.loading$)
  const [dataSource, setDataSource] = useState<IPaginationResponse<ICampaignModel>>({
    total: 1,
    rows: []
  })

  const [openQuestionModal, setOpenQuestionModal] = useState(false)
  const [openSettingModal, setOpenSettingModal] = useState(false)

  const fetch = useCallback<IPaginateCallback>(
    (params) => _paginationService.paging({
      ...params,
      order: 'id-DESC'
    }),
    [_paginationService]
  )

  const { pagination, pushPagination, setPagination } = usePaginateParams(fetch)
  const [filter, setFilter] = useState<Omit<IPaginateParams, 'page' | 'limit' | 'offset'>>(
    omit(pagination, ['page', 'limit', 'offset'])
  )

  useDidMountDebounce(() => {
    setPagination({ ...pagination, page: 1 })
    return pushPagination({
      ...pagination,
      page: 1,
      ...filter
    })
  }, 2000, [filter])

  useEffect(() => {
    _paginationService
      .pagination$
      .pipe(takeUntil(unsubscribe$))
      .subscribe((data) => setDataSource(data))
  }, [_paginationService, unsubscribe$])

  /**
   * breadcrumb
   */
  useEffect(() => {
    BreadcrumbService.items = [{
      route: EPaths.CAMPAIGN,
      label: PAGE_TITLE
    }]
    return () => {
      BreadcrumbService.items = []
    }
  }, [])

  // const handleChangeWarning = (campaign: ICampaignModel) => {
  //   const currentWarning = campaign.showWarning
  //   updateWarning(campaign.id, !currentWarning)
  //   from(CampaignApi.setWarning(campaign.id, !currentWarning))
  //     .pipe(
  //       takeUntil(unsubscribe$),
  //       catchError((error: AxiosError) => {
  //         updateWarning(campaign.id, currentWarning)
  //         NotifyUtils.handleAxiosError(error)
  //         return EMPTY
  //       })
  //     )
  //     .subscribe()
  // }

  const handleToggleQuestionModal = useCallback(() => {
    setOpenQuestionModal(prev => !prev)
  }, [])

  const handleToggleSettingModal = useCallback(() => {
    setOpenSettingModal(prev => !prev)
  }, [])

  return (
    <section className="fx fx-column fx-extend">
      <div className="fx fx-jc-space-between mb-2">
        <div className="fx fx-extend gap-2">
          <Input
            allowClear
            placeholder="Search by content..."
            style={{ flex: '0 0 20vw' }}
            readOnly={loading}
            value={filter.keyword as string}
            onChange={(e) => {
              e.persist()
              setFilter((prev) => ({
                ...prev,
                keyword: e.target.value
              }))
            }}
          />

          <Input
            allowClear
            placeholder="Search company..."
            style={{ flex: '0 0 20vw' }}
            readOnly={loading}
            value={filter.companyName as string || ''}
            onChange={(e) => setFilter((prev) => ({
              ...prev,
              companyName: e.target.value
            }))}
          />

          <Button type="primary" onClick={handleToggleQuestionModal}>
            <IconQuestionCircleFilled/>
            Questions
          </Button>

          <Button type="primary" onClick={handleToggleSettingModal}>
            <SettingOutlined/>
            Campaign Setting
          </Button>
        </div>
      </div>

      <Table
        className="fx-extend"
        rowKey="id"
        loading={loading}
        columns={renderColumns({})}
        dataSource={dataSource.rows}
        pagination={false}
      />

      <Pagination
        className="fx-as-end mt-3"
        disabled={loading}
        total={dataSource.total}
        current={pagination.page}
        pageSize={pagination.limit}
        {
          ...{
            ...pagination,
            onChange(page, limit) {
              pushPagination({
                ...pagination,
                page,
                limit
              })
            }
          }
        }
      />
      <QuestionModal
        open={openQuestionModal}
        onOk={handleToggleQuestionModal}
      />
      <CampaignSettingsModal
        open={openSettingModal}
        onOk={handleToggleSettingModal}
      />
    </section>
  )
}
