import { IRouterOption } from 'src/router'
import { CompanyDashboard } from './components/dashboard'
import { Companies } from './components/list'
import { EPaths } from './routes.path'

export enum ERoutes {
  COMPANY = 'Company.List',
  COMPANY_DASHBOARD = 'Company.Dashboard'
}

export const Routes: IRouterOption[] = [
  {
    path: EPaths.COMPANY,
    component: Companies,
    name: ERoutes.COMPANY,
    exact: true,
    meta: {}
  },
  {
    path: EPaths.COMPANY_DASHBOARD,
    component: CompanyDashboard,
    name: ERoutes.COMPANY_DASHBOARD,
    exact: true,
    meta: {}
  }
]

export const CompanyModule = {
  ERoutes,
  Routes
}
