import { Checkbox, Input, Pagination, Table } from 'antd'
import { omit } from 'lodash'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { finalize, from, takeUntil } from 'rxjs'
import { HashtagApi } from 'src/api'
import { ModalHashtag } from 'src/components'
import { ERoleType } from 'src/constants/enum'
import { IPaginateCallback, IPaginateParams, useDidMountDebounce, usePaginateParams, useUnsubscribe } from 'src/hooks'
import { IHashtagModel, IPaginationResponse } from 'src/interfaces'
import { BreadcrumbService, PaginationService } from 'src/services'
import { GlobalAction, GlobalState, useAuthorization, useStore } from 'src/store'
import { NotifyUtils } from 'src/utils'
import { EPaths } from '../../routes.path'
import { renderColumns } from './columns'

const PAGE_TITLE = 'Hashtags'

export const Hashtags: FC = () => {
  const unsubscribe$ = useUnsubscribe()
  const { is } = useAuthorization()
  const { value: { loading } } = useStore(GlobalState)
  const _paginationService = useMemo(() => new PaginationService<IHashtagModel>(HashtagApi), [])
  const [dataSource, setDataSource] = useState<IPaginationResponse<IHashtagModel>>({
    total: 1,
    rows: []
  })

  const fetch = useCallback<IPaginateCallback>(
    (params) => _paginationService.paging({
      ...params,
      order: 'id-DESC'
    }),
    [_paginationService]
  )

  const { pagination, pushPagination, setPagination } = usePaginateParams(fetch)
  const [filter, setFilter] = useState<Omit<IPaginateParams, 'page' | 'limit' | 'offset'>>({
    ...omit(pagination, ['page', 'limit', 'offset']),
    isPlaylist: pagination.isPlaylist === 'true' || undefined
  })

  const onUpdate = useCallback((record: IHashtagModel) => {
    setDataSource((prev) => ({
      ...prev,
      rows: prev.rows?.map(
        (item) => item.id === record.id
          ? { ...item, ...record }
          : item
      )
    }))
  }, [])

  const toggleIsPlaylist = useCallback((record: IHashtagModel) => {
    GlobalAction.setLoading(true)
    from(HashtagApi.toggleIsPlaylist(record.id))
      .pipe(
        takeUntil(unsubscribe$),
        finalize(() => GlobalAction.setLoading(false))
      )
      .subscribe({
        next: () => fetch(pagination),
        error: NotifyUtils.handleAxiosError
      })
  }, [fetch, pagination, unsubscribe$])

  const onDelete = useCallback((record: IHashtagModel) => {
    GlobalAction.setLoading(true)
    from(HashtagApi.destroy(record.id))
      .pipe(
        takeUntil(unsubscribe$),
        finalize(() => GlobalAction.setLoading(false))
      )
      .subscribe({
        next: () => fetch(pagination),
        error: NotifyUtils.handleAxiosError
      })
  }, [fetch, pagination, unsubscribe$])

  useDidMountDebounce(() => {
    setPagination({ ...pagination, page: 1 })
    return pushPagination({
      ...pagination,
      page: 1,
      ...filter
    })
  }, 2000, [filter])

  useEffect(() => {
    _paginationService
      .loading$
      .pipe(takeUntil(unsubscribe$))
      .subscribe((value) => GlobalAction.setLoading(value))
    _paginationService
      .pagination$
      .pipe(takeUntil(unsubscribe$))
      .subscribe((data) => setDataSource(data))
  }, [_paginationService, unsubscribe$])

  /**
   * breadcrumb
   */
  useEffect(() => {
    BreadcrumbService.items = [{
      route: EPaths.HASHTAG,
      label: PAGE_TITLE
    }]
    return () => {
      BreadcrumbService.items = []
    }
  }, [])

  return (
    <section className="fx fx-column fx-extend">
      <div className="fx fx-jc-space-between mb-2">
        <div className="fx fx-extend fx-ai-center gap-2">
          <Input
            allowClear
            placeholder="Search by tag, title..."
            style={{ flex: '0 0 30vw' }}
            readOnly={loading}
            value={filter.keyword as string}
            onChange={(e) => {
              e.persist()
              setFilter((prev) => ({
                ...prev,
                keyword: e.target.value
              }))
            }}
          />

          <Checkbox
            checked={!!filter.isPlaylist}
            onChange={(e) => setFilter((prev) => ({
              ...prev,
              isPlaylist: e.target.checked
            }))}
          >Only playlist
          </Checkbox>
        </div>

        <ModalHashtag
          afterClose={(saved) => saved && dataSource.page === 1 && fetch(pagination)}
        >Create
        </ModalHashtag>
      </div>

      <Table
        className="fx-extend"
        rowKey="id"
        loading={loading}
        columns={renderColumns({
          toggleIsPlaylist: is(ERoleType.SUPER_ADMIN)
            ? toggleIsPlaylist
            : undefined,
          onUpdate,
          onDelete: is(ERoleType.SUPER_ADMIN)
            ? onDelete
            : undefined
        })}
        dataSource={dataSource.rows}
        pagination={false}
      />

      <Pagination
        className="fx-as-end mt-3"
        disabled={loading}
        total={dataSource.total}
        current={pagination.page}
        pageSize={pagination.limit}
        {
          ...{
            ...pagination,
            onChange(page, limit) {
              pushPagination({
                ...pagination,
                page,
                limit
              })
            }
          }
        }
      />
    </section>
  )
}
