import clsx from 'clsx'
import { FC, useState } from 'react'
import ReactPlayer from 'react-player'
import { Video } from 'src/components'
import { ICampaignModel } from 'src/interfaces'
import { VideoThumbPicker } from 'src/partials'

interface IProps {
  campaign?: ICampaignModel
  className?: string
  isCompany?: boolean
}

export const CampaignVideo: FC<IProps> = ({ campaign, className, isCompany = false }) => {
  const [player, setPlayer] = useState<ReactPlayer>()

  return (
    <div className={clsx('fx fx-row gap-2', className)}>
      {isCompany
        ? campaign?.companyVideo && (
          <Video
            key={campaign?.id}
            video={campaign.companyVideo}
            playerProps={{
              style: {
                width: 300,
                height: 200
              }
            }}
          />
        )
        : campaign?.uploadVideo && (
          <div>
            <Video
              key={campaign?.id}
              video={campaign.uploadVideo}
              playerProps={{
                onReady: (player) => setPlayer(player),
                style: {
                  width: 300,
                  height: 200
                }
              }}
            />

            <VideoThumbPicker
              className="fx-1"
              player={player}
              encoding={campaign?.encoding}
              video={campaign?.uploadVideo}
            />
          </div>
        )}
    </div>
  )
}
