import { FC } from 'react'
import { Router } from 'react-router-dom'
import { AppLayout } from './layout'
import { browserHistory } from './router'

export const App: FC = () => {
  return (
    <Router history={browserHistory}>
      <AppLayout/>
    </Router>
  )
}
