import axios from 'axios'
import { ICampaignModel, IPaginationQuery, IScrapedJobModel, TAxiosResponseData, TAxiosResponsePagination } from 'src/interfaces'
import { AxiosUtils, Helpers } from 'src/utils'

export class ScrapedJobApi {
  static readonly _prefix = '/scraped-jobs'

  static index(params: IPaginationQuery = {}): TAxiosResponsePagination<IScrapedJobModel> {
    return axios.get(`${ScrapedJobApi._prefix}`, { params })
  }

  static download(params: IPaginationQuery = {}): Promise<void> {
    return axios.get(`${ScrapedJobApi._prefix}/download`, {
      params,
      responseType: 'arraybuffer'
    }).then((response) => {
      const blob = new Blob([response.data], { type: response.headers['content-type'] })
      const url = URL.createObjectURL(blob)
      Helpers.download({
        href: url,
        filename: AxiosUtils.exposeFileNameFromContentDisposition(response.headers, 'candidates.csv')
      })

      URL.revokeObjectURL(url)
    })
  }

  static upload(file: File): Promise<void> {
    const formData = new FormData()
    formData.append('file', file)
    return axios.post(`${ScrapedJobApi._prefix}/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }

  static show(id: IScrapedJobModel['id']): TAxiosResponseData<IScrapedJobModel> {
    return axios.get(`${ScrapedJobApi._prefix}/${id}`)
  }

  static getCampaign(id: IScrapedJobModel['id']): TAxiosResponseData<ICampaignModel> {
    return axios.patch(`${ScrapedJobApi._prefix}/${id}/campaign`)
  }

  static seedSubmissions(id: IScrapedJobModel['id']): TAxiosResponseData<void> {
    return axios.put(`${ScrapedJobApi._prefix}/${id}/campaign/seed`)
  }
}
