import { Pagination, Table } from 'antd'
import clsx from 'clsx'
import HTMLReactParser from 'html-react-parser'
import { FC, useCallback, useEffect, useMemo } from 'react'
import { CandidateApi } from 'src/api'
import { IPaginateCallback, useBehaviorMapper, usePaginateParams } from 'src/hooks'
import { ENotificationActionText, ICandidateModel, INotificationModel } from 'src/interfaces'
import { PaginationService, TimeZoneService } from 'src/services'

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
    render: (val: Required<INotificationModel>['title']) => (
      <span className="twoline-text">{HTMLReactParser(val || '')}</span>
    )
  },
  {
    title: 'Body',
    dataIndex: 'body',
    key: 'body',
    render: (val: Required<INotificationModel>['body']) => (
      <span className="twoline-text">{HTMLReactParser(val || '')}</span>
    )
  },
  {
    title: 'Image',
    dataIndex: 'imageUrl',
    key: 'imageUrl',
    render: (val: Required<INotificationModel>['imageUrl']) => !val
      ? null
      : <img src={val} alt="icon" style={{ height: 50 }}/>
  },
  {
    title: 'Action',
    dataIndex: 'action',
    render: (val: Required<INotificationModel>['action']) => ENotificationActionText[val] || 'Other'
  },
  {
    title: 'CreatedAt',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (val: string) => TimeZoneService.format(val)
  },
  {
    title: 'Pushed Notification At',
    dataIndex: 'pushedAt',
    key: 'pushedAt',
    render: (val: string) => val && TimeZoneService.format(val)
  },
  {
    title: 'ReceivedAt',
    dataIndex: 'receivedAt',
    key: 'receivedAt',
    render: (val: string) => val && TimeZoneService.format(val)
  },
  {
    title: 'SeenAt',
    dataIndex: 'seenAt',
    key: 'seenAt',
    render: (val: string) => val && TimeZoneService.format(val)
  },
  {
    title: 'Candidate take action of notification at',
    dataIndex: 'actionAt',
    key: 'actionAt',
    render: (val: string) => val && TimeZoneService.format(val)
  }
]

interface IProps {
  candidate?: ICandidateModel
  className?: string
}

export const CandidateNotifications: FC<IProps> = ({ candidate, className }) => {
  const candidateId = useMemo(() => candidate?.id, [candidate])
  const _paginationService = useMemo(
    () => new PaginationService<INotificationModel>({ endpoint: `${CandidateApi._prefix}/${candidateId}/notifications` }),
    [candidateId]
  )
  const loading = useBehaviorMapper(_paginationService.loading$)
  const dataSource = useBehaviorMapper(_paginationService.pagination$)

  const fetch = useCallback<IPaginateCallback>(
    (params) => candidateId && _paginationService.paging({ ...params }),
    [_paginationService, candidateId]
  )

  const { pagination, pushPagination } = usePaginateParams(fetch)

  useEffect(() => {
    fetch(pagination)
  }, [fetch, pagination])

  return (
    <div className={clsx('fx fx-column gap-2', className)}>
      <strong>Notifications</strong>

      <Table
        rowKey="id"
        loading={loading}
        columns={columns}
        dataSource={dataSource.rows}
        pagination={false}
      />

      <Pagination
        className="fx-as-end mt-3"
        disabled={loading}
        total={dataSource.total}
        current={pagination.page}
        pageSize={pagination.limit}
        {
          ...{
            ...pagination,
            onChange(page, limit) {
              console.log('pushPagination')
              pushPagination({
                ...pagination,
                page,
                limit
              })
            }
          }
        }
      />
    </div>
  )
}
