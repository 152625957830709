import { Input, Pagination, Table } from 'antd'
import { omit } from 'lodash'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { VideoImportApi } from 'src/api'
import { ModalImportTikTok } from 'src/components'
import { EVideoImportProvider, EVideoImportStatus } from 'src/constants/enum'
import { IPaginateCallback, IPaginateParams, useBehaviorMapper, useDidMountDebounce, usePaginateParams } from 'src/hooks'
import { IVideoImportModel } from 'src/interfaces'
import { BreadcrumbService, PaginationService } from 'src/services'
import { EPaths } from '../../routes.path'
import { renderColumns } from './columns'

const PAGE_TITLE = 'TikTok Import'

export const TikTokImport: FC = () => {
  const _paginationService = useMemo(() => new PaginationService<IVideoImportModel>(VideoImportApi), [])
  const loading = useBehaviorMapper(_paginationService.loading$)
  const dataSource = useBehaviorMapper(_paginationService.pagination$)

  const fetch = useCallback<IPaginateCallback & {
    keyword?: string
    provider?: EVideoImportProvider
    status?: EVideoImportStatus
  }>((params) => _paginationService.paging(params), [_paginationService])

  const { pagination, pushPagination, setPagination } = usePaginateParams(fetch)
  const [filter, setFilter] = useState<Omit<IPaginateParams, 'page' | 'limit' | 'offset'>>(
    omit(pagination, ['page', 'limit', 'offset'])
  )

  useDidMountDebounce(() => {
    setPagination({ ...pagination, page: 1 })
    return pushPagination({
      ...pagination,
      page: 1,
      ...filter
    })
  }, 2000, [filter])

  /**
   * breadcrumb
   */
  useEffect(() => {
    BreadcrumbService.items = [{
      route: EPaths.TIKTOK_IMPORT,
      label: PAGE_TITLE
    }]
    return () => {
      BreadcrumbService.items = []
    }
  }, [])

  return (
    <section className="fx fx-column fx-extend">
      <div className="fx fx-jc-space-between mb-2">
        <div className="fx fx-extend gap-2">
          <Input
            allowClear
            placeholder="Search by url..."
            style={{ flex: '0 0 30vw' }}
            readOnly={loading}
            value={filter.keyword as string}
            onChange={(e) => {
              e.persist()
              setFilter((prev) => ({
                ...prev,
                keyword: e.target.value
              }))
            }}
          />
        </div>

        <ModalImportTikTok
          afterClose={(saved) => saved && dataSource.page === 1 && fetch(pagination)}
        />
      </div>

      <Table
        className="fx-extend"
        rowKey="id"
        loading={loading}
        columns={renderColumns()}
        dataSource={dataSource.rows}
        pagination={false}
      />

      <Pagination
        className="fx-as-end mt-3"
        disabled={loading}
        total={dataSource.total}
        current={pagination.page}
        pageSize={pagination.limit}
        {
          ...{
            ...pagination,
            onChange(page, limit) {
              pushPagination({
                ...pagination,
                page,
                limit
              })
            }
          }
        }
      />
    </section>
  )
}
