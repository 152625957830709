import { IRouterOption } from 'src/router'
import { VibeCheckVideo } from './components/check'
import { VibeRating } from './components/rating'
import { EPaths } from './routes.path'

export enum ERoutes {
  VIBE_RATING = 'Vibe.Rating',
  VIBE_CHECK = 'Vibe.Check'
}

export const Routes: IRouterOption[] = [
  {
    path: EPaths.VIBE_RATING,
    component: VibeRating,
    name: ERoutes.VIBE_RATING,
    exact: true,
    meta: {}
  },
  {
    path: EPaths.VIBE_CHECK,
    component: VibeCheckVideo,
    name: ERoutes.VIBE_CHECK,
    exact: true,
    meta: {}
  }
]

export const VibeModule = {
  ERoutes,
  Routes
}
