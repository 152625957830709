import clsx from 'clsx'
import { FC } from 'react'
import { Video } from 'src/components'
import { IUserModel } from 'src/interfaces'

interface IProps {
  hiringManager?: IUserModel
  className?: string
}

export const HiringManagerVideos: FC<IProps> = ({ hiringManager, className }) => {
  return (
    <div className={clsx('fx fx-column gap-2', className)}>
      <strong>Videos</strong>

      {hiringManager?.pfv && (
        <Video
          key={hiringManager?.pfv?.id}
          video={hiringManager?.pfv}
          playerProps={{
            style: {
              width: 300,
              height: 200
            }
          }}
        />
      )}
    </div>
  )
}
