import { Button, Form, Input, Select, Table } from 'antd'
import { uniqWith } from 'lodash'
import moment, { Moment } from 'moment'
import { ChangeEvent, ComponentProps, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { finalize, from, takeUntil } from 'rxjs'
import { NotificationTemplateApi } from 'src/api'
import { DatePicker, ModalFullscreen, RangePicker } from 'src/components'
import { useDidMountEffect, useUnsubscribe, useValidation } from 'src/hooks'
import { ENotificationAction, ENotificationActionText, ENotificationGroupUser, ENotificationGroupUserText, INotificationTemplateModel, IUserModel } from 'src/interfaces'
import { NotifyUtils } from 'src/utils'
import * as Yup from 'yup'
import { ErrorMessage } from '../error-message'
import { SelectCandidate } from '../select-candidate'
import { renderColumns } from './columns'

const userGroupOptions = Object.values(ENotificationGroupUser).map((value) => ({
  label: ENotificationGroupUserText[value],
  value
}))

const actionOptions = Object.values(ENotificationAction).map((value) => ({
  label: ENotificationActionText[value],
  value
}))

const yupSchema = Yup.object().shape({
  action: Yup.string().nullable().oneOf([null, ...Object.values(ENotificationAction)]), // .required('TikTok URL is required'),
  title: Yup.string().nullable().required('Title is required'),
  groupUser: Yup.string().nullable().oneOf([null, ...Object.values(ENotificationGroupUser)]), // .required('Title is required'),
  joinFromDate: Yup.date()/** .matches(/^$/, 'Invalid Date') */.nullable().optional(),
  joinToDate: Yup.date()/** .matches(/^$/, 'Invalid Date') */.nullable().optional(),
  body: Yup.string().nullable(), // .required('Body is required'),
  imageUrl: Yup.string().nullable().url('Image URL must be a valid'), // .required('Image URL is required'),
  data: Yup.string().JSONString('Data must be a valid JSON string'), // .required('Data is required')
  nextExecutionDate: Yup.date().nullable().optional(),
  intervalMilliseconds: Yup.number().nullable().optional()
})

const REPEAT_OPTIONS = [
  { value: 6 * 60 * 60 * 1000, label: '6h' },
  { value: 12 * 60 * 60 * 1000, label: '12h' },
  { value: 24 * 60 * 60 * 1000, label: '1 day' }
]

export const ModalNotificationTemplate: FC<Omit<
  ComponentProps<typeof ModalFullscreen>,
  'onOk' | 'afterClose'
> & {
  id?: INotificationTemplateModel['id']
  // onOk?: () => any
  afterClose?: (saved: boolean, doc?: INotificationTemplateModel) => any
  btnProps?: ComponentProps<typeof Button>
}> = ({
  btnProps,
  ...props
}) => {
  const unsubscribe$ = useUnsubscribe()
  const [loading, setLoading] = useState(false)
  const [invisible, setInvisible] = useState<boolean | INotificationTemplateModel>(true)
  const [users, setUsers] = useState<IUserModel[]>([])
  const [selected, setSelected] = useState<IUserModel[]>([])
  const [formData, setFormData] = useState<Parameters<typeof NotificationTemplateApi.store>[0]>({})

  const payload = useMemo(() => ({
    ...formData,
    userIds: users.map((user) => user.id)
  }), [formData, users])

  const handleChangeInput = useCallback((e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
    e.persist()
    const { name, value } = e.target
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }))
  }, [])

  const { validate, errors, reset } = useValidation({
    data: formData,
    schema: yupSchema
  })

  const save = useCallback(async () => {
    const { isValid } = await validate()
    if (!isValid) {
      return
    }

    const apiHandler = props.id
      ? NotificationTemplateApi.update(props.id, payload)
      : NotificationTemplateApi.store(payload)

    setLoading(true)
    from(apiHandler)
      .pipe(
        takeUntil(unsubscribe$),
        finalize(() => setLoading(false))
      )
      .subscribe({
        next: ({ data }) => {
          NotifyUtils.success({ message: `${props.id ? 'Update' : 'Create'} notification template successfully` })
          setInvisible(data)
        },
        error: NotifyUtils.handleAxiosError
      })
  }, [validate, props.id, payload, unsubscribe$])

  useEffect(() => {
    if (formData.groupUser) {
      setUsers([])
    }
  }, [formData.groupUser])

  useDidMountEffect(() => {
    reset()

    if (invisible) {
      setLoading(false)
      setFormData(() => ({}))
      setSelected(() => [])
      setUsers(() => [])

      const isSaved = typeof invisible !== 'boolean'
      props.afterClose?.(isSaved, isSaved ? invisible : undefined)
    }
  }, [invisible])

  useDidMountEffect(() => {
    if (!invisible && props.id) {
      setLoading(true)
      from(NotificationTemplateApi.show(props.id))
        .pipe(
          takeUntil(unsubscribe$),
          finalize(() => setLoading(false))
        )
        .subscribe({
          next: ({ data: notificationTemplate }) => {
            setFormData(() => ({
              action: notificationTemplate.action,
              groupUser: notificationTemplate.groupUser,
              joinToDate: notificationTemplate.joinToDate,
              joinFromDate: notificationTemplate.joinFromDate,
              title: notificationTemplate.title,
              body: notificationTemplate.body,
              imageUrl: notificationTemplate.imageUrl,
              nextExecutionDate: notificationTemplate.nextExecutionDate,
              intervalMilliseconds: notificationTemplate.intervalMilliseconds,
              data: notificationTemplate.data
                ? JSON.stringify(notificationTemplate.data, null, 2)
                : undefined
            }))

            if (notificationTemplate.users?.length) {
              setUsers(notificationTemplate.users)
            }
          },
          error: NotifyUtils.handleAxiosError
        })
    }
  }, [props.id, invisible])

  const minDate = moment()

  const disabledDate = (current: moment.Moment) => {
    return current && current < minDate.startOf('day')
  }

  const disabledHours = () => {
    return Array.from(Array(24).keys()).filter(hour => hour <= moment().get('hour'))
  }

  const disabledMinutes = (hour: number) => {
    const currentHour = moment().get('hour')
    // Disable all minutes if the hour is less than the current hour
    if (hour <= currentHour) {
      return Array.from(Array(60).keys())
    }

    // Otherwise, disable no minutes
    return []
  }

  const disabledSeconds = (hour: number, minute: number) => {
    // Disable all seconds if the hour or minute is disabled
    if (disabledHours().includes(hour) || disabledMinutes(hour).includes(minute)) {
      return Array.from(Array(60).keys())
    }
    // Otherwise, disable no seconds
    return []
  }

  return (
    <>
      <Button
        type="primary"
        {...btnProps}
        onClick={() => setInvisible(false)}
      >
        {props.children || 'Create'}
      </Button>

      <ModalFullscreen
        wrapClassName="__modal-notification-template"
        title={`${props.id ? 'Edit' : 'Create'} Notification Template`}
        open={!invisible}
        closable={!loading} // display X icon
        keyboard={false} // disable close on press ESC
        maskClosable={false} // disable close on click outside
        okText={props.id ? 'Update' : 'Save'}
        cancelText="Cancel"
        onOk={save}
        onCancel={() => setInvisible(true)}
        okButtonProps={{ disabled: loading }}
        cancelButtonProps={{ disabled: loading }}
      >
        {invisible
          ? null
          : (
            <div className="fx fx-column fx-extend gap-2">
              {/* <Form.Item
                required
                label="Action"
                labelAlign="left"
                labelCol={{ flex: '0 0 100px' }}
                className="m-0"
              >
                <Input
                  required
                  readOnly={loading}
                  name="action"
                  placeholder="Action"
                  value={formData.action}
                  status={errors.action ? 'error' : undefined}
                  onChange={handleChangeInput}
                />
                <ErrorMessage>{errors.getError('action')}</ErrorMessage>
              </Form.Item> */}

              <Form.Item
                label="Group of users"
                labelAlign="left"
                labelCol={{ flex: '0 0 100px' }}
                className="m-0"
              >
                <Select
                  allowClear
                  disabled={loading}
                  placeholder="Select group of user"
                  value={formData.groupUser}
                  options={userGroupOptions}
                  status={errors.hasError('groupUser') ? 'error' : undefined}
                  onChange={(groupUser) => setFormData((prev) => ({
                    ...prev,
                    groupUser: groupUser ?? null
                  }))}
                />
                <ErrorMessage>{errors.getError('groupUser')}</ErrorMessage>
              </Form.Item>

              <Form.Item
                label="Joined date"
                labelAlign="left"
                labelCol={{ flex: '0 0 100px' }}
                className="m-0"
              >
                <RangePicker
                  allowClear
                  disabledDate={(current: Moment) => current && current > moment().endOf('day')}
                  value={[
                    formData.joinFromDate ? moment(formData.joinFromDate) : null,
                    formData.joinToDate ? moment(formData.joinToDate) : null
                  ]}
                  onChange={(values, dates) => setFormData((prev) => ({
                    ...prev,
                    joinFromDate: values?.[0]?.startOf('day').toISOString(),
                    joinToDate: values?.[1]?.endOf('day').toISOString()
                  }))}
                />
                <ErrorMessage>{errors.getError('joinFromDate') || errors.getError('joinToDate')}</ErrorMessage>
              </Form.Item>

              <Form.Item
                required
                label="Title"
                labelAlign="left"
                labelCol={{ flex: '0 0 100px' }}
                className="m-0"
              >
                <Input
                  required
                  readOnly={loading}
                  name="title"
                  placeholder="Title"
                  value={formData.title}
                  status={errors.hasError('title') ? 'error' : undefined}
                  onChange={handleChangeInput}
                />
                <ErrorMessage>{errors.getError('title')}</ErrorMessage>
              </Form.Item>

              <Form.Item
                label="Next Execution"
                labelAlign="left"
                labelCol={{ flex: '0 0 100px' }}
                className="m-0"
              >
                <DatePicker
                  showTime={{
                    format: 'HH:mm',
                    defaultValue: moment('09:00', 'HH:mm'),
                    disabledHours,
                    disabledMinutes,
                    disabledSeconds
                  }}
                  disabledDate={disabledDate}
                  disabled={loading}
                  name="nextExecutionDate"
                  value={formData.nextExecutionDate && moment(formData.nextExecutionDate)}
                  onChange={(date) => setFormData((prev) => ({ ...prev, nextExecutionDate: date?.toDate() }))}
                  status={errors.hasError('nextExecutionDate') ? 'error' : undefined}
                />
                <ErrorMessage>{errors.getError('nextExecutionDate')}</ErrorMessage>
              </Form.Item>

              <Form.Item
                label="Repeat Every"
                labelAlign="left"
                labelCol={{ flex: '0 0 100px' }}
                className="m-0"
              >
                <Select
                  allowClear
                  style={{ width: 120 }}
                  defaultValue={null}
                  onClear={() => setFormData((prev) => ({ ...prev, intervalMilliseconds: undefined }))}
                  value={formData.intervalMilliseconds}
                  onChange={(value: any) => setFormData((prev) => ({ ...prev, intervalMilliseconds: value }))}
                  status={errors.hasError('intervalMilliseconds') ? 'error' : undefined}
                  options={REPEAT_OPTIONS}
                />
                <ErrorMessage>{errors.getError('intervalMilliseconds')}</ErrorMessage>
              </Form.Item>

              <Form.Item
                // required
                label="Body"
                labelAlign="left"
                labelCol={{ flex: '0 0 100px' }}
                className="m-0"
              >
                <Input.TextArea
                  rows={4}
                  // required
                  readOnly={loading}
                  name="body"
                  placeholder="Body"
                  value={formData.body}
                  status={errors.hasError('body') ? 'error' : undefined}
                  onChange={handleChangeInput}
                />
                <ErrorMessage>{errors.getError('body')}</ErrorMessage>
              </Form.Item>

              <Form.Item
                // required
                label="Image URL"
                labelAlign="left"
                labelCol={{ flex: '0 0 100px' }}
                className="m-0"
              >
                <Input
                  // required
                  readOnly={loading}
                  name="imageUrl"
                  placeholder="Image URL"
                  value={formData.imageUrl}
                  status={errors.hasError('imageUrl') ? 'error' : undefined}
                  onChange={handleChangeInput}
                />
                <ErrorMessage>{errors.getError('imageUrl')}</ErrorMessage>
              </Form.Item>

              <Form.Item
                label="Action"
                labelAlign="left"
                labelCol={{ flex: '0 0 100px' }}
                className="m-0"
              >
                <Select
                  allowClear
                  disabled={loading}
                  placeholder="Select action"
                  value={formData.action}
                  options={actionOptions}
                  status={errors.hasError('action') ? 'error' : undefined}
                  onChange={(action) => setFormData((prev) => ({
                    ...prev,
                    action: action ?? null
                  }))}
                />
                <ErrorMessage>{errors.getError('action')}</ErrorMessage>
              </Form.Item>

              {/* <Form.Item
                required
                label="Data"
                labelAlign="left"
                labelCol={{ flex: '0 0 100px' }}
                className="m-0"
              >
                <Input.TextArea
                  rows={10}
                  required
                  readOnly={loading}
                  name="data"
                  placeholder="Data"
                  status={errors.data ? 'error' : undefined}
                  value={formData.data}
                  onChange={handleChangeInput}
                />
                <ErrorMessage>{errors.getError('data')}</ErrorMessage>
              </Form.Item> */}

              {formData.groupUser
                ? null
                : (
                  <>
                    <div className="fx fx-jc-space-between gap-2">
                      <div className="fx fx-column fx-extend">
                        <SelectCandidate
                          multiple
                          value={selected.map(({ id }) => id)}
                          onChange={(values, options) => setSelected(
                            (prev) => uniqWith(
                              [
                                ...prev.filter(({ id }) => Array.ensure(values).includes(id)),
                                ...Array.ensure(options).map(({ row }) => row)
                              ],
                              (a, b) => a.id === b.id
                            )
                          )}
                        />
                      </div>

                      <Button
                        type="primary"
                        onClick={() => {
                          setSelected(() => [])
                          setUsers(
                            (prev) => uniqWith(
                              [
                                ...prev,
                                ...selected
                              ],
                              (a, b) => a.id === b.id
                            )
                          )
                        }}
                      >
                        Add
                      </Button>
                    </div>

                    <Table
                      className="fx-extend"
                      rowKey="id"
                      loading={loading}
                      columns={renderColumns()}
                      dataSource={users}
                      pagination={false}
                    />
                  </>
                )}
            </div>
          )}
      </ModalFullscreen>
    </>
  )
}
