import { BellFilled, EditFilled } from '@ant-design/icons'
import { Avatar, Button, Input } from 'antd'
import { AxiosError } from 'axios'
import clsx from 'clsx'
import { FC, useCallback, useEffect, useState } from 'react'
import { EMPTY, catchError, delay, finalize, from, map, takeUntil } from 'rxjs'
import { CandidateApi } from 'src/api'
import { ModalSendNotification } from 'src/components'
import { ERoleType } from 'src/constants/enum'
import { useDidMountEffect, useUnsubscribeCallback } from 'src/hooks'
import { ICandidateModel } from 'src/interfaces'
import { useAuthorization } from 'src/store'
import { Helpers, NotifyUtils, UserUtils } from 'src/utils'

interface IProps {
  candidate?: ICandidateModel
  className?: string
  onDisabled?: (val: boolean) => void
}

export const CandidateGeneral: FC<IProps> = ({ candidate, className, ...props }) => {
  const { is } = useAuthorization()

  const copyAndToast = useCallback((content: any) => {
    Helpers.copy(String(content)).then(
      () => NotifyUtils.success({ message: `Copied to clipboard: ${content}` })
    )
  }, [])

  const [loading, setLoading] = useState(false)

  useDidMountEffect(() => {
    props.onDisabled?.(loading)
  }, [loading])

  const [isEditing, setIsEditing] = useState(false)
  const [linkedInUrl, setLinkedInUrl] = useState('')
  useEffect(() => {
    if (candidate) {
      setLinkedInUrl(candidate.linkedInUrl || '')
    }
  }, [candidate])

  const updateLinkedInUrl = useUnsubscribeCallback((unsubscribe$, linkedInUrl: string) => {
    if (!candidate?.id) {
      return
    }

    setLoading(true)
    from(CandidateApi.update(candidate.id, { linkedInUrl }))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error: AxiosError) => {
          NotifyUtils.handleAxiosError(error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(() => {
        NotifyUtils.success({ message: 'Update successfully' })
        setIsEditing(false)
        candidate.linkedInUrl = linkedInUrl
      })
  }, [candidate])

  const scrapeLinkedin = useUnsubscribeCallback((unsubscribe$, id: number) => {
    setLoading(true)
    from(CandidateApi.scrapeLinkedin(id))
      .pipe(
        map(() => NotifyUtils.success({ message: 'Please wait a moment while work in progress' })),
        catchError((error: AxiosError) => {
          NotifyUtils.handleAxiosError(error)
          return EMPTY
        }),
        delay(40 * 1000),
        takeUntil(unsubscribe$),
        finalize(() => setLoading(false))
      )
      .subscribe()
  }, [])

  return (
    <div className={clsx('fx fx-ai-center gap-4 fx-jc-space-between', className)}>
      <div className="fx-1 fx fx-ai-center gap-4">
        <Avatar size={56} src={candidate?.pfp?.url}>
          {UserUtils.getFullName(candidate)}
        </Avatar>

        <div className="fx-1 fx fx-column">
          <div className="fx gap-2 fx-ai-center">
            <strong>Full Name:</strong>
            <span>{UserUtils.getFullName(candidate)}</span>
          </div>

          <div className="fx gap-2 fx-ai-center">
            <strong>Telephone:</strong>
            {candidate?.userPhoneNo && (
              <span
                className="pointer"
                onClick={() => copyAndToast(candidate.userPhoneNo)}
              >{UserUtils.formatPhoneUSA(candidate.userPhoneNo)}
              </span>
            )}
          </div>

          <div className="fx gap-2 fx-ai-center">
            <strong>Email:</strong>
            {candidate?.email && (
              <span
                className="pointer"
                onClick={() => copyAndToast(candidate.email)}
              >{UserUtils.formatPhoneUSA(candidate.email)}
              </span>
            )}
          </div>

          <div className="fx gap-2 fx-ai-center">
            <strong>LinkedIn URL:</strong>

            {
              isEditing
                ? (
                  <Input
                    style={{ width: 'auto' }}
                    className="fx-1"
                    ref={(e) => e?.focus()}
                    disabled={loading}
                    value={linkedInUrl}
                    onChange={(e) => setLinkedInUrl(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === 'Escape') {
                        setLinkedInUrl(candidate?.linkedInUrl || '')
                        return setIsEditing(false)
                      }
                      if (e.key === 'Enter') {
                        if (linkedInUrl !== candidate?.linkedInUrl) {
                          updateLinkedInUrl(linkedInUrl)
                        } else {
                          setIsEditing(false)
                        }
                      }
                    }}
                  />
                )
                : (
                  candidate?.linkedInUrl && (
                    <a
                      href={candidate.linkedInUrl}
                      rel="noreferrer"
                      target="_blank"
                    >{candidate.linkedInUrl}
                    </a>
                  )
                )
            }

            {!isEditing && (
              <EditFilled
                className="pointer"
                onClick={() => setIsEditing((prev) => !prev)}
              />
            )}
          </div>
        </div>
      </div>

      <div className="fx fx-column gap-2">
        {is(ERoleType.SUPER_ADMIN) && candidate?.id && (
          <ModalSendNotification
            id={candidate.id}
            btnProps={{
              icon: <BellFilled/>
            }}
          >Send notification
          </ModalSendNotification>
        )}

        {candidate?.linkedInUrl && (
          <Button
            type="primary"
            disabled={loading}
            onClick={() => scrapeLinkedin(candidate.id)}
          >
            Scrape linkedin
          </Button>
        )}
      </div>
    </div>
  )
}
