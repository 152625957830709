import { FC, ReactNode, useEffect, useState } from 'react'
import { useAsRef } from 'src/hooks'
import Style from './style.module.scss'

interface IProps {
  onChange?: (value: number) => void
  children?: ReactNode
}
export const Control: FC<IProps> = (props) => {
  const [opacity, setOpacity] = useState(1)

  useEffect(() => {
    if (opacity) {
      const timer = setTimeout(() => setOpacity(0), 2000)
      return () => clearTimeout(timer)
    }
  }, [opacity])

  const onChangeRef = useAsRef(props.onChange)
  useEffect(() => {
    onChangeRef.current?.(opacity)
  }, [opacity, onChangeRef])

  return (
    <div
      className={Style.control}
      onMouseMove={() => setOpacity(1)}
      style={{ opacity }}
    >
      <div className={Style.controller}>
        {props.children}
      </div>
    </div>
  )
}
