import { IRouterOption } from 'src/router'
import { AffiliateForms } from './components/list'
import { EPaths } from './routes.path'

export enum ERoutes {
  AFFILIATE_FORM = 'AFFILIATE_FORM'
}

export const Routes: IRouterOption[] = [
  {
    path: EPaths.AFFILIATE_FORM,
    component: AffiliateForms,
    name: ERoutes.AFFILIATE_FORM,
    exact: true,
    meta: {}
  }
]

export const AffiliateFormModule = {
  ERoutes,
  Routes
}
