import qs, { IStringifyOptions } from 'qs'
import { ValidationError } from 'yup'

export class Helpers {
  /**
   * alphabeticalSort option of qs module
   * @param {String} a
   * @param {String} b
   */
  static alphabeticalSort(a: string, b: string) {
    return a.localeCompare(b)
  }

  // =================================================================================
  /**
   * Check input is Object or not
   * @param {Any} obj
   * @return {Boolean}
   */
  static isObject <T>(obj: T): boolean {
    return obj && typeof obj === 'object' && !Array.isArray(obj)
  }

  // =================================================================================
  /**
   * Valid input is an Object
   * @param {Any} arr
   * @return {Object}
   */
  static ensureObject <T, D>(obj: T, defaultValue?: D) {
    return Helpers.isObject(obj)
      ? obj
      : Helpers.isObject(defaultValue) ? defaultValue : {}
  }

  // =================================================================================
  /**
   * Valid input is an Object
   * @param {Any} arr
   * @return {Object}
   */
  static ensureArray = <T = any>(array: T | T[], defaults?: T[]): T[] => {
    if (Array.isArray(array)) {
      return array
    }

    if (Array.isArray(defaults)) {
      return defaults
    }

    return [array]
  }

  // =================================================================================
  /**
   * Compare two object, true if match
   * @param {Object} obj1
   * @param {Object} obj2
   * @return {Boolean}
   */
  static compareObj <T, K>(obj1: T, obj2: K): boolean {
    const options: IStringifyOptions = {
      arrayFormat: 'repeat',
      sort: Helpers.alphabeticalSort
    }
    return (
      qs.stringify(Helpers.ensureObject(obj1), options) ===
      qs.stringify(Helpers.ensureObject(obj2), options)
    )
  }

  // ------------------------------------------------------------------------------------

  static convertErrorYup <T>(error: ValidationError): T {
    const errors: T = {} as T
    error.inner.forEach((err: ValidationError) => {
      if (err.path && !errors[err.path as keyof T]) {
        (errors as any)[err.path] = err.message
      }
    })
    return errors
  }

  static async copy(str: string): Promise<void> {
    const execCopy = () => {
      const element = document.createElement('textarea')
      element.value = str
      element.setAttribute('readonly', '')
      element.style.position = 'absolute'
      element.style.left = '-9999px'

      const selection = document.getSelection()
      const selected = (selection?.rangeCount || 0) > 0 // Check if there is any content selected previously
        ? selection?.getRangeAt(0) // Store selection if found
        : false

      document.body.appendChild(element)
      element.select()
      document.execCommand('copy')
      document.body.removeChild(element)

      if (selected) {
        selection?.removeAllRanges() // Unselect everything on the HTML document
        selection?.addRange(selected) // Restore the original selection
      }
    }

    if (navigator.clipboard) {
      return (new Promise<void>(
        (resolve, reject) => navigator.clipboard.writeText(str).then(resolve, reject)
      )).catch(
        (error) => {
          console.log({ error })
          return execCopy()
        }
      )
    }

    return execCopy()
  }

  /**
   * Download text content as a file
   */
  static download({
    content,
    href,
    filename = 'text.txt'
  }: {
    content?: string
    href?: string
    filename?: string
  }) {
    const element = document.createElement('a')
    element.setAttribute('download', filename)
    element.style.display = 'none'
    if (content) {
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(content))
    }
    if (href) {
      element.setAttribute('href', href)
    }

    document.body.appendChild(element)
    element.click()
    document.body.removeChild(element)
  }

  static addProtocolToUrl(url: string) {
    if (!url.startsWith('https://') && !url.startsWith('http://')) {
      return 'https://' + url
    }

    return url
  }

  static htmlToTxt(html: string) {
    // create a new div element
    const tmpEl = document.createElement('div')

    // set the HTML content with the given value
    tmpEl.innerHTML = html

    // retrieve the text property of the element
    return tmpEl.textContent || tmpEl.innerText || ''
  }
}

export const parseBoolean = (value: string | number | boolean): boolean => {
  if (typeof value === 'boolean') {
    return value
  }

  if (typeof value === 'number') {
    return value === 1
  }

  return (value === 'true' || value === '1' || value === 'True' || value === 'TRUE' || value === 'yes' || value === 'Yes' || value === 'YES' || value === 'y' || value === 'Y')
    ? true
    : value === 'false' || value === '0' || value === 'False' || value === 'FALSE' || value === 'no' || value === 'No' || value === 'NO' || value === 'n' || value === 'N' || value === ''
      ? false
      : Boolean(value)
}

export const extractChannelId = (url: string) => {
  const pattern = /\/channel\/([\w-]+)/

  const match = url.match(pattern)

  if (match) {
    const extractedString = match[1]
    return extractedString
  }

  return url
}
