import axios, { AxiosRequestConfig } from 'axios'
import { TAxiosResponseData } from 'src/interfaces'
import { IS3PresignedMultipartResult, S3Utils } from 'src/utils'

export class UploadApi {
  static readonly _prefix = '/upload'

  static presignedMultipart(
    params: {
      entity: string
      type: string
      size: number
    },
    config?: AxiosRequestConfig
  ): TAxiosResponseData<IS3PresignedMultipartResult> {
    return axios.get(`${UploadApi._prefix}/presigned-multipart`, { ...config, params })
  }

  static abortPresignedMultipartUpload(
    params: Omit<IS3PresignedMultipartResult, 'parts'>,
    config?: AxiosRequestConfig
  ): TAxiosResponseData<void> {
    return axios.delete(`${UploadApi._prefix}/presigned-multipart`, { ...config, params })
  }

  static completePresignedMultipartUpload(payload: Omit<IS3PresignedMultipartResult, 'parts'> & { parts: Awaited<ReturnType<typeof S3Utils.presignedMultipartUpload>>}): TAxiosResponseData<void> {
    return axios.post(`${UploadApi._prefix}/presigned-multipart`, payload)
  }

  static async upload(
    payload: Pick<Parameters<typeof UploadApi.presignedMultipart>[0], 'entity'> & {
      file: File | Blob
    },
    config?: AxiosRequestConfig
  ): Promise<string> {
    const presignedParams = {
      entity: payload.entity,
      type: payload.file.type,
      size: payload.file.size
    }

    const { data: signedResult } = await UploadApi.presignedMultipart(presignedParams)
    const parts = await S3Utils.presignedMultipartUpload(signedResult, payload.file, config)
    await UploadApi.completePresignedMultipartUpload({ ...signedResult, parts })
    return signedResult.key
  }
}
