import { IRouterOption } from 'src/router'
import { CategoriesManagement } from './components/categories'
import { PostsManagement } from './components/posts'
import { EPaths } from './routes.path'

export enum ERoutes {
  BLOG_POST_MANAGEMENT = 'BLOG_POST_MANAGEMENT',
  BLOG_CATEGORY_MANAGEMENT = 'BLOG_CATEGORY_MANAGEMENT'
}

export const Routes: IRouterOption[] = [
  {
    path: EPaths.BLOG_POST_MANAGEMENT,
    component: PostsManagement,
    name: ERoutes.BLOG_POST_MANAGEMENT,
    exact: true,
    meta: {}
  },
  {
    path: EPaths.BLOG_CATEGORY_MANAGEMENT,
    component: CategoriesManagement,
    name: ERoutes.BLOG_CATEGORY_MANAGEMENT,
    exact: true,
    meta: {}
  }
]

export const BlogModule = {
  ERoutes,
  Routes
}
