import { Button, Space, TableProps } from 'antd'
import { EEnv } from 'src/constants'
import { ICampaignModel } from 'src/interfaces'
import { ERoutes, generate } from 'src/router'
import { UserUtils } from 'src/utils'

export const renderColumns = (): Required<TableProps<ICampaignModel>>['columns'] => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: 'Creator',
    dataIndex: 'author',
    key: 'author',
    render: (val?: ICampaignModel['author']) => UserUtils.getFullName(val)
  },
  {
    title: 'Name',
    dataIndex: 'jobTitle',
    key: 'jobTitle'
  },
  {
    title: 'Logo',
    dataIndex: 'logoUrl',
    key: 'logoUrl',
    render: (val: ICampaignModel['logoUrl']) => !val
      ? null
      : <img src={val} alt="icon" style={{ height: 50 }}/>
  },
  {
    title: 'Company Name',
    dataIndex: 'companyName',
    key: 'companyName'
  },
  // {
  //   title: 'CreatedAt',
  //   dataIndex: 'createdAt',
  //   key: 'createdAt',
  //   render: (val: ICampaignModel['createdAt']) => TimeZoneService.format(val)
  // },
  // {
  //   title: 'UpdatedAt',
  //   dataIndex: 'updatedAt',
  //   key: 'updatedAt',
  //   render: (val: ICampaignModel['updatedAt']) => TimeZoneService.format(val)
  // },
  {
    title: 'Action',
    key: 'action',
    render: (_, record: ICampaignModel) => (
      <Space
        wrap
        size="small"
        direction="vertical"
      >
        <Button
          type="link"
          size="small"
          target="_blank"
          href={generate([ERoutes.CAMPAIGN_DETAIL, { id: record.id }])}
        >
          Detail
        </Button>

        <Button
          type="link"
          size="small"
          target="_blank"
          href={generate([ERoutes.CAMPAIGN_SUBMISSIONS, { id: record.id }])}
        >
          View submissions
        </Button>
        <Button
          type="link"
          size="small"
          target="_blank"
          href={`${EEnv.REACT_APP_LANDING_WEB_DNS}/campaigns/${record.slug}`}
        >
          View Job
        </Button>
      </Space>
    )
  }
]
