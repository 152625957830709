import { FC, ReactNode, useEffect } from 'react'
import { useHistory } from 'react-router'
import { AuthState, useStore } from 'src/store'

export const NotAllowAuthenticated: FC<{ children?: ReactNode }> = (props) => {
  const history = useHistory()
  const { value: { isAuthenticated } } = useStore(AuthState)

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/')
    }
  }, [history, isAuthenticated])

  return <>{props.children}</>
}
