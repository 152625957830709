import { BehaviorSubject } from 'rxjs'
import { noticesCollection } from './firebase'

export interface IFirebaseNotification {
  countNotifications?: number
}

export const FirebaseNotificationService = new (
  class _NotificationService {
    readonly firebaseNotification$ = new BehaviorSubject<IFirebaseNotification>({})

    private notifications: IFirebaseNotification = {}
    private unsubscribe() {}

    bind() {
      this.unsubscribe = noticesCollection
        .doc('tricorder')
        .onSnapshot({ includeMetadataChanges: true }, snapshot => {
          const data = (snapshot.data() as IFirebaseNotification) || {}
          this.notifications = {
            ...this.notifications,
            countNotifications: Math.max(0, data.countNotifications || 0)
          }

          this.firebaseNotification$.next(this.notifications)
        })

      return this.unbind
    }

    unbind() {
      this.unsubscribe()

      setTimeout(() => {
        this.firebaseNotification$.next({})
      }, 100)
    }
  }
)()
