/**
 * @readonly
 * @enum
 */
export enum EDevicePlatform {
  WEB = 'web',
  IOS = 'ios',
  ANDROID = 'android'
}

/**
 * @readonly
 * @enum
 */
export enum EUserGuideState {
  FIRST_INTRO = 'firstIntro',
  FIRST_TIME_VIBE = 'firstTimeVibe',
  ONBOARDING = 'onboarding',
  LIKE = 'like',
  SUPERLIKE = 'superLike',
  SUPERNOVA = 'superNova',
  SKIP = 'skip'
}

/**
 * @readonly
 * @enum
 */
export enum EUserSalaryType {
  SALARY = 'salary',
  HOURLY = 'hourly'
}

/**
 * @readonly
 * @enum
 */
export enum ESalaryRate {
  PER_HOUR = 'perHour',
  PER_MONTH = 'perMonth',
  PER_YEAR = 'perYear'
}

/**
 * @readonly
 * @enum
 */
export enum EReactionableType {
  VIDEO = 'Video',
  REEL = 'Reel',
  NOTIFICATION = 'Notification'
}

/**
 * @readonly
 * @enum
 */
export enum EUserEntitiesRelateTypes {
  VIDEO = 'Video'
}

/**
 * @readonly
 * @enum
 */
export enum EUserEntitiesActionTypes {
  VIEW_COMMENT = 'VIEW_COMMENT'
}

/**
 * @readonly
 * @enum
 */
export enum ECommentableType {
  VIDEO = 'Video',
  REEL = 'Reel'
}

/**
 * @readonly
 * @enum
 */
export enum EShareableType {
  VIDEO = 'Video',
  REEL = 'Reel'
}

/**
 * @readonly
 * @enum
 */
export enum EReaction {
  NOPE = 'nope', // hide forever
  UNSURE = 'unsure', // display in skips screen
  LIKE = 'like', // display in like/superlike/supernova screen
  SUPERLIKE = 'superlike', // display in like/superlike/supernova screen
  SUPERNOVA = 'supernova', // display in like/superlike/supernova screen
  SKIP = 'skip',
  BOOKMARK = 'bookmark'
  // HEART = 'heart', // knowme-api
  // SHARE = 'share', // knowme-api
}

/**
 * @readonly
 * @enum
 */
export enum EVideoType {
  PFV = 'pfv',
  DEFAULT = 'default',
  CORPORATE = 'corporate',
  AUDIO_VIBE_CHECK = 'audioVibeCheck',
  VIDEO_VIBE_CHECK = 'videoVibeCheck',
  VIDEO_CAMPAIGN = 'campaignVideoVibeCheck',
  AUDIO_CAMPAIGN = 'campaignAudioVibeCheck',
  PFV_CAMPAIGN = 'campaignVideoPfv'
}

/**
 * @readonly
 * @enum
 */
export enum EVideoPrivacy {
  PUBLIC = 'public',
  PRIVATE = 'private',
  SEEDED = 'seeded'
}

/**
 * @readonly
 * @enum
 */
export enum EFileUploadCategory {
  PFP = 'pfp',
  PFV = 'pfv',
  RESUME = 'resume',
  TAPE = 'tape',
  VIDEO_VIBE_CHECK = 'videoVibeCheck'
}

/**
 * @readonly
 * @enum
 */
export enum EOrderOptions {
  ASC = 'ASC',
  DESC = 'DESC'
}

/**
 * @readonly
 * @enum
 */
export enum EOrderStatus {
  INIT = 'init',
  PENDING = 'pending',
  FAIL = 'fail',
  SUCCESS = 'success'
}

/**
 * @readonly
 * @enum
 */
export enum ERoleType {
  SUPER_ADMIN = 'superadmin',
  ADMIN = 'admin',
  MANAGER = 'manager',
  RECRUITER = 'recruiter',
  CANDIDATE = 'candidate',
  EXTERNAL = 'external'
}

/**
 * @readonly
 * @enum
 */
export enum EInterviewState {
  INTRO = 0,
  HIRED = 1,
  DISQUALIFIED = 2
}

/**
 * @readonly
 * @enum
 */
export enum ESubscriptionStatus {
  CANCELLED = 0,
  ACTIVATED = 1
}

/**
 * @readonly
 * @enum
 */
export enum EQuestionnaireType {
  CORE = 'core',
  AMB = 'amb'
}

/**
 * @readonly
 * @enum
 */
export enum EFeedbackRelateType {
  CANDIDATE = 'Candidate',
  VIDEO = 'Video',
  REEL = 'Reel'
}

/**
 * @readonly
 * @enum
 */
export enum EPrivateNoteRelateType {
  CANDIDATE = 'Candidate'
}

/**
 * @readonly
 * @enum
 */
export enum ESkipCompanyType {
  CRAWL = 'CompaniesCrawl',
  TYPING = 'CompaniesTyping'
}

/**
 * @readonly
 * @enum
 */
export enum ECommentReaction {
  LIKE = 1,
  UNLIKE = 2
}

/**
 * @readonly
 * @enum
 */
export enum ECreditType {
  INTRO = 1
}

/**
 * @readonly
 * @enum
 */
export enum ECreditHistoryType {
  INCREASE = 1,
  REDUCE = 2
}

/**
 * @readonly
 * @enum
 */
export enum EInvoiceStatus {
  PAID = 1
}

/**
 * @readonly
 * @enum
 */
export enum EStripePaymentIntentType {
  CREDITS = 'credits'
}

/**
 * @readonly
 * @enum
 */
export enum EStripePaymentIntentStatus {
  CANCELED = 'canceled',
  PROCESSING = 'processing',
  REQUIRES_ACTION = 'requires_action',
  REQUIRES_CAPTURE = 'requires_capture',
  REQUIRES_CONFIRMATION = 'requires_confirmation',
  REQUIRES_PAYMENT_METHOD = 'requires_payment_method',
  SUCCEEDED = 'succeeded'
}

/**
 * @readonly
 * @enum
 */
export enum EVideoImportProvider {
  TIKTOK = 1
}

/**
 * @readonly
 * @enum
 */
export enum EVideoImportStatus {
  PROCESSING = 0,
  SUCCEEDED = 1,
  FAILED = 2
}

/**
 * @readonly
 * @enum
 */
export const ENotificationTopicType = Object.freeze({
  TRICORDER_SUBMISSION: 'tricorder:submission',
  TRICORDER_LINKEDIN_URL: 'tricorder:linkedinUrl',
  INVIDUAL: 'individual:*'
})

/**
 * @readonly
 * @enum
 */
export const EEmploymentType = Object.freeze({
  FULL_TIME: 'FULL_TIME',
  PART_TIME: 'PART_TIME',
  SELF_EMPLOYED: 'SELF_EMPLOYED',
  FREELANCE: 'FREELANCE',
  CONTRACT: 'CONTRACT',
  INTERNSHIP: 'INTERNSHIP',
  APPRENTICESHIP: 'APPRENTICESHIP',
  SEASONAL: 'SEASONAL'
})

/**
 * @readonly
 * @enum
 */
export const ELocationType = Object.freeze({
  ON_SITE: 'ON_SITE',
  REMOTE: 'REMOTE',
  HYBRID: 'HYBRID'
})

/**
 * @readonly
 * @enum
 */
export enum EExpandYourReachAreaActions {
  ANSWER_QUESTION= 'answer_question',
  WATCH_VIDEO= 'watch_video',
  DO_QUIZZ= 'do_quizz'
}

export const ECampaignSettings = Object.freeze({
  GRACE_PERIOD: 'GracePeriod'
})
