import clsx from 'clsx'
import { FC } from 'react'
import { InfiniteScroll, VideoPlayer } from 'src/components'
import { ICompanyModel, ICompanyYoutubeVideoModel } from 'src/interfaces'
import Style from '../style.module.scss'

interface IProps {
  company?: ICompanyModel
  className?: string
  videos: ICompanyYoutubeVideoModel[]
  next: any
  hasMore: boolean
}

export const CompanyVideos: FC<IProps> = ({ company, className, ...props }) => {
  return (
    <div className={clsx('fx fx-column gap-2', Style.videoContainer)}>
      <strong>Videos</strong>

      <InfiniteScroll
        className="fx fx-extend fx-wrap-wrap gap-3"
        loader={null}
        scrollThreshold={0.95}
        dataLength={props?.videos?.length}
        next={props?.next}
        hasMore={props?.hasMore}
      >
        {props?.videos?.map((item) => (
          <div key={item.videoId} className="fx fx-column gap-3" style={{ maxWidth: '250px' }}>
            <VideoPlayer
              style={{
                width: '250px',
                height: '400px'
              }}
              className="mx-auto"
              url={`https://www.youtube.com/watch?v=${item.videoId}`}
            />

            <div className="fx fx-wrap-wrap fx-ai-center gap-1">
              <strong>Views:</strong>
              <span>{item?.countViews || 0}</span>
            </div>
          </div>
        ))}
      </InfiniteScroll>
    </div>
  )
}
