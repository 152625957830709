import { IRouterOption } from 'src/router'
import { QueueCampaignInfo } from './components/queue-campaign-info'
import { QueueScrapeJobs } from './components/queue-scrape-jobs'
import { QueueScrapedJob } from './components/queue-scraped-job'
import { EPaths } from './routes.path'

export enum ERoutes {
  QUEUE_SCRAPED_JOB = 'QUEUE_SCRAPED_JOB',
  QUEUE_SCRAPE_JOBS = 'QUEUE_SCRAPE_JOBS',
  QUEUE_CAMPAIGN_INFO = 'QUEUE_CAMPAIGN_INFO'
}

export const Routes: IRouterOption[] = [
  {
    path: EPaths.QUEUE_SCRAPED_JOB,
    component: QueueScrapedJob,
    name: ERoutes.QUEUE_SCRAPED_JOB,
    exact: true,
    meta: {}
  },
  {
    path: EPaths.QUEUE_SCRAPE_JOBS,
    component: QueueScrapeJobs,
    name: ERoutes.QUEUE_SCRAPE_JOBS,
    exact: true,
    meta: {}
  },
  {
    path: EPaths.QUEUE_CAMPAIGN_INFO,
    component: QueueCampaignInfo,
    name: ERoutes.QUEUE_CAMPAIGN_INFO,
    exact: true,
    meta: {}
  }
]

export const QueueModule = {
  ERoutes,
  Routes
}
