import { Button, Form, Input, Modal } from 'antd'
import { ChangeEvent, ComponentProps, FC, useCallback, useState } from 'react'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { ConversationApi } from 'src/api'
import { ErrorMessage, ModalFullscreen } from 'src/components'
import { useDidMountEffect, useUnsubscribe, useValidation } from 'src/hooks'
import { IConversationModel } from 'src/interfaces'
import { NotifyUtils } from 'src/utils'
import { array, number, object, string } from 'yup'
import { SelectAdmin } from '../select-admin'

const yupSchema = object().shape({
  title: string(),
  userIds: array().required().min(1).of(number())
})

const defaultFormData = {
  title: '',
  userIds: []
} as {
  title: string
  userIds: number[]
}

export const ModalConversation: FC<Omit<
  ComponentProps<typeof ModalFullscreen>,
  'onOk' | 'afterClose'
> & {
  id?: IConversationModel['id']
  // onOk?: () => any
  afterClose?: (saved: boolean, doc?: IConversationModel) => any
  btnProps?: ComponentProps<typeof Button>
}> = ({
  btnProps,
  ...props
}) => {
  const unsubscribe$ = useUnsubscribe()
  const [loading, setLoading] = useState(false)
  const [invisible, setInvisible] = useState<boolean | IConversationModel>(true)
  const [formData, setFormData] = useState({ ...defaultFormData })

  const onChange = useCallback((e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
    e.persist()
    const { name, value } = e.target
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }))
  }, [])

  const { validate, errors, reset } = useValidation({
    data: formData,
    schema: yupSchema
  })

  const save = useCallback(async () => {
    const { isValid } = await validate()
    if (!isValid) {
      return
    }

    const apiHandler = props.id
      ? ConversationApi.update(props.id, formData)
      : ConversationApi.store(formData)

    setLoading(true)
    from(apiHandler)
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          NotifyUtils.handleAxiosError(error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(({ data }) => {
        NotifyUtils.success({ message: `${props.id ? 'Update' : 'Create'} conversation successfully` })
        setInvisible(data)
      })
  }, [validate, props.id, formData, unsubscribe$])

  useDidMountEffect(() => {
    reset()

    if (invisible) {
      setLoading(false)
      setFormData(() => ({ ...defaultFormData }))

      const isSaved = typeof invisible !== 'boolean'
      props.afterClose?.(isSaved, isSaved ? invisible : undefined)
    }
  }, [invisible])

  // useDidMountEffect(() => {
  //   if (!invisible && props.id) {
  //     setLoading(true)
  //     from(ConversationApi.show(props.id))
  //       .pipe(
  //         takeUntil(unsubscribe$),
  //         catchError((error) => {
  //           NotifyUtils.handleAxiosError(error)
  //           return EMPTY
  //         }),
  //         finalize(() => setLoading(false))
  //       )
  //       .subscribe(({ data: conversation }) => {
  //         setFormData(() => ({
  //           title: conversation.title,
  //           tag: conversation.tag,
  //           weight: conversation.weight
  //         }))
  //       })
  //   }
  // }, [props.id, invisible])

  return (
    <>
      <Button
        type="primary"
        {...btnProps}
        onClick={() => setInvisible(false)}
      >
        {props.children || 'Create'}
      </Button>

      <Modal
        wrapClassName="__modal-conversation"
        title={`${props.id ? 'Edit' : 'Create'} Conversation`}
        open={!invisible}
        closable={!loading} // display X icon
        keyboard={false} // disable close on press ESC
        maskClosable={false} // disable close on click outside
        okText={props.id ? 'Update' : 'Save'}
        cancelText="Cancel"
        onOk={save}
        onCancel={() => setInvisible(true)}
        okButtonProps={{ disabled: loading }}
        cancelButtonProps={{ disabled: loading }}
      >
        {invisible
          ? null
          : (
            <Form
              className="fx fx-column fx-extend gap-2"
              labelCol={{ span: 4 }}
              // wrapperCol={{ span: 14 }}
            >

              <Form.Item
                required
                label="Users"
                labelAlign="left"
                labelCol={{ flex: '0 0 90px' }}
                className="m-0 fx-wrap-nowrap"
              >
                <SelectAdmin
                  multiple
                  label="fullName"
                  placeholder="Users"
                  value={formData.userIds}
                  status={errors.hasError('userIds') ? 'error' : undefined}
                  onChange={(values) => setFormData((prev) => ({
                    ...prev,
                    userIds: values as number[]
                  }))}
                />
                <ErrorMessage>{errors.getError('userIds')}</ErrorMessage>
              </Form.Item>

              {formData.userIds?.length > 1 && (
                <Form.Item
                  required
                  label="Title"
                  labelAlign="left"
                  labelCol={{ flex: '0 0 90px' }}
                  className="m-0"
                >
                  <Input
                    required
                    readOnly={loading}
                    name="title"
                    placeholder="Title"
                    value={formData.title}
                    status={errors.hasError('title') ? 'error' : undefined}
                    onChange={onChange}
                  />
                  <ErrorMessage>{errors.getError('title')}</ErrorMessage>
                </Form.Item>
              )}
            </Form>
          )}
      </Modal>
    </>
  )
}
