import { Pagination, Table } from 'antd'
import { omit } from 'lodash'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { takeUntil } from 'rxjs'
import { SettingApi } from 'src/api'
import { ERoleType } from 'src/constants/enum'
import { IPaginateCallback, IPaginateParams, useDidMountDebounce, usePaginateParams, useUnsubscribe } from 'src/hooks'
import { IPaginationResponse, ISettingModel } from 'src/interfaces'
import { BreadcrumbService, PaginationService } from 'src/services'
import { useAuthorization } from 'src/store'
import { EPaths } from '../../routes.path'
import { renderColumns } from './columns'

const PAGE_TITLE = 'Settings'

export const Settings: FC = () => {
  const unsubscribe$ = useUnsubscribe()
  const { is } = useAuthorization()
  const [loading, setLoading] = useState(false)
  const _paginationService = useMemo(() => new PaginationService<ISettingModel>(SettingApi), [])
  const [dataSource, setDataSource] = useState<IPaginationResponse<ISettingModel>>({
    total: 1,
    rows: []
  })

  const fetch = useCallback<IPaginateCallback>(
    (params) => _paginationService.paging(params),
    [_paginationService]
  )

  const { pagination, pushPagination, setPagination } = usePaginateParams(fetch)
  const [filter/** , setFilter */] = useState<Omit<IPaginateParams, 'page' | 'limit' | 'offset'>>(
    omit(pagination, ['page', 'limit', 'offset'])
  )

  const onUpdate = useCallback((record: ISettingModel) => {
    setDataSource((prev) => ({
      ...prev,
      rows: prev.rows?.map(
        (item) => item.key === record.key
          ? { ...item, ...record }
          : item
      )
    }))
  }, [])

  useDidMountDebounce(() => {
    setPagination({ ...pagination, page: 1 })
    return pushPagination({
      ...pagination,
      page: 1,
      ...filter
    })
  }, 2000, [filter])

  useEffect(() => {
    _paginationService
      .loading$
      .pipe(takeUntil(unsubscribe$))
      .subscribe(setLoading)
    _paginationService
      .pagination$
      .pipe(takeUntil(unsubscribe$))
      .subscribe((data) => setDataSource(data))
  }, [_paginationService, unsubscribe$])

  /**
   * breadcrumb
   */
  useEffect(() => {
    BreadcrumbService.items = [{
      route: EPaths.SETTINGS,
      label: PAGE_TITLE
    }]
    return () => {
      BreadcrumbService.items = []
    }
  }, [])

  return (
    <section className="fx fx-column fx-extend">
      <Table
        className="fx-extend"
        rowKey="id"
        loading={loading}
        columns={renderColumns({
          onUpdate: is(ERoleType.SUPER_ADMIN)
            ? onUpdate
            : undefined
        })}
        dataSource={dataSource.rows}
        pagination={false}
      />

      <Pagination
        className="fx-as-end mt-3"
        disabled={loading}
        total={dataSource.total}
        current={pagination.page}
        pageSize={pagination.limit}
        {
          ...{
            ...pagination,
            onChange(page, limit) {
              pushPagination({
                ...pagination,
                page,
                limit
              })
            }
          }
        }
      />
    </section>
  )
}
