import { Avatar, Divider, List } from 'antd'
import classNames from 'clsx'
import { FC, ReactNode, useCallback } from 'react'
import sanitizeHtml from 'sanitize-html'
import { InfiniteScroll } from '../infinite-scroll'
import Style from './style.module.scss'

interface INoticeListProps {
  data: any[]
  onClick: (item: any) => void
  onClear: () => void
  title: string
  locale: any
  emptyText?: ReactNode | string
  emptyImage?: string
  showClear?: boolean
  loadMore?: () => void
  hasMore?: boolean
}

export const NoticeList: FC<INoticeListProps> = ({
  data = [],
  onClick,
  onClear,
  title,
  locale,
  emptyText,
  emptyImage,
  showClear = true,
  hasMore = false,
  loadMore = () => null
}) => {
  const handleLoadMore = useCallback(() => {
    return loadMore()
  }, [loadMore])

  if (data.length === 0) {
    return (
      <div className={Style.notFound}>
        {emptyImage ? <img src={emptyImage} alt="not found"/> : null}
        <div className={Style.text}>{emptyText || locale.emptyText}</div>
      </div>
    )
  }

  return (
    <div style={{ height: 400 }}>
      <InfiniteScroll
        loader={null}
        scrollThreshold={0.95} // 95% scrollHeight
        dataLength={data.length}
        next={handleLoadMore}
        hasMore={hasMore}
        // endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
      >
        <List className={Style.list}>
          {data.map((item, i) => {
            const itemCls = classNames(Style.item, {
              [Style.read]: item.read
            })
            // eslint-disable-next-line no-nested-ternary
            const leftIcon = item.avatar
              ? (
                typeof item.avatar === 'string'
                  ? <Avatar className={Style.avatar} src={item.avatar}/>
                  : item.avatar
              )
              : null

            return (
              <List.Item className={itemCls} key={item.key || i} onClick={() => onClick(item)}>
                <List.Item.Meta
                  className={Style.meta}
                  avatar={<span className="iconElement">{leftIcon}</span>}
                  title={(
                    <div className={Style.title}>
                      {item.title}
                      <div className="extra">{item.extra}</div>
                    </div>
                  )}
                  description={(
                    <div>
                      <div className={Style.description} title={item.description}>
                        <span dangerouslySetInnerHTML={{ __html: sanitizeHtml(item.description) }}/>
                      </div>
                      <div className={Style.datetime}>{item.datetime}</div>
                    </div>
                  )}
                />
              </List.Item>
            )
          })}
        </List>

        {!hasMore && <Divider plain>You've seen all notifications</Divider>}
      </InfiniteScroll>
    </div>
  )
}
