import { Button, Input } from 'antd'
import { AxiosError } from 'axios'
import { FC, useCallback, useEffect, useState } from 'react'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { ChatGPTMessages } from 'src/components'
import { useBehaviorMapper, useUnsubscribe } from 'src/hooks'
import { IOpenAIModel } from 'src/interfaces'
import { BreadcrumbService, OpenAIChatService, OpenAIService } from 'src/services'
import { NotifyUtils } from 'src/utils'
import { EPaths } from './routes.path'

const PAGE_TITLE = 'AI'

export const AI: FC = () => {
  const unsubscribe$ = useUnsubscribe()
  const [models, setModels] = useState(OpenAIService.models)

  useEffect(() => {
    OpenAIService.getModels()

    OpenAIService.models$
      .pipe(takeUntil(unsubscribe$))
      .subscribe(setModels)
  }, [unsubscribe$])

  const [model, setModel] = useState<IOpenAIModel>()
  useEffect(() => {
    if (!model && models.length) {
      setModel(models[0])
    }
  }, [model, models])

  /**
   * breadcrumb
   */
  useEffect(() => {
    BreadcrumbService.items = [{
      route: EPaths.AI,
      label: PAGE_TITLE
    }]
    return () => {
      BreadcrumbService.items = []
    }
  }, [])

  const [content, setContent] = useState('')
  const [temperature/** , setTemperature */] = useState<number>()
  const [loading, setLoading] = useState(false)
  const messages = useBehaviorMapper(OpenAIChatService.messages$)

  const messaging = useCallback((content: string, temperature?: number) => {
    if (!model) {
      return
    }

    const promise = OpenAIChatService.send({
      model: model.id,
      content,
      temperature
    })

    setLoading(true)
    from(promise)
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error: AxiosError) => {
          NotifyUtils.handleAxiosError(error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(() => setContent(''))
  }, [model, unsubscribe$])

  return (
    <section className="fx fx-column fx-extend gap-2">
      <div className="fx fx-jc-space-between">
        {/* <div className="fx fx-extend fx-ai-center gap-2">
          <Select
            placeholder="Select model"
            style={{ flex: '0 0 50vw' }}
            value={model?.id}
            onChange={(id) => setModel(models.find((model) => model.id === id))}
          >
            {models.map((model) => (
              <Select.Option
                key={model.id}
                value={model.id}
              >
                Model - {model.id}
              </Select.Option>
            ))}
          </Select>
        </div> */}

        {/* <Input
          type="number"
          placeholder="Temperature"
          step={0.1}
          min={0}
          max={2}
          value={temperature || ''}
          onChange={(e) => setTemperature(+e.target.value)}
        /> */}
      </div>

      <ChatGPTMessages
        messages={messages}
        className="fx-1 bg-white"
      />

      <div className="fx gap-2">
        <Input.TextArea
          // showCount
          // maxLength={500}
          style={{ height: 120, resize: 'none' }}
          placeholder="Message..."
          disabled={loading}
          value={content}
          onChange={(e) => setContent(e.target.value)}
        />

        <Button
          type="primary"
          disabled={loading || !content}
          onClick={() => messaging(content, temperature)}
        >
          Send
        </Button>
      </div>
    </section>
  )
}
