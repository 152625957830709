import { Badge, Button, Checkbox, Space } from 'antd'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { takeUntil } from 'rxjs'
import { VideoApi } from 'src/api'
import { InfiniteScroll, ModalEditVibeSharing, SelectHashtag, SelectPrivacy, Video } from 'src/components'
import { EVideoPrivacy, EVideoType } from 'src/constants/enum'
import { useDidMountDebounce, useUnsubscribe } from 'src/hooks'
import { IVideoModel } from 'src/interfaces'
import { BreadcrumbService, LoadMoreService } from 'src/services'
import { DateUtils } from 'src/utils'
import { EPaths } from '../../routes.path'

const PAGE_TITLE = 'Browse Videos'

export const VideoBrowse: FC = () => {
  const unsubscribe$ = useUnsubscribe()
  // const [loading, setLoading] = useState(false)
  const loadMoreService = useMemo(() => new LoadMoreService<IVideoModel>(VideoApi), [])
  const [total, setTotal] = useState<number>()
  const [videos, setVideos] = useState<IVideoModel[]>([])
  const [filter, setFilter] = useState<{
    hashtagIds?: number[]
    privacy?: EVideoPrivacy
  }>({})

  const [modalVibeSharing, setModalVibeSharing] = useState<IVideoModel>()

  const [selected, setSelected] = useState<number[]>([])

  const onCheckChange = useCallback((checked: boolean, id: number) => {
    if (checked) {
      setSelected(
        (prev) => prev.includes(id)
          ? prev
          : [...prev, id]
      )
    } else {
      setSelected(
        (prev) => prev.filter(
          (val) => val !== id
        )
      )
    }
  }, [])

  const openModalVibeSharing = useCallback((video: IVideoModel) => {
    setModalVibeSharing(video)
  }, [setModalVibeSharing])

  const next = useCallback((params: typeof filter) => {
    loadMoreService.loadMore({
      videoType: EVideoType.DEFAULT,
      includes: ['author', 'curators', 'hashtags'],
      ...params
    })
  }, [loadMoreService])

  useDidMountDebounce(() => {
    setSelected([])
    loadMoreService.reset()
    next(filter)
  }, 100, [filter])

  useEffect(() => {
    // loadMoreService
    //   .loading$
    //   .pipe(takeUntil(unsubscribe$))
    //   .subscribe(setLoading)

    loadMoreService
      .pagination$
      .pipe(takeUntil(unsubscribe$))
      .subscribe(({ total }) => setTotal(total))

    loadMoreService
      .items$
      .pipe(takeUntil(unsubscribe$))
      .subscribe(setVideos)
  }, [loadMoreService, unsubscribe$])

  /**
   * breadcrumb
   */
  useEffect(() => {
    BreadcrumbService.items = [{
      route: EPaths.VIDEO_BROWSE,
      label: PAGE_TITLE
    }]
    return () => {
      BreadcrumbService.items = []
    }
  }, [])

  return (
    <>
      <section className="mod-video-browse">
        <div className="fx gap-2 mb-3">
          <SelectHashtag
            className="fx fx-extend"
            isPlaylist
            allowClear
            onChange={(value) => setFilter((prev) => ({
              ...prev,
              hashtagIds: value ? [value as number] : []
            }))}
          />

          <SelectPrivacy
            className="fx fx-extend"
            onChange={(value) => setFilter((prev) => ({
              ...prev,
              privacy: value as EVideoPrivacy
            }))}
          />

          <Badge showZero count={total}/>
        </div>

        <div className="fx gap-2 mb-3">
          <Button disabled={!selected.length} type="primary">
            Make public selected
          </Button>
          <Button disabled={!selected.length} type="primary" danger>
            Remove selected
          </Button>
          <Button disabled={!selected.length} type="primary" ghost className="bg-white" danger>
            Flag selected
          </Button>
          <Button disabled={!selected.length} type="primary" ghost className="bg-white">
            Reviewed selected
          </Button>
        </div>

        <InfiniteScroll
          className="fx fx-extend fx-wrap-wrap gap-3"
          loader={null}
          scrollThreshold={0.95} // 95% scrollHeight
          dataLength={videos.length}
          next={() => next(filter)}
          hasMore={loadMoreService.hasMore}
        >
          {videos.map(
            (item) => (
              <div key={item.id} className="video-item">
                <div className="fx fx-jc-space-between p-1 px-2" style={{ width: '100%' }}>
                  <Checkbox
                    checked={selected.includes(item.id)}
                    onChange={(e) => onCheckChange(e.target.checked, item.id)}
                  />
                </div>

                <Video
                  className="mb-3"
                  video={item}
                />

                {item.curators?.length
                  ? (
                    <div className="mb-3">
                      <strong>Curator notes:</strong>
                      <p>{item.curators[0].notes || 'No note'}</p>
                    </div>
                  )
                  : null}

                <Space size={6} wrap>

                  {/* <Button size="small" type="primary" danger>Remove</Button> */}
                  {/* <Button size="small" type="primary" ghost danger>Flag</Button> */}
                  {/* <Button size="small" type="primary" ghost>Reviewed</Button> */}

                  <Button
                    size="small"
                    type="primary"
                    onClick={() => openModalVibeSharing(item)}
                  >
                    Edit vibe sharing page
                  </Button>
                </Space>

                <div className="fx fx-column">
                  <div>
                    <span>User: </span>
                    <strong>{item.author?.username}</strong>
                  </div>
                  <div>
                    <span>Curated date: </span>
                    <strong>
                      {item.curators?.length
                        ? DateUtils.toLocaleString(item.curators[0].updatedAt)
                        : ''}
                    </strong>
                  </div>
                </div>
              </div>
            )
          )}
        </InfiniteScroll>
      </section>

      <ModalEditVibeSharing
        video={modalVibeSharing}
        open={!!modalVibeSharing}
        title="Edit vibe's SEO metadata"
        onOk={(videoUpdatedMetadata) => {
          if (modalVibeSharing && videoUpdatedMetadata) {
            modalVibeSharing.title = videoUpdatedMetadata.title
            modalVibeSharing.description = videoUpdatedMetadata.description
          }

          setModalVibeSharing(undefined)
        }}
        onCancel={() => setModalVibeSharing(undefined)}
      />
    </>
  )
}
