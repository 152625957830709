import { IRouterOption } from 'src/router'
import { Admin } from './components/list'
import { EPaths } from './routes.path'

export enum ERoutes {
  ADMIN = 'Admin.List'
}

export const Routes: IRouterOption[] = [
  {
    path: EPaths.ADMIN,
    component: Admin,
    name: ERoutes.ADMIN,
    exact: true,
    meta: {}
  }
]

export const AdminModule = {
  ERoutes,
  Routes
}
