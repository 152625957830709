import { Button, Pagination, Popconfirm, Select, Table } from 'antd'
import { omit } from 'lodash'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { QueueJobApi } from 'src/api'
import { ModalQueueScrapeJob } from 'src/components'
import { ERoleType } from 'src/constants'
import { IPaginateCallback, IPaginateParams, useBehaviorMapper, useDidMountDebounce, usePaginateParams, useUnsubscribe } from 'src/hooks'
import { EQueueJobStatus, EQueueName, IQueueJobModel, QueueJobStatusOptions } from 'src/interfaces'
import { BreadcrumbService, GodModeService, PaginationService } from 'src/services'
import { GlobalAction, useAuthorization } from 'src/store'
import { NotifyUtils } from 'src/utils'
import { EPaths } from '../../routes.path'
import { renderColumns } from '../columns'

const PAGE_TITLE = 'Queue Scraped Job'

export const QueueScrapedJob: FC = () => {
  const unsubscribe$ = useUnsubscribe()
  const { is } = useAuthorization()
  const isGodMode = useBehaviorMapper(GodModeService.enable$)
  const _paginationService = useMemo(() => new PaginationService<IQueueJobModel>(QueueJobApi), [])
  const loading = useBehaviorMapper(_paginationService.loading$)
  const dataSource = useBehaviorMapper(_paginationService.pagination$)

  const fetch = useCallback<IPaginateCallback>(
    (params) => _paginationService.paging({
      ...params,
      queueName: EQueueName.SCRAPE_JOB_INFO_QUEUE
    }),
    [_paginationService]
  )

  const { pagination, pushPagination, setPagination } = usePaginateParams(fetch)
  const [filter, setFilter] = useState<{ status?: EQueueJobStatus } & Omit<IPaginateParams, 'page' | 'limit' | 'offset'>>(
    omit(pagination, ['page', 'limit', 'offset'])
  )

  const onRetry = useCallback((job?: IQueueJobModel) => {
    const promise = job
      ? QueueJobApi.store({
        queueName: job.queueName,
        data: job.data || { refId: job.refId }
      })
      : QueueJobApi.retry({ queueName: EQueueName.SCRAPE_JOB_INFO_QUEUE })

    GlobalAction.setLoading(true)
    from(promise)
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          NotifyUtils.handleAxiosError(error)
          return EMPTY
        }),
        finalize(() => GlobalAction.setLoading(false))
      )
      .subscribe(() => {
        NotifyUtils.success({ message: 'Retry job successfully' })
        if (!job) {
          fetch({
            ...pagination,
            page: 1
          })
        }
      })
  }, [pagination, fetch, unsubscribe$])

  useDidMountDebounce(() => {
    const { status, ...paging } = pagination
    setPagination({ ...paging, page: 1 })
    return pushPagination({
      ...paging,
      page: 1,
      ...filter
    })
  }, 2000, [filter])

  /**
   * breadcrumb
   */
  useEffect(() => {
    BreadcrumbService.items = [{
      route: EPaths.QUEUE_SCRAPE_JOBS,
      label: PAGE_TITLE
    }]
    return () => {
      BreadcrumbService.items = []
    }
  }, [])

  return (
    <section className="fx fx-column fx-extend">
      <div className="fx fx-jc-space-between mb-2">
        <Select
          style={{ width: '200px' }}
          allowClear
          placeholder="All status"
          options={QueueJobStatusOptions}
          value={filter.status}
          onChange={(value) => setFilter((prev) => ({
            ...prev,
            status: value as EQueueJobStatus
          }))}
        />

        <div className="fx gap-2">
          <ModalQueueScrapeJob
            btnProps={{
              size: 'small',
              type: 'primary'
            }}
            afterClose={(saved) => saved && dataSource.page === 1 && fetch(pagination)}
          >
            Create
          </ModalQueueScrapeJob>

          {isGodMode && is(ERoleType.SUPER_ADMIN) && (
            <Popconfirm
              title="Confirm to retry?"
              onConfirm={() => onRetry()}
              okText="Yes"
              cancelText="No"
            >
              <Button
                danger
                size="small"
                type="primary"
              >
                Retry
              </Button>
            </Popconfirm>
          )}
        </div>
      </div>

      <Table
        className="fx-extend"
        rowKey="id"
        loading={loading}
        columns={renderColumns({
          onRetry: isGodMode && is(ERoleType.SUPER_ADMIN)
            ? onRetry
            : undefined
        })}
        dataSource={dataSource.rows}
        pagination={false}
      />

      <Pagination
        className="fx-as-end mt-3"
        disabled={loading}
        total={dataSource.total}
        current={pagination.page}
        pageSize={pagination.limit}
        {
          ...{
            ...pagination,
            onChange(page, limit) {
              pushPagination({
                ...pagination,
                page,
                limit
              })
            }
          }
        }
      />
    </section>
  )
}
