import { AxiosError } from 'axios'
import clsx from 'clsx'
import { FC, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import { EMPTY, catchError, finalize, from, map, switchMap, takeUntil } from 'rxjs'
import { CandidateApi } from 'src/api'
import { useUnsubscribe } from 'src/hooks'
import { ICandidateModel, IQuizResult } from 'src/interfaces'
import { QuizAmb, QuizCore } from 'src/partials'
import { BreadcrumbService } from 'src/services'
import { GlobalAction } from 'src/store'
import { NotifyUtils, UserUtils } from 'src/utils'
import { EPaths } from '../../../routes.path'
import Style from './style.module.scss'

export const CandidateQuizResultThirdView: FC = () => {
  const unsubscribe$ = useUnsubscribe()
  const { id } = useParams<{ id: string }>()
  const [candidate, setCandidate] = useState<ICandidateModel>()
  const [quizResult, setQuizResult] = useState<IQuizResult>()
  const [activeSnippetKey, setActiveSnippetKey] = useState<string>()

  const snippets = useMemo(() => {
    if (!quizResult?.snippets?.length) {
      return []
    }

    return quizResult.snippets
  }, [quizResult])

  const activeSnippet = useMemo(() => {
    if (!activeSnippetKey || !snippets.length) {
      return
    }

    return snippets.find((snippet) => snippet.key === activeSnippetKey)
  }, [activeSnippetKey, snippets])

  useEffect(() => {
    if (snippets.length) {
      setActiveSnippetKey(snippets[0].key)
    }
  }, [snippets])

  useEffect(() => {
    GlobalAction.setLoading(true)

    from(CandidateApi.show(id))
      .pipe(
        takeUntil(unsubscribe$),
        switchMap(({ data }) => {
          setCandidate(data)
          return from(CandidateApi.getQuizResult(data.id, { pointOfView: 'thirdPersonSingular' })).pipe(
            map(({ data }) => setQuizResult(data))
          )
        }),
        catchError((error: AxiosError) => {
          NotifyUtils.handleAxiosError(error)
          return EMPTY
        }),
        finalize(() => GlobalAction.setLoading(false))
      )
      .subscribe()
  }, [id, unsubscribe$])

  /**
   * breadcrumb
   */
  useEffect(() => {
    BreadcrumbService.items = [
      {
        route: EPaths.CANDIDATE,
        label: 'Candidates'
      },
      {
        route: [EPaths.CANDIDATE_QUIZ_RESULT, { id }],
        label: 'Quiz result'
      }
    ]
    return () => {
      BreadcrumbService.items = []
    }
  }, [id])

  const firstName = useMemo(
    () => UserUtils.getFirstName(candidate),
    [candidate]
  )

  return (
    <section className="fx-1 fx fx-column gap-4">
      <h2 className="text-center">{firstName}'s Coaching Playbook</h2>

      <div className="fx gap-4">
        <div className="fx-1 fx fx-column">
          <h3 className="text-center">{firstName}'s Work Style</h3>

          <div className={clsx('fx fx-column gap-2', Style.blockStats)}>
            <QuizCore coreScores={quizResult?.coreScores}/>
          </div>
        </div>

        <div className="fx-1 fx fx-column">
          <h3 className="text-center">What Drives {firstName}</h3>

          <div className={clsx('fx fx-column gap-2', Style.blockStats)}>
            <QuizAmb ambScores={quizResult?.ambScores}/>
          </div>
        </div>
      </div>

      <div className="fx gap-3">
        <div className="fx fx-column gap-2">
          {snippets.map((snippet, index) => (
            <div
              key={`${index}-${snippet.key}`}
              onClick={() => setActiveSnippetKey(snippet.key)}
              className={clsx('fx fx-ai-center fx-jc-center bg-white', Style.snippetsSelector, { [Style.active]: activeSnippetKey === snippet.key })}
            >
              <span>{snippet.key}</span>
            </div>
          ))}
        </div>

        {activeSnippet && (
          <div className={clsx('fx-1 fx fx-column gap-3 p-4 bg-white', Style.snippetContent)}>
            <div property="title">{activeSnippet.title}</div>

            <div property="description">{activeSnippet.description}</div>

            <div property="snippets" className="fx fx-column gap-4">
              {activeSnippet.key === 'Overview' && activeSnippet.svg.map((svg, index) => (
                <div key={`${index}-${activeSnippet.key}`}>
                  {svg}
                </div>
              ))}

              {activeSnippet.key !== 'Overview' && (
                <ul>
                  {activeSnippet.svg.map((svg, index) => (
                    <li key={`${index}-${activeSnippet.key}`}>
                      {svg}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        )}
      </div>
    </section>
  )
}
