import { EExpandYourReachAreaActions } from 'src/constants/enum'
import { IModel } from './model'

export interface IExpandYourAreaModel extends IModel {
  action: string
  item: string
  description?: string
  redirectURL?: string
}

export const EExpandYourAreaActionsText = Object.freeze({
  [EExpandYourReachAreaActions.ANSWER_QUESTION]: 'Answer Question',
  [EExpandYourReachAreaActions.WATCH_VIDEO]: 'Watch Video',
  [EExpandYourReachAreaActions.DO_QUIZZ]: 'Do Quizz'
})
