import axios from 'axios'
import { INotificationModel, IPaginationQuery, TAxiosResponsePagination } from 'src/interfaces'

export class NotificationApi {
  static readonly _prefix = '/notifications'

  static index(params: IPaginationQuery & {
    topic?: string
  } = {}): TAxiosResponsePagination<INotificationModel> {
    return axios.get(`${NotificationApi._prefix}`, { params })
  }
}
