import { Button, Popconfirm, Space, Switch, TableProps } from 'antd'
import { ModalHashtag } from 'src/components'
import { IHashtagModel } from 'src/interfaces'
import { TimeZoneService } from 'src/services'

export const renderColumns = ({
  toggleIsPlaylist,
  onUpdate,
  onDelete
}: {
  toggleIsPlaylist?: (record: IHashtagModel) => any
  onUpdate?: (record: IHashtagModel) => any
  onDelete?: (record: IHashtagModel) => any
} = {}): Required<TableProps<IHashtagModel>>['columns'] => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: 'Tag',
    dataIndex: 'tag',
    key: 'tag'
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title'
  },
  {
    title: 'Weight',
    dataIndex: 'weight',
    key: 'weight'
  },
  {
    title: 'Is playlist',
    dataIndex: 'isPlaylist',
    key: 'isPlaylist',
    render: (val: string, record: IHashtagModel) => (
      <Switch
        disabled={!toggleIsPlaylist}
        checked={record.hashtagFeature?.isPlaylist}
        onChange={() => toggleIsPlaylist?.(record)}
      />
    )
  },
  {
    title: 'CreatedAt',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (val: string) => TimeZoneService.format(val)
  },
  {
    title: 'UpdatedAt',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    render: (val: string) => TimeZoneService.format(val)
  },
  {
    title: 'Action',
    key: 'action',
    render: (text: string, record) => (
      <Space
        wrap
        size="small"
        direction="vertical"
      >
        {onUpdate && (
          <ModalHashtag
            id={record.id}
            afterClose={(saved, doc) => saved && doc && onUpdate(doc)}
            btnProps={{
              type: 'ghost',
              size: 'small'
            }}
          >Edit
          </ModalHashtag>
        )}

        {onDelete && (
          <Popconfirm
            title="Confirm to delete?"
            onConfirm={() => onDelete(record)}
            okText="Yes"
            cancelText="No"
          >
            <Button size="small" danger type="primary">Delete</Button>
          </Popconfirm>
        )}
      </Space>
    )
  }
]
