import { Avatar, Button, Space, TableProps } from 'antd'
import { Link } from 'react-router-dom'
import { ModalLinkedInJob } from 'src/components'
import { IScrapedJobModel } from 'src/interfaces'
import { ERoutes, generate } from 'src/router'
import { TimeZoneService } from 'src/services'
import { ScrapedJobUtils, UserUtils } from 'src/utils'

export const renderColumns = (props: {
  disabled?: boolean
  selectedIds?: IScrapedJobModel['id'][]
  onSelect?: ((doc: IScrapedJobModel, selected?: boolean) => any)
  onEdit?: (doc: IScrapedJobModel) => any
  onSeedSubmissions?: ((doc: IScrapedJobModel) => any)
  onCopyClaimCampaignUrl?: ((doc: IScrapedJobModel) => any)
  onCopyPublicCampaignUrl?: ((doc: IScrapedJobModel) => any)
}): TableProps<IScrapedJobModel>['columns'] => [
  {
    title: 'ID',
    dataIndex: 'jobId',
    key: 'jobId',
    render: (jobId: string, record: IScrapedJobModel) => (
      <a
        className="oneline-text"
        style={{ maxWidth: '200px' }}
        rel="noreferrer"
        target="_blank"
        href={ScrapedJobUtils.getJobLink(record)}
      >
        {jobId}
      </a>
    )
  },
  // {
  //   title: 'Provider',
  //   dataIndex: 'provider',
  //   key: 'provider'
  // },
  {
    title: 'Title',
    dataIndex: 'jobTitle',
    key: 'jobTitle',
    render: (jobTitle: string, record: IScrapedJobModel) => (
      <a
        title={jobTitle}
        className="oneline-text"
        style={{ maxWidth: '200px' }}
        rel="noreferrer"
        target="_blank"
        href={ScrapedJobUtils.getJobLink(record)}
      >
        {jobTitle}
      </a>
    )
  },
  {
    title: 'Applicants',
    dataIndex: 'applicants',
    key: 'applicants'
  },
  // {
  //   title: 'Job Type',
  //   dataIndex: 'jobType',
  //   key: 'jobType'
  // },
  // {
  //   title: 'Working Type',
  //   dataIndex: 'jobWorkingType',
  //   key: 'jobWorkingType'
  // },
  // {
  //   title: 'Status',
  //   dataIndex: 'status',
  //   key: 'status'
  // },
  {
    title: 'Company',
    dataIndex: 'company',
    key: 'company',
    render: (_: string, record: IScrapedJobModel) => (
      <div className="fx fx-ai-center gap-2">
        <Avatar size={30} src={record.companyLogo}>
          {record.companyName}
        </Avatar>
        <div className="fx fx-column">
          <a
            rel="noreferrer"
            target="_blank"
            href={record.companyUrl}
          >
            <strong>{record.companyName}</strong>
          </a>

          {Boolean(record.employeesFrom || record.employeesTo) && (
            <span>
              {[record.employeesFrom, record.employeesTo].filter(Boolean).join('-')}
                &nbsp;employees
            </span>
          )}
        </div>
      </div>
    )
  },
  {
    title: 'Poster',
    dataIndex: 'poster',
    key: 'poster',
    render: (_: string, record: IScrapedJobModel) => (
      <div className="fx fx-column gap-1">
        <a
          rel="noreferrer"
          target="_blank"
          href={record.recruiterUrl}
        >
          {record.recruiterName}
        </a>
        <span title={record.recruiterBio}>
          {record.recruiterBio}
        </span>
      </div>
    )
  },
  {
    title: 'Campaign',
    dataIndex: 'campaignId',
    key: 'campaignId',
    render: (campaignId: number, record: IScrapedJobModel) => campaignId && (
      <div className="fx fx-column gap-1">
        <Link to={generate([ERoutes.CAMPAIGN_SUBMISSIONS, { id: campaignId }])}>{campaignId}</Link>

        {record.campaign?.author && (
          <>
            Owner: {UserUtils.getFullName(record.campaign.author) ||
            record.campaign.author.email ||
            record.campaign.author.username}
          </>
        )}
      </div>
    )
  },
  {
    title: 'CreatedAt',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (val: string) => TimeZoneService.format(val)
  },
  // {
  //   title: 'UpdatedAt',
  //   dataIndex: 'updatedAt',
  //   key: 'updatedAt',
  //   render: (val: string) => TimeZoneService.format(val)
  // }
  {
    title: 'Action',
    key: 'action',
    render: (_: string, record: IScrapedJobModel) => (
      <Space
        wrap
        size="small"
        direction="vertical"
      >
        <ModalLinkedInJob
          job={record}
          btnProps={{
            size: 'small',
            disabled: props.disabled
          }}
        />

        <Button
          type="primary"
          size="small"
          disabled={props.disabled}
          onClick={() => props.onEdit?.(record)}
        >
          Edit
        </Button>

        {!!props.onSelect && (
          <Button
            type="primary"
            size="small"
            disabled={props.disabled}
            onClick={() => props.onSelect?.(record, props.selectedIds?.includes(record.id))}
          >
            {props.selectedIds?.includes(record.id) ? 'Remove' : 'Select'}
          </Button>
        )}

        {!!props.onSeedSubmissions && (
          <Button
            type="primary"
            size="small"
            disabled={props.disabled}
            onClick={() => props.onSeedSubmissions?.(record)}
          >
            Seed campaign submissions
          </Button>
        )}

        {!!props.onCopyPublicCampaignUrl && (
          <Button
            type="primary"
            size="small"
            disabled={props.disabled}
            onClick={() => props.onCopyPublicCampaignUrl?.(record)}
          >
            Get public campaign URL
          </Button>
        )}

        {!!props.onCopyClaimCampaignUrl && (
          <Button
            type="primary"
            size="small"
            disabled={props.disabled}
            onClick={() => props.onCopyClaimCampaignUrl?.(record)}
          >
            Get claim campaign URL
          </Button>

        // <Popconfirm
        //   title="Are you sure to copy campaign?"
        //   okText="Yes"
        //   cancelText="No"
        //   disabled={props.disabled}
        //   onConfirm={() => props.onCopyClaimCampaignUrl?.(record)}
        // >
        //   <Button
        //     type="primary"
        //     size="small"
        //     disabled={props.disabled}
        //   >
        //     Copy campaign
        //   </Button>
        // </Popconfirm>
        )}
      </Space>
    )
  }
]
