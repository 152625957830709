import { FC, useEffect } from 'react'
import { BreadcrumbService, MessengerService } from 'src/services'
import { Conversations } from './components/conversations'
import { MessagesBox } from './components/messages-box'
import { EPaths } from './routes.path'

const PAGE_TITLE = 'Messages'

export const Messenger: FC = () => {
  useEffect(() => {
    MessengerService.pull()
    return () => {
      MessengerService.stopPull()
      MessengerService.setConversation()
      MessengerService._loadMoreConversations.reset()
    }
  }, [])

  /**
   * breadcrumb
   */
  useEffect(() => {
    BreadcrumbService.items = [{
      route: EPaths.MESSAGES,
      label: PAGE_TITLE
    }]
    return () => {
      BreadcrumbService.items = []
    }
  }, [])

  return (
    <section className="fx fx-extend">
      <Conversations className="bg-white"/>
      <MessagesBox className="bg-white"/>
    </section>
  )
}
