import { BehaviorSubject } from 'rxjs'
import { AIApi } from 'src/api'
import { EOpenAIMessageRole, IOpenAIMessage } from 'src/interfaces/open-ai'

export const OpenAIChatService = new (
  class {
    private readonly MAX_MESSAGES = 500

    readonly messages$ = new BehaviorSubject<IOpenAIMessage[]>([])
    get messages() {
      return this.messages$.getValue()
    }

    add(content: string, ofClient = false) {
      const messages = [
        ...this.messages,
        {
          id: this.messages.length,
          role: ofClient
            ? EOpenAIMessageRole.USER
            : EOpenAIMessageRole.ASSISTANT,
          content
        }
      ]

      if (messages.length >= this.MAX_MESSAGES) {
        messages.shift()
      }

      this.messages$.next(messages)
    }

    send(payload: {
      model: string
      temperature?: number
      content: string
    }) {
      this.add(payload.content, true)
      return AIApi.messaging({
        // model: payload.model,
        // temperature: payload.temperature,
        messages: this.messages.map((message) => ({
          role: message.role,
          content: message.content
        }))
      }).then((response) => {
        this.add(response.data.content)
        return response
      })
    }

    reset() {
      this.messages$.next([])
    }
  }
)()
