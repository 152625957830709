import { Button } from 'antd'
import { FC, useCallback, useEffect, useState } from 'react'
import { finalize, from, takeUntil } from 'rxjs'
import { CacheApi } from 'src/api'
import { ERoleType } from 'src/constants/enum'
import { useBehaviorMapper, useUnsubscribe } from 'src/hooks'
import { BreadcrumbService, GodModeService } from 'src/services'
import { useAuthorization } from 'src/store'
import { NotifyUtils } from 'src/utils'
import { EPaths } from '../../routes.path'

const PAGE_TITLE = 'Dashboard'

export const Dashboard: FC = () => {
  /**
   * breadcrumb
   */
  useEffect(() => {
    BreadcrumbService.items = [{
      route: EPaths.DASHBOARD,
      label: PAGE_TITLE
    }]
    return () => {
      BreadcrumbService.items = []
    }
  }, [])

  const { is } = useAuthorization()
  const unsubscribe$ = useUnsubscribe()
  const isGodMode = useBehaviorMapper(GodModeService.enable$)
  const [loading, setLoading] = useState(false)
  const onCacheClear = useCallback(() => {
    setLoading(true)
    from(CacheApi.clear())
      .pipe(
        takeUntil(unsubscribe$),
        finalize(() => setLoading(false))
      )
      .subscribe({
        next: () => NotifyUtils.success({ message: 'Cache clear successfully' }),
        error: NotifyUtils.handleAxiosError
      })
  }, [unsubscribe$])

  return (
    <section className="fx fx-column gap-2">
      <div>
        <a
          href="https://docs.google.com/spreadsheets/d/1XDZ4Q404xV0cBUuD-9TB8PupN0mvXZEnhiMBMT6AgUQ/edit#gid=0"
          target="_blank"
          rel="noreferrer"
        >
          Jobs (Google Sheet)
        </a>
      </div>

      <div>
        <a
          href="https://docs.google.com/spreadsheets/d/1bY3u4jfyOkSfPdcYFletwLn8xhimFANG9u_fkBXIuBk/edit?pli=1#gid=0"
          target="_blank"
          rel="noreferrer"
        >
          Job Attributes (Google Sheet)
        </a>
      </div>

      {isGodMode && is(ERoleType.SUPER_ADMIN) && (
        <div>
          <Button
            danger
            disabled={loading}
            onClick={onCacheClear}
          >
            Clear Cache
          </Button>
        </div>
      )}
    </section>
  )
}
