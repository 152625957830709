import { ComponentProps, FC } from 'react'
import { Select } from 'antd'
import { EVideoPrivacy } from 'src/constants/enum'

export const PRIVACY_OPTIONS: ComponentProps<typeof Select>['options'] = [
  {
    value: EVideoPrivacy.PRIVATE,
    label: 'Private'
  },
  {
    value: EVideoPrivacy.PUBLIC,
    label: 'Public'
  }
]

export const SelectPrivacy: FC<Omit<ComponentProps<typeof Select>, 'options'>> = (props) => {
  return (
    <Select
      {...props}
      allowClear
      placeholder={props.placeholder || 'All privacy'}
      options={PRIVACY_OPTIONS}
    />
  )
}
