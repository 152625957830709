import { AuthApi, ProfileApi } from 'src/api'
import { AUTH_FALLBACK_KEY } from 'src/constants'
import { browserHistory } from 'src/router'
import { FirebaseNotificationService } from 'src/services'
import { NotifyUtils, StorageUtils } from 'src/utils'
import { AuthenticationUtils } from 'src/utils/authentication.utils'
import { AuthState } from '../state'

export class AuthAction {
  static signIn(payload: Parameters<typeof AuthApi.signIn>[0]) {
    return AuthApi.signIn(payload)
      .then(({ data }) => {
        AuthState.next({
          isAuthenticated: true,
          user: data.profile
        })

        const toURL = StorageUtils.getItem<string>(AUTH_FALLBACK_KEY)
        if (toURL) {
          StorageUtils.removeItem(AUTH_FALLBACK_KEY)
          browserHistory.push(toURL)
        }
      })
      .catch(NotifyUtils.handleAxiosError)
  }

  static getProfile() {
    return ProfileApi.get()
      .then(({ data }) => AuthState.next({
        isAuthenticated: true,
        user: data
      }))
  }

  static signOut() {
    return AuthApi.signOut()
      .then(() => FirebaseNotificationService.unbind())
      .finally(() => {
        AuthState.next({ isAuthenticated: false })
        AuthenticationUtils.clear()
      })
  }
}
