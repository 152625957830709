import axios from 'axios'
import { IExpandYourAreaModel, IPaginationQuery, TAxiosResponseData, TAxiosResponsePagination } from 'src/interfaces'

export class ExpandYourAreaApi {
  static readonly _prefix = '/expand-your-reachs'

  static parseParams(params: IPaginationQuery = {}) {
    return {
      ...params
    }
  }

  static index(params: IPaginationQuery = {}): TAxiosResponsePagination<IExpandYourAreaModel> {
    return axios.get(`${ExpandYourAreaApi._prefix}`, { params })
  }

  static show(id: number | string): TAxiosResponseData<IExpandYourAreaModel> {
    return axios.get(`${ExpandYourAreaApi._prefix}/${id}`)
  }

  static store(payload: IExpandYourAreaModel): TAxiosResponseData<IExpandYourAreaModel> {
    return axios.post(`${ExpandYourAreaApi._prefix}`, payload)
  }

  static update(id: number | string, payload: IExpandYourAreaModel): TAxiosResponseData<IExpandYourAreaModel> {
    return axios.patch(`${ExpandYourAreaApi._prefix}/${id}`, payload)
  }

  static destroy(id: number | string): TAxiosResponseData<void> {
    return axios.delete(`${ExpandYourAreaApi._prefix}/${id}`)
  }
}
