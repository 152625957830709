import { AppstoreOutlined, CalendarOutlined, CommentOutlined, ContactsOutlined, FileWordOutlined, GiftOutlined, MailOutlined, MenuOutlined, MessageOutlined, NotificationOutlined, ReconciliationOutlined, SettingOutlined, TagsOutlined, TeamOutlined, UsergroupAddOutlined, VideoCameraOutlined } from '@ant-design/icons'
import { ComponentType } from 'react'
import { matchPath } from 'react-router'
import { ERoutes, generate } from 'src/router'

// export const menus: IMenuItem[] = Array.from(
//   new Array(5),
//   (val, i) => ({
//     key: `menuItem${i + 1}`,
//     label: `Menu item ${i + 1}`,
//     pathname: `/menu-url-${i + 1}`,
//     subMenus: Array.from(
//       new Array(10),
//       (val, j) => ({
//         key: `menuItemSub${i * 10 + j + 1}`,
//         label: `Sub menu item ${i * 10 + j + 1}`,
//         pathname: `/menu-url-${i * 10 + j + 1}`
//       })
//     )
//   })
// )

export interface IMenuItem {
  key: string
  label: string
  pathname?: string
  icon?: ComponentType
  subMenus?: IMenuItem[]
  superAdmin?: boolean
}

export const menus: IMenuItem[] = [
  {
    key: ERoutes.DASHBOARD,
    label: 'Dashboard',
    icon: AppstoreOutlined,
    pathname: generate(ERoutes.DASHBOARD)
  },
  {
    key: ERoutes.COMPANY,
    label: 'Company Management',
    icon: ReconciliationOutlined,
    pathname: generate(ERoutes.COMPANY)
  },
  {
    key: 'users-management',
    label: 'User Management',
    icon: MenuOutlined,
    subMenus: [
      {
        key: ERoutes.ADMIN,
        label: 'Admin Management',
        icon: TeamOutlined,
        pathname: generate(ERoutes.ADMIN),
        superAdmin: true
      },
      {
        key: ERoutes.CANDIDATE,
        label: 'Candidates',
        icon: UsergroupAddOutlined,
        pathname: generate(ERoutes.CANDIDATE)
      },
      {
        key: ERoutes.HIRING_MANAGER_LIST,
        label: 'Hiring Managers',
        icon: UsergroupAddOutlined,
        pathname: generate(ERoutes.HIRING_MANAGER_LIST)
      },
      {
        key: ERoutes.CALENDAR_USERS,
        label: 'Calendar Report',
        icon: CalendarOutlined,
        pathname: generate(ERoutes.CALENDAR_USERS)
      }
    ]
  },
  {
    key: 'campaigns-management',
    label: 'Campaign Management',
    icon: MenuOutlined,
    subMenus: [
      {
        key: ERoutes.CAMPAIGN_DASHBOARD,
        label: 'Jobs Dashboard',
        icon: ReconciliationOutlined,
        pathname: generate(ERoutes.CAMPAIGN_DASHBOARD)
      },
      {
        key: ERoutes.CAMPAIGN,
        label: 'Campaign',
        icon: ReconciliationOutlined,
        pathname: generate(ERoutes.CAMPAIGN)
      },
      {
        key: ERoutes.CAMPAIGNS_SUBMISSIONS,
        label: 'Submissions',
        icon: ReconciliationOutlined,
        pathname: generate(ERoutes.CAMPAIGNS_SUBMISSIONS)
      },
      {
        key: ERoutes.ARCHIVED_CAMPAIGN,
        label: 'Archived Campaign',
        icon: ReconciliationOutlined,
        pathname: generate(ERoutes.ARCHIVED_CAMPAIGN)
      }
    ]
  },
  {
    key: 'scraped-data-management',
    label: 'Scraped Data Management',
    icon: MenuOutlined,
    subMenus: [
      {
        key: ERoutes.SCRAPED_JOBS,
        label: 'Jobs',
        icon: AppstoreOutlined,
        pathname: generate(ERoutes.SCRAPED_JOBS)
      },
      {
        key: ERoutes.SCRAPED_CONTACTS,
        label: 'Contacts',
        icon: ContactsOutlined,
        pathname: generate(ERoutes.SCRAPED_CONTACTS)
      },
      {
        key: ERoutes.SCRAPED_COMPANIES,
        label: 'Companies',
        icon: UsergroupAddOutlined,
        pathname: generate(ERoutes.SCRAPED_COMPANIES)
      },
      {
        key: ERoutes.SCRAPED_SHEETS,
        label: 'Sheets',
        icon: FileWordOutlined,
        pathname: generate(ERoutes.SCRAPED_SHEETS)
      }
    ]
  },
  {
    key: 'forms-management',
    label: 'Form Management',
    icon: MenuOutlined,
    subMenus: [
      {
        key: ERoutes.AFFILIATE_FORM,
        label: 'Affiliate',
        icon: CommentOutlined,
        pathname: generate(ERoutes.AFFILIATE_FORM)
      },
      {
        key: ERoutes.CONTACT_FORM,
        label: 'Contact',
        icon: CommentOutlined,
        pathname: generate(ERoutes.CONTACT_FORM)
      },
      {
        key: ERoutes.FEEDBACK,
        label: 'Feedback',
        icon: CommentOutlined,
        pathname: generate(ERoutes.FEEDBACK)
      }
    ]
  },
  {
    key: 'videos-management',
    label: 'Videos Management',
    icon: MenuOutlined,
    subMenus: [
      {
        key: ERoutes.HASHTAG,
        label: 'Hashtag',
        icon: TagsOutlined,
        pathname: generate(ERoutes.HASHTAG)
      },
      {
        key: ERoutes.VIBE_RATING,
        label: 'Vibe Check Rating',
        icon: VideoCameraOutlined,
        pathname: generate(ERoutes.VIBE_RATING)
      },
      {
        key: ERoutes.VIDEO_BROWSE,
        label: 'Browse Videos',
        icon: VideoCameraOutlined,
        pathname: generate(ERoutes.VIDEO_BROWSE)
      },
      {
        key: ERoutes.TIKTOK_IMPORT,
        label: 'TikTok Import',
        icon: VideoCameraOutlined,
        pathname: generate(ERoutes.TIKTOK_IMPORT)
      }
      // {
      //   key: ERoutes.VIBE_CHECK,
      //   label: 'Vibe Check Video',
      //   icon: MenuOutlined,
      //   pathname: generate(ERoutes.VIBE_CHECK)
      // }
    ]
  },
  {
    key: 'blogs-management',
    label: 'Blogs Management',
    icon: MenuOutlined,
    subMenus: [
      {
        key: ERoutes.BLOG_CATEGORY_MANAGEMENT,
        label: 'Category',
        icon: AppstoreOutlined,
        pathname: generate(ERoutes.BLOG_CATEGORY_MANAGEMENT)
      },
      {
        key: ERoutes.BLOG_POST_MANAGEMENT,
        label: 'Post',
        icon: AppstoreOutlined,
        pathname: generate(ERoutes.BLOG_POST_MANAGEMENT)
      }
    ]
  },
  {
    key: 'analytics',
    label: 'Analytics',
    icon: MenuOutlined,
    subMenus: [
      {
        key: ERoutes.ANALYTIC_HM,
        label: 'Hiring Manager',
        icon: AppstoreOutlined,
        pathname: generate(ERoutes.ANALYTIC_HM)
      },
      {
        key: ERoutes.ANALYTIC_MOBILE,
        label: 'Mobile',
        icon: AppstoreOutlined,
        pathname: generate(ERoutes.ANALYTIC_MOBILE)
      },
      {
        key: ERoutes.ANALYTIC_MOBILE_SESSIONS,
        label: 'Mobile Sessions',
        icon: AppstoreOutlined,
        pathname: generate(ERoutes.ANALYTIC_MOBILE_SESSIONS)
      }
    ]
  },
  {
    key: 'Queue',
    label: 'Queue',
    icon: MenuOutlined,
    subMenus: [
      {
        key: ERoutes.QUEUE_SCRAPED_JOB,
        label: 'Queue Scraped Job Info',
        icon: AppstoreOutlined,
        pathname: generate(ERoutes.QUEUE_SCRAPED_JOB)
      },
      {
        key: ERoutes.QUEUE_SCRAPE_JOBS,
        label: 'Queue Scrape Jobs',
        icon: AppstoreOutlined,
        pathname: generate(ERoutes.QUEUE_SCRAPE_JOBS)
      },
      {
        key: ERoutes.QUEUE_CAMPAIGN_INFO,
        label: 'Queue Campaign Info',
        icon: AppstoreOutlined,
        pathname: generate(ERoutes.QUEUE_CAMPAIGN_INFO)
      }
    ]
  },
  {
    key: ERoutes.NOTIFICATION_TEMPLATE,
    label: 'Notification Template',
    icon: NotificationOutlined,
    pathname: generate(ERoutes.NOTIFICATION_TEMPLATE)
  },
  {
    key: ERoutes.PROMO_CODE,
    label: 'Promo Code',
    icon: GiftOutlined,
    pathname: generate(ERoutes.PROMO_CODE)
  },
  {
    key: ERoutes.MESSAGES,
    label: 'Messages',
    icon: MailOutlined,
    pathname: generate(ERoutes.MESSAGES)
  },
  {
    key: ERoutes.AI,
    label: 'AI Chatbot',
    icon: MessageOutlined,
    pathname: generate(ERoutes.AI)
  },
  {
    key: ERoutes.EXPAND_YOUR_AREA,
    label: 'Expand Your Area',
    icon: SettingOutlined,
    pathname: generate(ERoutes.EXPAND_YOUR_AREA)
  },
  {
    key: ERoutes.SETTINGS,
    label: 'Settings',
    icon: SettingOutlined,
    pathname: generate(ERoutes.SETTINGS)
  }
]

export const getOpenKeys = (pathname: string) => {
  const openKeys: string[] = []

  menus.forEach((menu) => {
    if (menu.subMenus) {
      menu.subMenus.forEach((subMenu) => {
        if (subMenu.pathname && matchPath(pathname, subMenu.pathname)) {
          openKeys.push(menu.key)
        }
      })
    } else if (menu.pathname && matchPath(pathname, menu.pathname)) {
      openKeys.push(menu.key)
    }
  })

  return openKeys
}
