import axios from 'axios'
import { IPaginationQuery, TAxiosResponsePagination } from 'src/interfaces'
import { IScrapedSheetModel } from 'src/interfaces/models/scraped-sheet.model'

export class ScrapedSheetApi {
  static readonly _prefix = '/cron-sheets'

  static index(params: IPaginationQuery = {}): TAxiosResponsePagination<IScrapedSheetModel> {
    return axios.get(`${ScrapedSheetApi._prefix}`, { params })
  }

  static linkedInConnection() {
    return axios.post(`${ScrapedSheetApi._prefix}/scraped-connection`)
  }

  static startupCompany() {
    return axios.post(`${ScrapedSheetApi._prefix}/scraped-companies`)
  }
}
