import { Button, Form, Input, Select } from 'antd'
import { ChangeEvent, ComponentProps, FC, useCallback, useState } from 'react'
import { finalize, from, takeUntil } from 'rxjs'
import { CandidateApi } from 'src/api'
import { ModalFullscreen } from 'src/components'
import { useDidMountEffect, useUnsubscribe, useValidation } from 'src/hooks'
import { ENotificationAction, ENotificationActionText, ICandidateModel } from 'src/interfaces'
import { NotifyUtils } from 'src/utils'
import * as Yup from 'yup'
import { ErrorMessage } from '../error-message'

const actionOptions = Object.values(ENotificationAction).map((value) => ({
  label: ENotificationActionText[value],
  value
}))

const yupSchema = Yup.object().shape({
  title: Yup.string().nullable().required('Title is required'),
  body: Yup.string().nullable(), // .required('Body is required'),
  action: Yup.string().nullable().oneOf([null, '', ...Object.values(ENotificationAction)]), // .required('TikTok URL is required'),
  imageUrl: Yup.string().nullable().url('Image URL must be a valid') // .required('Image URL is required'),
})

const defaultFormData = {
  title: '',
  body: '',
  action: '',
  imageUrl: ''
}

export const ModalSendNotification: FC<Omit<
  ComponentProps<typeof ModalFullscreen>,
  'onOk' | 'afterClose'
> & {
  id: ICandidateModel['id']
  // onOk?: () => any
  afterClose?: () => any
  btnProps?: ComponentProps<typeof Button>
}> = ({
  btnProps,
  ...props
}) => {
  const unsubscribe$ = useUnsubscribe()
  const [loading, setLoading] = useState(false)
  const [invisible, setInvisible] = useState<boolean | ICandidateModel>(true)
  const [formData, setFormData] = useState<{
    title?: string
    body?: string
    action?: string
    imageUrl?: string
  }>({ ...defaultFormData })

  const handleChangeInput = useCallback((e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
    e.persist()
    const { name, value } = e.target
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }))
  }, [])

  const { validate, errors, reset } = useValidation({
    data: formData,
    schema: yupSchema
  })

  const save = useCallback(async () => {
    const { isValid } = await validate()
    if (!isValid) {
      return
    }

    setLoading(true)
    from(CandidateApi.sendNotification(props.id, formData))
      .pipe(
        takeUntil(unsubscribe$),
        finalize(() => setLoading(false))
      )
      .subscribe({
        next: () => {
          NotifyUtils.success({ message: 'Notification had been sent' })
          setInvisible(true)
        },
        error: NotifyUtils.handleAxiosError
      })
  }, [props.id, formData, validate, unsubscribe$])

  useDidMountEffect(() => {
    reset()

    if (invisible) {
      setLoading(false)
      setFormData(() => ({ ...defaultFormData }))
      props.afterClose?.()
    }
  }, [invisible])

  return (
    <>
      <Button
        type="primary"
        {...btnProps}
        onClick={() => setInvisible(false)}
      >
        {props.children || 'Create'}
      </Button>

      <ModalFullscreen
        title="Send notification to candidate"
        open={!invisible}
        closable={!loading} // display X icon
        keyboard={false} // disable close on press ESC
        maskClosable={false} // disable close on click outside
        okText="Send"
        cancelText="Cancel"
        onOk={save}
        onCancel={() => setInvisible(true)}
        okButtonProps={{ disabled: loading }}
        cancelButtonProps={{ disabled: loading }}
      >
        {invisible
          ? null
          : (
            <div className="fx fx-column fx-extend gap-2">
              <Form.Item
                required
                label="Title"
                labelAlign="left"
                labelCol={{ flex: '0 0 100px' }}
                className="m-0"
              >
                <Input
                  required
                  readOnly={loading}
                  name="title"
                  placeholder="Title"
                  value={formData.title}
                  status={errors.hasError('title') ? 'error' : undefined}
                  onChange={handleChangeInput}
                />
                <ErrorMessage>{errors.getError('title')}</ErrorMessage>
              </Form.Item>

              <Form.Item
                // required
                label="Body"
                labelAlign="left"
                labelCol={{ flex: '0 0 100px' }}
                className="m-0"
              >
                <Input.TextArea
                  rows={4}
                  // required
                  readOnly={loading}
                  name="body"
                  placeholder="Body"
                  value={formData.body}
                  status={errors.hasError('body') ? 'error' : undefined}
                  onChange={handleChangeInput}
                />
                <ErrorMessage>{errors.getError('body')}</ErrorMessage>
              </Form.Item>

              <Form.Item
                // required
                label="Image URL"
                labelAlign="left"
                labelCol={{ flex: '0 0 100px' }}
                className="m-0"
              >
                <Input
                  // required
                  readOnly={loading}
                  name="imageUrl"
                  placeholder="Image URL"
                  value={formData.imageUrl}
                  status={errors.hasError('imageUrl') ? 'error' : undefined}
                  onChange={handleChangeInput}
                />
                <ErrorMessage>{errors.getError('imageUrl')}</ErrorMessage>
              </Form.Item>

              <Form.Item
                label="Action"
                labelAlign="left"
                labelCol={{ flex: '0 0 100px' }}
                className="m-0"
              >
                <Select
                  allowClear
                  disabled={loading}
                  placeholder="Select action"
                  value={formData.action}
                  options={actionOptions}
                  status={errors.hasError('action') ? 'error' : undefined}
                  onChange={(action) => setFormData((prev) => ({
                    ...prev,
                    action: action ?? null
                  }))}
                />
                <ErrorMessage>{errors.getError('action')}</ErrorMessage>
              </Form.Item>
            </div>
          )}
      </ModalFullscreen>
    </>
  )
}
