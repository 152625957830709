import { IRouterOption } from 'src/router'
import { AI } from './ai'
import { EPaths } from './routes.path'

export enum ERoutes {
  AI = 'AI'
}

export const Routes: IRouterOption[] = [
  {
    path: EPaths.AI,
    component: AI,
    name: ERoutes.AI,
    exact: true,
    meta: {}
  }
]

export const AIModule = {
  ERoutes,
  Routes
}
