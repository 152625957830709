import moment from 'moment'
import { BehaviorSubject } from 'rxjs'

export const TimeZoneService = new (
  class {
    readonly timezoneOffset$ = new BehaviorSubject(this.getTimezoneOffset())
    get timezoneOffset() {
      return this.timezoneOffset$.getValue()
    }

    readonly useUTC$ = new BehaviorSubject<boolean>(false)
    get useUTC() {
      return this.useUTC$.getValue()
    }

    set useUTC(val) {
      this.useUTC$.next(val)
    }

    getTimezoneOffset({
      asMilliseconds = false,
      asSeconds = false,
      asMinutes = true,
      asHours = false,
      asDays = false
    } = {}) {
      const x = new Date()
      const offset = -x.getTimezoneOffset()

      if (asMilliseconds) {
        return offset * 60 * 1000
      }

      if (asSeconds) {
        return offset * 60
      }

      if (asMinutes) {
        return offset
      }

      if (asHours) {
        return offset / 60
      }

      if (asDays) {
        return offset / 60 / 24
      }

      return offset
    }

    format(val?: Parameters<typeof moment>[0], format = 'MMM D, y h:mm A') {
      return moment(val)
        .subtract(this.useUTC ? this.timezoneOffset : 0, 'minutes')
        .format(format)
    }

    formatNoTz(val?: Parameters<typeof moment>[0], format = 'MMM D, y h:mm A') {
      return moment(val).format(format)
    }
  }
)()
