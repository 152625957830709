import axios from 'axios'
import { IPaginationQuery, IUserModel, TAxiosResponsePagination } from 'src/interfaces'

export class CalendarApi {
  static readonly _prefix = '/calendar'

  static users(params: IPaginationQuery = {}): TAxiosResponsePagination<IUserModel> {
    return axios.get(`${CalendarApi._prefix}/users`, { params })
  }
}
