import clsx from 'clsx'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { takeUntil } from 'rxjs'
import { CandidateApi } from 'src/api'
import { InfiniteScroll } from 'src/components'
import { useUnsubscribe } from 'src/hooks'
import { ICandidateModel, IVideoModel } from 'src/interfaces'
import { LoadMoreService } from 'src/services'
import { CandidateVideo } from './video'

interface IProps {
  candidate?: ICandidateModel
  className?: string
}

export const CandidateVideos: FC<IProps> = ({ candidate, className }) => {
  const unsubscribe$ = useUnsubscribe()
  const candidateId = useMemo(() => candidate?.id, [candidate])
  const [videos, setVideos] = useState<IVideoModel[]>([])

  const _loadMoreService = useMemo(
    () => candidateId && new LoadMoreService({
      endpoint: `${CandidateApi._prefix}/${candidateId}/videos`
    }),
    [candidateId]
  )

  const hasMore = useMemo(
    () => Boolean(_loadMoreService && _loadMoreService.hasMore),
    [_loadMoreService]
  )

  const next = useCallback(() => {
    _loadMoreService && _loadMoreService.loadMore({ limit: 100 })
  }, [_loadMoreService])

  useEffect(() => {
    if (_loadMoreService) {
      // _loadMoreService
      //   .loading$
      //   .pipe(takeUntil(unsubscribe$))
      //   .subscribe(setLoading)

      _loadMoreService
        .items$
        .pipe(takeUntil(unsubscribe$))
        .subscribe(setVideos)
    }
  }, [_loadMoreService, unsubscribe$])

  return (
    <div className={clsx('fx fx-column gap-2', className)}>
      <strong>Videos</strong>

      <InfiniteScroll
        className="fx gap-3"
        loader={null}
        scrollThreshold={0.95} // 95% scrollHeight
        dataLength={videos.length}
        next={next}
        hasMore={hasMore}
      >
        {videos.map((item) => (
          <CandidateVideo key={item.id} video={item}/>
        ))}
      </InfiniteScroll>
    </div>
  )
}
