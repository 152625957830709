import { Select, Spin } from 'antd'
import type { SelectProps } from 'antd/es/select'
import debounce from 'lodash/debounce'
import React, { useMemo, useRef, useState } from 'react'
import { ScrapedCompanyApi } from 'src/api/scraped-company'
import { IScrapedCompanyModel } from 'src/interfaces'

export interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'onChange' | 'children'> {
  debounceTimeout?: number
  onChange?: (company: IScrapedCompanyModel) => void
}

export function SearchAndSelectCompany<
  ValueType extends { key?: string; label: React.ReactNode; value: string | number } = any
>({ debounceTimeout = 800, ...props }: DebounceSelectProps<ValueType>) {
  const [fetching, setFetching] = useState(false)
  const [options, setOptions] = useState<CompanyValue[]>([])
  const fetchRef = useRef(0)

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1
      const fetchId = fetchRef.current
      setOptions([])
      setFetching(true)

      fetchCompanyList(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return
        }

        setOptions(newOptions)
        setFetching(false)
      })
    }

    return debounce(loadOptions, debounceTimeout)
  }, [debounceTimeout])

  return (
    <Select
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      showSearch
      notFoundContent={fetching ? <Spin size="small"/> : null}
      {...props}
      onChange={(_, option) => props.onChange?.(option as CompanyValue)}
      options={options}
    />
  )
}

// Usage of DebounceSelect
export interface CompanyValue extends IScrapedCompanyModel {
  label: string
  value: number
}

async function fetchCompanyList(companyName: string): Promise<CompanyValue[]> {
  // TODO: remove pagination here
  return ScrapedCompanyApi.index({ name: companyName, dontCareContact: true, limit: 100 })
    .then((response) => response.data)
    .then((body) =>
      (body.rows || []).map(
        (company) => ({
          ...company,
          label: company.name,
          value: company.id
        })
      )
    )
}
