import { Button, Input, Modal } from 'antd'
import { ComponentProps, FC, SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { QueueJobApi } from 'src/api'
import { useAsRef, useDidMountEffect, useUnsubscribe, useValidation } from 'src/hooks'
import { EQueueName, IQueueJobModel } from 'src/interfaces'
import { EventUtils, NotifyUtils } from 'src/utils'
import * as Yup from 'yup'

const schema = Yup.object().shape({
  campaignId: Yup.number().integer().positive().required('Campaign ID is required')
})

export const DialogQueueCampaignInfo: FC<Omit<
  ComponentProps<typeof Modal>,
  'onOk' | 'afterClose'
> & {
  // onOk?: () => any
  afterClose?: (saved: boolean, doc?: IQueueJobModel) => any
  btnProps?: ComponentProps<typeof Button>
}> = ({
  btnProps,
  ...props
}) => {
  const unsubscribe$ = useUnsubscribe()
  const [loading, setLoading] = useState(false)
  const [invisible, setInvisible] = useState<boolean | IQueueJobModel>(true)
  const [formData, setFormData] = useState<{ campaignId?: number }>({})
  const { validate, errors, reset } = useValidation({
    data: formData,
    schema
  })

  useEffect(() => {
    setInvisible(!props.open)
  }, [props.open])

  const afterCloseRef = useAsRef(props.afterClose)
  useDidMountEffect(() => {
    reset()

    if (invisible) {
      setLoading(false)
      setFormData(() => ({}))

      const isSaved = typeof invisible !== 'boolean'
      afterCloseRef.current?.(isSaved, isSaved ? invisible : undefined)
    }
  }, [invisible])

  const submit = useCallback(async (e: SyntheticEvent) => {
    EventUtils.preventDefault(e)

    const { isValid } = await validate()

    if (!isValid) {
      return
    }

    const promise = QueueJobApi.store({
      queueName: EQueueName.CAMPAIGN_FEED_INFO,
      data: formData
    })

    setLoading(true)
    from(promise)
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          NotifyUtils.handleAxiosError(error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(({ data }) => {
        NotifyUtils.success({ message: 'Create queue job successfully' })
        setInvisible(data)
      })
  }, [formData, unsubscribe$, validate])

  return (
    <>
      <Button
        type="primary"
        {...btnProps}
        onClick={() => setInvisible(false)}
      >
        {props.children || 'Create'}
      </Button>

      <Modal
        title={props.title || 'Create queue fetch CGPT campaign info'}
        open={!invisible}
        closable={!loading} // display X icon
        keyboard={false} // disable close on press ESC
        maskClosable={false} // disable close on click outside
        okText="Submit"
        cancelText="Close"
        okButtonProps={{ disabled: loading }}
        cancelButtonProps={{ disabled: loading }}
        onOk={submit}
        onCancel={() => setInvisible(true)}
      >
        {!invisible && (
          <form onSubmit={submit} className="fx fx-column fx-extend gap-2">
            <Input
              type="number"
              step={1}
              min={0}
              required
              readOnly={loading}
              name="campaignId"
              placeholder="Campaign ID"
              value={formData.campaignId || ''}
              status={errors.hasError('campaignId') ? 'error' : undefined}
              onChange={(e) => setFormData((prev) => ({
                ...prev,
                campaignId: Number(e.target.value)
              }))}
            />
          </form>
        )}
      </Modal>
    </>
  )
}
