import { IAllProps, Editor as TinyMCE } from '@tinymce/tinymce-react'
import { ComponentProps, FC, useEffect, useState } from 'react'
import { EEnv } from 'src/constants'

const CustomEditor = TinyMCE as unknown as FC<IAllProps>

const EDITOR_INIT: IAllProps['init'] = Object.freeze({
  min_height: 250,
  max_height: 500,
  menubar: false,
  font_size_formats: '8px 9px 10px 11px 12px 13px 14px 15px 16px 17px 18px 19px 20px 21px 22px 23px 24px 25px 26px 27px 28px 29px 30px 31px 32px 33px 34px 35px 36px 37px 38px 39px 40px 41px 42px 43px 44px 45px 46px 47px 48px 49px 50px 51px 52px 53px 54px 55px 56px',
  plugins: [
    'advlist',
    'anchor',
    'autolink',
    'autoresize',
    'autosave',
    'charmap',
    'code',
    'fullscreen',
    'help',
    'image',
    'insertdatetime',
    'link',
    'lists',
    'media',
    'preview',
    'save',
    'searchreplace',
    'table',
    'visualblocks',
    'wordcount'
  ],
  toolbar: [
    'insertfile undo redo',
    'blocks fontsize bold italic underline strikethrough',
    // 'forecolor backcolor',
    // 'superscript subscript',
    'alignleft aligncenter alignright alignjustify',
    'bullist numlist outdent indent lineheight',
    // 'formatselect',
    // 'removeformat',
    'link image media',
    'code preview phonepreview fullscreen',
    'help'
  ].join(' | '),
  content_css: '/css/tinymce.css'
  // content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; line-height: 1.5 } h1 { font-size: 32px; } h2 { font-size: 24px; } h3 { font-size: 18px; }'
})

interface IProps {
  id?: string
  init?: IAllProps['init']
  disabled?: boolean
  initialValue?: string
  onChange?: (value: string) => void
}

export const Editor: FC<Omit<ComponentProps<typeof CustomEditor>, keyof IProps> & IProps> = ({
  init,
  initialValue,
  onChange,
  ...props
}) => {
  const [value, setValue] = useState(initialValue ?? '')

  useEffect(() => {
    setValue(initialValue ?? '')
  }, [initialValue])

  return (
    <CustomEditor
      {...props}
      apiKey={EEnv.REACT_APP_TINYMCE_KEY}
      init={{
        ...EDITOR_INIT,
        ...init
      }}
      initialValue={initialValue}
      value={value}
      onEditorChange={(val, editor) => {
        setValue(val)
        props.onEditorChange?.(val, editor)
      }}
      onBlur={(...args) => {
        onChange?.(value)
        props.onBlur?.(...args)
      }}
    />
  )
}
