import axios from 'axios'
import { IMultimediaModel, TAxiosResponseData } from 'src/interfaces'
import { IS3PresignedMultipartResult, S3Utils } from 'src/utils'

export class MultimediaApi {
  static readonly _prefix = '/multimedia'

  static presignedMultipart(params: {
    size: number
    type: string
    filename: string
  }): TAxiosResponseData<IS3PresignedMultipartResult> {
    return axios.get(`${MultimediaApi._prefix}/presigned-multipart`, { params })
  }

  static abortPresignedMultipartUpload(params: Omit<IS3PresignedMultipartResult, 'parts'>): TAxiosResponseData<void> {
    return axios.delete(`${MultimediaApi._prefix}/presigned-multipart`, { params })
  }

  static completePresignedMultipartUpload(payload: Omit<IS3PresignedMultipartResult, 'parts'> & { parts: Awaited<ReturnType<typeof S3Utils.presignedMultipartUpload>>}): TAxiosResponseData<IMultimediaModel> {
    return axios.post(`${MultimediaApi._prefix}/presigned-multipart`, payload)
  }

  static async upload(payload: { file: File }) {
    const presignedParams = {
      size: payload.file.size,
      type: payload.file.type,
      filename: payload.file.name
    }

    // // always use multipart upload for Tagging support
    // if (payload.file.size <= UPLOAD_CHUNK_SIZE) {
    //   const { data: signedResult } = await MultimediaApi.presigned(presignedParams)
    //   await S3Utils.presignedUpload(signedResult, payload.file)
    //   return signedResult.fields.key
    // }

    const { data: signedResult } = await MultimediaApi.presignedMultipart(presignedParams)
    const parts = await S3Utils.presignedMultipartUpload(signedResult, payload.file)
    return MultimediaApi.completePresignedMultipartUpload({ ...signedResult, parts })
  }
}
