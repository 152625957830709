import axios from 'axios'
import { ICampaignModel, IPaginationQuery, TAxiosResponseData, TAxiosResponsePagination } from 'src/interfaces'

export class ArchivedCampaignApi {
  static readonly _prefix = '/campaigns/archived'

  static index(params: IPaginationQuery = {}): TAxiosResponsePagination<ICampaignModel> {
    return axios.get(`${ArchivedCampaignApi._prefix}`, { params })
  }

  static restore(id: ICampaignModel['id']): TAxiosResponseData<void> {
    return axios.patch(`${ArchivedCampaignApi._prefix}/${id}/restore`)
  }
}
