import { Modal } from 'antd'
import { AxiosError } from 'axios'
import { FC, ReactNode, useMemo, useState } from 'react'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { VideoApi } from 'src/api'
import { useCallbackUnsubscribe, useDidMountEffect } from 'src/hooks'
import { IHashtagModel, IVideoModel } from 'src/interfaces'
import { NotifyUtils } from 'src/utils'
import { SelectHashtag } from '../select-hashtag'

interface IProps {
  title?: string
  video: IVideoModel
  onOk?: (video: IVideoModel) => any
  children?: ReactNode
}

export const ModalVideoHashtags: FC<IProps> = ({
  video,
  ...props
}) => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState(video.hashtags || [])
  const valueIds = useMemo(
    () => value.map((hashtag) => hashtag.id).sort() || [],
    [value]
  )
  const currentIds = useMemo(
    () => video.hashtags?.map((hashtag) => hashtag.id).sort() || [],
    [video.hashtags]
  )

  useDidMountEffect(() => {
    setValue(video.hashtags || [])
  }, [open, video.hashtags])

  const onSubmit = useCallbackUnsubscribe((unsubscribe$) => {
    if (!video || currentIds.sort().join('.') === valueIds.sort().join('.')) {
      props.onOk?.(video)
      return setOpen(false)
    }

    setLoading(true)
    from(VideoApi.updateHashtags(video.id, { hashtagIds: valueIds }))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error: AxiosError) => {
          NotifyUtils.handleAxiosError(error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(() => {
        video.hashtags = value
        props.onOk?.(video)
        setOpen(false)
      })
  }, [video, value, currentIds, valueIds, props.onOk])

  return (
    <>
      <span onClick={() => setOpen(true)}>
        {props.children || 'Add'}
      </span>

      {open && (
        <Modal
          open
          centered
          closable={!loading}
          maskClosable
          destroyOnClose
          title={props.title || 'Edit Video Hashtags'}
          okButtonProps={{ disabled: loading }}
          cancelButtonProps={{ disabled: loading }}
          onOk={onSubmit}
          onCancel={() => setOpen(false)}
          afterClose={() => setOpen(false)}
        >
          <div className="fx fx-column">
            <SelectHashtag
              multiple
              isPlaylist
              placeholder="Select video hashtags"
              currentHashtags={video.hashtags}
              disabled={loading}
              value={valueIds}
              onChange={(values, options) => setValue(
                (options as { hashtag: IHashtagModel }[])
                  .filter((option) => option.hashtag?.id)
                  .map((option) => option.hashtag)
              )}
            />
          </div>
        </Modal>
      )}
    </>
  )
}
