import { Button, Modal, Table } from 'antd'
import { ComponentProps, FC, useEffect, useState } from 'react'
import { EMPTY, Subject, catchError, finalize, from, takeUntil } from 'rxjs'
import { CampaignApi } from 'src/api'
import { useDidMountEffect } from 'src/hooks'
import { ICampaignModel, ICandidateModel } from 'src/interfaces'
import { NotifyUtils } from 'src/utils'
import { renderColumns } from './columns'

export const DialogVideoCampaigns: FC<Omit<
  ComponentProps<typeof Modal>,
  'onOk' | 'afterClose'
> & {
  videoId: number
  // onOk?: () => any
  afterClose?: () => any
  btnProps?: ComponentProps<typeof Button>
}> = ({
  btnProps,
  ...props
}) => {
  const [loading, setLoading] = useState(false)
  const [campaigns, setCampaigns] = useState<ICampaignModel[]>([])
  const [invisible, setInvisible] = useState<boolean | ICandidateModel>(true)

  useEffect(() => {
    setInvisible(!props.open)
  }, [props.open])

  useDidMountEffect(() => {
    if (invisible) {
      props.afterClose?.()
    }
  }, [invisible])

  useEffect(() => {
    if (invisible || !props.videoId) {
      return
    }

    const unsubscribe$ = new Subject<void>()
    const params = {
      limit: 100,
      videoId: props.videoId
    }
    setLoading(true)
    from(CampaignApi.index(params))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          NotifyUtils.handleAxiosError(error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(({ data }) => {
        setCampaigns(data.rows || [])
      })
    return () => {
      unsubscribe$.next()
      unsubscribe$.complete()
    }
  }, [invisible, props.videoId])

  return (
    <>
      <Button
        type="primary"
        {...btnProps}
        onClick={() => setInvisible(false)}
      >
        {props.children || 'View Campaigns'}
      </Button>

      <Modal
        title={props.title || 'Campaigns'}
        open={!invisible}
        closable={!loading} // display X icon
        keyboard={false} // disable close on press ESC
        maskClosable={false} // disable close on click outside
        okText="OK"
        cancelText="Close"
        okButtonProps={{ disabled: loading }}
        cancelButtonProps={{ disabled: loading }}
        onOk={() => setInvisible(true)}
        onCancel={() => setInvisible(true)}
        width="max(900px, 40vw)"
      >
        {!invisible && (
          <div className="fx fx-column fx-extend gap-2">
            <Table
              className="fx-extend"
              rowKey="id"
              loading={loading}
              columns={renderColumns()}
              dataSource={campaigns}
              pagination={false}
            />
          </div>
        )}
      </Modal>
    </>
  )
}
